import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Dimmer, Loader,Pagination, Table } from 'semantic-ui-react'
import { CommonNotify } from '../../common/CommonNotify'
export default function AuditLog() {
  const [auditLogList, setAuditLogList] = useState([])
  const [loader, setLoader] = useState(false)
  const [totalPages, setTotalPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  useEffect(() => {
    handleGetAuditLog()
  }, [])

  const handleGetAuditLog = async (page) => {
    setLoader(true)
    const userId = localStorage.getItem('id')
    let url = ''
    if (userId) {
      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/account-activities?user_id=${userId}&page=${pageNumber}`
    } else {
      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/account-activities?page=${page}`
    }
    await axios
    .get(url)
    .then(res => {
      setAuditLogList(res?.data?.data?.data || [])
      setTotalPages(res?.data?.data?.pagination?.total_pages)
      setLoader(false)
    })
      .catch(err => {
        setLoader(false)
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Somethign went to wrong!'
        )
      })
  }
  const onPageChange = (e, a) => {
    setPageNumber(a.activePage)
      
    handleGetAuditLog(a.activePage)
     
    
  }
  return (
    <div className="history-campaign">
      <Dimmer active={loader} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      <h3 className="mb-4">Audit Logs</h3>
      <div>
        <Table>
          <Table.Header style={{ padding: '12px 15px' }}>
            <Table.Row>
            <Table.HeaderCell>Log ID</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              {/* <Table.HeaderCell>Log Type</Table.HeaderCell> */}
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body style={{ padding: '10px 12px' }}>
            {auditLogList &&
              auditLogList?.length > 0 &&
              auditLogList?.map((list, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell style={{width:"250px"}}>{list?.id || '-'}</Table.Cell>
                    <Table.Cell style={{width:"150px"}}>{list?.type || '-'}</Table.Cell>
                    {/* <Table.Cell style={{width:"120px"}}> {list?.log_type || '-'}</Table.Cell> */}
                    <Table.Cell style={{width:"250px"}}> {list?.log?.category || '-'}</Table.Cell>
                    <Table.Cell>{list?.log?.details || '-'}</Table.Cell>
                    <Table.Cell style={{width:"200px"}}>
                      {list?.created_at &&
                       `${ moment(list?.created_at).format('DD MMM YYYY hh:mm')}  ${moment.tz.guess()}`}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          </Table.Body>
        </Table>
        {totalPages > 1 && (
                                  <Pagination
                                    defaultActivePage={pageNumber}
                                    totalPages={totalPages}
                                    onPageChange={onPageChange}
                                  />
                                )}
      </div>
    </div>
  )
}
