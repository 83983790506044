import React from 'react'
import { Input, Dropdown, TextArea, Button } from 'semantic-ui-react'

import plusIcon from '../../assets/images/plusIcon.png'
import closeIcon from '../../assets/images/closeGray.png'
import zoomImg from '../../assets/images/Zoom.png'

const options = [
  {
    key: 'Jenny Hess',
    text: 'Jenny Hess',
    value: 'Jenny Hess',
    image: { avatar: true, src: zoomImg }
  },
  {
    key: 'Elliot Fu',
    text: 'Elliot Fu',
    value: 'Elliot Fu',
    image: { avatar: true, src: zoomImg }
  }
]

const CreateMeetingType = ({ createMeetingType, setMeetingType }) => {
  return (
    <div
      className={
        createMeetingType == true ? 'details-popup active' : 'details-popup'
      }
    >
      <div className="details-title">
        <p>Create Meeting type</p>
        <img
          className=""
          src={closeIcon}
          onClick={() => setMeetingType(false)}
          width="17"
        />
      </div>
      <div className="mettingSubMain">
        <div className="scheduleHeadDetail">
          <div className="schedulename">
            <label>Meeting name</label>
            <Input placeholder="New meerung type" />
          </div>
          <div className="scheduledate">
            <label>Duration</label>
            <div className="btnduration">
              <Button className="btnSave">10 min</Button>
              <Button className="btnSave">15 min</Button>
              <Button className="btnSave">20 min</Button>
              <Button className="btnSave">30 min</Button>
              <Button className="btnSave">60 min</Button>
            </div>
          </div>
          <div className="scheduledate">
            <label>Description</label>
            <TextArea placeholder="Host invited you to a meeting. Join the meeting through this meeting link:  You can connect your audio via browser or phone. " />
          </div>
        </div>
        <div className="memberMainWrap">
          <div className="contact-card-boxes guestWrap">
            <p className="card-boxes-title">MEMBERS</p>

            <div className="createdropdown">
              <Dropdown
                placeholder="Linda Crowford"
                fluid
                selection
                options={options}
              />
            </div>
            <div className="addGuest">
              <img className="ui medium  image" src={plusIcon} alt="" width="17" />
              <label>Add Member</label>
            </div>
          </div>
          <div className="contact-card-boxes guestWrap">
            <p className="card-boxes-title">LOCATION</p>

            <div className="senddropdown">
              <Dropdown placeholder="Zoom" fluid selection options={options} />
            </div>
          </div>
        </div>
        <div className="scheduleButton">
          <Button className="btnCancle" onClick={() => setMeetingType(false)}>
            Cancel
          </Button>
          <Button className="btnSave">Save</Button>
        </div>
      </div>
    </div>
  )
}
export default CreateMeetingType
