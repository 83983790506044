import { apiPost, apiGet } from '.'
import { API_URL } from './constant'

export const getUserIntegrationsApi = () =>
  apiGet(`${API_URL}/integration/user-integrations`)

export const updateCalendarSettingsApi = (id, payload) => {
  return apiPost(
    `${API_URL}/integration/update-calendar-settings?id=${id}`,
    payload
  )
}

export const getGoogleCalendarURLApi = () =>
  apiGet(`${API_URL}/integration/generate-google-calendar-url`)

export const disConnectGoogleCalendarApi = () =>
  apiPost(`${API_URL}/integration/disconnect-google-calendar`)
