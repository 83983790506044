import axios from 'axios'
import _ from 'lodash'

const addNotification = async (data, notification, keyName, checkKeyName) => {
  const manipulateData = data.map(item => {
    let data = {}
    data[keyName ? item[keyName] : item.key] = checkKeyName
      ? item[checkKeyName]
        ? 1
        : 0
      : item.checked
      ? 1
      : 0
    data['notification'] = notification
    return data
  })

  let singleObject = _.extend.apply({}, manipulateData)
  const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/notifications`
  return await axios.put(URL, singleObject)
}

export const addInappNotification = async (data, keyName, checkKeyName) => {
  const manipulateData = data.map(item => {
    let data = {}
    data[keyName ? item[keyName] : item.key] = checkKeyName
      ? item[checkKeyName]
        ? 1
        : 0
      : item.checked
      ? 1
      : 0
    return data
  })

  let singleObject = _.extend.apply({}, manipulateData)
  const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/in-app-notification-actions`
  return await axios.post(URL, singleObject)
}

export default addNotification
