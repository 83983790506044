import React, { useState } from 'react'
import {
  Dimmer,
  Loader,
  Input,
  Modal,
  TextArea
} from 'semantic-ui-react'
import CommonButtons from '../common/CommonButtons'
import close from '../assets/images/close.png'
import { CommonNotify } from '../common/CommonNotify'
import Axios from 'axios'
import 'react-datetime/css/react-datetime.css'
import Datetime from 'react-datetime'

export default function SavedRepliesModal({
  setOpenReplayModal,
  handleSave,
  schedule,
  templateList,
  getLeadTemplate,
  timeValue,
  scheduleInfo
}) {
  const [formValue, setFormValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [addNew, setAddNew] = useState(false)

  const handleSaveTemplate = async () => {
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-response`,
      payload = {
        type: 'message',
        target: 'Lead',
        title: '',
        description: ''
      }

    payload.title = formValue?.title
    payload.description = formValue?.message
    setLoading(true)
    await Axios.post(url, payload)
      .then(res => {
        getLeadTemplate()
        handleSave(payload)
        setOpenReplayModal(false)
        CommonNotify('Created successfully')
      })
      .catch(err => {
        setLoading(false)
        CommonNotify(
          err?.response?.data?.errors[0] || 'Something went to wrong!'

        )
      })
  }

  const handleSaveTime = e => {
    timeValue(e)
  }

  return (
    <div>
      {' '}
      <Modal
        className="local-number-modal"
        open={true}
        onClose={() => setOpenReplayModal(false)}
      >
        <div
          style={{
            maxHeight: '700px',
            minHeight: schedule ? '210px' : '400px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Dimmer active={loading}>
            <Loader />
          </Dimmer>
          <div
            style={{
              borderBottom: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: 'grey',
              padding: 15,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div className="d-flex">
              <h6
                style={{
                  fontSize: '17px',
                  fontWeight: '600',
                  margin: '0 auto',
                  borderBottomWidth: 1,
                  borderBottomColor: 'gray'
                }}
              >
                {schedule === true ? 'Schedule Your Replay' : 'Saved Replies'}
              </h6>
              {schedule !== true && (
                <span className="ml-2" onClick={() => setAddNew(true)}>
                  <a style={{ color: '#007bff' }}>Add New Reply</a>
                </span>
              )}
            </div>
            <div
              className="close-icon"
              onClick={() => setOpenReplayModal(false)}
            >
              <img src={close} alt="close" />
            </div>
          </div>
          <Modal.Content>
            {addNew ? (
              <div
                className="modal-content px-2 py-2"
                style={{ textAlign: 'inherit' }}
              >
                <label className="mt-3 mb-3">Title*</label>
                <div>
                  <Input
                    placeholder="Title"
                    //   style={{width:"454px"}}
                    fluid
                    onChange={e =>
                      setFormValue({
                        ...formValue,
                        title: e?.target?.value
                      })
                    }
                    value={formValue?.title || ''}
                  />
                </div>
                <label className="mb-2 mt-4">Message*</label>
                <div className="mt-2">
                  <TextArea
                    name="message"
                    className="mb-4"
                    value={formValue?.message || ''}
                    onChange={e =>
                      setFormValue({
                        ...formValue,
                        message: e?.target?.value
                      })
                    }
                    rows={8}
                    placeholder="Message..."
                  />
                  <br />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                    paddingTop: 10,
                    paddingBottom: 10
                  }}
                >
                  <CommonButtons
                    onClick={() => setOpenReplayModal(false)}
                    type="button"
                    content="Cancel"
                    background="grey"
                    style={{
                      borderRadius: 0,
                      backgroundColor: '#e0e1e2',
                      color: '#5a5a5a'
                    }}
                  />

                  <CommonButtons
                    loading={loading}
                    onClick={handleSaveTemplate}
                    type="button"
                    content="Save"
                    background="blue"
                    style={{
                      marginLeft: 20,
                      marginRight: 20,
                      borderRadius: 0,
                      backgroundColor: '#1071eb',
                      color: '#ffffff'
                    }}
                  />
                </div>
              </div>
            ) : schedule !== true ? (
              <div>
                {templateList && templateList?.length > 0 ? (
                  <ul className="template-list">
                    {templateList?.map((list, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => {
                            return handleSave(list), setOpenReplayModal(false)
                          }}
                        >
                          {list?.title}
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  <div>You don't have any saved replay.</div>
                )}
              </div>
            ) : null}
            {schedule === true && (
              <div
                className="modal-content px-2 py-2"
                style={{ textAlign: 'inherit' }}
              >
                <label className="mt-3 mb-3">Select Your Date Time</label>
                <div>
                  <Datetime
                    value={scheduleInfo}
                    onChange={e => handleSaveTime(e)}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                    paddingTop: 10,
                    paddingBottom: 10
                  }}
                >
                  <CommonButtons
                    onClick={() => setOpenReplayModal(false)}
                    type="button"
                    content="Cancel"
                    background="grey"
                    style={{
                      borderRadius: 0,
                      backgroundColor: '#e0e1e2',
                      color: '#5a5a5a'
                    }}
                  />

<CommonButtons
                    loading={loading}
                    onClick={() => setOpenReplayModal()}
                    type="button"
                    content={schedule === true ? 'Schedule Message' :"Save"}
                    background="blue"
                    style={{
                      marginLeft: 20,
                      marginRight: 20,
                      borderRadius: 0,
                      backgroundColor: '#1071eb',
                      color: '#ffffff'
                    }}
                  />
                </div>
              </div>
            )}
          </Modal.Content>
        </div>
      </Modal>
    </div>
  )
}
