import axios from 'axios'
import React, { Component } from 'react'
//redux
import { connect } from 'react-redux'
import { Dimmer, Loader, Table } from 'semantic-ui-react'
import {
  getNotificationsActions,
  getNotificationsWidget
} from '../../actions/settings'
import CommonCheckbox from '../../common/CommonCheckbox'
import { CommonNotify } from '../../common/CommonNotify'
import addNotification, {
  addInappNotification
} from '../../config/addNotification'
class NotificationSettings extends Component {
  state = {
    activeIndexes: [0],
    isLoading: false,
    //Notification Data
    widget: 0,
    data: {
      checkboxForMobile: false,
      checkboxCallNotif: false,
      selectConvoType: '',
      selectConvoSound: '',
      inputBillingEmail: '',
      checkboxBrowserDeiplayNotif: false,
      checkboxDesktopNotifOnScreen: false,
      selectAdvanceSound: '',
      checkboxNotifRepeat: false,
      selectEmailNotif: '',
      nodeCheckboxEmail: [],
      nodeCheckboxDesktop: [],
      nodeCheckboxMobile: []
    },
    nodeCheckboxEmail: {
      check_box_1: true,
      check_box_2: false,
      check_box_3: false,
      check_box_4: true
    },
    settingsData: null,

    allCheckboxLists: [
      {
        label: 'Added To Schedule Calls',
        SMSKey: 'schedule_call_added_notify_sms',
        webKey: false,
        emailKey: false,
        appKey: false,
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Lead Missed',
        SMSKey: 'lead_missed_notify_sms',
        webKey: false,
        emailKey: false,
        appKey: false,
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Lead Assigned',
        SMSKey: 'lead_assigned_notify_sms',
        webKey: 'assign_to_lead_browser_notify',
        emailKey: 'lead_assigned_notify',
        appKey: 'lead_assigned',
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Mentioned In Lead',
        SMSKey: 'mention_notify_sms',
        webKey: false,
        emailKey: false,
        appKey: false,
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Schedule Calls',
        SMSKey: false,
        webKey: false,
        emailKey: 'schedule_call_added_notify',
        appKey: false,
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Missed Call',
        SMSKey: false,
        webKey: false,
        emailKey: 'lead_missed_notify',
        appKey: 'call_missed',
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Share Access In Lead',
        SMSKey: false,
        webKey: 'desktop_mention_notify',
        emailKey: 'mention_notify',
        appKey: false,
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'New task Assigned',
        SMSKey: false,
        webKey: false,
        emailKey: 'new_task_assigned_mail_notify',
        appKey: false,
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Lead Tagged',
        SMSKey: false,
        webKey: false,
        emailKey: false,
        appKey: 'lead_tagged',
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Note Tagged',
        SMSKey: false,
        webKey: false,
        emailKey: false,
        appKey: 'note_tagged',
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Meeting Tagged',
        SMSKey: false,
        webKey: false,
        emailKey: false,
        appKey: 'meeting_booked',
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Task Assigned',
        SMSKey: false,
        webKey: false,
        emailKey: false,
        appKey: 'task_assigned',
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      },
      {
        label: 'Upcoming Meeting',
        SMSKey: false,
        webKey: false,
        emailKey: false,
        appKey: 'upcoming_meeting',
        SMSChecked: 0,
        webChecked: 0,
        emailChecked: 0,
        appChecked: 0
      }
    ]
  }

  onChangeInput = e => {
    const { name, value } = e.target
    const { data } = this.state
    data[name] = value

    this.setState({ data })
  }

  onChangeCheckbox = e => {
    const { name } = e.target.parentNode.querySelector('.hidden')
    const { data } = this.state
    data[name] = !data[name]

    this.setState(data)
  }

  componentDidUpdate(prevProps) {
    if (this.props.notificationsWidgets !== prevProps.notificationsWidgets) {
      this.setsettingData(this.props.notificationsWidgets.data)
    }
    if (this.props.notificationsActions !== prevProps.notificationsActions) {
      this.setState(
        { settingsData: this.props.notificationsActions.data },
        () => {
          this.fetchInappNotification(this.props.notificationsActions.data)
        }
      )
    }
  }

  componentDidMount = () => {
    if (this.props.notificationsWidgets) {
      this.setsettingData(this.props.notificationsWidgets.data)
      this.setState({ isLoading: false })
    } else {
      this.props.getNotificationsWidget()
    }

    if (this.props.notificationsActions) {
      this.setState(
        { settingsData: this.props.notificationsActions.data },
        () => {
          this.fetchInappNotification(this.props.notificationsActions.data)
        }
      )
    } else {
      this.props.getNotificationsActions()
    }
    //this.fetchWidget()
    //this.fetchInappNotiSetting()
  }

  fetchWidget = () => {
    this.setState({ isLoading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/notifications`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          this.setsettingData(res.data.data)
          this.setState({ isLoading: false })
        }
      })
      .catch(er => {
        this.setState({ isLoading: false })
        //CommonNotify('Cant Fetch Widget Setting')
      })
  }

  fetchNotiSetting = async () => {
    this.setState({ isLoading: true })

    const w_id = await localStorage.getItem('widget_id')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-notification?widget_id=${w_id}`

    axios
      .get(url)
      .then(res => {
        this.setState({ isLoading: false })
        if (res.data.data) {
          this.setState({ settingsData: res.data.data[0] }, () => {
            this.setsettingData()
          })
        }
      })
      .catch(er => {
        this.setState({ isLoading: false })
        //CommonNotify('Cant Fetch Widget Notifications')
      })
  }

  fetchInappNotiSetting = async () => {
    this.setState({ isLoading: true })

    const w_id = await localStorage.getItem('widget_id')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/in-app-notification-actions`

    axios
      .get(url)
      .then(res => {
        this.setState({ isLoading: false })
        if (res.data.data) {
          this.setState({ settingsData: res.data.data }, () => {
            this.fetchInappNotification(res.data.data)
          })
        }
      })
      .catch(er => {
        this.setState({ isLoading: false })
        //CommonNotify('Cant Fetch Widget Notifications')
      })
  }

  smsSettings = data => {
    let checkSms = [...this.state.allCheckboxLists]
    const getKeys = Object.keys(data)
    checkSms.map((row, index, rows) => {
      if (getKeys.map(item => item === row.SMSKey) && data[row.SMSKey] === 1) {
        checkSms[index].SMSChecked = 1
        return
      }
    })
    this.setState({ allCheckboxLists: checkSms })
  }

  webSettings = data => {
    let checkWebNotification = [...this.state.allCheckboxLists]

    const getKeys = Object.keys(data)
    checkWebNotification.map((row, index, rows) => {
      if (getKeys.map(item => item === row.webKey) && data[row.webKey] === 1) {
        checkWebNotification[index].webChecked = 1
        return
      }
    })
    this.setState({ allCheckboxLists: checkWebNotification })
  }

  mailSettings = data => {
    let checkEmail = [...this.state.allCheckboxLists]
    const getKeys = Object.keys(data)
    checkEmail.map((row, index, rows) => {
      if (
        getKeys.map(item => item === row.emailKey) &&
        data[row.emailKey] === 1
      ) {
        checkEmail[index].emailChecked = 1
        return
      }
    })
    this.setState({ allCheckboxLists: checkEmail })
  }

  fetchInappNotification = data => {
    let check = [...this.state.allCheckboxLists]
    const getKeys = Object.keys(data)
    check.map((row, index, rows) => {
      if (getKeys.map(item => item === row.appKey) && data[row.appKey] === 1) {
        check[index].appChecked = 1
        return
      }
    })
    this.setState({ allCheckboxLists: check })
  }

  setsettingData = data => {
    this.mailSettings(data.email)

    this.smsSettings(data.sms)

    this.webSettings(data.desk)
  }

  onChangeNodeCheckbox = value => {}

  onChangeSelectInApp = (e, val, key, ch) => {
    this.setState({ isLoading: true })

    let temp_inapp = [...this.state.allCheckboxLists]
    temp_inapp[val].appChecked = !key
    this.setState({ allCheckboxLists: temp_inapp })
    const data = temp_inapp
    addInappNotification(data, 'appKey', 'appChecked')
      .then(res => {
        if (res) {
          this.setState({ isLoading: false })
          CommonNotify('update successfully', 'success')
          this.fetchWidget()
        }
      })
      .catch(err => {
        const errors = { ...err }
        if (errors.response.data.errors.length) {
          this.setState({ isLoading: false })
          CommonNotify(errors.response.data.errors[0])
          this.fetchWidget()
        }
      })
  }

  onChangeSelect = (e, val, key, ch, type) => {
    if (type === 'email') {
      this.setState({ isLoading: true })
      let temp_mail = [...this.state.allCheckboxLists]
      temp_mail[val].emailChecked = !key
      this.setState({ allCheckboxLists: temp_mail })
      const data = temp_mail
      addNotification(data, 'email', 'emailKey', 'emailChecked')
        .then(res => {
          if (res) {
            this.setState({ isLoading: false })
            CommonNotify('update successfully', 'success')
            this.fetchWidget()
          }
        })
        .catch(err => {
          const errors = { ...err }
          if (errors.response.data.errors.length) {
            this.setState({ isLoading: false })
            CommonNotify(errors.response.data.errors[0])
            this.fetchWidget()
          }
        })
    } else if (type === 'sms') {
      this.setState({ isLoading: true })
      let temp_sms = [...this.state.allCheckboxLists]
      temp_sms[val].SMSChecked = !key
      this.setState({ allCheckboxLists: temp_sms })
      const data = temp_sms
      addNotification(data, 'sms', 'SMSKey', 'SMSChecked')
        .then(res => {
          if (res) {
            this.setState({ isLoading: false })
            CommonNotify('update successfully', 'success')
            this.fetchWidget()
          }
        })
        .catch(err => {
          const errors = { ...err }
          if (errors.response.data.errors.length) {
            this.setState({ isLoading: false })
            CommonNotify(errors.response.data.errors[0])
            this.fetchWidget()
          }
        })
    } else if (type === 'web') {
      this.setState({ isLoading: true })
      let temp_web = [...this.state.allCheckboxLists]
      temp_web[val].webChecked = !key
      this.setState({ allCheckboxLists: temp_web })
      const data = temp_web
      addNotification(data, 'desktop', 'webKey', 'webChecked')
        .then(res => {
          if (res) {
            this.setState({ isLoading: false })
            CommonNotify('update successfully', 'success')
            this.fetchWidget()
          }
        })
        .catch(err => {
          const errors = { ...err }
          if (errors.response.data.errors.length) {
            this.setState({ isLoading: false })
            CommonNotify(errors.response.data.errors[0])
            this.fetchWidget()
          }
        })
    } else {
      if (val == 'leave_your_feedback_message') {
        let temp_sms = [...this.state.allCheckboxLists]
        temp_sms[val].SMSChecked = !key
        this.setState({ allCheckboxLists: temp_sms })
      }
    }
  }

  render() {
    return (
      <>
        <Dimmer active={this.state.isLoading}>
          <Loader></Loader>
        </Dimmer>
        <div className="account-settings">
          <div className="holder-notification">
            <form method="" action="">
              <div className="holder-checkboxes notification_detail">
                <p className="title-label">
                  Set notifications which you would like to recieve
                </p>

                {
                  <Table
                    className=""
                    style={{
                      border: '1px solid #f4f4f4 !important',
                      width: '50%'
                    }}
                  >
                    <Table.Header style={{ padding: '12px 15px' }}>
                      <Table.Row>
                        <Table.HeaderCell>Notify Me About</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>SMS</Table.HeaderCell>
                        <Table.HeaderCell>Web</Table.HeaderCell>
                        <Table.HeaderCell>InApp</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body style={{ padding: '10px 12px' }}>
                      {this.state.allCheckboxLists?.map((list, index) => {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell className="title-notification">
                              {list?.label}
                            </Table.Cell>
                            <Table.Cell
                              style={{ width: '100px' }}
                              className="text-center"
                            >
                              {list?.emailKey && (
                                <CommonCheckbox
                                  onChange={this.onChangeSelect.bind(this)}
                                  index={index}
                                  text={''}
                                  value={list?.emailKey}
                                  checked={list?.emailChecked}
                                  data={list}
                                  type="email"
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell
                              style={{ width: '100px' }}
                              className="text-center"
                            >
                              {list?.SMSKey && (
                                <CommonCheckbox
                                  onChange={this.onChangeSelect.bind(this)}
                                  index={index}
                                  text={''}
                                  value={list?.SMSKey}
                                  checked={list?.SMSChecked}
                                  data={list}
                                  type="sms"
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell
                              style={{ width: '100px' }}
                              className="text-center"
                            >
                              {list?.webKey && (
                                <CommonCheckbox
                                  onChange={this.onChangeSelect.bind(this)}
                                  index={index}
                                  text={''}
                                  value={list?.webKey}
                                  checked={list?.webChecked}
                                  data={list}
                                  type="web"
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell
                              style={{ width: '100px' }}
                              className="text-center"
                            >
                              {list?.appKey && (
                                <CommonCheckbox
                                  onChange={this.onChangeSelectInApp.bind(this)}
                                  index={index}
                                  text={''}
                                  value={list?.appKey}
                                  checked={list?.appChecked}
                                  data={list}
                                />
                              )}
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                }
              </div>
            </form>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  notificationsWidgets: state.settings.notificationsWidgets,
  notificationsActions: state.settings.notificationsActions
})

export default connect(mapStateToProps, {
  getNotificationsWidget,
  getNotificationsActions
})(NotificationSettings)
