import React, { useState } from 'react'

import {
  Button,
  Dropdown,
  Header,
  Modal,
  Input  
} from 'semantic-ui-react'


import CommonButtons from './CommonButtons'

const CreateCallDialerModal = ({ open, isModalClose, dropDownData, onChangeRequest }) => {
  const [selectedNo, setSelectedNo] = useState("")
  return (
    <Modal
      className="send-message-modal"
      onClose={isModalClose}
      closeIcon
      open={open}
      size="tiny"      
    >
      <Header content="Create Call" />
      <Modal.Content  style={{ padding: "30px" }}>
        <div className="send-message__dropdown">
          <label>Source No *</label>
          <Dropdown
            name="from"
            placeholder="Select Number"
            selection
            options={dropDownData}
            onChange={(e, data) =>
                onChangeRequest( data.value )
            }
          />
        </div>
       
       
      </Modal.Content>
      <Modal.Actions className="send-message__btn">
        <Button onClick={isModalClose} className="send-message__btn-grey">
          close
        </Button>
        <CommonButtons
          content="Save"
          btnClass="send-message__btn-blue"
          background="#007bff"
          style={{ color: 'white' }}
          onClick={e => onChangeRequest(selectedNo)}
        />
      </Modal.Actions>
    </Modal>
  )
}
export default CreateCallDialerModal
