import React, { useState } from 'react'
import { Button, Modal, Radio } from 'semantic-ui-react'

import closeIcon from '../../assets/images/closecontact.png'

const OwnerModel = ({ fetchId, assignedPopUp, setAssigned, selected }) => {
 
  const [radioState] = useState('yes')
  const owners = localStorage.getItem('first_name')

  return (
    <>
      <Modal open={assignedPopUp} className="assignModal">
        <div className="assign-header">
          <Modal.Header>Assigned Owner</Modal.Header>
          <img src={closeIcon} onClick={() => setAssigned(false)} />
        </div>
        <Modal.Content image>
          <Modal.Description>
            <form
              onSubmit={e => {
                setAssigned(false)
                fetchId(selected)
              }}
            >
              <Radio
                label={owners}
                value="yes"
                checked={radioState === 'yes'}
              />
              <Button>Done</Button>
            </form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  )
}
export default OwnerModel
