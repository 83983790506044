import React from 'react'

import { Grid, Dropdown } from 'semantic-ui-react'
import axios from 'axios'
import BackIcon from '../../assets/images/backPage.png'


const ownerDropdown = [
  {
    key: 1,
    text: 'Lead',
    value: 1
  },
  {
    key: 2,
    text: 'Customer',
    value: 2
  },
  {
    key: 3,
    text: 'Partner',
    value: 3
  },
  {
    key: 4,
    text: 'Other',
    value: 4
  }
]
const Tags = [
  {
    key: 1,
    text: 'New',
    value: 1
  },
  {
    key: 2,
    text: 'VIP',
    value: 2
  },
  {
    key: 3,
    text: 'Something',
    value: 3
  }
]
const scoreDropdown = [
  {
    key: 1,
    text: 'Cold',
    value: 'Cold'
  },
  {
    key: 2,
    text: 'Warm',
    value: 'Warm'
  },
  {
    key: 3,
    text: 'Hot',
    value: 'Hot'
  }
]

const InsightsPanel = ({ getData }) => {



  const data = getData?.getData

  console.info({data})

  const associateApi = (e, dat) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${data.id}/info`
    const formData = new FormData()
    if (dat?.placeholder == 'tags') {
      formData.append('tags', dat?.value)
    }
    if (dat?.placeholder == 'status') {
      formData.append('status', dat?.value)
    }
    if (dat?.placeholder == 'score') {
      formData.append('score', dat?.value)
    }

    axios
      .post(url, formData)
      .then(res => {
       
      })
      .catch(error => {
       
      })
  }
  const leadChange = (e, dat) => {
    associateApi(e, dat)
  }
  
  return (
    <div className="contact-card">


      <div className="contact-card-boxes">
        <p className="card-boxes-title">visitor insights</p>
        <Grid className="card-boxes-text">
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Industry:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{data?.industry}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Headquarters:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{data?.industry}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Role:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{data?.role}</p>
            </Grid.Column>{' '}
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Website:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text"></p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      <div className="contact-card-boxes">
        <p className="card-boxes-title">Visited Pages</p>
        <Grid className="card-boxes-text">
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Last seen:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{ data?.last_seen ? data?.last_seen : 'not available' }</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Last visit:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{ data?.last_viewed_url ? data?.last_viewed_url : 'not available' }</p>
            </Grid.Column>
            {/* <Grid.Column width={6}>
              <p className="card-boxes-left-text">Visits:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">2 pages in 2m 56s</p>
            </Grid.Column> */}
            {/* <Grid.Column width={2}>
              <img src={BackIcon} alt="" />
            </Grid.Column>
            <Grid.Column width={14}>
              <p className="card-boxes-right-text">Shopmarket - Product page</p>
            </Grid.Column>
            <Grid.Column width={2}>
              <img src={BackIcon} alt="" />
            </Grid.Column>
            <Grid.Column width={14}>
              <p className="card-boxes-right-text">Shopmarket - Catalog</p>
            </Grid.Column> */}


          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

export default InsightsPanel
