import React from 'react'
import { Modal } from 'semantic-ui-react'

const CalendarIntegrationModal = ({
  dimmer,
  open,
  onClose,
  renderHeader,
  renderContent,
  renderFooter,
  size,
  className
}) => {
  return (
    <Modal
      dimmer={dimmer}
      open={open}
      onClose={onClose}
      size={size}
      className={className}
    >
      <Modal.Header>{renderHeader()}</Modal.Header>
      <Modal.Content>{renderContent()}</Modal.Content>
      <Modal.Actions>{renderFooter()}</Modal.Actions>
    </Modal>
  )
}

export default CalendarIntegrationModal
