import axios from 'axios'
import React, { Component } from 'react'
//redux
import { connect } from 'react-redux'
import TimezonePicker from 'react-timezone'
import { Dimmer, Loader } from 'semantic-ui-react'
import { getProfile, getWidgetDays } from '../../actions/settings'
import { CommonNotify } from '../../common/CommonNotify'
import CommonWorkingHour from '../../common/CommonWorkingHour'
import NodeToggle from '../../common/NodeToggle'
import updateHourStatus from '../../config/togglehoursStatus'
import BreakTime from './BreakTime'
import DayOff from './DayOff'


const weekDays = [
  {
    key: 1,
    text: 'Sunday',
    value: 2,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  },
  {
    key: 2,
    text: 'Monday',
    value: 3,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: true
  },
  {
    key: 3,
    text: 'Tuesday',
    value: 4,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  },
  {
    key: 4,
    text: 'Wednesday',
    value: 5,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  },
  {
    key: 5,
    text: 'Thursday',
    value: 6,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  },
  {
    key: 6,
    text: 'Friday',
    value: 7,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  },
  {
    key: 7,
    text: 'Saturday',
    value: 1,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  }
]

class WorkingHours extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updated: 0,
      activeToggle: false,
      selectedTimeZone: 'UTC',
      working_from: '',
      working_to: '',
      day_name: '',
      day_id: '',
      time_zone: '',
      isLoading: true,
      javaScriptSnippet: false,
      business_hours_status: 0,
      statusLoaded: false,
      days: weekDays,
      data: [],
      widget: null,
      selectedDay: 0,
      dayList: [],
      daysSelected: [],
      isReminderButtonDisplay: false,
      isChecked: false,
      isCheckedBoxData: false,
      branding: false,
      isBranding: false,
      weekDaysValues: [],
      firstRun: true
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      this.setState({
        business_hours_status: this.props.profile.data.working_hours_status,
        selectedTimeZone: this.props.profile.data.time_zone,
        isLoading: false
      })
    }

    if (this.props.widgetDays !== prevProps.widgetDays) {
      let results = [...this.props.widgetDays.data]
      results.reverse()
      const dts = this.state.days
      const rt = results.map((val, key) => {
        dts[key].value = val.id
        dts[key].id = val.id
        dts[key].text = val.name

        return dts[key]
      })
      this.setState({ days: rt })
    }
  }

  componentDidMount() {
    this.getProfileData()
    this.fetchDays()
  }

  setUpdated() {
    let { updated } = this.state
    this.setState({ updated: updated + 1 })
  }

  fetchDays = () => {
    if (this.props.widgetDays) {
      let results = [...this.props.widgetDays.data]
      results.reverse()
      const dts = this.state.days
      const rt = results.map((val, key) => {
        dts[key].value = val.id
        dts[key].id = val.id
        dts[key].text = val.name

        return dts[key]
      })
      this.setState({ days: rt })
    } else {
      this.props.getWidgetDays()
    }
  }

  getProfileData = () => {
    if (this.props.profile) {
      this.setState({
        business_hours_status: this.props.profile.data.working_hours_status,
        selectedTimeZone: this.props.profile.data.time_zone,
        isLoading: false
      })
    } else {
      this.setState({ isLoading: true })
      this.props.getProfile()
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { business_hours_status, firstRun } = this.state
  }

  callToggle = {
    callTitle: 'JavaScript Snippet',
    callDesc:
      'When turned on, our system is permitted to make automated calls to your customers when requited',
    callId: 'toogleJavaScriptSnippet',
    callref: 'javaScriptSnippet'
  }
  brandingData = {
    callTitle: 'branding',
    callDesc:
      'When turned on, our system is permitted to make automated calls to your customers when requited',
    callId: 'branding',
    callref: 'branding'
  }
  handleToggleData = async toggleData => {
    const w_id = await localStorage.getItem('widget_id')
    const url = 'user/working-hours/status'
    this.setState({
      business_hours_status: toggleData
    })
    updateHourStatus(toggleData, w_id, url)
      .then(async res => {
        await localStorage.setItem('user_working_hours', toggleData ? '1' : '0')
      })
      .catch(err => console.log())
  }

  handleToggleDataBranding = toggleData => {
    if (toggleData) {
      this.setState({
        isBranding: true
      })
    }
  }

  selectTimeZone = e => {
    this.setState({
      selectedTimeZone: e
    })
    this.updateTimeZone(e)
  }

  updateTimeZone = async time => {
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/time-zone`
    const data = {
      time_zone: time
    }
    await axios
      .post(url, data)
      .then(async res => {
        CommonNotify('Timezone updated successfully.')
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Something went to wronng!'
        )
      })
  }

  setLoader = async loadingState => {
    this.setState({ isLoading: loadingState })
  }

  render() {
    const { business_hours_status } = this.state
    const URL = {
      get: 'user/working-hours',
      post: 'user/working-hours',
      delete: 'user/working-hours'
    }

    const content = {
      header: 'Time Slots',
      p: `Available On`
    }


    return (
      <>
        <Dimmer style={{ position: 'fixed' }} active={this.state.isLoading}>
          <Loader></Loader>
        </Dimmer>
        <div>
          <div className="business_hours_main">
          <p className="availability-title">
          Set your availability
              </p>
              <p>
              Let limecall know when you're typically available to accept calls and meetings
              </p>
            <div className="working-hours-wrapper availability-block">
            
              <div className="availability-toggle">
                <div style={{ width: 100 }}>
                  <NodeToggle
                    handleDataRef={this.handleToggleData}
                    activeDefault={this.state.business_hours_status}
                    dataToggle={{
                      callTitle: '',
                      callDesc: '',
                      callId: 'toggleSMSShowBtn',
                      callRef: 'ShowSMSButton'
                    }}
                  />
                </div>
                <p className="title-label-working">Enable Work Schedule</p>
                <div style={{marginLeft:'-153px'}}>Control when you would like to receive calls and notifications</div>
              </div>
              {business_hours_status ? (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      marginTop: 10
                    }}
                  >
                    <p
                      style={{
                        marginRight: '20px',
                        fontWeight: '700',
                        color: '#6b5e5e',
                        fontSize: '16px',
                        display: 'flex',
                        marginTop: 5
                      }}
                    >
                      {' '}
                      Timezone{' '}
                    </p>{' '}
                    <div className="timezone-picker" style={{ width: '60%' }}>
                      <TimezonePicker
                        value={this.state.selectedTimeZone}
                        onChange={this.selectTimeZone}
                        inputProps={{
                          placeholder: 'Select Timezone...',
                          name: 'timezone'
                        }}
                      />{' '}
                    </div>{' '}
                  </div>
                  <CommonWorkingHour
                    days={this.state.days}
                    nextStep={this.props.nextStep}
                    increaseStep={this.props.increaseStep}
                    btnUpdated={this.state.updated}
                    setUpdated={this.setUpdated}
                    setLoading={this.setLoader}
                    timeZone={this.state.selectedTimeZone}
                    content={content}
                    isLoading={this.state.isLoading}
                    isWidgets={true}
                    URL={URL}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {this.props.activeTab !== 'Business Hours' &&
            (business_hours_status === true || business_hours_status === 1) && (
              <>
                <DayOff
                  loading={this.props.loading}
                  number={this.props.number}
                  working={true}
                />
                <BreakTime
                  setLoading={this.setLoader}
                  isLoading={this.state.isLoading}
                  loading={this.props.loading}
                />
              </>
            )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.settings.profile,
  widgetDays: state.settings.widgetDays
})

export default connect(mapStateToProps, { getProfile, getWidgetDays })(
  WorkingHours
)
