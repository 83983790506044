import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useGetLeadQuery } from '../../../features/api/apiSlice'
import {
  Menu,
  Tab,
  Icon,
  Dropdown,
  Input,
  TextArea,
  Sidebar,
  Segment,
  Popup,
  Accordion,
  Progress
} from 'semantic-ui-react'
import { AssignmentIndOutlined } from '@mui/icons-material'
import CommonButtons from '../../../common/CommonButtons'
import { Box, Divider, Typography } from '@mui/material'

const LeadDetails = ({ id }) => {
  const res = useGetLeadQuery(id)
  const [lead, setLead] = useState(null)
  useEffect(() => {
    if (res.isSuccess) {
      setLead(res.data.lead)
    }
  }, [res])

  const ChatSendContent = [
    {
      menuItem: 'Reply',
      render: () => (
        <>
          <div className="replyTab">
            <TextArea placeholder="Joe Dude, this one is for you! A bug within the team settings." />
            <div className="message-save">
              <div className="message-footer-items">
                <div className="content-icons"></div>
                <CommonButtons content="Reply" background="green" />
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      menuItem: 'Note',
      render: () => (
        <>
          <div className="replyTab note-style">
            <TextArea />
            <div className="message-save">
              <div className="message-footer-items">
                <div className="content-icons">
                  <Icon name="attach" />
                  <Icon name="smile outline" />
                </div>
                <CommonButtons content="Add Note" background="green" />
              </div>
            </div>
          </div>
        </>
      )
    }
  ]

  return (
    <Segment basic className="message-segment">
      {lead && (
        <div className="conversation-content-messages auto-width">
          <div className="conversation-content-messages-holder">
            <div className="content-text" style={{ marginBottom: '17px' }}>
              <Typography variant="h6">{lead.customer_name}</Typography>
            </div>
            <Divider />
            <div className="content-message-body">
              <div className="message-content height-message-content">
                <div className="message-text message-send">
                  <div className="chatMessages">
                    <div className="chatTime">
                      <div
                        className="message-text-holder"
                        style={{
                          backgroundColor: '#fff'
                        }}
                      >
                        <p>Name: {lead.customer_name}</p>
                        <p>Email: {lead.email}</p>
                        <p>Phone: {lead.phone_number} </p>
                      </div>
                    </div>
                    <div className="messageProfile">
                      <label>A</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="message-footer">
                <Tab
                  menu={{ secondary: true, pointing: true }}
                  panes={ChatSendContent}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Segment>
  )
}

export default LeadDetails
