import React, { useState, useEffect } from 'react'
import {
  Tab,
  Checkbox,
  Select,
  Dropdown,
  Dimmer,
  Loader,
  Input,
  Button
} from 'semantic-ui-react'
import CommonDateRangePicker from '../../daterangepicker/CommonDateRangePicker'
import moment from 'moment'
import axios from 'axios'

import deleteIcon from '../../../assets/images/delete-icon.png'

import { getLeadOwner } from '../../../config/leadAPI'

import { logOut } from '../../../common/ProfileModal'
import DetailLead from '../MyleadsDetail'

import CommonButton from '../../../common/CommonButtons'

import RightPane from '../../../components/leads/leadRightPane/rightPopUp'

import { CommonNotify } from '../../../common/CommonNotify'

const sortOptions = [
  { key: 1, text: 'Newest First', value: 'Newest First' },
  { key: 2, text: 'Oldest First', value: 'Oldest First' }
  
]

const options = [
  { key: 1, text: 'All Time', value: 'All Time' },
  { key: 1, text: 'Today', value: 'Today' },
  { key: 2, text: 'Yesterday', value: 'Yesterday' },
  { key: 3, text: 'This week', value: 'This week' },
  { key: 4, text: 'Last week', value: 'Last week' },
  { key: 5, text: 'This month', value: 'This month' },
  { key: 6, text: 'Last month', value: 'Last month' },
  { key: 7, text: 'Custom range', value: 'Select custom' }
]

const countryOptions = [
  { key: 'af', value: 'af', text: 'Afghanistan' },
  { key: 'ax', value: 'ax', text: 'Aland Islands' },
  { key: 'al', value: 'al', text: 'Albania' },
  { key: 'dz', value: 'dz', text: 'Algeria' },
  { key: 'as', value: 'as', text: 'American Samoa' },
  { key: 'ad', value: 'ad', text: 'Andorra' },
  { key: 'ao', value: 'ao', text: 'Angola' },
  { key: 'ai', value: 'ai', text: 'Anguilla' },
  { key: 'ag', value: 'ag', text: 'Antigua' },
  { key: 'ar', value: 'ar', text: 'Argentina' },
  { key: 'am', value: 'am', text: 'Armenia' },
  { key: 'aw', value: 'aw', text: 'Aruba' },
  { key: 'au', value: 'au', text: 'Australia' },
  { key: 'at', value: 'at', text: 'Austria' },
  { key: 'az', value: 'az', text: 'Azerbaijan' },
  { key: 'bs', value: 'bs', text: 'Bahamas' },
  { key: 'bh', value: 'bh', text: 'Bahrain' },
  { key: 'bd', value: 'bd', text: 'Bangladesh' },
  { key: 'bb', value: 'bb', text: 'Barbados' },
  { key: 'by', value: 'by', text: 'Belarus' },
  { key: 'be', value: 'be', text: 'Belgium' },
  { key: 'bz', value: 'bz', text: 'Belize' },
  { key: 'bj', value: 'bj', text: 'Benin' }
]

const tagOptions = [
  {
    key: 'ID',
    text: 'ID',
    value: 'id'
  },
  
  {
    key: 'phone_number',
    text: 'Phone Number',
    value: 'phone_number'
  },
  {
    key: 'Type',
    text: 'Type',
    value: 'type'
  },
  
  {
    key: 'Call Status',
    text: 'Call Status',
    value: 'final_status'
  },
  {
    key: 'status',
    text: 'status',
    value: 'status'
  },
  {
    key: 'Agent',
    text: 'Agent',
    value: 'agent'
  },
  {
    key: 'Score',
    text: 'Score',
    value: 'score'
  },
  {
    key: 'Owner',
    text: 'Owner',
    value: 'owner'
  }
]

const conditionOptions = [
  {
    key: 'equals',
    text: 'equals',
    value: 'is'
  },
  {
    key: 'not equal to',
    text: 'not equal to',
    value: '!is'
  },
  {
    key: 'starts with',
    text: 'starts with',
    value: 'begin'
  }
]



const filter = {
  type: ['Type', 'Existing', 'Meeting', 'Opportunity'],
  status: ['Civil Status', 'Online', 'Offline'],
  score: ['Score', 'All', 'Qualified', 'Bad Fit'],
  owner: ['Owner', 'All', 'Developer AIPXPERTS'],
  agent: ['Agent', 'All', 'Developer AIPXPI'],
  dateRange: ['Date', 'Today', 'Last 7 Days', 'Last 30 Days', 'Custom Date']
}

const leadSubStatus = [
  { key: 'all', text: 'All', value: 'all' },
  { key: 'new', text: 'Unassigned', value: 'new' },
  { key: 'pending', text: 'Pending', value: 'pending' },
  { key: 'qualified', text: 'Qualified', value: 'Qualified' }
]

const mainTabs = ['call', 'message', 'schedule']

const tabsOptions = {
  call: ['LIVE_CALL', 'DIGITAL_CALL', ''],
  message: ['LEAVE_MESSAGE'],
  schedule: ['SCHEDULE_CALL']
}

const sortObj = {
  sortField: 'id',
  sortValue: 'DESC',
  pageNumber: 1,
  sortMain: '-id'
}

const filterObj = {
  selectedValue: '',
  query: '',
  filterCondition: ''
}

const dateFilters = {
  startDateFilter: '',
  endDateFilter: ''
}

const colFilterData = [
  { key: 1, text: 'Id', value: 'id' },
  { key: 2, text: 'Phone Number', value: 'phone_number' },
  { key: 3, text: 'Email', value: 'email' },
  { key: 4, text: 'Customer Name', value: 'customer_name' },
  { key: 5, text: 'Call Status', value: 'final_status' },
  { key: 6, text: 'Lead Status', value: 'status' },
  { key: 7, text: 'Agent', value: 'agent' },
  { key: 8, text: 'Score', value: 'score' },
  { key: 9, text: 'Owner', value: 'owner' }  
]

const colConditionData = {
  id: [
    { key: 1, text: 'Equals', value: 'equals' },
    { key: 2, text: 'Not Equals', value: 'not-equals' },
    { key: 3, text: 'Less Than', value: 'less-then' },
    { key: 4, text: 'Greater than', value: 'greater-then' },
    { key: 8, text: 'Between', value: 'between' }
  ],
  phone_number: [
    { key: 1, text: 'Equals', value: 'equals' },
    { key: 2, text: 'Not Equals', value: 'not-equals' },
    { key: 5, text: 'Starts With', value: 'starts-with' },
    { key: 6, text: 'Ends With', value: 'ends-with' },
    { key: 7, text: 'Contains', value: 'contains' }
  ],
  email: [
    { key: 1, text: 'Equals', value: 'equals' },
    { key: 2, text: 'Not Equals', value: 'not-equals' },
    { key: 5, text: 'Starts With', value: 'starts-with' },
    { key: 6, text: 'Ends With', value: 'ends-with' },
    { key: 7, text: 'contains', value: 'contains' }
  ],
  customer_name: [
    { key: 1, text: 'Equals', value: 'equals' },
    { key: 2, text: 'Not Equals', value: 'not-equals' },
    { key: 5, text: 'Starts With', value: 'starts-with' },
    { key: 6, text: 'Ends With', value: 'ends-with' },
    { key: 7, text: 'contains', value: 'contains' }
  ],
  final_status: [
    { key: 1, text: 'Equals', value: 'equals' },
    { key: 2, text: 'Not Equals', value: 'not-equals' }
  ],
  status: [
    { key: 1, text: 'Equals', value: 'equals' },
    { key: 2, text: 'Not Equals', value: 'not-equals' }
  ],
  agent: [
    { key: 1, text: 'Equals', value: 'equals' },
    { key: 2, text: 'Not Equals', value: 'not-equals' }
  ],
  score: [
    { key: 1, text: 'Equals', value: 'equals' },
    { key: 2, text: 'Not Equals', value: 'not-equals' }
  ],
  owner: [
    { key: 1, text: 'Equals', value: 'equals' },
    { key: 2, text: 'Not Equals', value: 'not-equals' }
  ],
  created_at: [
    { key: 1, text: 'Equals', value: 'contains' },
    { key: 2, text: 'Not Equals', value: 'not-equals' },
    { key: 8, text: 'between', value: 'between' }
  ]
}

const queryListData = {
  col: '',
  condition: '',
  oparator: '',
  query: '',
  start: '',
  end: '',
  searchType: 0
}

const queryListAr = [queryListData]

const colOperatorData = [
  { key: 2, text: 'And', value: 'and' },
  { key: 3, text: 'Or', value: 'or' }
]

const callStatusData = [
  { key: 1, text: 'Completed', value: 'completed' },
  { key: 2, text: 'Failed', value: 'failed_to_connect_agent' },
  { key: 3, text: 'Suspended', value: 'suspended' }
]

const leadStatusData = [
  { key: 1, text: 'Awaiting Review', value: 1 },
  { key: 2, text: 'Reviewed', value: 2 },
  { key: 3, text: 'Disqualifed', value: 3 },
  { key: 4, text: 'Qualified', value: 4 }
]

const AllCalls = ({
  activeSegmentsLead,
  activeSeg,
  activeTagsLead,
  setSegmentAdded,
  segmentAdded,
  activeCallType,
  setSideList,
  sideList,
  allTags,
  setAllTags,
  updateTagCount 
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setDate] = React.useState(new Date())
  const [mainTab, setMainTab] = useState(mainTabs[0])
  const [filterPage, setFilterPage] = useState(true)

  const [colFilter, setColFilter] = useState(colFilterData)
  const [colCondition, setColCondition] = useState(colConditionData)
  const [colOperator, setColOperator] = useState(colOperatorData)
  const [selectedCondition, setSelectedCondition] = useState([])
  const [selectedDropDown, setSelectedDropDown] = useState([])
  const [allDropDown, setAllDropDown] = useState({
    status: leadStatusData,
    final_status: callStatusData
  })

  const [segment, setSegment] = useState(false)
  const [segmentName, setSegmentName] = useState('')

  const [queryList, setQueryList] = useState(queryListAr)
  const [segmentLeads, setSegmentsLeads] = useState([])
  const [totalAssigned, setTotalAssigned] = useState(0)
  const [totalPending, setTotalPending] = useState(0)
  const [totalQualified, setTotalQualified] = useState(0)
  const [totalClosed, setTotalClosed] = useState(0)
  const [totalSubscribed, setTotalSubscribed] = useState(0)
  const [totalInprogress, setTotalInprogress] = useState(0)

  const [runQuery, setRunQuery] = useState(false)

  const [filterCondition, setFilterCondition] = useState('is')
  const [sortPickerOption, setSortPickerOption] = useState('Newest First')
  const [selectedPickerOption, setSelectedPickerOption] = useState('All Time')
  const [sortMain, setSortMain] = useState('-id')
  const [currentLeadSubStatus, setCurrentLeadSubStatus] = useState(
    leadSubStatus[0].value
  )
  const [isMarkAllCheckbox, setIsMarkAllCheckbox] = useState(false)
  const [sortField, setSortField] = useState('id')

  const [totalPaginated, setTotalPaginated] = useState({
    assigned: 10,
    pending: 10,
    qualified: 10,
    subscribed: 10
  })

  const [sortOption, setSortOption] = useState(sortObj)
  const [dateFilter, setDateFilter] = useState(dateFilters)
  const [queryOption, setQueryOption] = useState(filterObj)
  const [queryCounter, setQueryCounter] = useState(0)

  const [rightPane, setRightPane] = useState(false)
  const [dataloaded, setDataLoaded] = useState(false)
  const [leadData, setLeadData] = useState(null)

  const [nextLeadId, setNextLeadId] = useState(0)
  const [prevLeadId, setPrevLeadId] = useState(0)
  const [leadScore, setLeadScore] = useState([])
  const [leadOwner, setLeadOwner] = useState([])
  const [leadStage, setLeadStage] = useState([])
  const [updatedTable, setUpdatedTable] = useState(0)
  const [updatedPane, setUpdatedPane] = useState(0)

  

  const [loading, setLoading] = useState(false)
 

  const today = new Date()
  const [schedulePopUp, setSchedulePopUp] = useState(false)
  const [createMeetingType, setMeetingType] = useState(false)

  const [detailPopUp, setDetailPopUp] = useState(false)

  const [meetingData, setMeetData] = useState([])
  const [addOfficeHour2, setAddOfficeHour2] = useState([1])
  const [selectedValue, setSelectedValue] = useState(null)
  const [query, setQuery] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [sortValue, setSortValue] = useState('DESC')
  const [filterTable, setFilterTable] = useState({
    startDateFilter: moment(),
    endDateFilter: moment()
  })

  const [allLeadSubStatus, setAllLeadSubStatus] = useState(leadSubStatus)
  const [filterDropDownOption, setFilterDropdownOptions] = useState({
    score: null,
    type: null,
    owner: null,
    agent: null,
    final_status: null,
    status: [
      { key: 'Awaiting Review', value: 1, text: 'Awaiting Review' },
      { key: 'Reviewed', value: 2, text: 'Reviewed' },
      { key: 'Disqualifed', value: 3, text: 'Disqualifed' },
      { key: 'Qualified', value: 4, text: 'Qualified' }
    ]
  })

  useEffect(() => {   
    getOwner()
    getWidget()
    fetchDropwDowns()
  }, [activeCallType])

  useEffect(() => {
    checkSegmentStatus()
  }, [queryList])

  const getWidget = async () => {
    const w_id = await localStorage.getItem('widget_id')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-call-settings?widget_id=${w_id}`

    axios
      .get(url)
      .then(res => {
        const data = res.data.data.scores_tags
        const updateLeadScore =
          data.allowed_scores &&
          data.allowed_scores.map((item, index) => {
            return {
              key: index,
              text: item,
              value: item
            }
          })

        const updateStageScore =
          data.allowed_stages &&
          data.allowed_stages.map((item, index) => {
            return {
              key: index,
              text: item,
              value: item
            }
          })

        if (
          !Number.isInteger(updateLeadScore[updateLeadScore.length - 1].key)
        ) {
          updateLeadScore.splice([updateLeadScore.length - 1, 1])
        }

        setLeadStage([...updateStageScore])
        setLeadScore([...updateLeadScore])

        let dp = { ...allDropDown }
        dp.score = updateLeadScore
        setAllDropDown(dp)
      })

      .catch(err => {
        if (err?.response?.status === 401) {
          
          return
        }
        if (!err?.response) {
         
        }
      })
  }

  const getOwner = async () => {
    const owner = []
    const allMembers = []

    await getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin

        await owner.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.id
        })

        await allMembers.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.id
        })

        await res.data.data.members.map(team => {
          owner.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
          allMembers.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
        })

        setLeadOwner(owner)
      })
      .catch(err => {})
  }

  const getOwnerDropDown = async () => {
    const owner = []
    const allMembers = []

    await getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin

        await owner.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.first_name + ' ' + admin.last_name
        })

        await allMembers.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.first_name + ' ' + admin.last_name
        })

        await res.data.data.members.map(team => {
          owner.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.first_name + ' ' + team.last_name
          })
          allMembers.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.first_name + ' ' + team.last_name
          })
        })

        let dp = { ...allDropDown }
        dp.owner = owner
        dp.agent = owner
        setAllDropDown(dp)
      })
      .catch(err => {})
  }

  const fetchDropwDowns = () => {
    getOwnerDropDown()
  }

  const removeQuery = key => {
    let qList = [...queryList]
    qList.splice(key, 1)    
    setQueryCounter(queryCounter - 1)
    setQueryList(qList)
  }

  const saveSegment = key => {
    const apiToken = localStorage.getItem('access_token')

    if (segmentName === '') {
      CommonNotify('Please Enter Segment Name')
    }

    if (!segmentLeads.length) {
      CommonNotify('leads is empty')
    }

    const leads = segmentLeads
    let data = { name: segmentName, lead_ids: leads }

    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists`
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        setLoading(false)
        CommonNotify('Segment Saved', 'success')
        setSegment(false)
        setSegmentAdded(segmentAdded + 1)
        
      })
      .catch(err => {
        setLoading(false)
        CommonNotify('Segment Name Exists / not available')
        if (err?.response?.status === 401) {
          
          return
        }
        if (!err?.response) {
          
        }
      })
  }

  const addQuery = () => {
    if (queryCounter > 1) return

    let qList = [...queryList]
    qList.push(queryListData)
    
    setQueryCounter(queryCounter + 1)
    setQueryList(qList)
  }

  const handleOnChangeFilter = (data, key) => {
    let ar
    if (selectedCondition.length) {
      ar = [...selectedCondition]
    } else {
      ar = []
    }

    let includes = ['final_status', 'status', 'owner', 'agent', 'score']

    const conditions = colCondition[data]
    ar[key] = conditions

    let query = [...queryList]

    let tm = { ...query[key] }
    tm.col = data

    if (includes.includes(data)) {
      

      let sDropDown = [...selectedDropDown]
      sDropDown[key] = allDropDown[data]
      setSelectedDropDown(sDropDown)
      

      tm.searchType = 1
    } else {
      tm.searchType = 0
    }
    query[key] = tm
    setQueryList(query)
    setSelectedCondition(ar)
  }

  const handleOnChangeCondition = (e, data, key) => {
    let query = [...queryList]
    let tm = { ...query[key] }
    tm.condition = data.value
    query[key] = tm
    
    setQueryList(query)
  }

  const handleOnChangeOperator = (e, data, key) => {
    let query = [...queryList]
    let tm = { ...query[key] }
    tm.oparator = data.value
    query[0] = tm
   
    setQueryList(query)
  }

  const setQueryString = (data, key, name) => {
    let query = [...queryList]
    let tm = { ...query[key] }
    tm[name] = data
    query[key] = tm
    
    setQueryList(query)
  }

  const checkSegmentStatus = () => {
    const dropDowns = ['agent', 'final_status', 'id', 'owner', 'status']
    if (queryList[queryList.length - 1].condition !== 'between') {
      setSegment(true)
    } else {
      setSegment(true)
    }
    if (dropDowns.includes(queryList[queryList.length - 1].col)) {
      setSegment(true)
    }
    if (queryList[queryList.length - 1].condition !== 'between') {
      if (queryList[queryList.length - 1].query.length === 0) {
        setSegment(false)
      }
    } else {
      if (queryList[queryList.length - 1].end.length === 0) {
        setSegment(false)
      }
    }
  } 

  const fetchTotalLeadsNo = () => {
   
  }

  const todayFetch = () => {
    setDateFilter({
      startDateFilter: moment().format('YYYY-MM-DD'),
      endDateFilter: moment().format('YYYY-MM-DD')
    })
  }

  const clearDate = () => {
    setDateFilter({
      startDateFilter: '',
      endDateFilter: ''
    })
  }

  const yesterday = async () => {
    setDateFilter({
      startDateFilter: moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      endDateFilter: moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
    })
  }

  const lastWeek = () => {
    setDateFilter({
      startDateFilter: moment()
        .subtract(1, 'weeks')
        .startOf('isoWeek')
        .format('YYYY-MM-DD'),
      endDateFilter: moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
        .format('YYYY-MM-DD')
    })
  }

  const thisWeek = () => {
    const startOfWeek = moment()
      .startOf('isoWeek')
      .format('YYYY-MM-DD')
    const endOfWeek = moment()
      .endOf('isoWeek')
      .format('YYYY-MM-DD')
    setDateFilter({
      startDateFilter: startOfWeek,
      endDateFilter: endOfWeek
    })
  }

  const thisMonth = () => {
    const startOfMonth = moment()
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD')
    const endOfMonth = moment()
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD')
    setDateFilter({
      startDateFilter: startOfMonth,
      endDateFilter: endOfMonth
    })
  }

  const lastMonth = () => {
    const prevMonthFirstDay = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD')
    const prevMonthLastDay = moment()
      .subtract(1, 'months')
      .endOf('month')
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD')
    setDateFilter({
      startDateFilter: prevMonthFirstDay,
      endDateFilter: prevMonthLastDay
    })
  } 

  useEffect(() => {   
    setupCallCounter()
    
  }, [])

 

  useEffect(() => {
    handleOnChangeFilter('id', 0)
  }, [])

  const getMainTabQuery = () => {
    

    

    const callType = activeCallType

    if (callType.includes('all')) {
      var index = callType.indexOf('all')
      if (index !== -1) {
        callType.splice(index, 1)
      }
    }

    return callType.join()
  } 

  

  const handleSortData = (e, { value }) => {
    setSortPickerOption(value)

    if (value === 'Newest First') {
      const tObj = { ...sortOption }
      tObj.sortMain = '-id'
      setSortOption(tObj)
    } else {
      const tObj = { ...sortOption }
      tObj.sortMain = 'id'
      setSortOption(tObj)
    }
  } 

 

  const handleChangeDate = (e, { value }) => {
    setSelectedPickerOption(value)

    if (value === 'Select custom') {
      setFilterTable({
        startDateFilter: moment(),
        endDateFilter: moment()
      })
      return
    }
    switch (value) {
      case 'All Time':
        setMainTab(mainTab)
        clearDate()
        break
      case 'Today':
        todayFetch()
        break
      case 'Yesterday':
        yesterday()
        break
      case 'This week':
        thisWeek()
        break
      case 'Last week':
        lastWeek()
        break
      case 'This month':
        thisMonth()
        break
      case 'Last month':
        lastMonth()
        break
    }
  }

  const setupCallCounter = async () => {


    const func = [loadDigitalCounter, loadLiveCall, loadMessages, loadScheduleCall]

   


    const nList = [...sideList]

    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/my-leads-count`   

    axios
      .get(url)
      .then(res => {
        setLoading(false)
        nList[0].total = res.data.data.digital_calls || 0
        nList[1].total = res.data.data.live_calls  || 0
        nList[2].total = res.data.data.messages  || 0
        nList[3].total = res.data.data.schedule_calls  || 0

        console.info({nList})
        setTotalAssigned(res.data.data.my_leads || 0);
        setTotalPending(res.data.data.pending || 0);
        setTotalQualified(res.data.data.qualified || 0);
        setTotalClosed(res.data.data.closed || 0);
        setTotalInprogress(res.data.data.in_progress || 0);
        setSideList(nList)        
        setDataLoaded(true)
      })
      .catch(err => {
        if (err.response?.data?.errors?.length) {
           
        }
        if (err?.response?.status === 401) {
         
          return
        }
        if (!err?.response) {
          
        }
      })


  

  }

  const loadDigitalCounter = async () => {
    setLoading(true)

    const apiToken = await localStorage.getItem('access_token')

    const { selectedValue, query, filterCondition } = queryOption

    const header = {
      Authorization: 'Bearer ' + apiToken
    }

    var params = {
      limit: 1,
      page: 1
    }

    const uName =
      localStorage.getItem('first_name') +
      ' ' +
      localStorage.getItem('last_name')
    params['sort_field'] = 'id'
    params['sort_value'] = 'DESC'
    params['filter[owner][is]'] = uName
    params['filter[type][in]'] = 'DIGITAL_CALL'

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`

    let data = false

    return await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: params
      })
      .then(res => {
        setLoading(false)
        return res.data.data.leads.total
        
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const loadLiveCall = async () => {
    setLoading(true)
    const apiToken = localStorage.getItem('access_token')
    const { selectedValue, query, filterCondition } = queryOption

    const header = {
      Authorization: 'Bearer ' + apiToken
    }

    var params = {
      limit: 1,
      page: 1
    }

    const uName =
      localStorage.getItem('first_name') +
      ' ' +
      localStorage.getItem('last_name')
    params['sort_field'] = 'id'
    params['sort_value'] = 'DESC'
    params['filter[owner][is]'] = uName
    params['filter[type][in]'] = 'LIVE_CALL'

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`

    return await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: params
      })
      .then(res => {
        setLoading(false)
        return res.data.data.leads.total
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const loadMessages = () => {}

  const loadScheduleCall = () => {}

  const setCounter = async (total, index) => {
    let temp = [...sideList]
    let obj = { ...temp[index] }
    obj.total = total
    temp[index] = obj
    return await setSideList(temp)
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const getNextPrevLead = (next, prev) => {
    setNextLeadId(next)
    setPrevLeadId(prev)
  }

  const handleRightPaneOpen = (id, next, prev) => {

    setDataLoaded(false)
    getNextPrevLead(next, prev)
    setRightPane(true)
    setLoading(true)
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/lead/${id}`
    axios
      .get(url)
      .then(res => {
        setLoading(false)
        setLeadData(res?.data)
        setDataLoaded(true)
      })
      .catch(err => {
        if (err.response?.data?.errors?.length) {
          setRightPane(false)
          setLoading(false)
          CommonNotify('Id is not available')
        }
        if (err?.response?.status === 401) {
         
          return
        }
        if (!err?.response) {
          
        }
      })
  }

  const handleRightClose = () => {
    setDataLoaded(false)
    setRightPane(false)
  }

  const setUpdatedPane1 = () => {
    setTimeout(setUpdatedPaneArg, 2000)
  }

  const setUpdatedPaneArg = () => {
    let updatedPane1 = updatedPane
    updatedPane1 = updatedPane1 + 1
    setUpdatedPane(updatedPane1)
  }

  const handleApply = (event, picker) => {
    setDateFilter({
      startDateFilter: moment(picker.startDate).format('YYYY-MM-DD'),
      endDateFilter: moment(picker.endDate).format('YYYY-MM-DD')
    })
  }

  const panes = [
    {
      menuItem: `Assigned (${totalAssigned})`,
      render: ({ setDetailPopUp, meetingData }) => (
        <DetailLead
          type={1}
          setRunQuery={setRunQuery}
          runQuery={runQuery}
          allTags={allTags}
          setAllTags={setAllTags}
          totalPaginated={totalPaginated}
          setTotalPaginated={setTotalPaginated}
          totalAssigned={totalAssigned}
          totalPending={totalPending}
          totalQualified={totalQualified}
          totalClosed={totalClosed}
          totalSubscribed={totalSubscribed}
          getMainTabQuery={getMainTabQuery}
          mainTab={mainTab}
          dateFilter={dateFilter}
          sortOption={sortOption}
          reloadTotal={fetchTotalLeadsNo}
          queryOption={queryOption}
          queryList={queryList}
          setQueryList={setQueryList}
          resetQuery={queryListAr}
          setSegmentsLeads={setSegmentsLeads}
          activeSegmentsLead={activeSegmentsLead}
          activeSeg={activeSeg}
          activeTagsLead={activeTagsLead}
          activeCallType={activeCallType}
          handleRightPaneOpen={handleRightPaneOpen}
          updateTagCount={updateTagCount}
         
        />
      )
    },
    {
      menuItem: `Pending (${totalPending})`,
      render: () => (
        <DetailLead
          type={2}
          setRunQuery={setRunQuery}
          runQuery={runQuery}
          allTags={allTags}
          setAllTags={setAllTags}
          totalPaginated={totalPaginated}
          setTotalPaginated={setTotalPaginated}
          totalAssigned={totalAssigned}
          totalPending={totalPending}
          totalQualified={totalQualified}
          totalClosed={totalClosed}
          totalSubscribed={totalSubscribed}
          getMainTabQuery={getMainTabQuery}
          activeCallType={activeCallType}
          mainTab={mainTab}
          dateFilter={dateFilter}
          sortOption={sortOption}
          reloadTotal={fetchTotalLeadsNo}
          queryOption={queryOption}
          queryList={queryList}
          setQueryList={setQueryList}
          resetQuery={queryListAr}
          setSegmentsLeads={setSegmentsLeads}
          activeSegmentsLead={activeSegmentsLead}
          activeSeg={activeSeg}
          activeTagsLead={activeTagsLead}
          handleRightPaneOpen={handleRightPaneOpen}
          updateTagCount={updateTagCount}
        
        />
      )
    },
    {
      menuItem: `Inprogress (${totalInprogress})`,
      render: () => (
        <DetailLead
          type={3}
          setRunQuery={setRunQuery}
          runQuery={runQuery}
          allTags={allTags}
          setAllTags={setAllTags}
          totalPaginated={totalPaginated}
          setTotalPaginated={setTotalPaginated}
          totalAssigned={totalAssigned}
          totalPending={totalPending}
          totalQualified={totalQualified}
          totalClosed={totalClosed}
          totalSubscribed={totalSubscribed}
          getMainTabQuery={getMainTabQuery}
          activeCallType={activeCallType}
          mainTab={mainTab}
          dateFilter={dateFilter}
          sortOption={sortOption}
          reloadTotal={fetchTotalLeadsNo}
          queryOption={queryOption}
          queryList={queryList}
          setQueryList={setQueryList}
          resetQuery={queryListAr}
          setSegmentsLeads={setSegmentsLeads}
          activeSegmentsLead={activeSegmentsLead}
           activeSeg={activeSeg}
          activeTagsLead={activeTagsLead}
          handleRightPaneOpen={handleRightPaneOpen}
          updateTagCount={updateTagCount}
         
        />
      )
    },
    {
      menuItem: `Qualified (${totalQualified})`,
      render: () => (
        <DetailLead
          type={5}
          allTags={allTags}
          setAllTags={setAllTags}
          setRunQuery={setRunQuery}
          runQuery={runQuery}
          totalPaginated={totalPaginated}
          setTotalPaginated={setTotalPaginated}
          totalAssigned={totalAssigned}
          totalPending={totalPending}
          totalQualified={totalQualified}
          totalClosed={totalClosed}
          totalSubscribed={totalSubscribed}
          getMainTabQuery={getMainTabQuery}
          mainTab={mainTab}
          dateFilter={dateFilter}
          reloadTotal={fetchTotalLeadsNo}
          sortOption={sortOption}
          queryOption={queryOption}
          queryList={queryList}
          setQueryList={setQueryList}
          resetQuery={queryListAr}
          setSegmentsLeads={setSegmentsLeads}
          activeCallType={activeCallType}
           activeSeg={activeSeg}
          activeSegmentsLead={activeSegmentsLead}
          activeTagsLead={activeTagsLead}
          handleRightPaneOpen={handleRightPaneOpen}
          updateTagCount={updateTagCount}
         
        />
      )
    },
   
    {
      menuItem: `Closed (${totalClosed})`,
      render: () => (
        <DetailLead
          type={4}
          setRunQuery={setRunQuery}
          runQuery={runQuery}
          allTags={allTags}
          setAllTags={setAllTags}
          totalPaginated={totalPaginated}
          setTotalPaginated={setTotalPaginated}
          totalAssigned={totalAssigned}
          totalPending={totalPending}
          totalQualified={totalQualified}
          totalClosed={totalClosed}
          getMainTabQuery={getMainTabQuery}
          mainTab={mainTab}
          dateFilter={dateFilter}
          sortOption={sortOption}
          reloadTotal={fetchTotalLeadsNo}
          queryOption={queryOption}
          queryList={queryList}
          setQueryList={setQueryList}
          resetQuery={queryListAr}
          setSegmentsLeads={setSegmentsLeads}
          activeSegmentsLead={activeSegmentsLead}
          activeTagsLead={activeTagsLead}
           activeSeg={activeSeg}
          activeCallType={activeCallType}
          handleRightPaneOpen={handleRightPaneOpen}
          totalSubscribed={totalSubscribed}
          updateTagCount={updateTagCount}
         
        />
      )
    }
    
  ]

  return (
    <div>
      <div className="meet-holder">
        <div className="meet-title-holder" style={{ padding: "0px" }}>
          <div className="schedule_meeting"></div>
          <div className="metting-filter-section"></div>
        </div>
      </div>

      {rightPane && dataloaded ? (
        <RightPane
          allTags={allTags}
          setAllTags={setAllTags}
          setUpdatedPane={setUpdatedPane1}
          updatedTable={updatedTable}
          leadStage={leadStage}
          rightPane={rightPane}
          handleRightClose={handleRightClose}
          leadData={leadData}
          nextLeadId={nextLeadId}
          prevLeadId={prevLeadId}
          leadOwner={leadOwner}
          leadScore={leadScore}
          handleRightPaneOpen={handleRightPaneOpen}
        />
      ) : (
        ''
      )}

      <div className="metting-event">
        <div
          className="lead-header"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div className="filter-wrapper" style={{ marginBottom: '30px', display: 'flex',justifyContent: "space-between", width: "100%" }}>
            <div
              className="date-range-parent custom-range-parent"
              style={{  minWidth: '200px' }}
            >
              <Dropdown
                onChange={handleChangeDate}
                options={options}
                selection
                value={selectedPickerOption}
                style={{ minWidth: '150px', marginRight: '30px' }}
              />
              {selectedPickerOption === 'Select custom' ? (
                <CommonDateRangePicker
                  handleApply={handleApply}
                 
                  initialSettings={{
                    startDate: filterTable.startDateFilter,
                    endDate: filterTable.endDateFilter,
                    maxDate: moment()
                  }}
                />
              ) : null}             
            </div>

            <div   className="date-range-parent custom-range-parent"
              style={{  minWidth: '150px', marginLeft: "auto",  marginRight: "20px"  }}>
                <Dropdown
                      onChange={handleSortData}
                      options={sortOptions}
                      style={{
                        minWidth: '150px',
                        float: 'left',
                        marginLeft: '20px'
                      }}
                      selection
                      value={sortPickerOption}
                    />


            </div>


          </div>
        </div>

        <div
          className="holder-filter-leads"
          style={{
            margin: '0 auto',
            border: '1px solid #007bff',
            color: '#007bff'
          }}
        >
          {filterPage && (
            <div
              className="filter-lead"
              style={{ margin: '0 auto', width: '100%' }}
            >
              {queryList.map((query, key) => {
                return (
                  <div key={key} className="filter-tab">
                    <div
                      className="filter-holder"
                      style={{ backgroundColor: '#black' }}
                    >
                     

                      <Dropdown
                        clearable
                        defaultValue={'id'}
                        options={colFilter}
                        style={{ minWidth: '200px', marginLeft: '20px' }}
                        onChange={(e, data) =>
                          handleOnChangeFilter(data.value, key)
                        }
                        selection
                        key={key}
                      />
                      <Dropdown
                        clearable
                        options={selectedCondition[key]}
                        style={{ minWidth: '200px', marginLeft: '20px' }}
                        selection
                        onChange={(e, data) =>
                          handleOnChangeCondition(e, data, key)
                        }
                        key={key}
                      />

                      {query.searchType ? (
                        <>
                          <Dropdown
                            clearable
                            options={selectedDropDown[key]}
                            style={{ minWidth: '200px', marginLeft: '20px' }}
                            selection
                            onChange={(e, data) =>
                              setQueryString(data.value, key, 'query')
                            }
                            key={key}
                          />
                        </>
                      ) : (
                        <>
                          {query.condition !== 'between' ? (
                            <Input
                              placeholder="Search"
                              style={{ minWidth: '180px', marginLeft: '20px' }}
                              value={query.query}
                              onChange={e =>
                                setQueryString(e.target.value, key, 'query')
                              }
                            />
                          ) : (
                            <>
                              <Input
                                placeholder="Start"
                                style={{
                                  minWidth: '120px',
                                  marginLeft: '20px'
                                }}
                                value={query.start}
                                onChange={e =>
                                  setQueryString(e.target.value, key, 'start')
                                }
                              />

                              <Input
                                placeholder="End"
                                style={{
                                  minWidth: '120px',
                                  marginLeft: '20px'
                                }}
                                value={query.end}
                                onChange={e =>
                                  setQueryString(e.target.value, key, 'end')
                                }
                              />
                            </>
                          )}
                        </>
                      )}


                    {key === queryCounter && (                        
                          <CommonButton
                          onClick={e => setRunQuery(true)}
                          btnClass="btn-delete"
                          icon="search"
                          style={{ fontSize: '1.2rem', marginLeft: '20px' }}
                        />
                      )}

                       


                      {key === queryCounter && queryCounter < 2 ? (
                        <Button
                          onClick={() => addQuery()}
                          circular
                          icon="plus"
                          style={{
                            marginLeft: '20px',
                            color: '#fff',
                            backgroundColor: '#007bff'
                          }}
                        />
                      ) : null}

                      {key !== queryCounter && (
                        <Dropdown
                          clearable
                          options={colOperator}
                          onChange={(e, data) =>
                            handleOnChangeOperator(e, data, key)
                          }
                          style={{ minWidth: '200px', marginLeft: '20px' }}
                          selection
                        />
                      )}

                      {key ? (
                        <Button
                          onClick={() => removeQuery(key)}
                          circular
                          icon="minus"
                          style={{
                            marginLeft: '20px',
                            color: '#fff',
                            backgroundColor: '#f17b2f'
                          }}
                        />
                      ) : null}
                    </div>

                    {segment && key === 0 && (
                      <div className="filter-tab">
                        <div
                          className="filter-holder"
                          style={{
                            marginTop: '16px',
                            backgroundColor: '#black',
                            float: 'left'
                          }}
                        >
                          <Input
                            placeholder="Segment Name"
                            style={{ minWidth: '250px', marginLeft: '20px' }}
                            onChange={e => setSegmentName(e.target.value)}
                          />

                          <Button
                            onClick={() => saveSegment()}
                            style={{
                              marginLeft: '20px',
                              color: '#fff',
                              backgroundColor: '#007bff'
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}

          {!filterPage && (
            <div
              onClick={() => {
                setFilterPage(true)
              }}
              style={{ margin: '0 auto' }}
            >
              {' '}
              Filter Lead
            </div>
          )}
        </div>

        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          setDetailPopUp={setDetailPopUp}
          detailPopUp={detailPopUp}
          meetingData={meetingData}
        />
      </div>
    </div>
  )
}

export default AllCalls
