import axios from 'axios'
import { GET_CONVERSATIONS } from './types'

// Get All Numbers
export const getConversations = (page, status) => async dispatch => {
  const widgetScript = localStorage.getItem('widgetScript')
  let pageNumber = 1;
  let statusNumber = 1;
  if (page) {
    pageNumber = page;
  }
  if (status) {
    statusNumber = status;
  }
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/fetch-conversations?script_id=${widgetScript}&direction=desc&page=${pageNumber}`
  const res = await axios.get(url)

  dispatch({
    type: GET_CONVERSATIONS,
    payload: res.data.data
  })
}
