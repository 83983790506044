import React, { useEffect } from 'react'
import loginLogo from '../assets/images/limephone-logo.png'
import { Image } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect';

const AdminVerification = () => {
 
  useEffect(() => {
    if (isMobile) {
      window.location.href = 'https://dialer.limephone.io/'
    } else {
      window.location.href = "/home"
    }

  }, [])

  return (
    <div id="admin-verification">
        <div className='logo-container'>
            <div className="logo"><Image src={loginLogo} /></div>
        </div>
        <div className="container">
            <h2>Account under manual review</h2>
            <p>For security purposes your account is pending review by our staff. This process usually takes less than 24 hours. we'll let you know as soon as your new account is activated</p>
        </div>
    </div>
  )
}

export default AdminVerification