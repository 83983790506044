import React, { Component } from 'react'

class Toggle extends Component {
 

  state = {
    activeToggle: this.props.dataToggleActive,
    prevToggle: this.props.dataToggleActive,
    firstRun: true
  }

  onClickToggle = e => {
    const { checked } = this.props
    const actToggle = this.state.activeToggle
    this.setState(
      {
        activeToggle: !actToggle,
      },
      () => {
        return this.props.handleToggleData(this.state.activeToggle ? 1 : 0)
      }
    )
  }

  componentDidMount() {
    const { activeDefault } = this.props

    if (activeDefault && this.state.firstRun) {
      
       this.setState({ firstRun: false, activeToggle: true  })
    }
  }

  componentWillReceiveProps = nextProps => {
    const { activeDefault } = this.props

    if (activeDefault && this.state.firstRun) {
     
      this.setState({ firstRun: false, activeToggle: true  })
    }
  }

  render() {
    const { dataStateToggle, checked, activeDefault } = this.props
    const attributes = checked
      ? {
          checked: activeDefault
        }
      : {}
    return (
      <div>
        <input
          type="checkbox"
          className="toggle"
          onClick={this.onClickToggle}
          id={dataStateToggle && dataStateToggle.callId}
          ref={e => (this.triggerElement = e)}        
          checked={this.state.activeToggle == 1 ? true : false}
        />{' '}
        <label htmlFor={dataStateToggle && dataStateToggle.callId}>
          <span className="off"> OFF </span>
          <span className="on"> ON </span>
        </label>{' '}
      </div>
    )
  }
}

export default Toggle