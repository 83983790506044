import React, { useState } from 'react'
import { Accordion } from 'semantic-ui-react'


function LeadForm({ data }) {
  const [activeIndex, setActiveIndex] = useState(0) 

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const activeIndexs = activeIndex
    const newIndex = activeIndexs === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <>
      <Accordion className="Lead_wrapper" fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          lead form
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={process.env.REACT_APP_BASE_APP_URL + "/assets/lead_details_icons/Lead popup edited-33.svg"}
                  className="lead-data-icon"
                />
                <label>Name</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data.callData.customer_name}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={process.env.REACT_APP_BASE_APP_URL + "/assets/lead_details_icons/Lead popup edited-33.svg"}
                  className="lead-data-icon"
                />
                <label>Email</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data.callData.email}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={process.env.REACT_APP_BASE_APP_URL + "/assets/lead_details_icons/Lead popup edited-33.svg"}
                  className="lead-data-icon"
                />
                <label>Message</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data.callData.widget_message}</b>
              </p>
            </div>
          </div>



          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={process.env.REACT_APP_BASE_APP_URL + "/assets/lead_details_icons/Lead popup edited-33.svg"}
                  className="lead-data-icon"
                />
                <label>Source</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b style={{ lineBreak: 'anywhere' }}>{data.callData.source}</b>
              </p>
            </div>
          </div>            
         
         
          
        </Accordion.Content>
      </Accordion>
    </>
  )
}

export default LeadForm
