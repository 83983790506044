import React, { useEffect, useState } from 'react'

import assignAgentIcon from '../../assets/images/assignAgent.png'
import { Grid, Dropdown } from 'semantic-ui-react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddAssociated from './AddAssociated.js'
import deleteIcon from '../../assets/images/delete-icon.png'
import Axios from 'axios';

const AssociationData = ({ logData, getData,company,contact }) => {
  const Logs = logData?.logData
  const [addAssociated, setAddAssociated] = useState(false)
  const [data, setData] = useState([])
  // const data = getData?.getData
 useEffect(() => {
setData(company ? getData?.getData?.contacts : getData?.getData?.organization ? [getData?.getData?.organization]:[])
 }, [getData?.getData])
 
 
const handleDelete=(id)=>{
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/dissociate-contacts`
  Axios
    .post(url,{contact_ids:[contact ?getData?.getData?.id: id]})
    .then(res => {
      const index=data?.findIndex((obj)=>obj?.id===id)
      const dd=data?.splice(index,1)
      setData([...data])
      var d = []
      if (res?.data) {
   
      }
    })
    .catch(error => {
    })
 }
 
  return (
    <div className="associationsTab">
      <div className="contact-card">
      {( (!data || data?.length === 0) || company) &&  <div className="contact-card-boxes">
    <Grid className="card-boxes-text">
      <Grid.Row>
      <Grid.Column width={6}>
      <a onClick={()=>setAddAssociated(true)}>Add <AddCircleOutlineIcon/></a>
      </Grid.Column>
      </Grid.Row>
      </Grid>
      </div>}
        {data?.map((owner)=>(
  <div className="contact-card-boxes">
    <div style={{display:'flex',justifyContent:'space-between'}}>
    <p className="card-boxes-title">{contact ? 'Company':'Associated contact'}</p>
  <div className="details-title" style={{boxShadow:'none'}}>
      <img
            style={{ marginLeft: '10px' }}
            src={deleteIcon}
            onClick={() => 
              handleDelete(owner?.id)
            }
          />
          </div>
          </div>
  <Grid className="card-boxes-text">
    {console.log('owner', owner)}
    <Grid.Row>
  
      <Grid.Column width={6}>
        <p className="card-boxes-left-text">{contact? 'Company Name:': 'Name:'}</p>
      </Grid.Column>
      <Grid.Column width={10}>
        <p className="card-boxes-right-text">{contact ?owner?.organization_name : (owner?.name) }</p>
      </Grid.Column>
  {  contact &&   
  <><Grid.Column width={6}>
        <p className="card-boxes-left-text">{'Company Id:'}</p>
      </Grid.Column>
      <Grid.Column width={10}>
        <p className="card-boxes-right-text">{owner?.id}</p>
      </Grid.Column>
      </>}
  {  company &&   
  <>
  <Grid.Column width={6}>
        <p className="card-boxes-left-text">{'Contact Id:'}</p>
      </Grid.Column>
      <Grid.Column width={10}>
        <p className="card-boxes-right-text">{owner?.id}</p>
      </Grid.Column>
  <Grid.Column width={6}>
        <p className="card-boxes-left-text">{'Phone Number:'}</p>
      </Grid.Column>
      <Grid.Column width={10}>
        <p className="card-boxes-right-text">{owner?.phone_number}</p>
      </Grid.Column>
      </>}
      {/* <Grid.Column width={6}>
        <p className="card-boxes-left-text">Phone Number:</p>
      </Grid.Column>
      <Grid.Column width={10}>
        <p className="card-boxes-right-text">{owner?.phone_number}</p>
      </Grid.Column> */}
      <Grid.Column width={6}>
        <p className="card-boxes-left-text">Email:</p>
      </Grid.Column>
      <Grid.Column width={10}>
        <p className="card-boxes-right-text">{owner?.email}</p>
      </Grid.Column>
      {/* <Grid.Column width={6}>
        <p className="card-boxes-left-text">Company:</p>
      </Grid.Column>
      <Grid.Column width={10}>
        <p className="card-boxes-right-text">{owner?.company_name}</p>
      </Grid.Column> */}
      
      {/* <Grid.Column width={6}>
        <p className="card-boxes-left-text">Department:</p>
      </Grid.Column>
      <Grid.Column width={10}>
        <p className="card-boxes-right-text">{owner?.department}</p>
      </Grid.Column>
      <Grid.Column width={6}>
        <p className="card-boxes-left-text">Role:</p>
      </Grid.Column>
      <Grid.Column width={10}>
      <p className="card-boxes-right-text">{owner?.designation}</p>
      </Grid.Column>{' '}
      <Grid.Column width={6}>
        <p className="card-boxes-left-text">Personal Link</p>
      </Grid.Column>
      <Grid.Column width={10}>
      <p className="card-boxes-right-text">{owner?.personal_link}</p>
      </Grid.Column> */}
    </Grid.Row>
  </Grid>
</div>

        ))}
        
      </div>
      {addAssociated && 
      <AddAssociated setData={setData} data={data}company={company} contact={contact} addAssociatedOpen={addAssociated} setAddAssociated={setAddAssociated} getData={getData} />
      }
    </div>
  )
}

export default AssociationData
