import React, { useState, useEffect } from 'react'
import {
  Tab,
  Dropdown,
  Image,
  Popup,
  Modal,
  Menu,
  Label,
  Button,
  TextArea
} from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

import Flag from 'react-flags'

import ReactCountryFlag from 'react-country-flag'
import SemanticDatepicker from 'react-semantic-ui-datepickers'

import MissedCall from '../../../../assets/images/orangeCall.png'
import PickedCall from '../../../../assets/images/greenCall.png'
import cutCall from '../../../../assets/images/callRed.jpeg'
import LeadAboutRight from './leadAbout'
import LeadInsight from './leadInsight'
import LeadActivities from './leadActivity'
import ModalEverGreen from '../../../../common/EverGreenModal'
import LeadAssociate from './leadAssociate'
import closeIcon1 from '../../../../assets/images/closeIcon1.png'
import gmailIcon from '../../../../assets/images/gmail.svg'
import targetIcon from '../../../../assets/images/target.svg'
import dotIcon from '../../../../assets/images/dot.svg'
import userIcon from '../../../../assets/images/user.svg'
import rightArrow from '../../../../assets/images/right-arrow.png'
import leftArrow from '../../../../assets/images/left-arrow.png'
import callIcon from '../../../../assets/images/call.svg'
import PhoneInput from 'react-phone-input-2'
import moment from 'moment-timezone'

import axios from 'axios'

import getWidget from '../../../../config/getWidget'

import AddTagPopup from './AddTagPopup'

import CommonButtons from '../../../../common/CommonButtons'

import EverGreenSelect from '../../../../common/EvergreenSelect'

import { CommonNotify } from '../../../../common/CommonNotify'

import {
  onChangeOwner,
  onLeadScoreHandler,
  onStatusChangeHandler,
  onLeadStageHandler,
  _createActivity
} from '../../../../config/leadAPI'
import { reactRouterV3Instrumentation } from '@sentry/react'
import { useGetLeadQuery } from '../../../../features/api/apiSlice'
import { Tooltip } from '@mui/material'

const ownerDropdown = [
  {
    key: 1,
    text: 'Assigned',
    value: 'Assigned'
  },
  {
    key: 2,
    text: 'Qualified',
    value: 'Qualified'
  },
  {
    key: 3,
    text: 'Negotiation',
    value: 'Negotiation'
  },
  {
    key: 4,
    text: 'Won',
    value: 'Won'
  },
  {
    key: 5,
    text: 'Lost',
    value: 'Lost'
  }
]
const callStatus = [
  {
    key: 1,
    text: 'Awaiting',
    value: 1
  },
  {
    key: 2,
    text: 'Recieved',
    value: 2
  },
  {
    key: 3,
    text: 'Suspended',
    value: 3
  }
]

const tagsData = [
  {
    color: '#1dd2c0',
    tag: 'VIP'
  },
  {
    color: '#e26cd4',
    tag: 'NEW'
  },
  {
    color: '#eb4034',
    tag: 'VVIP'
  }
]

const rightPanes = [
  {
    menuItem: `About`,
    render: ({ leadData, stateOptions, leadOwner, leadScore }) => (
      <LeadAboutRight
        leadData={leadData}
        stateOptions={stateOptions}
        leadOwner={leadOwner}
        leadScore={leadScore}
      />
    )
  },
  {
    menuItem: 'Notes',
    render: ({ leadData, leadScore }) => (
      <LeadAssociate leadData={leadData} leadScore={leadScore} />
    )
  },
  {
    menuItem: 'Insights',
    render: ({ leadData, leadScore }) => (
      <LeadInsight leadData={leadData} leadScore={leadScore} />
    )
  },
  {
    menuItem: 'Activities',
    render: ({ leadData, leadScore }) => (
      <LeadActivities leadData={leadData} leadScore={leadScore} />
    )
  }
]

const w_id = localStorage.getItem('widget_id')

const RightSide = ({
  rightPane,
  leadOwner,
  leadScore,
  id,
  leadStage,
  allTags
}) => {
  const res = useGetLeadQuery(id)
  const history = useHistory()

  const [lScores, setLScores] = useState([])
  const [summary, setSummary] = useState('')
  const [tagsUpdated, setTagsUpdated] = useState(false)
  const [blockModal, setBlockModal] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [leadData, setLeadData] = useState({})
  const [leadAbout, setLeadAbout] = useState({})
  const [leadDetail, setLeadDetail] = useState({})
  const [fvLead, setfvLead] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [collaboratesData, setCollaboratesData] = useState([])
  const [defaultCollaborator, setDefaultCollaborator] = useState('')
  const [leadInterest, setLeadInterest] = useState(1)
  const [startDate, setStartDate] = useState(new Date())

  useEffect(() => {
    if (res.isSuccess) {
      setLeadData(res.data)
      setLeadAbout(res.data.lead)
      setLeadInterest(res.data.lead.interseted)
      setLeadDetail({
        phone: res.data.lead.phone_number,
        email: res.data.lead.email
      })
      setDataLoaded(true)
    }
  }, [res])

  const [tags, setTags] = useState([])

  const deleteModalView = async bool => {
    setDeleteModal(bool)
  }

  const shareModalView = async bool => {
    setShareModal(bool)
  }

  const getCollaborates = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
    const user = []
    axios
      .get(url)
      .then(res => {
        var defaultIds = []
        var moderateArray = []

        const admin = res.data.data.admin

        moderateArray.push({
          key: admin.id,
          text: `${admin.first_name} ${admin.last_name}`,
          value: admin.id,
          label: `${admin.first_name} ${admin.last_name}`,
          image: {
            avatar: true,
            src: require('../../../../assets/images/new-collab.png')
          }
        })

        res.data.data.members &&
          res.data.data.members.map(item => {
            user.push({
              _id: item.id,
              name: { first: item.first_name, last: item.last_name }
            })
            defaultIds.push(item.id)
            const d = {
              key: item.id,
              text: `${item.first_name} ${item.last_name}`,
              value: item.id,
              label: `${item.first_name} ${item.last_name}`,
              image: {
                avatar: true,
                src: require('../../../../assets/images/new-collab.png')
              }
            }
            moderateArray.push(d)
          })

        const finalCollabList = moderateArray
        setCollaboratesData(finalCollabList)
      })
      .catch(err => { })
  }

  const onDeleteLeadHandler = async () => {
    setIsLoading(true)
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-leads`
    var postId = [leadAbout.id]

    const postData = {
      ids: postId
    }

    axios
      .post(URL, postData)
      .then(res => {
        setIsLoading(false)
        CommonNotify('Lead Deleted Successfully', 'success')

        deleteModalView(false)
      })
      .catch(err => {
        setIsLoading(false)
        CommonNotify('Not able to Delete Lead')
      })
  }

  const updateText = (e, index) => {
    let obj = { ...leadDetail }
    obj[index] = e.target.value
    setLeadDetail(obj)
  }

  const updateText2 = (e, index) => {
    let obj = { ...leadDetail }
    obj[index] = e
    setLeadDetail(obj)
  }

  useEffect(() => {
    updateLeadScore()
  }, [leadScore?.length])

  useEffect(() => {
    if (!tagsUpdated) return
    if (!tags?.length) return

    saveTags()
  }, [tags])

  useEffect(() => {
    if (!allTags?.length) return
    if (!leadAbout.tags) return
    fetchTags(leadAbout.tags)
  }, [allTags])

  useEffect(() => {
    fetchFLead()
    getCollaborates()
  }, [])

  const updateLeadScore = () => {
    let lScore = [...leadScore]

    if (!lScore?.length) return
    if (!Number.isInteger(lScore[lScore?.length - 1].key)) {
      lScore.splice(lScore?.length - 1, 1)
    }
    setLScores(lScore)
  }

  const handleItemClick = async (e, { name }, interseted) => {
    const oldValue = leadInterest

    setLeadInterest(interseted)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-interested`
    const postData = {
      lead_id: id,
      interested: interseted
    }

    axios
      .post(url, postData)
      .then(res => {
      })
      .catch(err => {
        setLeadInterest(oldValue)
        CommonNotify('Not able to update  Good Fit')
      })
  }

  function getDate() {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    let date = mm + '/' + dd + '/' + yyyy
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month?.length < 2) month = '0' + month
    if (day?.length < 2) day = '0' + day

    let ddd = [year, month, day].join('-')
    return ddd + ' ' + today.getHours() + ':' + (today.getMinutes() + 5)
  }

  const onActivityAdd = async () => {
    const user_id = await localStorage.getItem('id')
    if (summary == '') {
      CommonNotify('PLease enter summary')
      return
    }

    let leadType = 'NOTE'
    let cDate = getDate()
    // cDate = "2022-07-20 00:00";

    const payload = {
      type: leadType,
      summary: summary,
      lead_id: id,
      desktop_reminder: 1,
      title: summary,
      showActivityType: 'new notes',
      due_date: cDate
    }

    _createActivity(payload)
      .then(data => {
        setSummary('')
        CommonNotify('Lead note saved', 'success')
      })
      .catch(err => {
        if (err.response) {
          CommonNotify(err.response.data?.errors[0])
        }
      })
  }

  const saveLeadDetail = e => {
    if (e.key !== 'Enter') return
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadAbout?.id}/info`

    axios
      .post(URL, { phone_number: leadDetail.phone, email: leadDetail.email })
      .then(res => {
        CommonNotify('Lead info updated', 'success')
      })
      .catch(err => {
        CommonNotify('Not able to set tags')
      })
  }

  const saveTags = () => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/add-tags`

    const pTags = tags.map(tag => {
      return tag.tag
    })

    const postData = {
      tags: pTags,
      lead_id: leadAbout?.id
    }

    axios
      .post(URL, postData)
      .then(res => { })
      .catch(err => {
        CommonNotify('Not able to set tags')
      })
  }

  const fetchTags = tags => {
    let tgs = [...tags]
    const tg = tgs

    tg.forEach((item, index) => {
      if (!getTag(item)) {
        tgs.splice(index, 1)
      }
    })

    const newTags = tgs.map(item => {
      return getTag(item)
    })

    setTags(newTags)
  }

  const getTag = tag => {
    let selectedTag = -1
    allTags.forEach((item, index) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        selectedTag = index
      }
    })
    if (selectedTag == -1) return false
    return allTags[selectedTag]
  }

  const removeTag = tag => {
    let selectedTag = -1
    const selTags = [...tags]
    selTags.forEach((item, index) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        selectedTag = index
      }
    })
    selTags.splice(selectedTag, 1)
    setTags(selTags)
    setTagsUpdated(true)
  }

  const onStageValueChanged = data => { }

  const openLead = () => {
    history.push(`/leads/${leadAbout.id}`)
  }

  const addTag = (tag, index) => {
    let avilable = false

    tags.forEach(tg => {
      if (tg.tag.toLowerCase() === tag.toLowerCase()) {
        avilable = true
      }
    })

    if (avilable) return
    let newTags = [...tags]
    newTags.push(allTags[index])
    setTags(newTags)
    setTagsUpdated(true)
  }

  const addBlockList = async (blockType, blockData) => {
    const w_id = await localStorage.getItem('widget_id')

    const data = {
      block_reason: 'Blocked from Lead',
      widget_id: w_id
    }

    if (blockType == 'Email') {
      if (blockData.email === '' || blockData.email === null) {
        CommonNotify('Email is not correct.')
        return
      } else {
        data.email = blockData.email
      }
    } else if (blockType == 'Phone') {
      if (blockData.phone === '' || blockData.phone === null) {
        CommonNotify('Phone is not correct.')
        return
      } else {
        data.phone_number = blockData.phone
        data.phone_country = blockData.countryCode
        data.block_reason = 'Blocked From Lead'
      }
    } else {
      return
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/blacklist-user`
    axios
      .post(url, data)
      .then(res => {
        if (res.data.message[0] == 'Successfully') {
          CommonNotify('User Added to BlockList', 'success')
        }
      })
      .catch(error => {
        CommonNotify(error.response.data.errors[0], 'warning')
      })
  }

  const fLead = id => { }

  const toggleFLead = async (id, fav) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${id}/flags`
    if (fav) {
      axios
        .post(url, {
          flag: 1
        })
        .then(res => {
          if (res.data.message[0] == 'Successfully') {
            setfvLead(fav)
            fetchFLead()
          }
        })
        .catch(error => {
          CommonNotify(error.response.data.errors[0], 'warning')
        })
    } else {
      axios
        .delete(url, {
          data: {
            flag: 1
          }
        })
        .then(res => {
          if (res.data.message[0] == 'Successfully') {
            fetchFLead()
            setfvLead(fav)
          }
        })
        .catch(error => {
          CommonNotify(error.response.data.errors[0], 'warning')
        })
    }
  }

  const fetchFLead = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/fav`
    axios
      .get(url)
      .then(res => {
        isFLead(res.data.data)
      })
      .catch(error => {
        CommonNotify(error.response.data.errors[0], 'warning')
      })
  }

  const isFLead = data => {
    const leads = data.map(dt => {
      return dt.id
    })

    if (leads.includes(leadAbout.id)) {
      setfvLead(true)
    } else {
      setfvLead(false)
    }
  }

  const onCollaboratorsHandler = async data => {
    const lead_Id = leadAbout.id
    const value = data

    var filtered = value?.filter(function (e) {
      return this.indexOf(e) < 0
    }, defaultCollaborator)

    let stringParams = value.map((item, index) => {
      return `&to[${index}]=${item}`
    })

    const user_id = await localStorage.getItem('id')

    var url = ''
    if (stringParams?.length >= 1) {
      stringParams = stringParams.join('')

      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/mention-member?from=${user_id}&content=${lead_Id}${stringParams}`
    } else {
      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/mention-member?from=${user_id}&content=${lead_Id}`
    }

    const postData = {}
    axios
      .post(url, postData)
      .then(res => {
        CommonNotify('Lead collaborator saved ', 'success')
      })
      .catch(err => {
        CommonNotify('Some error occured.')
      })
  }

  return dataLoaded ? (
    <div>
      <div
        style={{
          zIndex: '0',
          position: 'relative',
          borderLeft: '1px solid #E6E8EE',
          boxShadow: 'none',
          paddingTop: 50,
          width: '100%'
        }}
        className={rightPane ? 'callModel rightPopUp' : 'callModel'}
      >
        <div className="aboutSubpopup">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }} className="detail-popup-profile">
              <div
                className="detailHeadProfile"
                style={{ overflow: 'auto', height: '95vh' }}
              >
                <div className="popUpHead">
                  <div style={{ padding: '0 15px', marginBottom: '6px' }}></div>
                  <p style={{ marginBottom: '3px' }}>
                    {leadAbout?.customer_name}
                  </p>
                  {leadAbout?.enrichment && (
                    <p style={{ marginBottom: '3px' }}>
                      {leadAbout?.enrichment.company_name}
                    </p>
                  )}
                  <p style={{ marginBottom: '3px' }}>{leadDetail.phone}</p>{' '}
                  {leadAbout?.country && (
                    <img
                      width="10%"
                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${leadAbout?.country.toUpperCase()}.svg`}
                    />
                  )}{' '}
                  {!leadAbout?.country && (
                    <img
                      width="10%"
                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg`}
                    />
                  )}
                </div>
                <div className="popUpDetail">
                  <div className="callDetail ">
                    <div className="Iconlabel">
                      <img alt="" src={gmailIcon} />
                      <label
                        style={{
                          color: '#A4ABC4',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px'
                        }}
                      >
                        Email
                      </label>
                    </div>

                    <input
                      onKeyPress={e => saveLeadDetail(e)}
                      type="email"
                      className="card-boxes-right-text"
                      style={{
                        maxWidth: '170px',
                        border: '1px solid #8f8f8f',
                        borderRadius: '3px',
                        padding: '6px 5px'
                      }}
                      onChange={e => updateText(e, 'email')}
                      value={leadDetail.email}
                    />

                    <i
                      onClick={() => addBlockList('Email', leadDetail)}
                      style={{ marginLeft: 10, color: 'red' }}
                      className="fa fa-ban"
                      aria-hidden="true"
                    ></i>
                  </div>

                  <div className="callDetail">
                    <div className="Iconlabel">
                      <img alt="" src={callIcon} />
                      <label
                        style={{
                          color: '#A4ABC4',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px'
                        }}
                      >
                        Recording
                      </label>
                    </div>

                    {leadAbout?.contact?.recording_url ? (
                      <audio controls>
                        <source
                          src={leadAbout?.contact?.recording_url}
                          type="audio/mp3"
                        />
                      </audio>
                    ) : (
                      <p className="card-boxes-right-text">Not Available</p>
                    )}
                  </div>

                  <div className="callDetail">
                    <div className="Iconlabel">
                      <img alt="" src={userIcon} />
                      <label
                        style={{
                          color: '#A4ABC4',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px'
                        }}
                      >
                        Owner
                      </label>
                    </div>
                    <Dropdown
                      className="card-boxes-right-text"
                      options={leadOwner}
                      placeholder="Lead Owner"
                      basic
                      defaultValue={
                        leadAbout?.owner_id === null
                          ? leadAbout?.interseted
                          : leadAbout?.owner_id
                      }
                      onChange={(e, dat) =>
                        onChangeOwner(e, dat, leadAbout?.id)
                      }
                    />
                  </div>

                  <div className="callDetail">
                    <div className="Iconlabel">
                      <img alt="" src={targetIcon} />
                      <label
                        style={{
                          color: '#A4ABC4',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px'
                        }}
                      >
                        Status
                      </label>
                    </div>
                    {leadAbout.final_status !== null &&
                      (leadAbout.final_status === 'failed_to_connect_agent' ||
                        leadAbout.final_status ===
                        'failed_to_connect_customer') ? (
                      <Image
                        src={MissedCall}
                        className={rightPane ? 'rightPopUp' : ''}
                        style={{ width: 25, height: 25 }}
                      />
                    ) : null}

                    {leadAbout.final_status !== null &&
                      leadAbout.final_status === 'completed' ? (
                      <Image
                        src={PickedCall}
                        style={{ width: 25, height: 25 }}
                      />
                    ) : null}

                    {leadAbout.final_status !== null &&
                      (leadAbout.final_status === 'suspended' ||
                        leadAbout.final_status === 'canceled') ? (
                      <Image src={cutCall} style={{ width: 25, height: 25 }} />
                    ) : null}
                  </div>

                  <div className="callDetail">
                    <div className="Iconlabel">
                      <img alt="" src={dotIcon} />
                      <label
                        style={{
                          color: '#A4ABC4',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px'
                        }}
                      >
                        Score
                      </label>
                    </div>

                    <Dropdown
                      className="card-boxes-right-text"
                      placeholder="Lead Score"
                      options={lScores}
                      defaultValue={leadAbout?.score}
                      onChange={(e, dat) =>
                        onLeadScoreHandler(e, dat, leadAbout.id)
                      }
                    />
                  </div>

                  <div className="callDetail">
                    <div className="Iconlabel">
                      <img alt="" src={dotIcon} />
                      <label
                        style={{
                          color: '#A4ABC4',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px'
                        }}
                      >
                        Stage
                      </label>
                    </div>

                    <Dropdown
                      className="card-boxes-right-text"
                      options={leadStage}
                      placeholder="Assigned"
                      defaultValue={leadAbout.stage ? leadAbout.stage : 'null'}
                      onChange={(e, dat) =>
                        onLeadStageHandler(dat.value, leadAbout.id)
                      }
                    />
                  </div>

                  <div className="callDetail">
                    <div className="Iconlabel">
                      <img alt="" src={dotIcon} />
                      <label
                        style={{
                          color: '#A4ABC4',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px'
                        }}
                      >
                        Tags
                      </label>
                    </div>

                    <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                      {tags.map(data => (
                        <span
                          onClick={() => removeTag(data.tag)}
                          style={{
                            backgroundColor: data.color,
                            padding: '4px 10px',
                            height: '30px',
                            color: '#fff',
                            borderRadius: '3px',
                            marginRight: '5px'
                          }}
                        >
                          {' '}
                          {data.tag}{' '}
                        </span>
                      ))}

                      <Popup
                        className="profile_popup"
                        content={<AddTagPopup tags={allTags} addTag={addTag} />}
                        size="large"
                        position="bottom center"
                        flowing
                        on="click"
                        trigger={
                          <div style={{ marginTop: '15px' }}>
                            {' '}
                            {!tags?.length && <a href="#">+ Add New Tag</a>}{' '}
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <div>{leadAbout?.type}</div>
                  <div>
                    <CommonButtons
                      style={{ display: 'block', margin: '10px auto' }}
                      content="Full profile"
                      background="blue"
                      btnClass="btn-verify"
                      onClick={openLead}
                    />
                  </div>
                </div>
                <div
                  className="lead_page"
                  style={{ backgroundColor: 'transparent', padding: '32px' }}
                >
                  <div className="main_wrapper">
                    <div className="good-fit-menu">
                      <div className="titleCall"></div>

                      <Label
                        style={{
                          fontWeight: 300,
                          color: 'black',
                          backgroundColor: '#ffffff',
                          borderColor: '#9a9393',
                          marginRight: 20
                        }}
                      >
                        Good Fit?
                      </Label>

                      <Menu>
                        <Popup
                          content="Yes"
                          trigger={
                            <Menu.Item
                              name="1"
                              active={leadInterest == '1'}
                              onClick={(e, name) =>
                                handleItemClick(e, { name }, 1)
                              }
                            >
                              <i className="fa fa-check"></i>
                            </Menu.Item>
                          }
                        />

                        <Popup
                          content="Not Reviewed"
                          trigger={
                            <Menu.Item
                              name="2"
                              active={leadInterest == '2'}
                              onClick={(e, name) =>
                                handleItemClick(e, { name }, 2)
                              }
                            >
                              <i className="fa fa-question"></i>
                            </Menu.Item>
                          }
                        />
                        <Popup
                          content="No"
                          trigger={
                            <Menu.Item
                              name="3"
                              active={leadInterest == '3'}
                              onClick={(e, name) =>
                                handleItemClick(e, { name }, 3)
                              }
                            >
                              <i className="fa fa-times"></i>
                            </Menu.Item>
                          }
                        />
                      </Menu>
                    </div>
                  </div>
                  <div
                    className="detail-title-icon"
                    style={{ textAlign: 'center' }}
                  >
                    <i
                      onClick={e => {
                        deleteModalView(true)
                      }}
                      style={{
                        fontSize: '20px',
                        margin: '10px',
                        color: 'red'
                      }}
                      className="icon trash alternate"
                    ></i>
                    <i
                      onClick={e => {
                        shareModalView(true)
                      }}
                      style={{
                        fontSize: '20px',
                        margin: '10px',
                        color: 'rgb(203, 211, 227)'
                      }}
                      className="icon share"
                    ></i>
                    <Tooltip title={'Mark as favourite'}>

                      <i
                        onClick={e => {
                          toggleFLead(leadAbout.id, !fvLead)
                        }}
                        style={
                          fvLead
                            ? {
                              fontSize: '20px',
                              margin: '10px',
                              color: '#0071eb'
                            }
                            : {
                              fontSize: '20px',
                              margin: '10px',
                              color: 'rgb(203, 211, 227)'
                            }
                        }
                        className="icon star"
                      ></i>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ flex: 1 }} className="contact-popup-detail">
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={rightPanes}
                leadData={leadData}
                leadOwner={leadOwner}
                leadScore={lScores}
              />
              <ModalEverGreen
                title="Alert"
                content="Are you sure you want to block ?"
                status={blockModal}
              ></ModalEverGreen>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="local-number-modal"
        open={deleteModal}
        onClose={e => deleteModalView(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this lead ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={() => deleteModalView(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={onDeleteLeadHandler}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>

      <Modal
        className="local-number-modal"
        open={shareModal}
        onClose={e => shareModalView(false)}
      >
        <Modal.Header className="modal-header">Share Lead </Modal.Header>
        <Modal.Content>
          <div className="modal-input-wrapper">
            <Popup
              content="Collaboration"
              mouseEnterDelay={500}
              trigger={
                <div className="tags_set">
                  <EverGreenSelect
                    defaultValues={defaultCollaborator}
                    head="Share"
                    type="members"
                    title="Share"
                    allOptions={collaboratesData}
                    onUpdateValues={onCollaboratorsHandler}
                  ></EverGreenSelect>
                </div>
              }
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  ) : (
    ''
  )
}

export default RightSide
