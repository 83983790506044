import React from 'react'
import './style.scss'
import axios from 'axios'
import { Dropdown } from 'semantic-ui-react'

import { CommonNotify } from '../../common/CommonNotify'

const bussinessLimecall = [
  'Industry',
  'Accounting',
  'Airlines',
  'Aviation',
  'Alternative Dispute Resolution',
  'Animation',
  'Apparel/Fashion',
  'Architecture/Planning',
  'Arts/Crafts',
  'Automotive',
  'Aviation/Aerospace',
  'Banking/Mortgage',
  'Biotechnology/Greentech',
  'Broadcast Media',
  'Building Materials',
  'Business Equipment',
  'Business Supplies',
  'Capital Markets',
  'Hedge Fund',
  'Private Equity',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Engineering ',
  'Software Engineering',
  'Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense/Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical Manufacturing',
  'Electronic Manufacturing',
  'Entertainment Production',
  'Movie Production',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Fundraising',
  'Furniture',
  'Gambling/Casinos',
  'Glass/Ceramics/Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design/Web Design',
  'Health/Fitness',
  'Higher Education ',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Individual Services',
  'Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology',
  'Insurance',
  'International Affairs',
  'Trade/Development',
  'Internet',
  'Investment Banking',
  'Investment Venture',
  'Investment Management',
  'Hedge Fund',
  'Private Equity',

  'Judiciary',
  'Law Enforcement',
  'Law Practice/Law Firms',
  'Legal Services',
  'Legislative Office',
  'Leisure/Travel',
  'Library',
  'Logistics/Procurement',
  'Luxury Goods/Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing/Sales',
  'Advertising',
  'Mechanical Engineering',
  'Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Mental Health Care',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Museums/Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers/Journalism',
  'Non-Profit/Volunteering',
  'Solar/Greentech',
  'Oil/Energy',
  'Online Publishing',
  'Other Industry',
  'Outsourcing/Offshoring',
  'Package Delivery',
  'Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary Education',
  'Secondary Education',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Relations/PR',
  'Public Safety',
  'Publishing Industry',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate/Mortgage',
  'Recreational Services',
  'Religious Institutions',
  'Renewables/Environment',
  'Research Industry',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing/Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation/Localization',
  'Transportation',
  'Utilities',
  'Venture Capital/VC',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine/Spirits',
  'Wireless',
  'Writing/Editing'
]

const roleLimeCall = [
  'Sale Manager',
  'Marketing / PR Manager',
  'Customer Services Manager',
  'CXO / General Manager',
  'It Manager',
  'Operation Manager',
  'Developer ',
  'software Engineer',
  'Analyst',
  'Student',
  'Personal Interest',
  'other'
]

const orgLImecall = [
  '1-10',
  '11-50',
  '51-250',
  '251-1K',
  '1k-5k',
  '5K-10K',
  '10K-50K',
  '50K-100K',
  '100K+'
]

class CallSummaryComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentRatingData: this.currentRatingData,
      leadOwnerOptionList: [],
      leadQualificationOptionList: [],
      callDisposition: '',
      feedbackText: ''
    }
  }

  currentRatingData = [
    {
      active: true
    },
    {
      active: true
    },
    {
      active: true
    },
    {
      active: false
    },
    {
      active: false
    }
  ]

  componentDidMount = () => {
    this.fetchMembersData()
    this.fetchWidgetsData()
    this.fetchCallData()
    this.getWidget()
  }

  getWidget = async () => {
    const w_id = await localStorage.getItem('widget_id')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-call-settings?widget_id=${w_id}`

    axios
      .get(url)
      .then(res => {
        const data = res.data.data.scores_tags

        const updateLeadScore =
          data.allowed_scores &&
          data.allowed_scores.map((item, index) => {
            return {
              key: index,
              text: item,
              value: item
            }
          })

        this.setState({
          leadScore: [...updateLeadScore]
        })
      })

      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  fetchCallData = () => {
    let token = localStorage.getItem('access_token')
  }

  fetchMembersData = () => {
    let token = localStorage.getItem('access_token')

    axios
      .get(`${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`)
      .then(res => {
        if (res.data.data) {
          const resData = res.data.data
          let tmpMembersList = []
          const adminUserAsMember = {
            key: 0,
            value: resData.admin.id,
            text: resData.admin.first_name + ' ' + resData.admin.last_name
          }

          tmpMembersList.push(adminUserAsMember)
          if (resData.members && resData.members.length) {
            resData.members.forEach((user, index) => {
              const userData = {
                key: index + 1,
                value: user.id,
                text: user.first_name + ' ' + user.last_name
              }
              tmpMembersList.push(userData)
            })
          }
          this.setState({ leadOwnerOptionList: tmpMembersList })
        }
      })
      .catch(error => {})
  }

  fetchWidgetsData = () => {
    let token = localStorage.getItem('access_token')

    axios
      .get(`${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`)
      .then(res => {
        if (res.data.data && res.data.data[0]) {
          const tmpScore = res.data.data[0].scores
          const ints = res.data.data[0].interested
          localStorage.setItem('widget_active', res?.data?.data?.[0]?.active)

          let scoreArr = []
          let intArr = []
          let bussinessArr = []
          let roleArr = []
          let orgArr = []

          if (tmpScore) {
            tmpScore.forEach((score, index) => {
              const scoreObj = {
                key: index,
                text: score,
                value: score
              }
              scoreArr.push(scoreObj)
            })
          }

          if (ints) {
            ints.forEach((score, index) => {
              const intObj = {
                key: index,
                text: score,
                value: score
              }
              intArr.push(intObj)
            })
          }

          //* add industry
          bussinessLimecall.forEach((score, index) => {
            const intObj = {
              key: index,
              text: score,
              value: score
            }
            bussinessArr.push(intObj)
          })

          roleLimeCall.forEach((score, index) => {
            const intObj = {
              key: index,
              text: score,
              value: score
            }
            roleArr.push(intObj)
          })

          orgLImecall.forEach((score, index) => {
            const intObj = {
              key: index,
              text: score,
              value: score
            }
            orgArr.push(intObj)
          })

          this.setState({ leadQualificationOptionList: scoreArr })
          this.setState({ leadInterestOptionList: intArr })
          this.setState({ bussinessOptionList: bussinessArr })
          this.setState({ roleOptionList: roleArr })
          this.setState({ orgOptionList: orgArr })
        }
      })
      .catch(error => {})
  }

  getStarCard = () => {
    return this.state.currentRatingData.map((data, index) => (
      <i
        className={
          'material-icons ' + (data.active ? 'star-icon' : 'star-outline-icon')
        }
        onClick={() => this.updateRating(index)}
      >
        {data.active ? 'star' : 'star_outline'}
      </i>
    ))
  }

  updateRating = index => {
    let currentRatingData = this.state.currentRatingData
    currentRatingData.forEach((data, i) => {
      if (i <= index) {
        data.active = true
      } else {
        data.active = false
      }
    })
    this.setState({ currentRatingData: currentRatingData })
  }

  handleChangeInput = (value, key = 'callDisposition') => {
    this.setState({
      [key]: value
    })
  }

  saveCallSummaryData = () => {
    this.saveLeadQualification()
    this.saveLeadOwner()

    // if (!this.state.feedbackText) {
    //   return false
    // }

    let rating = 0
    for (let i = 0; i < 5; i++) {
      if (this.state.currentRatingData[i].active) {
        rating = i + 1
      } else {
        break
      }
    }

    const feedbackData = {
      agent_feedback_rate: rating,
      agent_feedback_text: this.state.feedbackText
    }

    this.props.handleCallSummaryPost(feedbackData)
  }

  async savelead() {
    const lead_Id = this.props.currentCallData.id

    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${lead_Id}/info`

    const postData = {
      role: this.state.role,
      company_name: this.state.companyName,
      industry: this.state.industry,
      employees: this.state.orgSize,
      interested: this.state.leadInterest
    }

    axios
      .post(url, postData)
      .then(res => {})
      .catch(err => {})
  }

  saveLeadQualification = () => {
    if (!this.state.leadQualification) {
      return false
    }

    const qualificationPostData = {
      lead_id: this.props.currentCallData.id,

      score: this.state.leadQualification
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${this.props.currentCallData.id}/scores`
    axios
      .post(url, qualificationPostData)
      .then(res => {})
      .catch(error => {})
  }

  saveLeadOwner = () => {
    if (!this.state.leadOwner) {
    }

    const qualificationPostData = {
      lead_id: this.props.currentCallData.id,

      user_id: this.state.leadOwner
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/set-owner`

    if (this.state.leadOwner) {
      axios
        .post(url, qualificationPostData)
        .then(res => {})
        .catch(error => {})
    }

    const lead_Id = this.props.currentCallData.id
    const url2 = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${lead_Id}/info`

    const postData = {
      role: this.state.role,
      company_name: this.state.companyName,
      industry: this.state.industry,
      employees: this.state.orgSize,
      interested: this.state.leadInterest
    }

    axios
      .post(url2, postData)
      .then(res => {})
      .catch(err => {})
  }

  saveLeadScore = () => {
    const value = this.state.score

    const leadId = this.props.currentCallData.id

    if (value == 'null' || value) {
      CommonNotify('Cant set this option please select other option', 'warning')
      return
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadId}/scores`
    const postData = {
      score: value
    }

    axios
      .post(url, postData)
      .then(res => {
        CommonNotify('Score updated', 'success')
      })
      .catch(err => {
        CommonNotify('Not able to update  Score')
      })
  }

  render = () => {
    const {timerData} =this?.props
    return (
      <div className="call-summary-container-main">
        <div className="hor-row heading-main">
          Call Summary
          <i
            className="material-icons call-summary-close-icon"
            onClick={() => this.props.handleCallSummaryPost(null)}
          >
            close
          </i>
        </div>

        <div className="hor-row call-info-container-main">
          <div className="user-image-container">
            <div className="user-image"></div>
          </div>
          <div className="hor-row user-name-container">
            {this.props.currentCallData.customer_name &&
            this.props.currentCallData.customer_name !== 'undefined'
              ? this.props.currentCallData.customer_name
              : ' Unknown'}
          </div>

          {this.props.currentCallData.type !== 'DIGITAL_CALL' && (
            <div className="hor-row mobile-number-container">
              {this.props.currentCallData.phone_number}
            </div>
          )}
            <div className='hor-row mobile-number-container'>
              {new Date(timerData * 1000)
                .toISOString()
                .substr(11, 8)}
                </div>
        </div>

        <div className="hor-row call-rating-input-form-container">
        {timerData >0 &&
            ( 
                <div className='hor-row input-form-row'>
                  <div className='label-container'>
                    Call rating
                  </div>
                  <div className='value-container'>
                    <div className='hor-row star-container'>
                      { this.getStarCard() }
                    </div>
                  </div>
                </div>)
}
       

          <div className="hor-row input-form-row">
            <div className="label-container">Owner</div>
            <div className="value-container">
              <Dropdown
                onChange={(e, { value }) =>
                  this.handleChangeInput(value, 'leadOwner')
                }
                options={this.state.leadOwnerOptionList}
                selection
                className="custom-dropdown-class"
                value={this.state.leadOwner}
              />
            </div>
          </div>

          <div className="hor-row input-form-row">
            <div className="label-container">LeadScore</div>
            <div className="value-container">
              <Dropdown
                onChange={(e, { value }) =>
                  this.handleChangeInput(value, 'leadQualification')
                }
                options={this.state.leadQualificationOptionList}
                selection
                className="custom-dropdown-class"
                value={this.state.leadQualification}
              />
            </div>
          </div>

          {this.state.leadInterestOptionList && (
            <div className="hor-row input-form-row">
              <div className="label-container">Interested in</div>
              <div className="value-container">
                <Dropdown
                  onChange={(e, { value }) =>
                    this.handleChangeInput(value, 'leadInterest')
                  }
                  options={this.state.leadInterestOptionList}
                  selection
                  className="custom-dropdown-class"
                  value={this.state.leadInterest}
                />
              </div>
            </div>
          )}

          {/* <div className='hor-row input-form-row'>
                  <div className='label-container'>
                    Industry
                  </div>
                  <div className='value-container'>
                      <Dropdown                       
                        onChange={ (e, { value }) => this.handleChangeInput(value, 'industry')}
                        options={this.state.bussinessOptionList}
                        selection
                        className='custom-dropdown-class'
                        value={this.state.industry}
                      />
                  </div>
                </div>   */}

          {/* <div className='hor-row input-form-row'>
                  <div className='label-container'>
                    Role
                  </div>
                  <div className='value-container'>
                      <Dropdown
                        onChange={ (e, { value }) => this.handleChangeInput(value, 'role')}
                        options={this.state.roleOptionList}
                        selection
                        className='custom-dropdown-class'
                        value={this.state.role}
                      />
                  </div>
                </div>  */}

          {/* <div className='hor-row input-form-row'>
                  <div className='label-container'>
                    Organization Size
                  </div>
                  <div className='value-container'>
                      <Dropdown
                        onChange={ (e, { value }) => this.handleChangeInput(value, 'orgSize')}
                        options={this.state.orgOptionList}
                        selection
                        className='custom-dropdown-class'
                        value={this.state.orgSize}
                      />
                  </div>
                </div>  */}

          {/* <div className='hor-row input-form-row'>
                  <div className='label-container'>
                    Company name
                  </div>
                  <input style={{ width: "314px",   background: "inherit",  color: "#fff", border: "1px solid #A4ABC5",
                    fontSize: "12px",
                    borderRadius: "5px",
                    marginTop: "-4px",
                    height: "30px",
                    }} type="text"
                    value={this.state.companyName}
                    onChange = { (event) => this.handleChangeInput(event.target.value, 'companyName') } />                 
                </div> */}

          <div className="hor-row input-form-row">
            <div className="label-container">Call Outcome</div>
            <input
              style={{
                width: '314px',
                background: 'inherit',
                color: '#fff',
                border: '1px solid #A4ABC5',
                fontSize: '12px',
                borderRadius: '5px',
                marginTop: '-4px',
                height: '30px'
              }}
              type="text"
              value={this.state?.outcome}
              onChange={event =>
                this.handleChangeInput(event.target.value, 'outcome')
              }
            />
          </div>

          <div className="hor-row input-form-row">
            <div className="label-container">Call Notes</div>
            <textarea
              onChange={event =>
                this.handleChangeInput(event.target.value, 'feedbackText')
              }
            ></textarea>
          </div>

          <div className="hor-row input-form-row">
            <button
              className="save-button-container"
              onClick={() => this.saveCallSummaryData()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
  }
}
export default CallSummaryComponent
