import { combineReducers } from 'redux'
import home from './home'
import settings from './settings'
import { apiSlice } from '../features/api/apiSlice'
import numbers from './numbers'
import dialer from './dialer'
import conversation from './conversation'

const CLEAR_STATE = 'user/logoutUser'
const appReducer = combineReducers({
  home,
  settings,
  numbers,
  dialer,
  conversation,
  [apiSlice.reducerPath]: apiSlice.reducer
})

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STATE) {
    // eslint-disable-next-line
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
