import React from 'react'
import moment from 'moment'
import { Image, Icon } from 'semantic-ui-react'

const AutoActivity = ({act, color, onActivityDelete, clickLeadUpdate}) => {

    const diffDates = dt => {
        // const date1 = new Date(dt).getTime() / 1000
        // const date2 = Date.now() / 1000;
        // const diffTime = date1 - date2;
    
        dt = moment(dt)
        return dt.format("MMMM Do, YYYY HH:mm A")
       
         //return  Math.ceil(diffTime / ( 60 * 60 * 24)); 
      }

    
  return (
            <div
                    className={`sl-activities-item ${color(act.type)['colorClass']}`}
             >
            <div className="sl-icon">
            <i className={`${color(act.type)['icon']}`}></i>
            </div>

            <div className="sl-content box px-3 py-2" style={{ display:"flex", flexDirection:"row",  alignItems: "stretch" }}>
                <div style={{  flex: "80%", minHeight: "80px" }}>
                    <p className="text-color mb-0">
                    <h2 style={{ fontSize: "1.2rem", display: "inline-block" }}>{ act.type ? act.type : 'Untitled Activity' }</h2> 
                    {/* <Icon onClick={ (e) => onActivityDelete(act.id) } style={{ marginLeft: "10px" }} name='trash alternate outline' />
                    <Icon onClick={ (evt)=> clickLeadUpdate(act) } style={{ marginLeft: "3px" }} name='edit outline' /> */}
                    <p>{act.action.data} </p>
                    </p>
                    <small className="time-label">{  diffDates(act.date) }</small>
                </div>

                <div style={{  flex: "10%", float: "right" }}>                                  
                                
                </div>
                
                <div style={{  margin: "0 auto" }}>                               
                                    {/* <Image src={act?.agent?.profile_image }
                                size='small' style={{ width: "40px", height: "40px" }} disabled/> */}
                                <p className="user-name" style={{paddingTop: "10px",  color: "#2a3740"}}>{ act?.action?.userName }</p> 
                </div>
            
            </div>

            <div className="sl-content box-responsive px-2 py-1" style={{ display:"flex", flexDirection:"column",  flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                <div>
                   <h2 style={{ fontSize: "1.2rem", display: "inline-block" }}>{ act.type ? act.type : 'Untitled Activity' }</h2>                   
                </div> 
                <div>
                  <p style={{ textAlign: "center" }} >{act.action.data} </p>
                </div>
                <div>
                   <p  style={{paddingTop: "10px",  color: "#2a3740"}}>{ act?.action?.userName }</p> 
                </div>
                <div>
                <small className="time-label2">{  diffDates(act.date) }</small>
                </div>
            </div>


        </div>


         
  )
}

export default AutoActivity