import React, { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import CommonButtons from '../../common/CommonButtons'
import Axios from 'axios'
import { CommonNotify } from '../../common/CommonNotify'

export default function TestCall(props) {
  const [number, setNumber] = useState(null)
  const [details, setDetails] = useState({})

  useEffect(() => {
    getNumber()
  }, [])

  const getNumber = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-user-numbers`
    Axios.get(url)
      .then(res => {
        setDetails(res?.data?.data?.[0] || {})
        setNumber(
          res?.data?.data[0]?.phone_number ||
            res?.data?.data?.phoneNumbers?.[0] ||
            null
        )
      })
      .catch(error => {
        CommonNotify(
          error?.response?.data?.errors[0] || 'Something went to wrong!'
        )
      })
  }
  return (
    <div>
      <div className="testcall-container">
        <div className="testcall-heading">
          <h5>Make a test call</h5>
          <p>
            Once you're logged in make a test call with the LimePhone mobile or
            desktop app
          </p>
        </div>
        <div className="d-flex guide-box">
          <p>
            <i class="fas fa-lightbulb"></i>
          </p>
          <p>
            <span>Guide: </span>Open your LimePhone mobile app or web dialer, dial the phone number below on your dailpad and wait for the audio message prompts 
          </p>
        </div>
        <hr></hr>
        <div className="testcall-number">
          <h6>Test call number</h6>
          <span>{number || 'N/A'}</span>
        </div>
        <hr></hr>
        <div className="d-flex important-box">
          <p>
            <InfoIcon />
          </p>
          <div>
            <p className="important-text">Important:</p>
            <ul>
              <li>
                <span>Enable permission</span> for LimePhone apps to make and
                receive calls.
              </li>
              <li>
                <span>Test call failed?</span>
                <a href="http://help.limephone.io/"> Chat with us</a> or{' '}
                <a href="http://limephone.io/onboarding">book an onboarding session</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="quicksetup-footer footer-button mt-auto"
        style={{ justifyContent: 'space-between' }}
      >
        {props?.btnBack === false ? (
          <span></span>
        ) : (
          <CommonButtons
            content="Back"
            btnClass="btn-modal-style btn-back"
            onClick={props.prev}
          />
        )}
        <CommonButtons
          type="button"
          content="Test call successfull"
          background="blue"
          onClick={props.next}
          //   loading={loading}
        />
      </div>
    </div>
  )
}
