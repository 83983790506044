import React, { useEffect, useState } from 'react'
import { Accordion } from 'semantic-ui-react'
import moment from 'moment'

function RecentVisits({ data, history, open }) {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (open) {
      setActiveIndex(3)
    }
  }, [open])

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const activeIndexs = activeIndex
    const newIndex = activeIndexs === index ? -1 : index
    setActiveIndex(newIndex)
  }

  function renderlist() {
    let list = []
    if (history && history?.length > 0) {
      history.map((item, index) => {
        return list.push(
          <div
            className="form-group row customer-journey mb-0 align-items-start"
            key={index}
          >
            <div className="icon-img">
              {' '}
              <img
                src="https://qa.limecall.com/assets/lead_details_icons/Lead popup edited-33.svg"
                className="lead-data-icon"
              />
            </div>
            <div>
              <p className="mb-0 text-color lead_title">
                <label>
                  <b>
                    {moment.utc(item.visitied_on).format('YYYY-MM-DD hh:mm:ss')}
                  </b>
                </label>
              </p>

              <p className="mb-0 text-color lead_title">
                <label>{item?.page_title?.substring(0, 35)}</label>
              </p>

              <p className="mb-0 text-color lead_title">
                <a href={item.page_url} target="_blank">
                  <b style={{ lineBreak: 'anywhere' }}>
                    {item?.page_url?.substring(0, 35)}
                  </b>
                </a>
              </p>
            </div>
          </div>
        )
      })
      return list
    }
    return list
  }
  return (
    <>
      <Accordion className="Lead_wrapper" fluid styled>
        <Accordion.Title
          active={activeIndex === 3}
          index={3}
          onClick={handleClick}
          className="proper-title"
        >
          Customer Journey
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 3}>
          {renderlist()}
        </Accordion.Content>
      </Accordion>
    </>
  )
}

export default RecentVisits
