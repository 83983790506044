import axios from 'axios'
import { GET_NUMBERS } from './types'
import { logOut } from '../common/ProfileModal'

// Get All Numbers
export const getNumbers = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-user-numbers`
  const res = await axios.get(url)

  dispatch({
    type: GET_NUMBERS,
    payload: res.data
  })
}
