import React from 'react'

const BulkOperationMenu = ({ options, saveOption }) => {
  return (
    <>
      <div className="dispaly_profile1" style={{ zIndex: '2000 !important' }}>
        <div
          className="dispaly_profile_detail1"
          style={{ minWidth: '300px', maxHeight: '250px', overflow: 'auto' }}
        >
          <div className="display_button1">
            <div className="row" style={{ display: 'block' }}>
              {options?.map((data, index) => (
                <div
                  onClick={() => saveOption(data?.value, index)}
                  style={{
                    backgroundColor: '#13ca6e',
                    minWidth: '200px',
                    padding: '4px 10px',
                    color: '#fff',
                    borderRadius: '3px',
                    marginBottom: '10px',
                    cursor: 'pointer'
                  }}
                >
                  {' '}
                  {data?.text}{' '}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BulkOperationMenu
