import React, { useState, useEffect } from 'react'
import { Checkbox } from 'semantic-ui-react'
import axios from 'axios'
import close from '../assets/images/close.png'

const ManageAnalyticSetting = props => {
 
  const items = [
    { text: 'Call failed', checked: false, name: 'call_failed' },
    { text: 'New message', checked: false, name: 'new_message' },
    { text: 'Widget closed', checked: false, name: 'widget_closed' },
    { text: 'Widget opened', checked: false, name: 'widget_opened' },
    { text: 'Button clicked', checked: false, name: 'button_clicked' },
    { text: 'Call completed', checked: false, name: 'call_completed' },
    { text: 'Call in progress', checked: false, name: 'call_in_progress' },
    { text: 'Live call created', checked: false, name: 'live_call_created' },
    { text: 'New scheduled call', checked: false, name: 'new_scheduled_call' },
    {
      text: 'Digital call created',
      checked: false,
      name: 'digital_call_created'
    }
  ]
  const [checkiteam, setCheckiteam] = useState(items)
  const [savedChannle, setSavedChannle] = useState({})
  const [channle, setChannle] = useState(null)
  const [Id, setId] = useState()
  const [checkboxData, setCheckboxData] = useState({
    call_failed: 0,
    new_message: 0,
    widget_closed: 0,
    widget_opened: 0,
    button_clicked: 0,
    call_completed: 0,
    call_in_progress: 0,
    live_call_created: 0,
    new_scheduled_call: 0,
    property_id: 'UA-160610954-1'
  })

  const onUpdateHandler = async() => {

    const apiToken = await localStorage.getItem('access_token')

    

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/update-google-analytics-settings?id=${Id}`
    axios
      .post(url, checkboxData)
      .then(res => {
        props.closeAnalyticsModal()
       
      })
      .catch(error => {
      
      })
  }

  const fetchUserIntegrations = async () => {

    const apiToken = await localStorage.getItem('access_token')


    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/user-integrations`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          res.data.data.map(obj => {
            if (obj.integration == 'Google Analytics') {
              setId(obj.id)
            }
          })
        }
      })
      .catch(er => {
        
      })
  }

  const onCheckboxClickHandler = (e, check) => {
    const { checked, name } = check
    var value = checked
    if (value) {
      value = 1
    } else {
      value = 0
    }
    setCheckboxData({
      ...checkboxData,
      [name]: value
    })
  }
  useEffect(() => {
    fetchUserIntegrations()
  }, [])

  return (
    <>
      <div style={{ overflowY: "scroll", minHeight: "600px" }}>
      <div style={{borderBottom: 1, borderBottomStyle: 'solid',borderBottomColor : 'grey', padding: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h1 style={{ fontSize: 23, fontWeight: 'bold', textAlign: 'center', borderBottomWidth: 1, borderBottomColor: 'gray' }}>Manage Analytics Settings</h1>
          <div className="close-icon" onClick={()=>props.closeAnalyticsModal()}>
            <img src={close} alt="close"/>
          </div>
        </div>
        
        <div style={{display: 'inline-block', padding: 15, position: 'relative',width:'100%', marginTop: 10, marginBottom: 20}}>
          <h1 style={{fontSize: 18, fontWeight: 'normal', marginBottom: 20, textAlign: 'center'}}>Please select your target events</h1>
          <table style={{width: 'max-content', left: 0, right: 0, display: 'block', marginRight: 'auto', marginLeft: 'auto'}}>
            {checkiteam.map(data => {
              return (
                <>
                  <tr>
                    <td style={{paddingRight: 10}}>
                      <span>{data.text}</span>
                    </td>
                    <td style={{paddingLeft: 10}}>
                      <label style={{marginTop: 5}} className="md-check">
                        <Checkbox
                          onChange={onCheckboxClickHandler}
                          name={data.name}
                        />
                      </label>
                    </td>
                  </tr>
                </>
              )
            })}
          </table>
          <div style={{marginTop: 15, marginLeft: '50%', width: '100px' }} className="update-button">
            <button
              style={{padding: 7, width: '100%'}}
              type="submit"
              className="btn btn-primary"
              onClick={onUpdateHandler}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default ManageAnalyticSetting
