import React from 'react';
import {Button} from 'evergreen-ui';

function ButtonEvergreen({title,onClick,loading, style, className, iconBefore, iconAfter}) {
    return (
      <>
        <Button iconBefore={iconBefore} iconAfter={iconAfter}  className={className} style={style} marginRight={16} isLoading={loading} onClick={onClick} appearance="primary">
          {title}
        </Button>
      </>
    )
  }

  export default ButtonEvergreen