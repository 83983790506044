

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Grid } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

import callLOg from '../../../assets/images/call.svg'
import priceIcon from '../../../assets/images/price-tag.svg'
import assignedIcon from '../../../assets/images/add-contact.png'
import mention from '../../../assets/images/mention.png'
import satisfaction from '../../../assets/images/satisfaction.png'
import editIcon from '../../../assets/images/edit-icon.svg'
import penIcon from '../../../assets/images/pen.svg'
import QualifyFooter from './QualifyFooter'
import UpdateLeadModal from '../../../common/UpdateLeadModal'
import { CommonNotify } from '../../../common/CommonNotify'
import axios from 'axios'
import EditDetailOpen from '../../contactsPanels/editDetailsPopUp'
import { useGetContactsQuery } from '../../../features/api/apiSlice'
import AddContactModal from '../../contactsPanels/addNewContact'
import AddContactModalLead from '../../contactsPanels/addNewContactLead'
import { NULL } from 'sass'
const intialEditData = {
  name: '',
  phone_number: '',
  phone_number: '',
  email: '',
  country: '',
  type: 'individual',
  owner_id: '',
  owner_name: '',
  first_name: '',
  organization_name: ''
}
const LeadContact = ({ leadData, leadScore, handleReGetValue, teamMembers }) => {
  const resContacts = useGetContactsQuery({ date: 'all', type: 'all' })
  const [isEdit, setIsEdit] = useState(false)
const [contact, setContact] = useState({})
  const leads = leadData?.logs
  const [dropdownValue, setDropdownValue] = useState(null)
  const [editContact, setEditContact] = useState(intialEditData)
  const [companyData, setCompanyData] = useState()
const [addOpen, setAddOpen] = useState(false)
  const leadAbout = leadData?.lead


  // const contact =leadData?.lead?.contact
  const history = useHistory()

  const fetchContactByIds = () => {


      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${leadData?.lead?.contact?.id}`
      axios
        .get(url)
        .then(res => {
          setContact({
            ...contact,
            id: res?.data?.data?.id,
            name: res?.data?.data?.name,
            email: res?.data?.data?.email,
            phone_number: res?.data?.data?.phone_number,
            owner_id: res?.data?.data?.owner?.id
              ? res?.data?.data?.owner?.id
              : 0,
            owner_name:
              res?.data?.data?.owner ?
              res?.data?.data?.owner?.first_name +
                ' ' +
                res?.data?.data?.owner?.last_name
                ? res?.data?.data?.owner?.first_name +
                ' ' +
                res?.data?.data?.owner?.last_name
                : '' :'-',
            country: res?.data?.data?.country,
            type: res?.data?.data?.type,
            first_name: res?.data?.data?.owner?.first_name,
            organization_name: res?.data?.data?.organization_name
              ? res?.data?.data?.organization_name
              : 'test'
          })
        })
        .catch(error => {
          CommonNotify(
            error?.response?.data?.errors?.[0] || 'something went to wrong!'
          )
        })
    }
 
  
  

  useEffect(() => {
    if (leadData?.lead?.contact?.id){
      
      fetchContactByIds()
    }
 
  }, [])
  
  useEffect(() => {
    if (resContacts.isSuccess) {
      let contactData = resContacts?.data?.data
      const responseCompany = contactData?.filter(contact => {
        return contact.type == 'organization'
      })
      setCompanyData(responseCompany)
    }
  }, [resContacts])
  
  const handleInputChange = (event, code, type) => {
    if (type === 'phone_number') {
      setContact(prevContactData => ({
        ...prevContactData,
        phone_number: `+${event}`,
        phone_country: code.countryCode
      }))
    } else {
      const { name, value } = event.target
      editContact[name] = value
      setContact(prevContactData => ({
        ...prevContactData,
        [name]: value
      }))
    }
  }



  const onclickOwner = () => {
    let retData = contact
    if (contact.owner_id == 0) {
      delete contact.owner_id
      delete contact.owner_name
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/update/${leadData?.lead?.contact?.id}`
    axios
      .post(url, retData)
      .then(res => {
        CommonNotify('Contact successfully updated!', 'success')
        setIsEdit(false)
      })
      .catch(error => {
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Cant update contact!'
        )
      })
  }

  return (
    <div className="contact-card aboutTabpane">
      <div className="contact-card-boxes">
          {contact?.id ? 
        <Grid className="card-boxes-text">
          <><h4
          
            h4>Contact Detail :</h4>
          <img src={editIcon} alt='edit' onClick={() => setIsEdit(!isEdit)}/>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Name:</p>
            </Grid.Column>
            <Grid.Column width={12}>
            <p className="card-boxes-right-text">{contact.name}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Email:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p title="Block user email" className="card-boxes-right-text">
 <p className="card-boxes-right-text">{contact.email}</p>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Phone Number:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p title="Block user email" className="card-boxes-right-text">
                {contact?.phone_number}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Company Name:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p title="Block user email" className="card-boxes-right-text">
                {contact?.organization_name || '-'}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Country:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p title="Block user email" className="card-boxes-right-text">
                {contact?.country || '-'}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Owner:</p>
            </Grid.Column>
            <Grid.Column width={12}>
            <p title="Block user email" className="card-boxes-right-text">
                {contact?.owner_name || '-'}
              </p>
            </Grid.Column>
          </Grid.Row>
          </>
        </Grid>
        :<Button onClick={()=>setAddOpen(!addOpen)}>Add Contact</Button>}
      </div>{' '}
      {addOpen ? (
        <AddContactModalLead
          fetchDataOnSave={handleReGetValue}

          Agentmembers={teamMembers ? teamMembers : []}
          setAddOpen={setAddOpen}
          fetchAllContacts={()=>null}
          addOpen={addOpen}
          // countryOptions={countryOptions}
          leadId={leadData?.lead?.id}
          companyLoadedData={companyData}
          companyList={companyData}

        />
      ) : (
        ''
      )}
      <EditDetailOpen
        companyList={companyData}
        setDetailOpen={setIsEdit}
        editDetailOpen={isEdit}
        handleInputChange={handleInputChange}
        editContact={contact}
        setEditContact={setContact}
        onclickOwner={onclickOwner} />
    </div>
  )
}

export default LeadContact
