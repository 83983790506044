import React, { useState } from 'react'
import {
  Button,
  Dropdown,
  Input,
  Modal,
  TextArea,
  Image,
  Icon
} from 'semantic-ui-react'
import { flex } from 'ui-box'
import moment from 'moment'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import AddActivity from './AddActivity'
import ManualActivity from './ManualActivity'
import AutoActivity from './AutoActivity'

const AllTab = ({
  activity,
  color,
  FetchLead,
  onActivityDelete,
  addActivityBtn,
  logANoteBtn
}) => {
  const diffDates = dt => {
    // const date1 = new Date(dt).getTime() / 1000
    // const date2 = Date.now() / 1000;
    // const diffTime = date1 - date2;

    dt = moment(dt)
    return dt.format('MMMM Do, YYYY HH:mm A')

    //return  Math.ceil(diffTime / ( 60 * 60 * 24));
  }

  const clickLeadUpdate = act => {
    const payload = {
      title: act.title,
      due_date: act.due_date,
      type: act.type,
      summary: act.summary,
      actId: act.id
    }
    FetchLead(payload)
  }

  return (
    <div className="activities-wrapper" style={{ paddingTop: '10px' }}>
      <div className="button-position title_wrapper mb-3">
       {logANoteBtn}
       {addActivityBtn}
      </div>
      {activity?.map(act => {
        return (
          <div className="activities-main">
            {act.auto ? (
              <AutoActivity
                act={act}
                color={color}
                onActivityDelete={onActivityDelete}
                clickLeadUpdate={clickLeadUpdate}
              />
            ) : (
              <ManualActivity
                act={act}
                color={color}
                onActivityDelete={onActivityDelete}
                clickLeadUpdate={clickLeadUpdate}
              />
            )}
          </div>
        )
      })}

      {/* <AddActivity/> */}
    </div>
  )
}

export default AllTab
