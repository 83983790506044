import React, {useState, useEffect} from 'react'
import { TextArea } from 'semantic-ui-react'
import _ from 'lodash'
import NodeToggle from '../../common/NodeToggle'
import CommonButton from '../../common/CommonButtons'
import CommonSelect from '../../common/CommonSelect'
import CommonInput from '../../common/CommonInput'
import axios from 'axios'


import iconStyle from '../../assets/images/Dashboard 2-07.png'
import callMeBack from '../../assets/images/phone-black.svg'
import callMeBackRound from '../../assets/images/call-back.png'
import callMeLetter from '../../assets/images/mini-clock.png'
import { useSelector } from 'react-redux'



const bubbleDefaultSetting = {
  color: '#0080FF',
  shadow: false,
  textOnline: 'Would you like us to call you now ?',
  textOffline: 'Hey there! We are offline now ?',
  position: '',
  labelColor: '#2B2B2C',
  shape: 'default'
}

const bussinessLimecall = [
  'Industry',
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel/Fashion',
  'Architecture/Planning',
  'Arts/Crafts',
  'Automotive',
  'Aviation/Aerospace',
  'Banking/Mortgage',
  'Biotechnology/Greentech',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies/Equipment',
  'Capital Markets/Hedge Fund/Private Equity',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software/Engineering',
  'Computer/Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense/Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment/Movie Production',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Fundraising',
  'Furniture',
  'Gambling/Casinos',
  'Glass/Ceramics/Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design/Web Design',
  'Health/Fitness',
  'Higher Education/Acadamia',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Individual/Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology/IT',
  'Insurance',
  'International Affairs',
  'International Trade/Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management/Hedge Fund/Private Equity',
  'Judiciary',
  'Law Enforcement',
  'Law Practice/Law Firms',
  'Legal Services',
  'Legislative Office',
  'Leisure/Travel',
  'Library',
  'Logistics/Procurement',
  'Luxury Goods/Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing/Advertising/Sales',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Mental Health Care',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Museums/Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers/Journalism',
  'Non-Profit/Volunteering',
  'Oil/Energy/Solar/Greentech',
  'Online Publishing',
  'Other Industry',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Relations/PR',
  'Public Safety',
  'Publishing Industry',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate/Mortgage',
  'Recreational Facilities/Services',
  'Religious Institutions',
  'Renewables/Environment',
  'Research Industry',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing/Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation/Localization',
  'Transportation',
  'Utilities',
  'Venture Capital/VC',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine/Spirits',
  'Wireless',
  'Writing/Editing'
]
const roleLimeCall = [
  'Sale Manager',
  'Marketing / PR Manager',
  'Customer Services Manager',
  'CXO / General Manager',
  'It Manager',
  'Operation Manager',
  'Developer / software Engineer / Analyst',
  'Student / Personal Interest',
  'other'
]
const orgLimecall = [
  '1-10',
  '11-50',
  '51-250',
  '251-1K',
  '1k-5k',
  '5K-10K',
  '10K-50K',
  '50K-100K',
  '100K+'
]


let widgetDefaultSetting = {
  template: 'Classic'
}


const FormPreview = ( {widgetObject, widget,  allowedTabs,
  webCallDisplay,
  callMeBackDisplay,
  callMeLaterDisplay,
  leaveMessageDisplay,
  onClickTableCategory,
  widgetSettData,
  widgetStyledData,
  loading,
  fullNameToggle,
  emailToggle} ) => {
  
  const [widgetStyleData, setWidgetStyleData] = useState({})
  const [widgetSetting, setWidgetSetting] = useState(widgetDefaultSetting)
  const [teamData, setTeamData] = useState([])
  const [interestData, setInterestData] = useState([])
  const [leaveMessageButtonText, setLeaveMessageButtonText] = useState(
    'Send Message'
  )
  const [buttonColor, setButtonColor] = useState('#662D91')
  const [widgetApiResData, setWidgetApiResData] = useState({})

  const [clickToCallTabText, setClickToCallTabText] = useState(
    'Click to connect to agent!'
  )
  const [clickToCallButtonText, setClickToCallButtonText] = useState(
    'Start Call'
  )

  const [widgetApiData, setWidgetApiData] = useState(widgetDefaultSetting)
  const [bubbleSetting, setBubbleSetting] = useState(bubbleDefaultSetting)
  const [widgetAllSetting, setWidgetALlSetting] = useState(null)
  const teamsData = useSelector((state) => state.home?.teamsData)

  useEffect(() => {    
    if (!widget?.id) return   
    fetchSetting()     
  }, [widget, widgetObject])


  useEffect(() => {    
    if (!widget?.id) return   
    if(!widgetStyledData) return;
    setWidgetStyleData(widgetStyledData)
  }, [widgetStyledData])

  useEffect(() => {    
    if (!widget?.id) return   
    if(!widgetSettData) return;
    setWidgetSetting(widgetSettData)
  }, [widgetSettData])

  


  useEffect(() => {
    if (teamsData) {
      const teams = teamsData?.map(team => {
        return team.name
      })
      setTeamData(teams);
    }

  }, [teamsData])







  useEffect(() => {
  }, [allowedTabs])



  


  

  const setBubble = data => {
    let bubbleSetting1 = { ...bubbleSetting }

    bubbleSetting1.shadow = data.bubble_template == 2 ? true : false
    bubbleSetting1.color = data.circle_color
    bubbleSetting1.textOffline = data.bubble_offline_text
    bubbleSetting1.textOnline = data.bubble_text

    if (data.bubble_position == 'bottom_right') {
     
    } else if (data.bubble_position == 'bottom_left') {
    
    } else if (data.bubble_position == 'top_right') {
    
    } else if (data.bubble_position == 'top_left') {
     
    }

    if (data.shape == 'square') {
      data.shape = 'btnRight'
    } else {
      data.shape = 'btnLeft'
    }

    bubbleSetting1.position = data.bubble_position
    bubbleSetting1.shape = data.shape

    setBubbleSetting(bubbleSetting1)
  }

  const setWidget = data => {
    let widgetSettingData = { ...widgetSetting }


    const dataTemplateType = parseInt(data.template_type);
    if (dataTemplateType == 2) {
      widgetSettingData.template = 'Smart'
    } else if (dataTemplateType == 1) {
      widgetSettingData.template = 'Classic'
    } else if (dataTemplateType == 3) {
      widgetSettingData.template = 'Modern'
    }

    widgetSettingData.classicType =
      data.classic_template_design_type == 1 ? false : true
    widgetSettingData.font = data.font

    widgetSettingData.timerColor = data.popup_timer_color
    widgetSettingData.callColor = data.submit_request_call_color

    widgetSettingData.socialProof = data.social_proof
    widgetSettingData.legalNote = data.terms_and_conditions
    widgetSettingData.legalType = data.terms_type
    widgetSettingData.legalStatus = data.terms_and_conditions_status
    widgetSettingData.privateStatus = data.privacy_note_status
    widgetSettingData.privatePolicy = data.privacy_note

    if (widgetSettingData.legalType == 0) {
      widgetSettingData.legalType = 'Text'
    } else if (widgetSettingData.legalType == 1) {
      widgetSettingData.legalType = 'URL'
    } else {
      widgetSettingData.legalType = 'HTML'
    }

    widgetSettingData.webCall = data.digital_call
    widgetSettingData.callLeter = data.call_me_later
    widgetSettingData.callBack = data.call_now
    widgetSettingData.leaveMessage = data.leave_message

    widgetSettingData.webCallHeader = data.digital_call_tab_text
    widgetSettingData.webCallButton = data.digital_call_button_text
    widgetSettingData.webCallName = data.full_name_in_digital_calls_tab
    widgetSettingData.webCallEmail = data.email_in_digital_calls_tab    
    widgetSettingData.webCallTeam = data.team_in_digital_calls_tab




    widgetSettingData.callLeterHeader = data.schedule_call_tab_text
    widgetSettingData.callLeterButton = data.offline_schedule_call_message
    widgetSettingData.callLeterName = data.full_name_in_call_later_tab

    widgetSettingData.callLeterEmail = data.email_in_call_later_tab
    widgetSettingData.callLeterTeam = data.team_in_call_later_tab

    widgetSettingData.callBackHeader = data.widget_text
    widgetSettingData.callBackButton = data.submit_request_call_text
    widgetSettingData.callBackName = data.full_name_in_call_now_tab
    widgetSettingData.callBackEmail = data.email_in_call_now_tab
    widgetSettingData.callBackTeam = data.team_in_call_now_tab

    widgetSettingData.leaveMessageHeader = data.leave_message_tab_text
    widgetSettingData.leaveMessageName = data.full_name_in_leave_message_tab
    widgetSettingData.leaveMessageEmail = data.email_in_leave_message_tab
    widgetSettingData.leaveMessageTeam = data.team_in_leave_message

    setWidgetSetting(widgetSettingData)
    setWidgetApiData(widgetSettingData)
  }
  

  const fetchSetting = async () => {  

          
    await setWidgetALlSetting(widgetObject)
    await setWidgetApiData(widgetObject)
    await setWidget(widgetObject)
    await setBubble(widgetObject)
    let widgetData = widgetObject;
    widgetData["full_name_display_on_call_now"] = "on_call_now_screen";
    widgetData["full_name_display_on_digital_calls"] = "before_call";
    widgetData["full_name_display_on_call_later"] = "before_schedule";
    widgetData["email_display_on_call_now"] = "on_call_now_screen";
    widgetData["email_display_on_digital_calls"] = "before_call";
    widgetData["email_display_on"] = "before_schedule";
    widgetData["email_display_on_call_later"] = "before_schedule";   
    setInterestData(widgetObject.interested) 
    await setWidgetStyleData(widgetData)
    await setWidgetApiResData(widgetData)    
       
}



  return (
    <div id="form-preview">
       {parseInt(widgetStyleData.template_type) !== parseInt(1) ? (
                parseInt(widgetStyleData.template_type) === parseInt(3) ? (
                <div className="custom-widget-wrapper custom-widget-wrapper-smart modern-widget-container-main">
                  <div className="hor-row ">
                    <div className='modern-widget-container'>
                      <div className='hor-row lime-modern-componey-logo-container'>
                        <img
                          src="https://app.limecall.com/storage/company_logo/605a6f3c0e7d9/z4FKg65lUgEr4DXda28YGVqFBiXc7A4MtrbUXBzA.png"/>
                      </div>
                      <h3 style={{ fontFamily: widgetStyleData.font }}>
                         Hey 👋
                      </h3>
                      <div className='hor-row lime-modern-greeting-body' style={{ fontFamily: widgetStyleData.font }}>
                        Need help? We're here!
                      </div>
                    </div>
                    {widgetStyleData.digital_call ? <div className = 'hor-row lime-modern-selector-button' style={{ fontFamily: widgetStyleData.font }}>
                      <i className="material-icons" style={{color:"#25ae88",verticalAlign: 'middle', paddingRight: '2px'}}>phone_in_talk</i>
                       Talk to us now
                    </div> : null}
                    {(widgetStyleData.call_now || widgetStyleData.call_me_later) ? <div style={{ fontFamily: widgetStyleData.font }} className = 'hor-row lime-modern-selector-button'>
                      <img src = {callMeBackRound}
                        style={{width: '28px', marginRight: '5px'}}/>
                       Call me back
                    </div> : null}                   


                    <div className='hor-row lime-modern-powered-section'>

                        <div>{widgetStyleData.social_proof ? (
                                  <label style={{ marginBottom: ".5rem;", display:"block", fontFamily: widgetStyleData.font, fontSize: "14px", color: "#828181" }}>
                                    You are already the 1st person who has
                                    requested a call
                                  </label>
                                ) : null}
                                {widgetStyleData.terms_and_conditions_status ? (
                                  <label  style={{ marginBottom: ".5rem;", display:"block", fontFamily: widgetStyleData.font, fontSize: "14px", color: "#828181"   }}>{widgetStyleData.terms_and_conditions}</label>
                                ) : null}
                                {widgetStyleData.privacy_note_status ? (
                                  <label  style={{ marginBottom: ".5rem;", display:"block", fontFamily: widgetStyleData.font, fontSize: "14px", color: "#828181"   }}>{widgetStyleData.privacy_note}</label>
                                ) : null}
                        </div>


                      We run on 
                      <img src="https://limecall.com/wp-content/uploads/2019/12/logo_blue.png"
                        style={{marginLeft: '2px', height: '10px'}}/>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="custom-widget-wrapper custom-widget-wrapper-smart test-smart">
                   {Boolean(widgetStyleData.digital_call) && Boolean(allowedTabs?.clickToCall) && (
                     <>
                    <div className="custom-widget-body">
                      <h4 style={{ color: widgetSetting.timerColor, fontFamily: widgetStyleData.font }}>
                        {clickToCallTabText}
                      </h4>
                      <div className="custom-widget-content">
                        {widgetStyleData.full_name_in_digital_calls_tab ? (
                          <div className="widget-input">Joe Doe</div>
                        ) : null}
                        {widgetStyleData.email_in_digital_calls_tab ? (
                          <div className="widget-input">jon@email.com</div>
                        ) : null}
                        {widgetStyleData.team_in_digital_calls_tab ? (
                          <div className="custom-widget-content">
                            <label style={{ fontFamily: widgetStyleData.font }}>Select a team to connect with:</label>
                            <CommonSelect
                              name="config"
                              className="popup-font-select"
                              placeholder="Sales"
                              options={teamData}
                            />
                          </div>
                        ) : null}


                        {widgetStyleData.interest_in_digital_calls_tab ? (
                          <div className="custom-widget-content">
                            <label>Select interest</label>
                            <CommonSelect
                              name="config"
                              className="popup-font-select"
                              placeholder="Interest"
                              options={interestData}
                            />
                          </div>
                        ) : null}




                          {widgetStyleData.industry_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                            ) : null}


                              {widgetStyleData.employee_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={orgLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.revenue_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Budget"
                                    options={['< 100K', '100K - 1M', '1M - 10M', '10M - 100M', '> 100M']}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.role_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Role"
                                    options={roleLimeCall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                   <TextArea rows={2} placeholder='Tell us more' />
                                </div>
                              ) : null}



                            


                        <div
                          className="call-me-btn"
                          style={{ backgroundColor: buttonColor }}
                        >
                          {clickToCallButtonText}
                        </div>
                      </div>
                    </div>

                    <div className='smart-widget-button-container'>
                      <div className='button-connecting'>
                        Connecting
                        <i className="material-icons smart-call-cut animated infinite pulse">call_end</i>
                      </div>
                    </div>
                    </>
                  )}

                  {(widgetStyleData.call_me_later  && allowedTabs?.callMeLater  && (widgetStyleData.team_in_call_later_tab || widgetStyleData.email_in_call_later_tab || widgetStyleData.full_name_in_call_later_tab)) ? 
                    (<div className='smart-widget-container-main'>
                      {(widgetStyleData.email_in_call_later_tab || widgetStyleData.full_name_in_call_later_tab) ? (<div className= 'hor-row info-text'>
                        Let us know a few details about you
                      </div>): null}
                      {widgetStyleData.email_in_call_later_tab ? <div className='hor-row input-container-main'>
                        <input type='text' placeholder='Email*'/>
                      </div> : null}
                      {widgetStyleData.full_name_in_call_later_tab ? <div className='hor-row input-container-main'>
                        <input type='text' placeholder='Name*'/>
                      </div> : null}
                     
                      {widgetStyleData.team_in_call_later_tab ? <div className='hor-row team-container-main'>
                        <div className='hor-row team-text-main'>
                          I want to speak to:
                        </div>
                        { teamData.map( index => {
                          return (
                            <div className='team-button-container'>
                              {index}
                            </div>
                          )
                        } ) }                        
                        
                      </div> : null}

                      {widgetStyleData.interest_in_call_later_tab ? <div className='hor-row team-container-main'>
                        <div className='hor-row team-text-main'>
                          Interest:
                        </div>
                        { interestData.map( index => {
                          return (
                            <div className='team-button-container'>
                              {index}
                            </div>
                          )
                        } ) }                        
                        
                      </div> : null}




                      {widgetStyleData.industry_in_call_later_tab ? (
                                <div className="hor-row input-container-main"
                                  style={{marginTop: '10px', textAlign: "center"}}>
                                  <CommonSelect
                                   style={{ maxWidth: "200px", display: "block"}}
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.employee_in_call_later_tab ? (
                              
                                    <div className="hor-row input-container-main" style={{marginTop: '10px'}}>
                                      <CommonSelect
                                        style={{ maxWidth: "200px", textAlign: "center"}}
                                        name="config"
                                        className="popup-font-select"
                                        placeholder="Select No Of Employee"
                                        options={orgLimecall}
                                      />
                                    </div>
                               
                              ) : null}

                              {widgetStyleData.revenue_in_call_later_tab ? (
                               
                                    <div className="hor-row input-container-main" style={{marginTop: '10px'}}>
                                      <CommonSelect
                                       style={{ maxWidth: "200px", textAlign: "center"}}
                                        name="config"
                                        className="popup-font-select"
                                        placeholder="Select Budget"
                                        options={['< 100K', '100K - 1M', '1M - 10M', '> 10M']}
                                      />
                                    </div>
                                
                              ) : null}

                              {widgetStyleData.role_in_call_later_tab ? (                                
                                    <div className="hor-row input-container-main" style={{marginTop: '10px'}}>
                                  <CommonSelect
                                   style={{ maxWidth: "200px", textAlign: "center"}}
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={roleLimeCall}
                                  />
                                
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_call_later_tab ? (
                               
                                    <div className="hor-row input-container-main" style={{marginTop: '10px', textAlign: "center"}}>
                                      <TextArea rows={2}  style={{ maxWidth: "200px"}} placeholder='Tell us more' />
                                    </div>
                               
                              ) : null}   

                    {(widgetStyleData.email_in_call_later_tab || widgetStyleData.full_name_in_call_later_tab) ? 
                        <div className='hor-row input-container-main'>
                          <div className='submit-button'>
                            { widgetStyleData.offline_schedule_call_message }
                          </div>
                        </div> : null
                      }

                      



                      <div className='hor-row smart-widget-footer'>
                          <a href="http://limecall.com/pages/?utm_source=https://app.limecall.com" target="_blank" rel="noopener noreferrer" className="poweredby-section">
                            <img src="https://limecall.com/wp-content/uploads/2020/01/favicon.png" alt="" className="poweredby-icon"/>
                            We run on LimePhone
                          </a>
                      </div>
                    </div>
                  ) : null
                  }
                  
                  

                  
                </div>
               
              )
            ) : (
              <div className='classic-widget-outer-container-main'>
                {/* <div>
                  <h1 className="classic-text"
                    style={{marginBottom: '30px'}}>Classic Widget</h1>
                </div> */}
                <div className='classic-widget-container-main'>
                  <div className="custom-widget-wrapper hor-row">
                    <div className="custom-widget-header">
                      {widgetStyleData.digital_call ? (
                        <div
                          id="clickToCall"
                          onClick={e => onClickTableCategory(e)}
                          
                        >
                          <p
                            id="clickToCall"
                            onClick={e => onClickTableCategory(e)}
                          >
                            <i className="material-icons"
                              style={{ fontSize: '12px', paddingRight: '3px'}}>
                              headset_mic
                            </i>
                            Web Call
                          </p>
                          <div className="close" id="clickToCall"></div>
                        </div>
                      ) : null}
                      {widgetStyleData.call_now ? (
                        <div>
                          <div
                            id="callMeBack"
                            onClick={e => onClickTableCategory(e)}
                          >
                            <img
                              src={callMeBack}
                              alt=""
                              id="callMeBack"
                              onClick={e => onClickTableCategory(e)}
                            />
                            <p
                              id="callMeBack"
                              onClick={e => onClickTableCategory(e)}
                            >
                            Call Back
                            </p>
                            <div className="close" id="callMeBack"></div>
                          </div>
                        </div>
                      ) : null}
                      {widgetStyleData.call_me_later ? (
                        <div>                         
                            <div
                              id="callMeLater"
                              onClick={e => onClickTableCategory(e)}
                            >
                              <img
                                src={callMeLetter}
                                alt=""
                                id="callMeLater"
                                onClick={e => onClickTableCategory(e)}
                              />
                              <p
                                id="callMeLater"
                                onClick={e => onClickTableCategory(e)}
                              >
                                Appointment Scheduling
                              </p>
                              <div className="close" id="callMeLater"></div>
                            </div>                        
                        </div>
                      ) : null}
                      {widgetStyleData.leave_message ? (
                        <div>
                          <div
                            id="leaveMessage"
                           onClick={e => onClickTableCategory(e, widgetSetting)}
                          >
                            <p
                              id="leaveMessage"
                              onClick={e => onClickTableCategory(e, widgetSetting)}
                            >
                              <i className="material-icons"
                                style={{ fontSize: '12px', paddingRight: '3px'}}>
                                message
                              </i>
                              Contact form
                            </p>
                            <div className="close" id="leaveMessage"> </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="custom-widget-wrapper custom-widget-wrapper-head hor-row">
                    <div className="custom-widget-wrapper">
                      {allowedTabs?.clickToCall && widgetStyleData.digital_call && (
                        <>
                          <div className="custom-widget-body">
                            <h4 className = 'custom-widget-body-h1'
                              style={{fontFamily: widgetStyleData.font}}>
                              {widgetStyleData.digital_call_tab_text}
                            </h4>
                            <div className="custom-widget-content">
                              {widgetStyleData.email_in_digital_calls_tab ? (
                                <div className="widget-input">
                                  <i className="material-icons icon-class">email</i>
                                  Email
                                </div>
                              ) : null}
                              {widgetStyleData.full_name_in_digital_calls_tab ? (
                                <div className="widget-input">
                                  <i className="material-icons icon-class">account_circle</i>
                                  Name*
                                </div>
                              ) : null}
                              {widgetStyleData.team_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="I want to talk to"
                                    options={teamData}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.interest_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Interest"
                                    options={interestData}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.industry_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.employee_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={orgLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.revenue_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Budget"
                                    options={['< 100K', '100K - 1M', '1M - 10M', '10M - 100M', '> 100M']}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.role_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Role"
                                    options={roleLimeCall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                   <TextArea rows={2} placeholder='Tell us more' />
                                </div>
                              ) : null}


                              <div
                                className="call-me-btn"
                                style={{
                                  backgroundColor: widgetStyleData.submit_request_call_color, borderRadius: '5px'
                                }}
                              >
                                {widgetStyleData.digital_call_button_text}
                              </div>
                            </div>
                          </div>
                          <div className="custom-widget-footer">
                            {widgetStyleData.social_proof ? (
                              <label>
                                You are already the 1st person who has requested a
                                call
                              </label>
                            ) : null}
                            {widgetStyleData.terms_and_conditions_status ? (
                              <label>{widgetStyleData.terms_and_conditions}</label>
                            ) : null}
                            {widgetStyleData.privacy_note_status ? (
                              <label>{widgetStyleData.privacy_note}</label>
                            ) : null}
                            <p className='lime-call-footer-text-container'>
                              <a href="http://limecall.com/pages/?utm_source=https://app.limecall.com" target="_blank" rel="noopener noreferrer" className="poweredby-section">
                                <img src="https://limecall.com/wp-content/uploads/2020/01/favicon.png" alt="" className="poweredby-icon"/>
                                We run on LimePhone
                              </a>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                 
                      <div className="custom-widget-wrapper">
                        {allowedTabs?.callMeBack && widgetStyleData.call_now && (
                          <>
                            <div className="custom-widget-body">
                              <h4  className = 'custom-widget-body-h1'
                                style={{fontFamily: widgetStyleData.font}}>
                                {widgetStyleData.widget_text}
                              </h4>
                              <div className="custom-widget-content">
                                <div className="widget-drop">
                                  <div className="widget-drop-inner">
                                    <img src={callMeBack} alt="" />
                                    <span>+91</span>
                                  </div>
                                  <p>78906 28382</p>
                                </div>
                                {widgetStyleData.full_name_in_call_now_tab ? (
                                  <div className="widget-input">Joe Doe</div>
                                ) : null}
                                {widgetStyleData.email_in_call_now_tab ? (
                                  <div className="widget-input">
                                    jon@email.com
                                  </div>
                                ) : null}
                                {widgetStyleData.team_in_call_now_tab ? (
                                  <div className="custom-widget-content"
                                    style={{marginTop: '10px'}}>
                                    <CommonSelect
                                      name="config"
                                      className="popup-font-select"
                                      placeholder="I want to talk to"
                                      options={teamData}
                                    />
                                  </div>
                                ) : null}

                                {widgetStyleData.interest_in_call_now_tab ? (
                                  <div className="custom-widget-content"
                                    style={{marginTop: '10px'}}>
                                    <CommonSelect
                                      name="config"
                                      className="popup-font-select"
                                      placeholder="Interest"
                                      options={interestData}
                                    />
                                  </div>
                                ) : null}

                              {widgetStyleData.industry_in_call_now_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.employee_in_call_now_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={orgLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.revenue_in_call_now_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Budget"
                                    options={['< 100K', '100K - 1M', '1M - 10M', '> 10M']}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.role_in_call_now_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Role"
                                    options={roleLimeCall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_call_now_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                   <TextArea rows={2} placeholder='Tell us more' />
                                </div>
                              ) : null}     


                              </div>
                              <div
                                className="call-me-btn"
                                style={{
                                  backgroundColor: widgetStyleData.submit_request_call_color, borderRadius: '5px'
                                }}
                              >
                                
                                { widgetStyleData.submit_request_call_text
                                  ?  widgetStyleData.submit_request_call_text
                                  : null}
                              </div>
                            </div>
                            <div className="custom-widget-footer">
                              {widgetStyleData.social_proof ? (
                                <label>
                                  You are already the 1st person who has requested
                                  a call
                                </label>
                              ) : null}
                              {widgetStyleData.terms_and_conditions_status ? (
                                <label>{widgetStyleData.terms_and_conditions}</label>
                              ) : null}
                              {widgetStyleData.privacy_note_status ? (
                                <label>{widgetStyleData.privacy_note}</label>
                              ) : null}
                            </div>
                          </>
                        )}
                      </div>
                  
                   
                      <div className="custom-widget-wrapper">
                        {allowedTabs?.callMeLater && widgetStyleData.call_me_later && (
                          <>
                            <div className="custom-widget-body">
                              <h4  className = 'custom-widget-body-h1'
                                style={{fontFamily: widgetStyleData.font}}>
                                {widgetStyleData.schedule_call_tab_text}
                              </h4>
                              <div className="call-me-latter">
                                <CommonSelect
                                  name="config"
                                  className="popup-font-select"
                                  placeholder="Date"
                                  options={['Date']}
                                />
                                <CommonSelect
                                  name="config"
                                  className="popup-font-select"
                                  placeholder="Time"
                                  options={['Time']}
                                />
                              </div>
                              <div className="custom-widget-content">
                                <div className="widget-drop">
                                  <div className="widget-drop-inner">
                                    <img src={callMeBack} alt="" />
                                    <span>+91</span>
                                  </div>
                                  <p>78906 28382</p>
                                </div>
                                {widgetStyleData.full_name_in_call_later_tab ? (
                                  <div className="widget-input">Joe Doe</div>
                                ) : null}
                                {widgetStyleData.email_in_call_later_tab ? (
                                  <div className="widget-input">
                                    jon@email.com
                                  </div>
                                ) : null}
                                {widgetStyleData.interest_in_call_later_tab ? (
                                  <div className="custom-widget-content"
                                    style={{marginTop: '10px'}}>
                                    <CommonSelect
                                      name="config"
                                      className="popup-font-select"
                                      placeholder="Interest"
                                      options={interestData}
                                    />
                                  </div>
                                ) : null}

                              {widgetStyleData.industry_in_call_later_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.employee_in_call_later_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={orgLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.revenue_in_call_later_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Budget"
                                    options={['< 100K', '100K - 1M', '1M - 10M', '> 10M']}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.role_in_call_later_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={roleLimeCall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_call_later_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                   <TextArea rows={2} placeholder='Tell us more' />
                                </div>
                              ) : null}   


                              </div>
                              <div
                                className="call-me-btn"
                                style={{
                                  backgroundColor: widgetStyleData.submit_request_call_color, borderRadius: '5px'
                                }}
                              >
                                {widgetSetting.callLeterButton
                                  ? widgetSetting.callLeterButton
                                  : null}
                              </div>
                            </div>
                            <div className="custom-widget-footer">
                              {widgetStyleData.social_proof ? (
                                <label>
                                  You are already the 1st person who has requested
                                  a call
                                </label>
                              ) : null}
                              {widgetStyleData.terms_and_conditions_status ? (
                                <label>{widgetStyleData.terms_and_conditions}</label>
                              ) : null}
                              {widgetStyleData.privacy_note_status ? (
                                <label>{widgetStyleData.privacy_note}</label>
                              ) : null}
                            </div>
                          </>
                        )}
                      </div>                   
                   
                    
                      <div className="custom-widget-wrapper">
                        {
                          allowedTabs?.leaveMessage && widgetStyleData.leave_message && (
                            <>
                              <div className="custom-widget-body">
                                <h4  className = 'custom-widget-body-h1'
                                  style={{fontFamily: widgetStyleData.font}}>
                                  {widgetStyleData.leave_message_tab_text}
                                </h4>
                                <div className="custom-widget-content">
                                  { <TextArea placeholder="Your message" />}

                                  {widgetStyleData.full_name_in_leave_message_tab ? (
                                    <div className="widget-input">Joe Doe</div>
                                  ) : null}
                                  {widgetStyleData.email_in_leave_message_tab ? (
                                    <div className="widget-input">
                                      jon@email.com
                                    </div>
                                  ) : null}
                                  {widgetStyleData.interest_in_leave_message ? (
                                    <div className="custom-widget-content"
                                      style={{marginTop: '10px'}}>
                                      <CommonSelect
                                        name="config"
                                        className="popup-font-select"
                                        placeholder="I want to talk to"
                                        options={interestData}
                                      />
                                    </div>
                                  ) : null}

                                  
                              {/* {widgetStyleData.industry_in_leave_message ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.employee_in_leave_message ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={orgLimecall}
                                  />
                                </div>
                              ) : null} */}

                              {/* {widgetStyleData.revenue_in_leave_message ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Budget"
                                    options={['< 100K', '100K - 1M', '1M - 10M', '> 10M']}
                                  />
                                </div>
                              ) : null} */}

                              {/* {widgetStyleData.role_in_leave_message ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={roleLimeCall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_leave_message ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                   <TextArea rows={2} placeholder='Tell us more' />
                                </div>
                              ) : null}  */}

                                </div>


                                <div
                                  className="call-me-btn"
                                  style={{
                                    backgroundColor: widgetStyleData.submit_request_call_color, borderRadius: '5px'
                                  }}
                                >
                                  {leaveMessageButtonText
                                    ? leaveMessageButtonText
                                    : null}
                                </div>
                              </div>
                              <div className="custom-widget-footer">
                                {widgetStyleData.social_proof ? (
                                  <label>
                                    You are already the 1st person who has
                                    requested a call
                                  </label>
                                ) : null}
                                {widgetStyleData.terms_and_conditions_status ? (
                                  <label>{widgetStyleData.terms_and_conditions}</label>
                                ) : null}
                                {widgetStyleData.privacy_note_status ? (
                                  <label>{widgetStyleData.privacy_note}</label>
                                ) : null}
                              </div>
                            </>

                          )}
                      </div>
                  
                  </div>
                </div>
              </div>
            )}
    </div>
  )
}

export default FormPreview