import React, { useState, useEffect } from 'react'
import { Button, Dimmer, Form, Loader, Modal } from 'semantic-ui-react'

import Autosuggest from 'react-autosuggest'

import CommonInput from '../../common/CommonInput'
import ReactPhoneInput from 'react-phone-input-2'
import closeIcon from '../../assets/images/close-set.svg'
import { billingCountryList } from '../../helpers/billingCountryList'
import axios from 'axios'

const intialCompanyData = [
  {
    city: '',
    country: 'Afghanistan',
    name: 'Purpulerain',
    organization_name: 'Purpulerain',
    owner: '',
    phone_country: '',
    phone_number: '',
    company: '',
    type: 'organization'
  },

  {
    city: '',
    country: 'Afghanistan',
    name: 'Betacloud',
    organization_name: 'Betacloud',
    owner: '',
    phone_country: '',
    phone_number: '',
    company: '',
    type: 'organization'
  },

  {
    city: '',
    country: 'Afghanistan',
    name: 'LimePhone',
    organization_name: 'Betacloud',
    owner: '',
    phone_country: '',
    phone_number: '',
    company: '',
    type: 'organization'
  }
]

const EditDetailOpen = ({
  setDetailOpen,
  editDetailOpen,
  handleInputChange,
  editContact,
  onclickOwner,
  companyList,
  setEditContact,
  loading
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [value, setValue] = useState('')
  const [collaboratesData, setCollaboratesData] = useState([])
  const [ownerSet, setOwnerSet] = useState(false)
  const intialEditData = {
    name: '',
    phone_number: '',
    phone_number: '',
    email: '',
    country: '',
    type: '',
    owner_id: '',
    owner_name: '',
    first_name: '',
    organization_name: ''
  }
  // const [companyList, setCompanyList] = useState(intialCompanyData)

  console.info({ editContact })

  //  useEffect(() => {
  //   if(editContact.organization_name == value) return
  //   setValue(editContact.organization_name)
  //  }, [editContact.organization_name])

  useEffect(() => {
    setEditContact({
      ...editContact,
      organization_name: value
    })
  }, [value])

  useEffect(() => {
    getCollaborates()
  }, [])

  const changeOwner = event => {
    const { value } = event.target

    console.info({ collaboratesData, value })
    if (value) {
      let find = collaboratesData?.find(item => item.value == value)

      find = find ? find : ''

      //console.info({find})
      //return;

      find = find?.text ? find?.text : ''
      setEditContact({
        ...editContact,
        owner_name: find,
        owner_id: value
      })
    }
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const getCollaborates = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
    const user = []
    axios
      .get(url)
      .then(res => {
        var defaultIds = []
        var moderateArray = []

        const admin = res.data.data.admin

        moderateArray.push({
          key: admin.id,
          text: `${admin.first_name} ${admin.last_name}`,
          value: admin.id
        })

        res.data.data.members &&
          res.data.data.members.map(item => {
            if(item?.verified){

              const d = {
                key: item.id,
                text: `${item.first_name} ${item.last_name}`,
                value: item.id
              }
              moderateArray.push(d)
            }
          })

        console.info({ moderateArray })

        const finalCollabList = moderateArray
        setCollaboratesData(finalCollabList)

        const selected = finalCollabList.find(
          item => item.value === editContact.owner_id
        )
        if (selected) {
          setOwnerSet(editContact.owner_id)
        }
      })
      .catch(err => {})
  }

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    return inputLength === 0
      ? []
      : companyList.filter(
          lang =>
            lang?.organization_name?.toLowerCase().slice(0, inputLength) ===
            inputValue
        )
  }

  const onChange = (event, { newValue }) => {
    setValue(newValue)
  }

  const getSuggestionValue = suggestion => suggestion.name

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const inputProps = {
    placeholder: 'Organization name',
    value,
    onChange: onChange
  }

  const renderSuggestion = suggestion => (
    <div className="autocomplete-items">{suggestion?.organization_name}</div>
  )

  return (
    <Modal open={editDetailOpen} className="editModel">
      <Dimmer active={loading ? true : false} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      <Modal.Header className="editHead">
        <h4>Edit Contact </h4>
        <img
          src={closeIcon}
          onClick={() => {
            setEditContact(intialEditData)
            setDetailOpen(false)
          }}
        />
      </Modal.Header>
      <Form className="fromEdit" onSubmit={e => onclickOwner(e)}>
        <div className="contactWrap">
          <div className="profileMain">
            <div className="inputProfile">
              <Form.Field>
                <label>First Name</label>
                <CommonInput
                  type="text"
                  className="form-control"
                  onChange={e => handleInputChange(e)}
                  name="name"
                  placeholder="First name"
                  value={editContact.name}
                />
                <label>E-mail Address</label>
                <CommonInput
                  onChange={e => handleInputChange(e)}
                  placeholder="enter email address"
                  name="email"
                  type="email"
                  autocomplete="off"
                  value={editContact.email}
                />
                <label>Phone number</label>
                <ReactPhoneInput
                  inputExtraProps={{
                    name: 'phone_number',
                    required: true,
                    autoFocus: true
                  }}
                  defaultCountry={'sg'}
                  value={editContact.phone_number}
                  onChange={(e, c) => handleInputChange(e, c, 'phone_number')}
                />{' '}
                <label>Country</label>
                <select
                  name="country"
                  onChange={e => handleInputChange(e)}
                  value={editContact.country?.toString()?.toLocaleLowerCase()}
                  placeholder="country"
                >
                  {billingCountryList.map((country, key) => (
                    <option
                      key={key}
                      value={country.text?.toString()?.toLocaleLowerCase()}
                    >
                      {country.text}
                    </option>
                  ))}
                </select>
                {/* <label>City</label>
                <CommonInput
                  onChange={e => handleInputChange(e)}
                  placeholder="city"
                  name="city"
                  type="city"
                  value={editContact.city}
                /> */}
                <label>Contact Owner</label>
                <select
                  name="owner"
                  type="owner"
                  placeholder="Owner list"
                  onChange={e => changeOwner(e)}
                >
                  <>
                    {!ownerSet && (
                      <option key={0} selected value={0}>
                        Not Set
                      </option>
                    )}

                    {collaboratesData.map((item, key) => (
                      <option
                        key={key + 1}
                        selected={item.value == editContact.owner_id}
                        value={item.value}
                      >
                        {item.text}
                      </option>
                    ))}
                  </>
                </select>{' '}
                {/* <CommonInput
                  onChange={e => handleInputChange(e)}
                  placeholder="Company Owner"
                  name="owner"
                  type="owner"
                  value={editContact.owner}
                /> */}
                <label>Organization Name</label>
                {/* <CommonInput
                  onChange={e => setValue(e.target.value)}
                  placeholder="city"
                  name="city"
                  type="city"
                  value={editContact.organization_name}
                /> */}
                <Autosuggest                  
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                />
              </Form.Field>
            </div>
          </div>
        </div>
        <div className="btnMain">
          <Button
            onClick={() => {
              setEditContact(intialEditData)
              setDetailOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button className="btnSave">Save</Button>
        </div>
      </Form>
    </Modal>
  )
}
export default EditDetailOpen
