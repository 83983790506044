import React,{useEffect} from 'react';
import {SideSheet, Button, Pane, Heading, Paragraph,Tablist,Tab, Card} from 'evergreen-ui';
import GoogleMeet from '../../assets/images/Google-Meet.png'
import zoomImg from '../../assets/images/Zoom.png'


function MeetingSideModal({visibility, setEvergreenModalStatus,sidePaneData}) {
    const [selectedIndex, setSelectedIndex] = React.useState(0)

    useEffect(() => {
     
      }, [sidePaneData])

    return (
      <React.Fragment>
        <SideSheet
          isShown={visibility}
          onCloseComplete={() => setEvergreenModalStatus(false)}
          containerProps={{
            display: 'flex',
            flex: '1',
            flexDirection: 'column'
          }}
        >
          <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
            <Pane padding={16} borderBottom="muted">
              <Heading size={600}> </Heading>
              <Paragraph size={400} color="muted">
                {sidePaneData ? sidePaneData.description : ''}
              </Paragraph>
            </Pane>
          </Pane>
          <Pane flex="1" flexDirection="column" backgroundColor="white" overflowY="scroll" borderBottom="muted" padding={16}>
          <Tablist>
                {['Event Details'].map((tab, index) => (
                  <Tab
                    key={tab}
                    isSelected={true}
                    onSelect={() => setSelectedIndex(index)}
                  >
                    {tab}
                  </Tab>
                ))}
              </Tablist>
            <Card
              marginTop="10px"
              backgroundColor="white"
              elevation={0}
              display="flex"
              flexDirection="column"
            
            >
             
              <div style={{justifyContent: 'space-between', display: 'flex', marginTop: 20}}>
              <Paragraph marginLeft="20px" color="#A4ABC5" flex="1">Meeting Name: </Paragraph>
              <Paragraph flex="1" color="black">{sidePaneData ? sidePaneData.name : ''} </Paragraph>              
              </div>

              <div style={{justifyContent: 'space-between', display: 'flex', marginTop: 10}}>
              <Paragraph marginLeft="20px" color="#A4ABC5" flex="1">Description: </Paragraph>
              <Paragraph flex="1" color="black">{sidePaneData ? sidePaneData.description : ''} </Paragraph>              
              </div>


              <div style={{justifyContent: 'space-between', display: 'flex', marginTop: 10}}>
              <Paragraph marginLeft="20px" color="#A4ABC5" flex="1">Participation: </Paragraph>
              <Paragraph flex="1" color="black">{sidePaneData ? sidePaneData.participation : ''} </Paragraph>              
              </div>

              <div style={{justifyContent: 'space-between', display: 'flex', marginTop: 10}}>
              <Paragraph marginLeft="20px" color="#A4ABC5" flex="1">Buffer Time: </Paragraph>
              <Paragraph flex="1" color="black">{sidePaneData ? sidePaneData.buffer_time + " minutes" : ''} </Paragraph>        
              </div>

              <div style={{justifyContent: 'space-between', display: 'flex', marginTop: 10, marginBottom: 20}}>
              <Paragraph marginLeft="20px" color="#A4ABC5" flex="1">Duration: </Paragraph>
              <Paragraph flex="1" color="black">{sidePaneData ? sidePaneData.meeting_duration + " minutes" : ''}  </Paragraph>              
              </div>

            </Card>

            <Pane marginTop="30px" display="flex" backgroundColor="white"  flexDirection="column" borderBottom="muted" padding={8}>
              <Tablist>
                {['Meeting Location'].map((tab, index) => (
                  <Tab
                    key={tab}
                    isSelected={true}
                    onSelect={() => setSelectedIndex(index)}
                  >
                    {tab}
                  </Tab>
                ))}
              </Tablist>
              <Card
              backgroundColor="white"
              elevation={0}
              display="flex"
              marginTop="10px"
              flexDirection="column"
            >

        {sidePaneData ? (  
              <div style={{justifyContent: 'space-between', display: 'flex', marginTop: 10}}>
              <Paragraph marginLeft="20px" color="#A4ABC5" flex="1">Location: </Paragraph>

              
               
              {sidePaneData.location === "zoom_meeting" ? (
              <div style={{display: 'flex', flex: 1}}>
                      <img
                        style={{width: 30, height: 30}}
                        className="ui medium circular image"
                        src={zoomImg}
                        alt=""
                      />
                      <p><Paragraph marginLeft="10px" color="black">Zoom</Paragraph></p>
                    </div>   
              ) : '' }    

{sidePaneData.location === "limecall_meeting" ? (
              <div style={{display: 'flex', flex: 1}}>
                        <img
                        style={{width: 30, height: 30}}
                        className="ui medium circular image"
                        src="https://qa.limecall.com/assets/images/limephone-logo.png"
                        alt=""
                      />
                      <p><Paragraph marginLeft="10px" color="black">Lime Call </Paragraph></p>
                    </div>   
              ) : '' }  

{sidePaneData.location === "google_meeting" ? (
              <div style={{display: 'flex', flex: 1}}>
                   <img
                        style={{width: 30, height: 30}}
                        className="ui medium circular image"
                        src={GoogleMeet}
                        alt=""
                      />
                      <p><Paragraph marginLeft="10px" color="black">Google Meet </Paragraph></p>
                    </div>   
              ) : '' }  


              </div>

               ) : null }


              <div style={{justifyContent: 'space-between', display: 'flex', marginTop: 10}}>
              <Paragraph marginLeft="20px" color="#A4ABC5" flex="1">TimeZone: </Paragraph>
              <Paragraph flex="1" color="black"> {sidePaneData ? sidePaneData.time_zone : ''} </Paragraph>              
              </div>

              <div style={{justifyContent: 'space-between', display: 'flex', marginTop: 10}}>
              <Paragraph marginLeft="20px" color="#A4ABC5" flex="1">Link: </Paragraph>
              <Paragraph flex="1" color="black"> <a href="#"> {sidePaneData ? sidePaneData.link : ''} </a></Paragraph>              
              </div>

            </Card>
            </Pane>
          </Pane>
        </SideSheet>
      </React.Fragment>
    )
  }

  export default MeetingSideModal;