import React, { useState, useEffect } from 'react'
import { Button, Dimmer, Form, Loader, Modal, Tab } from 'semantic-ui-react'

import Autosuggest from 'react-autosuggest'

import CommonInput from '../../common/CommonInput'
import ReactPhoneInput from 'react-phone-input-2'
import closeIcon from '../../assets/images/close-set.svg'
import { billingCountryList } from '../../helpers/billingCountryList'
import axios from 'axios'
import AddContactModal from './addNewContact'
import { connect } from 'react-redux'
import { getCallerId, getTeamMembers } from '../../actions/settings'
import { Autocomplete, TextField } from '@mui/material'
import { useGetContactsQuery } from '../../features/api/apiSlice'




const AddAssociated = ({
addAssociatedOpen,
setAddAssociated,
getData,
contact,company,
setData,
data
}) => {

  const [activeIndex, setActiveIndex] = useState(0)
  const [addOpen, setAddOpen] = useState(false)
  const [contactData, setContactData] = useState([])

const addToggle=()=>{
  setAddOpen(!addOpen)
}
useEffect(() => {
  fetchAllContacts()
}, [])

const fetchDataOnSave = () => {
  fetchAllContacts()
}
const fetchAllContacts = async () => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/contacts-all?order_by=name`
  axios
    .get(url)
    .then(res => {
      var d = []
      if (res?.data) {
        const responseTwo = res.data.data?.filter(contact => {
          return contact.type == 'individual'
        })
        setContactData(responseTwo)
        const responseCompany = res.data.data?.filter(contact => {
          return contact.type == 'organization'
        })

      
      }
    })
    .catch(error => {
    })
}


const handleChange=(value)=>{
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/company/${contact? value?.id: getData?.getData?.id}/associate-contacts`
  axios
    .post(url,{contact_ids:[contact ?getData?.getData?.id: value?.id]})
    .then(res => {
      var d = []
      if (res?.data) {
      const datadd=data?.push(value)
       setData(data)
      setAddAssociated(!addAssociatedOpen)
      }
    })
    .catch(error => {
    })
}

console.log('contactData', contactData)

    
  const handleTabChange = (e, { activeIndex }) => {
    setActiveIndex(activeIndex)
  }     

  return (
    <>
    <div
      className={
        addAssociatedOpen == true
          ? 'details-popup contact-detail-drawer active'
          : 'details-popup'
      }
    >
   
     
     
   <div className="details-title">
        <div>
   
        </div>
        <img src={closeIcon} onClick={()=>setAddAssociated(!addAssociatedOpen)} />
      </div>
      <div className="contact-popup-detail contact-side-bar">
      <div style={{padding:'15px'}}>
      <Autocomplete
  disablePortal
  onChange={(e,value)=>handleChange(value)}
  id="combo-box-demo"
  options={[...(company ? contactData : getData?.companyList)?.map((obj)=>({...obj,label:obj?.name ||obj?.organization_name}))] }

  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label={contact ? "Add Company":"Add Contact"}/>}
/>
      </div>
      </div>
   
   



   
    </div>
    {/* {addOpen ? (
        <AddContactModal
          fetchDataOnSave={fetchDataOnSave}
          Agentmembers={Allmember ? Allmember : []}
          setAddOpen={setAddOpen}
          fetchAllContacts={fetchAllContacts}
          addOpen={addOpen}
          contactData={contactData}
          countryOptions={countryOptions}
          companyLoadedData={companyData}
        />
      ) : (
        ''
      )} */}
    </>
  )
}
const mapStateToProps = state => ({
  teamMembers: state.settings.teamMembers,
  callerId: state.settings.callerId
})

export default connect(mapStateToProps, { getCallerId, getTeamMembers })(
  AddAssociated
)