import React, { Fragment } from 'react'
import { Table } from 'semantic-ui-react'
import CommonCheckbox from '../../../common/CommonCheckbox'

const LeadTableHeaders2 = ({
  isMarkAllCheckbox,
  isMarkOpen,
  onClickMarkAllCheckbox,
  onClickMark,
  setSortField,
  leadType,
  LeadTableHeaders
}) => (
  <Fragment>
    <Table.HeaderCell>
      <div className="mark-all">
        <CommonCheckbox
          onChange={onClickMarkAllCheckbox}
          name="isMarkAllCheckbox"
          checked={isMarkAllCheckbox}
        />
        <button onClick={onClickMark} className="btn-mark" type="button">
          <i className="fas fa-chevron-down"></i>
        </button>
        <div className={isMarkOpen ? 'mark active' : 'mark'}>
          <span
            style={{ display: 'inline-block' }}
            className="checkbox-item"
            onClick={e => setSortField('id')}
          >
            ID
          </span>
          <span
            style={{ display: 'inline-block' }}
            onClick={e => setSortField('id')}
            className="checkbox-item"
          >
            Date
          </span>
          <span
            style={{ display: 'inline-block' }}
            onClick={e => setSortField('phone_number')}
            className="checkbox-item"
          >
            To
          </span>
          <span
            style={{ display: 'inline-block' }}
            onClick={e => setSortField('email')}
            className="checkbox-item"
          >
            From
          </span>
          <span
            style={{ display: 'inline-block' }}
            onClick={e => setSortField('tags')}
            className="checkbox-item"
          >
            Tags
          </span>
        </div>
      </div>
    </Table.HeaderCell>
    <Table.HeaderCell>ID</Table.HeaderCell>
    <Table.HeaderCell>Date</Table.HeaderCell>
    <Table.HeaderCell>To</Table.HeaderCell>
    <Table.HeaderCell>Via</Table.HeaderCell>
    <Table.HeaderCell>From</Table.HeaderCell>
    <Table.HeaderCell>Status</Table.HeaderCell>
    <Table.HeaderCell>Duration</Table.HeaderCell>
    <Table.HeaderCell>Disposition</Table.HeaderCell>
    <Table.HeaderCell>Lead Status</Table.HeaderCell>
    <Table.HeaderCell>Actions</Table.HeaderCell>
  </Fragment>
)

export default LeadTableHeaders2
