import React, { useMemo, useState, useEffect } from 'react'
import { Button, Modal, Radio, Tab, Table } from 'semantic-ui-react'

import messageIcon from '../../assets/images/messageIcon.png'
import callIcon from '../../assets/images/call_icon.png'
import celender from '../../assets/images/celender.png'
import closeIcon from '../../assets/images/closecontact.png'
import personIcon from '../../assets/images/person.png'
import contactprofile from '../../assets/images/contactprofile.png'
import Ellipse from '../../assets/images/Ellipse-39.png'
import CommonButtons from '../../common/CommonButtons'
import OwnerModel from './ownerModel'
import { CSVLink } from 'react-csv'
import CommonCheckbox from '../../common/CommonCheckbox'

const ContactsPanels = ({
  contactData,
  modalOpen,
  opendetails,
  onClickData,
  messageCall,
  fetchId,
  onClickDataModal,
  onCallLogs,
  setOpen,
  CompanyList,
  setActiveType,
  onClickMarkAllCheckbox,
  onClickMarkCheckbox,
  isMarkAllCheckbox,
  fetchAllContacts,
  onClickUnmarkCheckbox,
  onClickUnmarkCheckboxReset
}) => {
  const [assignedPopUp, setAssigned] = useState(false)
  const [radioState] = useState('yes')
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    onClickUnmarkCheckboxReset()
  }, [])

  return (
    <Tab.Pane attached={false}>
      <Table singleLine className={opendetails == true ? 'tablepopup' : ''}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <CommonCheckbox
                onChange={onClickMarkAllCheckbox}
                name="isMarkAllCheckbox"
                checked={isMarkAllCheckbox}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>Contact</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {contactData?.map((data, index) => {
            return (
              <Table.Row data-key={index}>
                <Table.Cell data-key={index}>
                  <CommonCheckbox
                    onChange={e => onClickMarkCheckbox(e, index)}
                    name="isChecked"
                    checked={data.isChecked}
                  />
                </Table.Cell>
                <Table.Cell
                  className="time-set-table"
                  onClick={() => {
                    modalOpen()
                    //  messageCall(data.phone_number, data.id)
                    onCallLogs(data.id)
                    onClickData(data.id)
                    setActiveType('contact')
                  }}
                >
                  {data.image ? (
                    <div className="date-set-table-boxes">
                      <img
                        className="ui medium circular image"
                        src={data?.image}
                        alt=""
                      />
                      <div>
                        <p>{data?.name}</p>
                      </div>
                    </div>
                  ) : (
                    data?.name
                  )}
                </Table.Cell>
                <Table.Cell
                  className="time-set-table"
                  onClick={() => {
                    modalOpen()
                    onCallLogs(data.id)
                    onClickData(data.id)
                  }}
                >
                  {data.organization_name}
                </Table.Cell>
                <Table.Cell
                  className="metting-name"
                  onClick={() => {
                    modalOpen()
                    onCallLogs(data.id)
                    onClickData(data.id)
                  }}
                >
                  {data.phone_number}
                </Table.Cell>
                <Table.Cell
                  className="metting-name"
                  onClick={() => {
                    modalOpen()
                    onCallLogs(data.id)
                    onClickData(data.id)
                  }}
                >
                  {data.email}
                </Table.Cell>

                <Table.Cell
                  className="link-table"
                  onClick={() => {
                    modalOpen()
                    onCallLogs(data.id)
                    onClickData(data.id)
                  }}
                >
                  {data?.owner?.first_name}
                </Table.Cell>

                <Table.Cell
                  className="link-table"
                  onClick={() => {
                    onCallLogs(data.id)
                    modalOpen()
                    onClickData(data.id)
                  }}
                >
                  {data.created_at}
                </Table.Cell>

                <Table.Cell className="host-table">
                  <div className="leadtable">
                    <img
                      className="ui medium circular image"
                      src={Ellipse}
                      alt=""
                    />
                    <p>LEAD</p>
                  </div>
                </Table.Cell>

                <Table.Cell className="social-icon-set">
                  <div className="social-box-wrapper">
                    <div
                      onClick={() => {
                        onCallLogs(data.id)
                        onClickDataModal(data.id)
                      }}
                    >
                      <CommonButtons
                        style={{
                          display: 'block',
                          fontSize: '8px',
                          padding: '7px 8px'
                        }}
                        content="Preview"
                        background="#009FAD"
                        btnClass="btn-verify"
                      />
                    </div>
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
        {assignedPopUp ? (
          <OwnerModel
            fetchId={fetchId}
            selected={selected}
            assignedPopUp={assignedPopUp}
            setAssigned={setAssigned}
          />
        ) : (
          ''
        )}
      </Table>
    </Tab.Pane>
  )
}
export default ContactsPanels
