import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import { connect } from 'react-redux'
import { getWidgetCountries } from '../../../actions/settings'
import { getNumbers } from '../../../actions/numbers'

import {
  Button,
  Dropdown,
  Header,
  Modal,
  Input,
  TextArea
} from 'semantic-ui-react'

import CommonButtons from '../../../common/CommonButtons'

import CountryData from '../../../helpers/data/countryCode.json'

const SendMessageModal = ({
  open,
  onClose,
  setupConversation,
  getWidgetCountries,
  widgetCountries,
  numbers,
  getNumbers
}) => {
  const [allowedCountries, setAllowedCountries] = useState([])
  const [dropDownData, setDropDownData] = useState([])

  const [sendMsgData, setSendMsgData] = useState({
    from: null,
    to: '',
    message: '',
  })

  useEffect(() => {
    if (numbers) {
      let tempRows = []
      if (numbers.data) {
        let tempData = [...numbers.data]
        tempData.map((row, index) => {
          if (!row.primary) {
            let tempObject = {
              id: row.id,
              value: row.phone_number,
              text: `${row.friendly_name} - ${row.phone_number}`
            }
            tempRows.push(tempObject)
          }
        })
      }
      setSendMsgData({ ...sendMsgData, from: +tempRows[0].value })
      setDropDownData(tempRows)
    } else {
      getNumbers()
    }
  }, [numbers])

  useEffect(() => {
    resetWidgetCountries(widgetCountries?.data?.allowed_countries)
  }, [widgetCountries])

  useEffect(() => {
    if (!open) return
    if (allowedCountries.length) return

    getWidgetCountries()
  }, [open])

  const resetWidgetCountries = countries => {
    const allCountry = CountryData
    const allowed = allCountry.map(item => item.code.toLowerCase())
    setAllowedCountries(allowed)
  }

  return (
    <Modal
      className="send-message-modal"
      onClose={onClose}
      closeIcon
      open={open}
      size="tiny"
    >
      <Header content="Create New Conversation" />
      <Modal.Content style={{ padding: '30px' }}>
        <div className="send-message__dropdown">
          <label>From *</label>
          <Dropdown
            name="from"
            placeholder="Select Number"
            selection
            value={`+${sendMsgData.from}`}
            options={dropDownData}
            onChange={(e, data) =>
              setSendMsgData({ ...sendMsgData, from: +data.value })
            }
          />
        </div>
        <div className="send-message__input">
          <label>To </label>
          <PhoneInput
            placeholder="To"
            onlyCountries={allowedCountries}
            value={sendMsgData.to}
            onChange={value => setSendMsgData({ ...sendMsgData, to: value })}
          />
          {/* <Input
            placeholder="Enter a number or phone number"
            value={sendMsgData.to}
            onChange={e =>
              setSendMsgData({ ...sendMsgData, to: e.target.value })
            }
          /> */}
        </div>
        <div className="send-message__textarea">
          <label>Message * </label>
          <TextArea
            maxlength="160"
            name="message"
            value={sendMsgData.message}
            onChange={e =>
              setSendMsgData({ ...sendMsgData, message: e.target.value })
            }
            rows={2}
            title="Message *"
          />
          <br />
          <p>Character left {160 - sendMsgData.message.length} </p>
        </div>
      </Modal.Content>
      <Modal.Actions className="send-message__btn">
        <Button onClick={onClose} className="send-message__btn-grey">
          close
        </Button>
        <CommonButtons
          content="Send SMS"
          btnClass="send-message__btn-blue"
          background="#007bff"
          style={{ color: 'white' }}
          onClick={e => setupConversation(sendMsgData)}
        />
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    widgetCountries: state.settings.widgetCountries,
    numbers: state.numbers.numbers
  }
}

export default connect(mapStateToProps, { getWidgetCountries, getNumbers })(
  SendMessageModal
)
