import React, { useState, useEffect, useCallback } from 'react'
import classnames from 'classnames'
import { Form } from 'semantic-ui-react'
import Input from '../../common/CommonInput'
import leadLogo from '../../assets/images/call-feedback.png'
import Button from '../../common/CommonButtons'
import InputValidator from '../../common/validator'
import Title from '../../common/Title'
import { CommonNotify } from '../../common/CommonNotify'
import { billinginfo, getbillingInfo } from '../../config/billinginfo'
import CommonButtons from '../../common/CommonButtons'
import { Dropdown } from 'semantic-ui-react'
import { billingCountryList } from '../../helpers/billingCountryList'
//redux
import { connect } from 'react-redux'
import { getBillingInfo } from '../../actions/settings'

const BillingInfo = ({ loading, getBillingInfo, billingInfoData }) => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    address1: '',
    address2: '',
    country: '',
    city: '',
    state: '',
    Zip: ''
  })
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    companyName: false,
    address1: false,
    address2: false,
    country: false,
    city: false,
    state: false,
    Zip: false
  })

  const handleValidation = () => {
    let fields = data
    let errors = {}
    let formIsValid = true

    let checkIfNotEmptyArr = [
      'firstName',
      'lastName',
      'companyName',
      'address1',
      'address2',
      'country',
      'city',
      'state',
      'Zip'
    ]

    checkIfNotEmptyArr.forEach(item => {
      if (!InputValidator.checkIfNotEmpty(fields[item])) {
        formIsValid = false
        errors[item] = 'This is a required field'
      }
    })

    return formIsValid
  }
  const getInfo = useCallback(() => {
    loading(true)
    getBillingInfo()
    /*
    const name = 'dd'
    getbillingInfo(name)
      .then(res => {
        loading(false)
        const d = res.data.data
        const data = {}
        data.firstName = d.first_name
        data.lastName = d.last_name
        data.email = d.email
        data.companyName = d.company_name
        data.address1 = d.line1
        data.address2 = d.line2
        data.country = d.country
        data.city = d.city
        data.state = d.state
        data.Zip = d.zip
        setData(data)
      })
      .catch(err => {
        loading(false)
        //CommonNotify('Cant Fetch Billing Info')        
      }) */
  }, [loading])
  useEffect(() => {
    if (billingInfoData) {
      loading(false)
      const d = billingInfoData.data
      const data = {}
      data.firstName = d.first_name
      data.lastName = d.last_name
      data.email = d.email
      data.companyName = d.company_name
      data.address1 = d.line1
      data.address2 = d.line2
      data.country = d.country
      data.city = d.city
      data.state = d.state
      data.Zip = d.zip
      setData(data)
    } else {
      loading(true)
      getBillingInfo()
    }
  }, [billingInfoData])

  const onChangeInput = e => {
    if (e.target.value === '') {
      const key = e.target.name
      errors[key] = true
      setErrors({ ...errors })
    } else {
      const key = e.target.name
      errors[key] = false
      setErrors({ ...errors })
    }
    if (e.target.name === 'email') {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const check = re.test(e.target.value)
      if (check) {
        const key = e.target.name
        errors[key] = false
        setErrors({ ...errors })
      } else {
        const key = e.target.name
        errors[key] = true
        setErrors({ ...errors })
      }
    }
    const key = e.target.name
    const value = e.target.value
    data[key] = value
    setData({ ...data })
  }

  const options = [
    { key: 'm', text: 'Male', value: 'male' },
    { key: 'f', text: 'Female', value: 'female' },
    { key: 'o', text: 'Other', value: 'other' }
  ]

  const onClickUpdate = () => {
      loading(true)
      billinginfo(data)
        .then(res => {
          loading(false)
          if (!res) {
            CommonNotify('Cant Save Billing Info')
            return
          }
          CommonNotify('Billing Info Saved', 'success')
          getInfo()
        })
        .catch(err => {
          loading(false)
          CommonNotify('Cant Save Billing Info')
        })
  }

  const onChangeDropdown = (e, value) => {
    const key = value.name
    const Data = value.value
    data[key] = Data
    setData({ ...data })
  }

  const title = {
    type: 'image',
    titleOne: leadLogo,
    titleTwo: 'Billing'
  }
  return (
    <>
      <div className="billing-info-wrapper billing_info_main">
        <Title data={title} />
        <div className="billing-title-holder">
          <h2 className="billingInfo-title bold-text">
            Your Billing Information
          </h2>
          <h6 className="billingInfo-title">Enter your billing information</h6>
        </div>
        <div className="billingInfo-content" style={{padding: '0px 100px 100px'}}>
          {/* <form>
            <div className="billingInfo-input-holder">
              <div
                className={classnames('input-invi-wrapper', {
                  'on-error': errors.firstName
                })}
              >
                <Input
                  onChange={e => onChangeInput(e)}
                  type="text"
                  name="firstName"
                  title="First Name"
                  required={true}
                  value={data.firstName}
                />
              </div>
              <div
                className={classnames('input-invi-wrapper', {
                  'on-error': errors.lastName
                })}
              >
                <Input
                  onChange={e => onChangeInput(e)}
                  type="text"
                  name="lastName"
                  title="Last Name"
                  required={true}
                  value={data.lastName}
                />
              </div>
            </div>
            <div className="billingInfo-input-holder">
              <div
                className={classnames('input-invi-wrapper', {
                  'on-error': errors.email
                })}
              >
                <Input
                  onChange={e => onChangeInput(e)}
                  type="email"
                  name="email"
                  title="Email"
                  required={true}
                  value={data.email}
                />
              </div>
              <div
                className={classnames('input-invi-wrapper', {
                  'on-error': errors.companyName
                })}
              >
                <Input
                  onChange={e => onChangeInput(e)}
                  type="text"
                  name="companyName"
                  title="Company Name"
                  required={true}
                  value={data.companyName}
                />
              </div>
            </div>
            <div className="billingInfo-input-holder">
              <div
                className={classnames('input-invi-wrapper', {
                  'on-error': errors.address1
                })}
              >
                <Input
                  onChange={e => onChangeInput(e)}
                  type="text"
                  name="address1"
                  title="Address Line 1."
                  required={true}
                  value={data.address1}
                />
              </div>
              <div
                className={classnames('input-invi-wrapper', {
                  'on-error': errors.address2
                })}
              >
                <Input
                  onChange={e => onChangeInput(e)}
                  type="text"
                  name="address2"
                  title="Address Line 2."
                  required={true}
                  value={data.address2}
                />
              </div>
            </div>
            <div className="billingInfo-input-holder dropdown">
              <div
                className={classnames('input-invi-wrapper', {
                  'on-error': errors.country
                })}
              >
                <div className="input-invi-dropDown">
                  <label style={{ fontSize: "16px !important" }}>Country</label>
                  <Dropdown
                    onChange={(e, data) => onChangeDropdown(e, data)}
                    selection
                    search
                    name="country"
                    title="Country"
                    required={true}
                    options={billingCountryList}
                    value={data.country}
                  />
                </div>
              </div>
              <div
                className={classnames('input-invi-wrapper', {
                  'on-error': errors.city
                })}
              >
                <Input
                  onChange={e => onChangeInput(e)}
                  type="text"
                  name="city"
                  title="City"
                  required={true}
                  value={data.city}
                />
              </div>
              
            </div>
            <div className="billingInfo-input-holder">
              <div
                className={classnames('input-invi-wrapper', {
                  'on-error': errors.state
                })}
              >
                <Input
                  onChange={e => onChangeInput(e)}
                  type="text"
                  name="state"
                  title="State"
                  required={true}
                  value={data.state}
                />
              </div>

              <div
                className={classnames('input-invi-wrapper', {
                  'on-error': errors.state
                })}
              >
               
               
                <Input
                  onChange={e => onChangeInput(e)}
                  type="text"
                  name="Zip"
                  title="Zip"
                  required={true}
                  value={data.Zip}
                />
              </div>
            </div>
            <Button
              onClick={onClickUpdate}
              content="Save"
              type="button"
              background="blue"
              btnClass="btn-billing"
            />
            <CommonButtons
               onClick={ () => {getInfo()} }
              type="reset"
              content="Cancel"
              background="grey"
            />
          </form> */}
          <Form>
            <Form.Group widths="equal" style={{marginTop: '50px'}}>
              <Form.Input
                error={
                  errors.firstName && {
                    content: 'This is a required field',
                    pointing: 'above'
                  }
                }
                fluid
                name="firstName"
                label="First name"
                placeholder="First name"
                onChange={e => onChangeInput(e)}
                required={true}
                value={data.firstName}
              />
              <Form.Input
                fluid
                name="lastName"
                label="Last name"
                placeholder="Last name"
                error={
                  errors.lastName && {
                    content: 'This is a required field',
                    pointing: 'above'
                  }
                }
                onChange={e => onChangeInput(e)}
                required={true}
                value={data.lastName}
              />
            </Form.Group>
            <Form.Group widths="equal" style={{marginTop: '50px'}}>
              <Form.Input
                error={
                  errors.email && {
                    content: 'This is a required field',
                    pointing: 'above'
                  }
                }
                fluid
                name="email"
                label="Email"
                placeholder="Email"
                onChange={e => onChangeInput(e)}
                required={true}
                value={data.email}
              />
              <Form.Input
                fluid
                error={
                  errors.companyName && {
                    content: 'This is a required field',
                    pointing: 'above'
                  }
                }
                label="Company name"
                name="companyName"
                placeholder="Company name"
                onChange={e => onChangeInput(e)}
                required={true}
                value={data.companyName}
              />
            </Form.Group>
            <Form.Group widths="equal" style={{marginTop: '50px'}}>
              <Form.Input
                error={
                  errors.address1 && {
                    content: 'This is a required field',
                    pointing: 'above'
                  }
                }
                fluid
                name="address1"
                label="Address Line 1"
                placeholder="Address Line 1"
                onChange={e => onChangeInput(e)}
                required={true}
                value={data.address1}
              />
              <Form.Input
                error={
                  errors.address2 && {
                    content: 'This is a required field',
                    pointing: 'above'
                  }
                }
                fluid
                name="address1"
                label="Address Line 2"
                placeholder="Address Line 2"
                onChange={e => onChangeInput(e)}
                required={true}
                value={data.address1}
              />
            </Form.Group>
            <Form.Group style={{marginTop: '50px'}}>
              <Form.Select
                fluid
                width={6}
                label="Country"
                name="country"
                error={
                  errors.country && {
                    content: 'This is a required field',
                    pointing: 'above'
                  }
                }
                onChange={(e, data) => onChangeDropdown(e, data)}
                options={billingCountryList}
                required={true}
                placeholder="Country"
                value={data.country}
              />
              <Form.Input
                error={
                  errors.city && {
                    content: 'This is a required field',
                    pointing: 'above'
                  }
                }
                fluid
                width={4}
                name="city"
                label="City"
                placeholder="City"
                onChange={e => onChangeInput(e)}
                required={true}
                value={data.city}
              />
              <Form.Input
                fluid
                width={4}
                error={
                  errors.state && {
                    content: 'This is a required field',
                    pointing: 'above'
                  }
                }
                label="State"
                name="state"
                placeholder="State"
                onChange={e => onChangeInput(e)}
                required={true}
                value={data.state}
              />
              <Form.Input
                fluid
                width={2}
                error={
                  errors.zip && {
                    content: 'This is a required field',
                    pointing: 'above'
                  }
                }
                label="Zip"
                name="zip"
                placeholder="Zip"
                type='number'
                onChange={e => onChangeInput(e)}
                required={true}
                value={data.zip}
              />
            </Form.Group>
            <Form.Group style={{marginTop: '50px', justifyContent: 'center'}}>
              <Form.Button onClick={onClickUpdate} primary>
                Save
              </Form.Button>
              <Form.Button
                onClick={onClickUpdate}
                background="grey"
                onClick={() => {
                  getInfo()
                }}
                type="reset"
              >
                Cancel
              </Form.Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  billingInfoData: state.settings.billingInfo
})

export default connect(mapStateToProps, { getBillingInfo })(BillingInfo)
