import axios from 'axios'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { Dimmer, Loader, Modal, Tab } from 'semantic-ui-react'
import { getDialer } from '../../actions/dialer'
import callIcon from '../../assets/images/call_set.png'
// import { TextInput } from 'evergreen-ui'
import closeIcon from '../../assets/images/close-set.svg'
import deleteIcon from '../../assets/images/delete-icon.png'
import editIcon from '../../assets/images/edit-icon.svg'
import messageIcon from '../../assets/images/MessageId.png'
import flagImg from '../../assets/images/Usa.png'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import AssociationData from './AssociationData'
import ContactLogData from './ContactLogData'
import ContactNoteData from './ContactNoteData'
// import callIcon from '../../assets/images/mobileIcon.png'
// import personIcon from '../../assets/images/adminIcons.png'
import DetailsPanel from './detailsPanels'
import EditDetailOpen2 from './editDetailPopUpCompany'
import EditDetailOpen from './editDetailsPopUp'
import InsightsPanel from './InsightsPanel'
import LogsPanel from './LogsPanel'

const panes = [
  {
    menuItem: 'Details',
    render: getData => <DetailsPanel getData={getData} />
  },
  {
    menuItem: 'Activities',
    render: getData => <ContactLogData logData={getData} />
  },
  {
    menuItem: 'Insights',
    render: getData => <InsightsPanel getData={getData} />
  },
  {
    menuItem: 'Note',
    render: getData => <ContactNoteData getData={getData} />
  },
  {
    menuItem: 'Logs',
    render: getData => <LogsPanel getData={getData} />
  },
  {
    menuItem: 'Associated',
    render: getData => <AssociationData getData={getData} contact={true} />
  }
]

const panes2 = [
  {
    menuItem: 'Details',
    render: getData => <DetailsPanel getData={getData} />
  },
  {
    menuItem: 'Logs',
    render: logData => <ContactLogData logData={logData} />
  },

  {
    menuItem: 'Insights',
    render: getData => <InsightsPanel getData={getData} />
  },
  {
    menuItem: 'Associated',
    render: getData => <AssociationData getData={getData} company={true}/>
  }
]

const ContactModal = ({
  getData,
  opendetails,
  modalClose,
  companyList,
  logData,
  fetchId,
  setOpen,
  setActiveType,
  activeType,
  messageCall,
  ContactModal,
  fetchAllContacts,
  getDialer
}) => {
  const [editDetailOpen, setDetailOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [edtiLoader, setEdtiLoader] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const intialEditData = {
    name: '',
    phone_number: '',
    phone_number: '',
    email: '',
    country: '',
    type: '',
    owner_id: '',
    owner_name: '',
    first_name: '',
    organization_name: ''
  }
  const [editContact, setEditContact] = useState(intialEditData)

  const fetchContactByIds = id => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${getData.id}`
    axios
      .get(url)
      .then(res => {
        setEditContact({
          ...editDetailOpen,
          name: res?.data?.data?.name,
          email: res?.data?.data?.email,
          phone_number: res?.data?.data?.phone_number,
          owner_id: res?.data?.data?.owner?.id ? res?.data?.data?.owner?.id : 0,
          owner_name:
            res?.data?.data?.owner?.first_name +
            ' ' +
            res?.data?.data?.owner?.last_name
              ? res?.data?.data?.owner?.first_name +
                ' ' +
                res?.data?.data?.owner?.last_name
              : '',
          country: res?.data?.data?.country,
          type: res?.data?.data?.type,
          first_name: res?.data?.data?.owner?.first_name,
          organization_name: res?.data?.data?.organization_name
            ? res?.data?.data?.organization_name
            : 'test'
        })
        modalClose()
      })
      .catch(error => {})
  }

  const handleInputChange = (event, code, type) => {
    if (type === 'phone_number') {
      setEditContact({
        ...editContact,
        phone_number: `+${event}`,
        phone_country: code.countryCode
      })
    } else {
      const { name, value } = event.target

      editContact[name] = value
      setEditContact(prevContactData => ({
        ...prevContactData,
        [name]: value
      }))
    }
  }
  const handleEdit = e => {
    setDetailOpen(true)
  }

  const onclickOwner = () => {
    let retData = editContact
    if (editContact.owner_id == 0) {
      delete editContact.owner_id
      delete editContact.owner_name
    }
    setEdtiLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/update/${getData.id}`
    axios
      .post(url, retData)
      .then(res => {
        setEdtiLoader(false)
        setDetailOpen(false)
        CommonNotify('Contact successfully updated!', 'success')
        fetchAllContacts()
        setEditContact(intialEditData)
      })
      .catch(error => {
        // if(editContact.owner_id == 0){
        //   CommonNotify('owner Update is invalid')
        //   return;
        // }
        setEdtiLoader(false)
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Cant update contact!'
        )
      })
  }

  const handleTabChange = (e, { activeIndex }) => {
    setActiveIndex(activeIndex)
  }

  const closeSideModal = () => {
    setActiveIndex(0)
    modalClose()
  }

  const deleteSelected = async () => {
    setDeleteModal(false)
    const ids = [getData?.id]
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/delete-contacts`
    setLoading(true)
    axios
      .post(url, { ids })
      .then(res => {
        if (res.data) {
          CommonNotify('Successfully deleted contacts!', 'success')
          modalClose()
          fetchAllContacts()
          setLoading(false)
        }
      })
      .catch(err => {
        CommonNotify(err?.response?.data?.errors?.[0] || 'Cant delete contact!')
        setLoading(false)
      })
  }

  return (
    <div
      className={
        opendetails == true
          ? 'details-popup contact-detail-drawer active'
          : 'details-popup'
      }
    >
      <Dimmer active={loading} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      <div className="details-title">
        <div>
          <img
            src={editIcon}
            onClick={d => {
              handleEdit()
              fetchContactByIds(getData?.id)
            }}
          />
          <img
            style={{ marginLeft: '10px' }}
            src={deleteIcon}
            onClick={() => {
              setDeleteModal(true)
            }}
          />
        </div>
        <img src={closeIcon} onClick={closeSideModal} />
      </div>
      <div className="details-popup-profile">
        <div className="details-popup-profile-detail">
          <p className="details-popup-title">
            {activeType == 'contact'
              ? getData?.name
              : getData?.organization_name}
          </p>
          {getData?.owner?.designation ? (
            <p className="employee-title">
              {getData?.owner?.designation}, {getData?.organization_name}
            </p>
          ) : null}
          <p className="details-number">
            <img src={flagImg} />
            {getData?.phone_number}
          </p>

          <div className="social-box-wrapper">
            {getData?.phone_number?.length ? (
              <div className="socialWrap"></div>
            ) : (
              ''
            )}
            {getData?.phone_number?.length ? (
              <div className="socialWrap d-flex mb-2">
                <div
                  onClick={() => {
                    getDialer(getData?.phone_number)
                  }}
                  className="social-boxes"
                >
                  <img
                    className="ui medium circular image"
                    src={callIcon}
                    alt=""
                  />
                </div>
                <div
                  onClick={() => {
                    messageCall(getData?.phone_number, getData?.id)
                  }}
                  className="social-boxes"
                >
                  <img
                    className="ui medium circular image"
                    src={messageIcon}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {getData?.role && <p className="details-number">{getData?.role}</p>}
          <div className="user-acc-info">
            <div className="acc-info-box">
              <div className="acc-inner-box">
                <span>Answered</span>
                <h2>{getData?.total_answered || 0}</h2>
              </div>
            </div>

            <div className="acc-info-box">
              <div className="acc-inner-box">
                <span>Outgoing</span>
                <h2>{getData?.total_outgoing_calls || 0}</h2>
              </div>
            </div>
            <div className="acc-info-box">
              <div className="acc-inner-box">
                <span>Leads</span>
                <h2>{getData?.total_leads || 0}</h2>
              </div>
            </div>
            <div className="acc-info-box">
              <div className="acc-inner-box">
                <span>Missed</span>
                <h2>{getData?.total_missed_calls || 0}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-popup-detail contact-side-bar">
        <Tab
          companyList={companyList}
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
          menu={{ secondary: true, pointing: true }}
          panes={activeType == 'contact' ? panes : panes2}
          getData={getData}
          logData={logData}
          onclickOwner={onclickOwner}
          editContact={editContact}
          handleInputChange={handleInputChange}
        />
      </div>

      {editDetailOpen && activeType == 'contact' ? (
        <EditDetailOpen
          companyList={companyList}
          setDetailOpen={setDetailOpen}
          editDetailOpen={editDetailOpen}
          opendetails={opendetails}
          fetchId={fetchId}
          handleInputChange={handleInputChange}
          editContact={editContact}
          setEditContact={setEditContact}
          getData={getData}
          fetchContactByIds={fetchContactByIds}
          onclickOwner={onclickOwner}
          loading={edtiLoader}
        />
      ) : (
        ''
      )}

      {editDetailOpen && activeType == 'company' ? (
        <EditDetailOpen2
          loading={edtiLoader}
          companyList={companyList}
          setDetailOpen={setDetailOpen}
          editDetailOpen={editDetailOpen}
          opendetails={opendetails}
          fetchId={fetchId}
          handleInputChange={handleInputChange}
          editContact={editContact}
          getData={getData}
          fetchContactByIds={fetchContactByIds}
          onclickOwner={onclickOwner}
        />
      ) : (
        ''
      )}
      <Modal
        className="local-number-modal"
        open={deleteModal}
        onClose={e => setDeleteModal(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this contact ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={e => setDeleteModal(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={deleteSelected}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    dialerStatus: state.dialer.currentStatu
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, { getDialer })
)(ContactModal)
