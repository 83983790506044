/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'
import moment from 'moment'
import {
    Label,
    Menu,
    Dropdown,
    Dimmer,
    Loader,
    Input,
    Button,
    Segment,
    Sidebar,
    Image,
    Modal,
    Pagination,
    Icon,
    Checkbox
} from 'semantic-ui-react'
import { endpoints } from '../features/api/apiSlice'

import axios from 'axios'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import CommonDateRangePicker from '../components/daterangepicker/CommonDateRangePicker'
import LeadTable from '../components/leads/leadTable/LeadTable'
import RightPane from '../components/leads/leadRightPane/rightPopUp'
import LeadBanner from '../components/leads/LeadBanner'

import queryString from 'query-string'

import Title from '../common/Title'

import { CommonNotify } from '../common/CommonNotify'
import CommonButton from '../common/CommonButtons'
import CommonSelect from '../common/CommonSelect'

import leadLogo from '../assets/images/lead-logo.svg'
import exportIcon from '../assets/images/export.svg'
import {
    dayData,
    dayLabels,
    weekLabels,
    weekData,
    monthLabels,
    monthData
} from '../lib/LeadChartData'
import deleteIcon from '../assets/images/delete-icon.png'

import { CSVLink } from 'react-csv'
import { getLeadOwner } from '../config/leadAPI'

import { ExportToExcel } from '../components/leads/exportExcel'
import ListSegments from '../components/leads/ListSegments'
import { reset } from 'loadjs'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ReactComponent as AdvanceView } from '../assets/images/advance-view.svg'
import { ReactComponent as ListView } from '../assets/images/list.svg'
import { Tooltip } from '@mui/material'
import { SET_FLAG } from '../actions/types'

const headers = [
    { label: 'id', key: 'id' },
    { label: 'time', key: 'time' },
    { label: 'contact', key: 'contact' },
    { label: 'status', key: 'status' },
    { label: 'agent', key: 'agent' },
    { label: 'score', key: 'score' },
    { label: 'source', key: 'source' }
]

const options = [
    { key: 0, text: 'All Time', value: 'All Time' },
    { key: 1, text: 'Today', value: 'Today' },
    { key: 2, text: 'Yesterday', value: 'Yesterday' },
    { key: 3, text: 'This week', value: 'This week' },
    { key: 4, text: 'Last week', value: 'Last week' },
    { key: 5, text: 'This month', value: 'This month' },
    { key: 6, text: 'Last month', value: 'Last month' },
    { key: 7, text: 'Custom range', value: 'Select custom' }
]

const sortOptions = [
    { key: 0, text: 'Sort By', value: '' },
    { key: 1, text: 'Newest First', value: 'Newest First' },
    { key: 2, text: 'Oldest First', value: 'Oldest First' }
]

const statusOptions = [
    { key: 1, text: 'Pending', value: 1 },
    { key: 2, text: 'In progress', value: 2 },
    { key: 3, text: 'Qualified', value: 3 },
    { key: 4, text: 'Closed', value: 4 }
]

const leadSubStatus = [
    // { key: 'new', text: 'Unassigned', value: 'new', var: 'totalUnassignedLeads' },
    {
        key: 'pending',
        text: 'Pending',
        value: 'pending',
        var: 'totalpendingLeads',
        scheduleText: 'Upcoming'
    },
    {
        key: 'in-progress',
        text: ' Assigned',
        value: 'in-progress',
        var: 'totalinprogressleads',
        scheduleText: 'Past'
    },
    /* {
      key: 'qualified',
      text: 'Qualified',
      value: 'Qualified',
      var: 'totalQualifiedLeads'
    }, */
    {
        key: 'closed',
        text: 'Closed',
        value: 'Closed',
        var: 'totalClosedLeads',
        scheduleText: 'Cancelled'
    },
    {
        key: 'all',
        text: 'All',
        value: 'all',
        var: 'totalLeads',
        scheduleText: 'All'
    }
]

const leadStatusOutboundCalls = [
    // { key: 'new', text: 'Unassigned', value: 'new', var: 'totalUnassignedLeads' },
    {
        key: 'missed',
        text: 'Missed',
        value: 'failed_to_connect_agent',
        var: 'totalMissedOutgoingLeads'
    },
    {
        key: 'completed',
        text: 'Completed',
        value: 'completed',
        var: 'totalCompletedOutgoingLeads'
    },
    {
        key: 'voicemail',
        text: 'Voicemail',
        value: 'voicemail',
        var: 'totalVoicemailOutgoingLeads'
    },
    { key: 'all', text: 'All', value: 'all', var: 'totalOutgoingLeads' }
]

class RightPaneContainer extends Component {
    state = {
        selectedRecordIds: [],
        settingModal: false,
        segmentModal: 0,
        totalLeads: 0,
        tableFullData: null,
        totalUnassignedLeads: 0,
        totalpendingleads: 0,
        totalinprogressleads: 0,
        totalQualifiedLeads: 0,
        totalClosedLeads: 0,
        totalMissedOutgoingLeads: 0,
        totalCompletedOutgoingLeads: 0,
        totalOutgoingLeads: 0,
        totalVoicemailOutgoingLeads: 0,
        deleteModal: false,
        widget: '',
        allTags: [],
        updatedPane: 0,
        updatedtable: 0,
        updateList: false,
        segmentModal: false,
        listModal: false,
        fetchSegments: false,
        segmentName: '',
        listName: '',
        segmentActive: false,
        selectedList: -2,
        //data for chart
        leadScoreFilter: [],
        allLeadSubStatus: leadSubStatus,
        currentLeadSubStatus: leadSubStatus[0].value,
        membrs: [],
        allOwners: [],
        allOwners2: [],
        allAgents: [],
        dataLoaded: false,
        sortMain: '-id',
        rightPane: false,
        nextLeadId: 0,
        prevLeadId: 0,
        leadData: [],
        leadScore: [],
        selectedPickerOption: 'All Time',
        sortPickerOption: 'Sort By',
        id: '',
        widget: '',
        params: {
            limit: 10,
            page: 1,
            sort_field: 'id',
            sort_value: 'DESC',
            sort: '-id',
            'filter[type][!in]': 'TEXT_MESSAGE',
            'filter[direction][is]': 'Inbound',
            'filter[status][is]': 1
        },
        filterTable: {
            startDateFilter: moment(),
            endDateFilter: moment()
        },
        activePage: 15,
        isLoading: false,
        activeIndex: 0,
        setOfficeHour2: {
            addOfficeHour2: '',
            link: 'Link',
            officeHourFrom: '',
            officeHourTo: ''
        },
        addOfficeHour2: [1],
        selectCheckboxData: [],
        callLog: {
            title: '',
            legend: false,
            stepSize: '10',
            yAxes: true,
            padding: {
                top: '20',
                right: '20',
                bottom: '0',
                left: '0'
            },
            fontSize: 14,
            fontStyle: '600',
            gridLines: true,
            labels: [
                '',
                '23/03/29',
                '23/04/2019',
                '23/05/2019',
                '23/06/2019',
                '23/07/2019',
                '23/08/2019',
                '23/10/2019'
            ],
            datasets: [
                {
                    label: 'call logs',
                    fill: false,
                    backgroundColor: 'rgba(249,166,9,1)',
                    borderColor: 'rgba(249,166,9,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(249,166,9,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(249,166,9,1)',
                    pointHoverBorderColor: 'rgba(249,166,9,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 8,
                    pointHitRadius: 10,
                    data: [0, 10, 3, 9, 4, 5, 8, 2],
                    options: {
                        bezierCurve: true,
                        title: {
                            fontSize: 25
                        },
                        tooltips: {
                            mode: 'point',
                            enabled: true
                        },
                        layout: {
                            padding: {
                                left: 50,
                                right: 0,
                                top: 0,
                                bottom: 0
                            }
                        }
                    }
                }
            ],
            isDayActive: false,
            isWeekActive: true,
            isMonthActive: false
        },
        tableData: {
            messages: [],
            call: [],
            schedules: [],
            isCall: true,
            isMessages: false,
            isSchedules: false,
            isOutBound: false,
            isDigital: false
        },

        tableDataContent: [],
        leadStage: [],
        leadOwner: [],
        widget_id: null,

        //for tanble pagination
        tableDataTotalItem: 0,
        pageNumber: 1,
        totalPages: null,
        startItemCount: 0,
        endItemCount: 0,

        //edit fields checkbox state
        nodeCheckBoxFields: [],

        //filter fields state
        filterTable: {
            type: null,
            startDateFilter: [],
            endDateFilter: new Date(),
            status: null,
            search: null,
            score: null,
            agent: null
        },

        query: null,
        //check if all marked
        isMarkAllCheckbox: false,
        countItem: 0,
        showExport: false,
        isChecked: false,
        //modal state
        open: false,
        showDatePicker: false,
        animation: 'overlay',
        direction: 'left',
        directionMobile: 'top',
        visible: false,
        sortField: 'id',
        sortValue: 'DESC',
        exportData: [],
        dropDownData: [],
        filterCondition: 'is',
        filterDropDownOption: {
            score: null,
            type: null,
            owner: null,
            agent: null,
            final_status: null,
            tags: [],
            interseted2: [],
            source: [
                { key: 'no-referer', value: '', text: 'All Source' },
                { key: 'localhost', value: 'localhost', text: 'Localhost' },
                { key: 'qa.limecall', value: 'qa.limecall', text: 'QA Limecall' },
                { key: 'app.limecall', value: 'app.limecall', text: 'App Limecall' }
            ],
            status: [
                { key: 'Pending', value: 1, text: 'Pending' },
                { key: 'In Progress', value: 2, text: 'In Progress' },
                { key: 'Qualified', value: 4, text: 'Qualified' },
                { key: 'Closed', value: 3, text: 'Closed' }
            ],
            interseted: [
                { key: 0, value: '', text: 'All' },
                { key: 1, text: 'Good Fit', value: 1 },
                { key: 2, text: 'Pending', value: 2 },
                { key: 3, text: 'Bad Fit', value: 3 }
            ]
        },

        queryValue: {
            agent: { type: 'is', value: '' },
            phone_number: { type: 'begin', value: '' },
            final_status: { type: 'is', value: '' },
            type: { type: 'in', value: '' },
            status: { type: 'is', value: '' },
            owner_id: { type: 'is', value: '' },
            direction: { type: 'is', value: '' },
            // flags: { type: 'in', value: [] },
            stage: { type: 'is', value: '' },
            score: { type: 'is', value: '' },
            source: { type: 'contain', value: '' },
            tags: { type: 'in', value: '' },
            interseted: { type: 'is', value: '' }
        },
    }

    setCurrentStatus = async val => {
        await this.setState({ currentLeadStatus: val })
        await this.setState({
            currentLeadSubStatus: this.state.allLeadSubStatus[0]
        })
        this.fetchData()
    }

    setCurrentSubStatus = async val => {
        await this.setState({ currentLeadSubStatus: val })
        this.fetchData()
    }

    deleteModalView = bool => {
        this.setState({ deleteModal: bool })
    }

    setUpdatedPaneArg = () => {
        let { updatedPane } = this.state
        updatedPane = updatedPane + 1
        this.setState({ updatedPane })
    }

    setUpdatedPane = () => {
        setTimeout(this.setUpdatedPaneArg, 2000)
    }

    setUpdatedTableArg = () => {
        let { updatedTable } = this.state
        updatedTable = updatedTable + 1
        this.setState({ updatedTable })
    }

    setUpdatedTable = () => {
        setTimeout(this.setUpdatedTableArg, 2000)
    }

    getArrayIndex = (list, find) => {
        for (const [i, v] of list.entries()) {
            if (v.id == find) {
                return i
            }
        }
        return -1
    }

    getNextPrevLead = id => {
        let leads = []
        if (this.state.tableData.isCall) {
            leads = this.state.tableData.call
        } else if (this.state.tableData.isMessages) {
            leads = this.state.tableData.messages
        } else {
            leads = this.state.tableData.schedules
        }

        // find the lead id
        const currIndex = this.getArrayIndex(leads, '#' + id)

        let nextIndex = 0
        let prevIndex = 0

        if (currIndex == 0) {
            nextIndex = 1
            prevIndex = leads?.length - 1
        } else if (currIndex == leads?.length - 1) {
            nextIndex = 0
            prevIndex = currIndex - 1
        } else {
            nextIndex = currIndex + 1
            prevIndex = currIndex - 1
        }

        if (!leads[nextIndex]) {
            nextIndex = currIndex
        }

        if (!leads[prevIndex]) {
            prevIndex = currIndex
        }

        let pLead = leads[prevIndex]?.id
        let nLead = leads[nextIndex]?.id
        if (leads[prevIndex]?.id.charAt(0) == '#') {
            nLead = leads[prevIndex]?.id.substring(1)
        }
        if (leads[nextIndex]?.id.charAt(0) == '#') {
            pLead = leads[nextIndex]?.id.substring(1)
        }

        this.setState({ nextLeadId: nLead, prevLeadId: pLead })
    }

    reloadLeads = () => {
        //this.fetchDataAfterDelete()
        this.fetchData()
    }

    fetchAllTags = widget => {
        this.setState({
            widget
        })
        /* const token = localStorage.getItem('access_token')
        const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget}/tags`
    
        const settings = {
          url: URL,
          method: 'GET',
          timeout: 0,
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
          },
          processData: false,
          mimeType: 'multipart/form-data',
          contentType: false
        }
    
        axios(settings)
          .then(res => {  
            let aTags = []
            aTags = res.data.data
             this.selectAllTags(aTags)          
          
             
          })
          .catch(err => {
            if (err?.response?.status === 401) {
             
              return;
            }
            if(!err?.response){
              
            }
         }) */
    }

    setAllTags = aTags => {
        this.selectAllTags(aTags)
    }

    selectAllTags = tags => {
        let newTags = []
        newTags = tags.map(tag => {
            return {
                tag: tag,
                color: this.generateRandomColor()
            }
        })
        this.setState({ allTags: newTags })
    }

    generateRandomColor = () => {
        var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return randomColor
    }

    handleSwitch = (data) => {
        this.setState({ switchChecked: data?.checked })
        if (data?.checked) {
            this.props.history.push({ pathname: '/manageleads', state: { checked: true } })
        }
    }

    fetchWidget = async () => {
        const token = await localStorage.getItem('access_token')
        const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets?limit=3&offset=0`

        const settings = {
            url: URL,
            method: 'GET',
            timeout: 0,
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            processData: false,
            mimeType: 'multipart/form-data',
            contentType: false
        }

        await axios(settings)
            .then(res => {
                this.setState({ widget: res.data.data[0].id })
                this.fetchAllTags(res.data.data[0].id)
                this.setWidgets(res)
                localStorage.setItem('widget_active', res?.data?.data?.[0]?.active)
            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                }
            })
    }

    handleRightPaneOpen = async id => {
        this.setState({ dataLoaded: false, id })
        this.setState({ rightPane: true })

        let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/lead/${id}`
        axios
            .get(url)
            .then(res => {
                this.setState({ isLoading: false })
                this.setState({ leadData: res?.data })
                this.setState({ dataLoaded: true })
                this.getNextPrevLead(id)
            })
            .catch(err => {
                this.setState({ rightPane: false })
                this.setState({ isLoading: false })
                if (err.response?.data?.errors?.length) {
                    CommonNotify('Id is not available / server error')
                    return
                }
                if (err?.response?.status === 401) {
                    CommonNotify('Id is not available / server error')
                    return
                }
                if (!err?.response) {
                    CommonNotify('Id is not available / server error')
                    return
                }
            })
    }
    componentDidUpdate(prevProps, prevState) {
        
        if ( this.props?.selectedId && prevProps?.selectedId !== this.props?.selectedId) {

            this.handleRightPaneOpen(this.props?.selectedId)
        }
    }

    handleLoading = loading => {
        this.setState({ isLoading: loading })
    }

    setLeadData = res => {
        this.setState({ isLoading: false })
        this.setState({ leadData: res?.data })
        this.setState({ dataLoaded: true })
    }

    handleRightClose = () => {
        this.setState({ dataLoaded: false })
        this.setState({ rightPane: false })
        this.props.setSelectedId(null)
    }
    cloneSetHoursWrapper2 = () => {
        const currentDiv2 = this.state.addOfficeHour2
        currentDiv2.push(1)
        this.setState({ addOfficeHour2: currentDiv2 })
    }

    onClickAddOfficeHour2 = () => {
        const { setOfficeHour2 } = this.state
        const link = this.state.setOfficeHour2.link
        const officeHourFrom = this.state.setOfficeHour2.officeHourFrom
        const officeHourTo = this.state.setOfficeHour2.officeHourTo
        const addOfficeHour2 = 'addOfficeHour2'
        const addOfficeHourItem2 = link + ' ' + officeHourFrom + ' ' + officeHourTo

        setOfficeHour2[addOfficeHour2] = addOfficeHourItem2

        this.setState({ setOfficeHour2 })

        this.cloneSetHoursWrapper2()
    }

    onClickRemoveOfficeHours2 = () => {
        const currentDiv2 = this.state.addOfficeHour2
        currentDiv2.pop()
        this.setState({ addOfficeHour2: currentDiv2 })
    }

    resetPage = () => {
        let { tableData, tableDataContent, pageNumber } = this.state

        tableData.messages = []
        tableData.schedules = []
        tableData.call = []

        tableDataContent = []

        this.setState({
            sortField: 'id',
            sortValue: 'DESC',
            tableDataContent,
            isMarkAllCheckbox: false,
            pageNumber,
            tableData,
            selectedRecordIds: []
        })
    }

    handleSortData = (e, { value }) => {
        if (value === '') return

        if (value === 'Newest First') {
            this.setState({ sortMain: '-id', sortPickerOption: value }, () => {
                this.fetchData()
            })
        } else if (value === 'Oldest First') {
            this.setState({ sortMain: 'id', sortPickerOption: value }, () => {
                this.fetchData()
            })
        } else if (value === 'Pending') {
            this.setState({ sortMain: '-id', sortPickerOption: value }, () => {
                this.fetchData()
            })
        } else {
            this.setState({ sortMain: 'id', sortPickerOption: value }, () => {
                this.fetchData()
            })
        }
    }

    handleChangeDate = (e, { value }) => {
        if (value === 'Select custom') {
            this.setState({
                selectedPickerOption: value,
                filterTable: {
                    startDateFilter: moment(),
                    endDateFilter: moment()
                }
            })
            return
        }
        this.setState({ selectedPickerOption: value })
        switch (value) {
            case 'All Time':
                this.fetchData()
                break
            case 'Today':
                this.today()
                break
            case 'Yesterday':
                this.yesterday()
                break
            case 'This week':
                this.thisWeek()
                break
            case 'Last week':
                this.lastWeek()
                break
            case 'This month':
                this.thisMonth()
                break
            case 'Last month':
                this.lastMonth()
                break
            default:
                this.fetchData()
        }
    }

    today = () => {
        this.setState(
            {
                filterTable: {
                    startDateFilter: moment().format('YYYY-MM-DD'),
                    endDateFilter: moment().format('YYYY-MM-DD')
                },
                selectedPickerOption: 'Today'
            },
            () => {
                this.fetchDataWithDate()
            }
        )
    }

    yesterday = async () => {
        this.setState(
            {
                filterTable: {
                    startDateFilter: moment()
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD'),
                    endDateFilter: moment()
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD')
                }
            },
            () => {
                this.fetchDataWithDate()
            }
        )
    }

    lastWeek = () => {
        this.setState(
            {
                filterTable: {
                    startDateFilter: moment()
                        .subtract(1, 'weeks')
                        .startOf('isoWeek')
                        .format('YYYY-MM-DD'),
                    endDateFilter: moment()
                        .subtract(1, 'weeks')
                        .endOf('isoWeek')
                        .format('YYYY-MM-DD')
                }
            },
            () => {
                this.fetchDataWithDate()
            }
        )
    }

    thisWeek = () => {
        const startOfWeek = moment()
            .startOf('isoWeek')
            .format('YYYY-MM-DD')
        const endOfWeek = moment()
            .endOf('isoWeek')
            .format('YYYY-MM-DD')
        this.setState(
            {
                filterTable: {
                    startDateFilter: startOfWeek,
                    endDateFilter: endOfWeek
                }
            },
            () => {
                this.fetchDataWithDate()
            }
        )
    }

    thisMonth = () => {
        const startOfMonth = moment()
            .clone()
            .startOf('month')
            .format('YYYY-MM-DD')
        const endOfMonth = moment()
            .clone()
            .endOf('month')
            .format('YYYY-MM-DD')
        this.setState(
            {
                filterTable: {
                    startDateFilter: startOfMonth,
                    endDateFilter: endOfMonth
                }
            },
            () => {
                this.fetchDataWithDate()
            }
        )
    }

    lastMonth = () => {
        const prevMonthFirstDay = moment()
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD')
        const prevMonthLastDay = moment()
            .subtract(1, 'months')
            .endOf('month')
            .clone()
            .endOf('month')
            .format('YYYY-MM-DD')
        this.setState(
            {
                filterTable: {
                    startDateFilter: prevMonthFirstDay,
                    endDateFilter: prevMonthLastDay
                }
            },
            () => {
                this.fetchDataWithDate()
            }
        )
    }

    handleApply = (event, picker) => {
        this.setState(
            {
                filterTable: {
                    startDateFilter: moment(picker.startDate).format('YYYY-MM-DD'),
                    endDateFilter: moment(picker.endDate).format('YYYY-MM-DD')
                }
            },
            () => {
                this.fetchDataWithDate()
            }
        )
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber })
    }

    setSortField = field => {
        let { sortField, sortValue } = this.state
        if (sortField == field) {
            sortValue = this.flipSortType(sortValue)
            this.setState(
                {
                    sortValue: sortValue
                },
                () => {
                    this.fetchData()
                }
            )
            return
        }
        sortValue = 'DESC'
        this.setState(
            {
                sortField: field,
                sortValue: 'DESC'
            },
            () => {
                this.fetchData()
            }
        )
    }

    flipSortType = type => {
        if (type == 'DESC') return 'ASC'
        return 'DESC'
    }
    fetchDataWithDate = async () => {
        //const apiToken = await  localStorage.getItem('access_token')

        const {
            selectedValue,
            query,
            filterCondition,
            sortField,
            sortValue,
            pageNumber,
            filterTable
        } = this.state

        this.setState({ exportData: [], countItem: 0 })
        /*  const header = {
          Authorization: 'Bearer ' + apiToken
        } */

        const leadType = this.getLeadTypeNew()
        var url

        var params = {
            [`filter[created_at][between]`]: `${filterTable.startDateFilter},${filterTable.endDateFilter}`,
            limit: 10,
            page: pageNumber,
            sort_field: sortField,
            sort_value: sortValue
        }

        if (leadType !== 'LIVE_CALL' && leadType !== 'OUTBOUND_CALL') {
            params['filter[type][is]'] = leadType
        } else {
            params['filter[type][!in]'] =
                'TEXT_MESSAGE'

            params['filter[direction][is]'] = 'Inbound'
            if (leadType == 'OUTBOUND_CALL') {
                params['filter[direction][is]'] = 'Outbound'
            }
        }

        this.setState({ params })

        url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`
        /* axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params: params
          })
          .then(res => {
            this.setState({ isLoading: false })
            
            this.resetPage()      
    
            this.storeTableData(res.data.data.leads)
          })
          .catch(err => {
            this.setState({ isLoading: false })
            if (err?.response?.status === 401) {
            
              return;
            }
            if(!err?.response){
             
            }
          }) */
    }

    setLeadsStates = res => {
        this.setFilterDropDownOption(res.data.data.leads.data)
        this.setState({
            totalLeads: res.data.data.leads.total,
            totalpendingleads: res.data.data.leads.total,
            totalUnassignedLeads: res.data.data.leads.total,
            totalQualifiedLeads: res.data.data.leads.total,
            totalInProgressLeads: res.data.data.leads.total
        })
        this.setLeadNo(res.data.data)
        this.storeSegmentLead(res.data.data.leads.data)
        this.storeTableData(res.data.data.leads)
    }

    setWidgets = res => {
        const data = res.data.data?.[0]

        const updateLeadScore =
            data.scores &&
            data.scores.map((item, index) => {
                return {
                    key: index + 1,
                    text: item,
                    value: item
                }
            })

        if (updateLeadScore?.length) {
            updateLeadScore.unshift({
                key: 0,
                text: 'Lead scores',
                value: 0
            })
        }

        const updateStageScore =
            data.stages &&
            data.stages.map((item, index) => {
                return {
                    key: index + 1,
                    text: item,
                    value: item
                }
            })

        this.setState({
            leadScore: [...updateLeadScore],
            leadScoreFilter: [...updateLeadScore],
            leadStage: [...updateStageScore]
        })
    }

    setUserId = res => {
        const data = []
        res.data.data.map(number => {
            data.push({
                key: number[0],
                value: number[0],
                text: `LimeCall Number (${number[0]})`
            })
        })

        this.setState({
            ...this.state,
            dropDownData: data
        })
    }

    removeFilter = async () => {
        let {
            query,
            filterDropDownOption,
            selectedValue,
            filterCondition,
            reloadNeed
        } = this.state
        await this.setState(
            {
                selectedValue: '',
                query: '',
                filterCondition: '',
                segmentActive: false
            },
            this.reloadData
        )
    }

    reloadData = () => {
        let { reloadNeed } = this.state
        if (!reloadNeed) return
        this.fetchData()
        this.setState({ reloadNeed: false })
    }

    fetchFilterData = async data => {
        await this.setState({ reloadNeed: true, segmentActive: true })
        this.fetchData()
    }

    storeleadTypeTotal = data => {
        return
        this.setState({
            totalLeads: data.leads.total,
            totalpendingleads: data.totalPendingLeads,
            totalUnassignedLeads: data.totalUnassignedLeads,
            totalQualifiedLeads: data.totalQualifiedLeads
        })
    }

    fetchData = async data => {
        const {
            selectedValue,
            query,
            filterCondition,
            sortField,
            sortValue,
            pageNumber,
            sortMain
        } = this.state
        const leadType = this.getLeadTypeNew()
        var url

        var params = {
            limit: 10,
            page: pageNumber,
            sort_field: sortField,
            sort_value: sortValue,
            sort: sortMain
        }
        if (query) {
            if (selectedValue === 'Date') {
                var cond = 'like'
                filterCondition === '!is' ? (cond = '!like') : (cond = 'like')
                params = {
                    [`filter[created_at][${cond}]`]: query,
                    limit: 10,
                    page: pageNumber,
                    sort_field: sortField,
                    sort_value: sortValue
                }
            } else {
                // params = {
                //   [`filter[${selectedValue}][${filterCondition}]`] : query ,
                //   limit: 10,
                //   page: pageNumber,
                //   sort_field: sortField,
                //   sort_value: sortValue,
                //   sort : sortMain
                // }
            }
        } else {
            // if(selectedValue != ''){
            //   params = {
            //     [`filter[${selectedValue}][${filterCondition}]`]: query,
            //     limit: 10,
            //     page: pageNumber,
            //     sort_field: sortField,
            //     sort_value: sortValue,
            //     sort: sortMain
            //   }
            // }
        }

        // todo use iteration instead of manual block

        // if(leadType !== "LIVE_CALL")
        // {
        //   params['filter[type][is]'] = leadType
        // }else {
        //   params['filter[type][in]'] = 'LIVE_CALL,DIGITAL_CALL,UNSUPPORTED_COUNTRY,USER_BLACKLISTED,LEAVE_MESSAGE'
        // }

        if (leadType !== 'LIVE_CALL' && leadType !== 'OUTBOUND_CALL') {
            params['filter[type][is]'] = leadType
        } else {
            params['filter[type][!in]'] =
                'TEXT_MESSAGE'

            params['filter[direction][is]'] = 'Inbound'
            if (leadType == 'OUTBOUND_CALL') {
                params['filter[direction][is]'] = 'Outbound'
            }
        }

        const leadSubType = this.state.currentLeadSubStatus
        if (leadSubType !== 'all') {
            if (
                leadSubType === 'pending' &&
                this.state.mainTab === 'Scheduled Calls'
            ) {
                params['filter[schedule_call_status][in]'] = 'scheduled,rescheduled'
            } else if (
                leadSubType === 'in-progress' &&
                this.state.mainTab === 'Scheduled Calls'
            ) {
                params['filter[schedule_call_status][in]'] = 'completed'
            } else if (
                leadSubType === 'Closed' &&
                this.state.mainTab === 'Scheduled Calls'
            ) {
                params['filter[schedule_call_status][in]'] = 'failed,canceled '
            } else if (leadSubType === 'new') {
                params['filter[owner][is]'] = 'null'
            } else if (
                leadSubType === 'pending' &&
                this.state.mainTab !== 'Scheduled Calls'
            ) {
                params['filter[status][is]'] = '1'
            } else if (
                leadSubType === 'in-progress' &&
                this.state.mainTab !== 'Scheduled Calls'
            ) {
                params['filter[status][is]'] = '2'
            } else if (leadSubType === 'Qualified') {
                params['filter[status][is]'] = '4'
            } else if (leadSubType === 'cancled') {
                params['filter[final_status][is]'] = 'cancled'
            } else if (leadSubType === 'completed') {
                params['filter[final_status][is]'] = 'completed'
            } else if (leadSubType === 'failed') {
                params['filter[final_status][is]'] = 'failed'
            } else {
                // params['filter[status][is]'] = '3'
                params['filter[final_status][is not]'] = 'completed'
            }
        }

        for (const key in this.state.queryValue) {
            if (this.state.queryValue[key].value) {
                params[
                    `filter[${key}][${this.state.queryValue[key].type}]`
                ] = this.state.queryValue[key].value
            }
        }

        this.setState({ params })

        url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`
        axios
            .get(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                params: params
            })
            .then(res => {
                localStorage.setItem('pendingLeads', res.data.data?.totalPendingLeads)
                this.props.loadPendingList({ pendingLeads: res.data.data.totalPendingLeads || 0 })

                this.setState({
                    isLoading: false,
                    exportData: [],
                    countItem: 0,
                    tableFullData: res.data.data
                })
                this.setFilterDropDownOption(res.data.data.leads.data)
                this.resetPage()
                this.storeTableData(res.data.data.leads)
                this.setLeadNo(res.data.data)
                this.storeSegmentLead(res.data.data.leads.data)
            })
            .catch(err => {
                this.setState({ isLoading: false })

                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                }
            })
    }

    storeSegmentLead = data => {
        const leads = data.map(lead => {
            return lead.id
        })
        this.setState({ segmentLeads: leads })
    }

    setLeadNo = data => {
        localStorage?.setItem('pendingLeads', data.totalPendingLeads || 0)
        this.props.loadPendingList({ pendingLeads: data.totalPendingLeads || 0 })

        this.setState({
            totalLeads: data.totalLeads,
            totalinprogressleads: data.totalInProgressLeads,
            totalpendingLeads: data.totalPendingLeads,
            totalQualifiedLeads: data.totalQualifiedLeads,
            totalUnassignedLeads: data.totalUnassignedLeads,
            totalClosedLeads: data.totalClosedLeads,
            totalMissedOutgoingLeads: data?.totalMissedOutgoingLeads,
            totalCompletedOutgoingLeads: data?.totalCompletedOutgoingLeads,
            totalOutgoingLeads: data?.totalOutgoingLeads,
            totalVoicemailOutgoingLeads: data?.totalVoicemailOutgoingLeads
        })
    }

    fetchDataAfterDelete = async data => {
        //const apiToken = await localStorage.getItem('access_token')

        const {
            selectedValue,
            query,
            filterCondition,
            sortField,
            sortValue,
            pageNumber
        } = this.state

        /* const header = {
          Authorization: 'Bearer ' + apiToken
        } */

        const leadType = this.getLeadTypeNew()
        var url

        var params = {
            limit: 10,
            page: pageNumber,
            sort_field: sortField,
            sort_value: sortValue
        }

        // if(leadType !== "LIVE_CALL")
        // {
        // params['filter[type][is]'] = leadType
        // }
        // else {
        //   params['filter[type][in]'] = 'LIVE_CALL,DIGITAL_CALL,UNSUPPORTED_COUNTRY,USER_BLACKLISTED,LIVE_MESSAGE'
        // }

        if (leadType !== 'LIVE_CALL' && leadType !== 'OUTBOUND_CALL') {
            params['filter[type][is]'] = leadType
        } else {
            params['filter[type][!in]'] =
                'TEXT_MESSAGE'

            params['filter[direction][is]'] = 'Inbound'
            if (leadType == 'OUTBOUND_CALL') {
                params['filter[direction][is]'] = 'Outbound'
            }
        }

        const leadSubType = this.state.currentLeadSubStatus

        if (leadSubType !== 'all') {
            if (leadSubType === 'new') {
                params['filter[owner_id][!is]'] = ''
            } else if (leadSubType === 'pending') {
                params['filter[status][is]'] = '1'
            } else {
                params['filter[status][is]'] = '4'
            }
        }

        this.setState({ params })

        url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`

        /* axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params: params
          })
          .then(res => {
            this.setState({ isLoading: false })
            this.setFilterDropDownOption(res.data.data.leads.data)
            this.resetPage()
            this.storeTableData(res.data.data.leads)
          })
          .catch(err => {
            this.setState({ isLoading: false })
            if (err?.response?.status === 401) {
              
              return;
            }
            if(!err?.response){
             
            }
          }) */
    }

    uniquesArray = (datas, type) => {
        return Object.values(
            datas.reduce((a, c) => {
                a[c[type]] = c
                return a
            }, {})
        )
            .map(res => {
                var value
                if (type === 'owner') {
                    value = res['owner']
                } else {
                    value = res[type]
                }
                return {
                    key: res[type],
                    text: res[type],
                    value: value
                }
            })
            ?.filter(data => {
                return data.key !== null
            })
    }

    setFilterDropDownOption = datas => {
        const fStatus = [
            { value: 'failed_to_connect_agent', text: 'Missed call' },
            { value: 'completed', text: 'Connected' },
            { value: 'suspended', text: 'Blocked' }
        ]

        const final_status = fStatus.map((st, index) => {
            return {
                key: index + 1,
                text: st.text,
                value: st.value
            }
        })

        final_status.unshift({
            key: 0,
            text: 'All',
            value: 0
        })

        const agent = this.state.allOwners

        const score = this.state.leadScore
        const owner = this.state.allOwners2

        const tag = this.state.allTags.map(item => {
            return {
                key: item.tag,
                value: item.tag,
                text: item.tag
            }
        })


        const type = [
            {
                key: 0,
                text: 'All Call Type',
                value: ''
            },
            {
                key: 1,
                text: 'SCHEDULE_CALL',
                value: 'SCHEDULE_CALL'
            },
            {
                key: 2,
                text: 'LEAVE_MESSAGE',
                value: 'LEAVE_MESSAGE'
            },
            {
                key: 3,
                text: 'LIVE_CALL',
                value: 'LIVE_CALL'
            },
            {
                key: 4,
                text: 'DIGITAL_CALL',
                value: 'DIGITAL_CALL'
            },
            {
                key: 5,
                text: 'UNSUPPORTED_COUNTRY',
                value: 'UNSUPPORTED_COUNTRY'
            },
            {
                key: 6,
                text: 'USER_BLACKLISTED',
                value: 'USER_BLACKLISTED'
            }
        ]

        const status = [
            { key: 'All', text: 'All Status', value: 0 },
            { key: 'Pending', text: 'Pending', value: 1 },
            { key: 'Reviewed', text: 'Reviewed', value: 2 },
            { key: 'Qualified', text: 'Qualified', value: 4 },
            { key: 'Closed', text: 'Closed', value: 3 }
        ]

        this.setState({
            ...this.state,
            filterDropDownOption: {
                ...this.state.filterDropDownOption,
                owner,
                score,
                type,
                agent,
                final_status,
                status,
                tags: tag
            }
        })
    }

    componentDidMount() {
        // this.props.getLeads(this.state.params)
        let permitRoles = ['Admin', 'MANAGER', 'Manager']
        let role_name = localStorage.getItem('role_name')
        const role = permitRoles.includes(role_name)
        this.setState({ userRole: role_name, deleteLead: role })
        document.title = 'Leads'
        //await this.getWidget()
        this.fetchData()

        this.getActiveTab()
        this.getUserId()
        this.getOwner()
        this.fetchWidget()
        this.fetchSegments()
    }

    fetchTotalLeadsNo = () => {
        this.fetchTotalAssigned()
        this.fetchTotalUnAssigned()
        this.fetchTotalPending()
        this.fetchTotalQualified()
        this.fetchTotalInProgress()
    }

    getMainTabQuery = () => {
        const leadType = this.getLeadTypeNew()
        if (leadType !== 'LIVE_CALL') {
            return leadType
        } else {
            return 'LIVE_CALL,DIGITAL_CALL'
        }
    }

    fetchTotalAssigned = async () => {
        const apiToken = await localStorage.getItem('access_token')

        const {
            selectedValue,
            query,
            filterCondition,
            sortField,
            sortValue,
            pageNumber,
            sortMain
        } = this.state

        const header = {
            Authorization: 'Bearer ' + apiToken
        }

        var params = {
            limit: 1,
            page: 1
        }

        const uID = localStorage.getItem('id')
        const uName =
            localStorage.getItem('first_name') +
            ' ' +
            localStorage.getItem('last_name')

        params['sort_field'] = 'id'
        params['sort_value'] = 'DESC'

        params['filter[type][!in]'] = this.getMainTabQuery()

        if (query) {
            if (selectedValue === 'Date') {
                var cond = 'like'
                filterCondition === '!is' ? (cond = '!like') : (cond = 'like')
                params[`filter[created_at][${cond}]`] = query
            } else {
                params[`filter[${selectedValue}][${filterCondition}]`] = query
            }
        } else {
            if (selectedValue != '') {
                params[`filter[${selectedValue}][${filterCondition}]`] = query
            }
        }

        this.setState({ params })

        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`

        /* axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params: params
          })
          .then(res => {
            
            
            this.setState({
              totalLeads: res.data.data.leads.total        
            })
            
    
          })
          .catch(err => {
            
          })    */
    }

    fetchTotalPending = async () => {
        const apiToken = await localStorage.getItem('access_token')

        const {
            selectedValue,
            query,
            filterCondition,
            sortField,
            sortValue,
            pageNumber,
            sortMain
        } = this.state

        const header = {
            Authorization: 'Bearer ' + apiToken
        }

        var params = {
            limit: 1,
            page: 1
        }

        const uID = localStorage.getItem('id')
        const uName =
            localStorage.getItem('first_name') +
            ' ' +
            localStorage.getItem('last_name')

        params['sort_field'] = 'id'
        params['sort_value'] = 'DESC'

        params['filter[type][in]'] = this.getMainTabQuery()
        params['filter[status][is]'] = '1'

        if (query) {
            if (selectedValue === 'Date') {
                var cond = 'like'
                filterCondition === '!is' ? (cond = '!like') : (cond = 'like')
                params[`filter[created_at][${cond}]`] = query
            } else {
                params[`filter[${selectedValue}][${filterCondition}]`] = query
            }
        } else {
            if (selectedValue != '') {
                params[`filter[${selectedValue}][${filterCondition}]`] = query
            }
        }

        this.setState({ params })

        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`

        /* axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params: params
          })
          .then(res => {
            
            
            this.setState({
              
              totalpendingleads: res.data.data.leads.total        
            })        
            
    
          })
          .catch(err => {
            
          })   */
    }

    fetchTotalUnAssigned = async () => {
        const apiToken = await localStorage.getItem('access_token')

        const {
            selectedValue,
            query,
            filterCondition,
            sortField,
            sortValue,
            pageNumber,
            sortMain
        } = this.state

        const header = {
            Authorization: 'Bearer ' + apiToken
        }

        var params = {
            limit: 1,
            page: 1
        }

        const uID = localStorage.getItem('id')
        const uName =
            localStorage.getItem('first_name') +
            ' ' +
            localStorage.getItem('last_name')

        if (query) {
            if (selectedValue === 'Date') {
                var cond = 'like'
                filterCondition === '!is' ? (cond = '!like') : (cond = 'like')
                params[`filter[created_at][${cond}]`] = query
            } else {
                params[`filter[${selectedValue}][${filterCondition}]`] = query
            }
        } else {
            if (selectedValue != '') {
                params[`filter[${selectedValue}][${filterCondition}]`] = query
            }
        }

        params['sort_field'] = 'id'
        params['sort_value'] = 'DESC'

        params['filter[type][in]'] = this.getMainTabQuery()

        params['filter[owner][is]'] = 'null'

        this.setState({ params })
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`

        /* axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params: params
          })
          .then(res => {
          
            
            this.setState({
              totalUnassignedLeads: res.data.data.leads.total        
            })
            
    
          })
          .catch(err => {
            
          })  */
    }

    getActiveTab = () => {
        let url = this.props.location.search
        const options = [
            'all',
            'new',
            'pendingg',
            'in-progress',
            'Qualified',
            'Closed'
        ]
        let params = queryString.parse(url)
        if (options?.filter(e => e === params.tab)?.length > 0) {
            this.setCurrentSubStatus(params.tab)
        }
    }

    fetchTotalQualified = async () => {
        const apiToken = await localStorage.getItem('access_token')
        const {
            selectedValue,
            query,
            filterCondition,
            sortField,
            sortValue,
            pageNumber,
            sortMain
        } = this.state

        const header = {
            Authorization: 'Bearer ' + apiToken
        }

        var params = {
            limit: 1,
            page: 1
        }

        const uID = localStorage.getItem('id')
        const uName =
            localStorage.getItem('first_name') +
            ' ' +
            localStorage.getItem('last_name')

        params['sort_field'] = 'id'
        params['sort_value'] = 'DESC'

        params['filter[type][in]'] = this.getMainTabQuery()

        params['filter[status][is]'] = '3'

        if (query) {
            if (selectedValue === 'Date') {
                var cond = 'like'
                filterCondition === '!is' ? (cond = '!like') : (cond = 'like')
                params[`filter[created_at][${cond}]`] = query
            } else {
                params[`filter[${selectedValue}][${filterCondition}]`] = query
            }
        } else {
            if (selectedValue != '') {
                params[`filter[${selectedValue}][${filterCondition}]`] = query
            }
        }

        this.setState({ params })
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`

        /* axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params: params
          })
          .then(res => {
           
            
            this.setState({
              totalQualifiedLeads: res.data.data.leads.total        
            })
            
    
          })
          .catch(err => {
            
          })  */
    }

    fetchTotalInProgress = () => {
        const apiToken = localStorage.getItem('access_token')

        const {
            selectedValue,
            query,
            filterCondition,
            sortField,
            sortValue,
            pageNumber,
            sortMain
        } = this.state

        const header = {
            Authorization: 'Bearer ' + apiToken
        }

        var params = {
            limit: 1,
            page: 1
        }

        const uID = localStorage.getItem('id')
        const uName =
            localStorage.getItem('first_name') +
            ' ' +
            localStorage.getItem('last_name')

        params['sort_field'] = 'id'
        params['sort_value'] = 'DESC'

        params['filter[type][in]'] = this.getMainTabQuery()

        params['filter[status][is]'] = '2'

        if (query) {
            if (selectedValue === 'Date') {
                var cond = 'like'
                filterCondition === '!is' ? (cond = '!like') : (cond = 'like')
                params[`filter[created_at][${cond}]`] = query
            } else {
                params[`filter[${selectedValue}][${filterCondition}]`] = query
            }
        } else {
            if (selectedValue != '') {
                params[`filter[${selectedValue}][${filterCondition}]`] = query
            }
        }

        this.setState({ params })
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`

        /* axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params: params
          })
          .then(res => {
            
            
            this.setState({
              totalinprogressleads: res.data.data.leads.total        
            })
            
    
          })
          .catch(err => {
           
          })   */
    }

    getWidget = async () => {
        /* const apiToken = await localStorage.getItem('access_token')
        const w_id = await localStorage.getItem('widget_id');
        
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-call-settings?widget_id=${w_id}`
    
    
    
        axios
          .get(url)
          .then(res => {
            const data = res.data.data.scores_tags
    
            
    
            const updateLeadScore = data.allowed_scores && data.allowed_scores.map((item, index) => {
              return {
                key: index,
                text: item,
                value: item
              }
            })      
    
            
    
            const updateStageScore = data.allowed_stages && data.allowed_stages.map((item, index) => {
              return {
                key: index,
                text: item,
                value: item
              }
            })
           
    
    
    
            this.setState({
              leadScore: [...updateLeadScore],
              leadScoreFilter: [...updateLeadScore],
              leadStage : [...updateStageScore]
            })
    
          
    
          })
    
          .catch(err => {
            if (err?.response?.status === 401) {
             
              return;
            }
            if(!err?.response){
              
            }
          }) */
    }

    getOwner = async () => {
        const owner = [
            {
                key: 'unassign owner',
                text: 'Unassign owner',
                value: null
            }
        ]
        const allMembers = []
        const allMembers2 = []

        await getLeadOwner()
            .then(async res => {
                const admin = res.data.data.admin
                await owner.push({
                    key: admin.id,
                    text: admin.first_name + ' ' + admin.last_name,
                    value: admin.id
                })

                allMembers.push({
                    key: 0,
                    text: 'All Agents',
                    value: 0
                })

                await allMembers.push({
                    key: admin.id,
                    text: admin.first_name + ' ' + admin.last_name,
                    value: admin.first_name + ' ' + admin.last_name
                })

                allMembers2.push({
                    key: 0,
                    text: 'All Owners',
                    value: 0
                })

                await res.data.data.members.map(team => {
                    owner.push({
                        key: team.id,
                        text: team.first_name + ' ' + team.last_name,
                        value: team.id
                    })
                    allMembers.push({
                        key: team.id,
                        text: team.first_name + ' ' + team.last_name,
                        value: team.first_name + ' ' + team.last_name
                    })

                    allMembers2.push({
                        key: team.id,
                        text: team.first_name + ' ' + team.last_name,
                        value: team.id
                    })
                })

                this.setState({
                    ...this.state,
                    leadOwner: owner,
                    allOwners: allMembers,
                    allOwners2: allMembers2,
                    allAgents: allMembers
                })
            })
            .catch(err => { })
    }

    async getUserId() {
        /* const apiToken = await localStorage.getItem('access_token')
        
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/caller-id`
    
        axios
          .get(url)
          .then(res => {
            const data = []
            res.data.data.map(number => {
              data.push({
                key: number[0],
                value: number[0],
                text: `LimeCall Number (${number[0]})`
              })
            })
    
            this.setState({
              ...this.state,
              dropDownData: data
            })
          })
          .catch(err => {
            
            if (err?.response?.status === 401) {
             
              return;
            }
            if(!err?.response){
             
            }
          }) */
    }

    storeTableData = data => {
        const dataA = data.data
        let { pageNumber, totalPages } = this.state
        totalPages = data.last_page
        let newData = []
        let LiveNewData = []
        const lType = this.getLeadTypeNew()
        let s = ''

        if (lType === 'LIIVE_CALL') {
            dataA.map(val => {
                if (val.type !== 'LEAVE_MESSAGE' && val.type !== 'SCHEDULE_CALL') {
                    LiveNewData.push(val)
                }
            })
        } else {
            LiveNewData = dataA
        }

        LiveNewData.map(val => {
            if (val.source) {
                if (
                    val.source.indexOf('http://') == 0 ||
                    val.source.indexOf('https://') == 0
                ) {
                    const d = val.source
                    s = d.split('//')

                    if (s[1]) {
                        const n = s[1].split('/')
                        s = n[0] ? n[0] : n
                    }
                } else {
                    const d = val.source
                    s = d.split('.')

                    if (s[1]) {
                        const n = s[1].split('/')
                        s = n[0] ? n[0] : n
                    }
                }
            }
            let item = {
                id: '#' + val.id,
                schedule_call_status: val.schedule_call_status,
                request_call_time: val.request_call_time,

                owner_id: val.owner_id,
                defaultStage: val.stage,
                stage: val.stage,

                time: val.created_at,
                contact: val.phone_number,
                phone_number: val.phone_number,
                email: val.email,
                source: val.source ? s : 'Unknown',
                interseted: val.interseted,
                call_status: val.final_status,
                final_status: val.final_status,
                status: val.status ? val.status : 1,
                recording_url: val.recording_url,
                duration: val.duration,
                tags: JSON.parse(val.tags) || [],
                agent: val.agent,
                score: val.score == null || val.score <= 1 ? 1 : val.score,
                cost: val.cost,
                failure_reason: val.failure_reason,
                direction: val.direction,
                customerName: val.customer_name,
                meetingLocation: val.meeting_location,
                contactName: val.contact,
                type: val.type,
                isHoverDisplay: false
            }

            if (
                item.customerName !== null &&
                item.customerName !== 'null' &&
                item.customerName !== 'undefined'
            ) {
                item.contact = item.customerName
                item.isHoverDisplay =
                    item.email === null && val.phone_number === null ? true : false
            } else if (item.contact !== null) {
                item.contact = item.contact
            } else if (item.email !== null && item.email !== 'undefined') {
                item.contact = item.email
            } else if (
                item.contact === null &&
                item.contactName === null &&
                item.email === null &&
                item.customerName === null &&
                item.customerName === 'undefined' &&
                item.email === 'undefined'
            ) {
                item.contact = 'Unknown'
            }

            newData.push(item)
        })

        const leadType = this.getLeadType()
        let call = false
        let message = false
        let schedule = false
        let digital = false
        let outbound = false
        if (leadType == 'call') {
            call = true
        } else if (leadType == 'message') {
            message = true
        } else if (leadType == 'digital') {
            digital = true
        } else if (leadType == 'OUTBOUND_CALL') {
            outbound = true
        } else {
            schedule = true
        }
        this.manageIdState(newData)
        this.setState({
            isLoading: false,
            tableDataContent: newData,
            isMarkAllCheckbox: false,
            pageNumber,
            totalPages,
            tableData: {
                messages: newData,
                call: newData,
                schedules: newData,
                isCall: call,
                isMessages: message,
                isSchedules: schedule,
                isOutBound: outbound,
                isDigital: digital
            }
        })
    }

    storeTableData2 = data => {
        const dataA = data
        let { pageNumber, totalPages } = this.state
        totalPages = data.last_page
        let newData = []
        let LiveNewData = []
        const lType = this.getLeadTypeNew()

        if (lType === 'LIIVE_CALL') {
            dataA.map(val => {
                if (val.type !== 'LEAVE_MESSAGE' && val.type !== 'SCHEDULE_CALL') {
                    LiveNewData.push(val)
                }
            })
        } else {
            LiveNewData = dataA
        }

        LiveNewData.map(val => {
            if (val.score == null || val.score <= 1) {
                val.score = 1
            }

            if (val.source) {
                if (
                    val.source.indexOf('http://') == 0 ||
                    val.source.indexOf('https://') == 0
                ) {
                    const d = val.source
                    const s = d.split('//')

                    if (s[1]) {
                        const n = s[1].split('/')
                        val.source = n[0] ? n[0] : n
                    } else {
                        val.source = s
                    }
                } else {
                }
            } else {
                val.source = 'Unknown'
            }

            if (val.member == null || val.member == '') {
                val.member = ''
            } else {
                val.member = val.member.first_name
            }
            let item = {
                id: '#' + val.id,
                schedule_call_status: val?.schedule_call_status,
                request_call_time: val?.request_call_time,
                owner_id: val.owner_id,
                defaultStage: val.stage,
                stage: val.stage,
                time: val.created_at,
                contact: val.phone_number,
                phone_number: val.phone_number,
                email: val.email,
                source: val.source,
                interseted: val.interseted,
                call_status: val.final_status,
                final_status: val.final_status,
                status: val.status ? val.status : 1,
                recording_url: val.recording_url,
                duration: val.duration,
                tags: JSON.parse(val.tags) || [],
                agent: val.agent,
                score: val.score,
                cost: val.cost,
                customerName: val.customer_name,
                meetingLocation: val.meeting_location,
                contactName: val.contact,
                type: val.type,
                isHoverDisplay: false
            }

            if (
                item.customerName !== null &&
                item.customerName !== 'null' &&
                item.customerName !== 'undefined'
            ) {
                item.contact = item.customerName
                item.isHoverDisplay =
                    item.email === null && val.phone_number === null ? true : false
            } else if (item.contact !== null) {
                item.contact = item.contact
            } else if (item.email !== null && item.email !== 'undefined') {
                item.contact = item.email
            } else if (
                item.contact === null &&
                item.contactName === null &&
                item.email === null &&
                item.customerName === null &&
                item.customerName === 'undefined' &&
                item.email === 'undefined'
            ) {
                item.contact = 'Unknown'
            }

            newData.push(item)
        })

        const leadType = this.getLeadType()
        let call = false
        let message = false
        let schedule = false
        let digital = false
        let outbound = false

        if (leadType == 'call') {
            call = true
        } else if (leadType == 'message') {
            message = true
        } else if (leadType == 'digital') {
            digital = true
        } else if (leadType == 'outbound') {
            outbound = true
        } else {
            schedule = true
        }

        this.setState({
            tableData: {
                messages: newData,
                call: newData,
                schedules: newData,
                isCall: call,
                isMessages: message,
                isSchedules: schedule,
                isDigital: digital,
                isOutBound: outbound
            }
        })
        this.manageIdState(newData)
        this.setState({
            tableDataContent: newData,
            isMarkAllCheckbox: false,
            pageNumber,
            totalPages
        })
    }

    toggleSettingModal = st => {
        if (this.state.listSegments && this.state.listSegments?.length > 1) {
            this.setState({ settingModal: st })
        } else {
            CommonNotify('Lead Lists not available')
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearTimeout(this.interval)
            this.interval = 0
        }
    }

    updateData = () => {
        const category = document
            .querySelectorAll('?.filter .active')[1]
            .innerHTML.toLocaleLowerCase()
        this.filterDataPerPage(category)
    }

    fetchDataOld = () => { }

    filterDataPerPage = cat => {
        this.interval = setTimeout(() => {
            const { tableData } = this.state

            const category = cat.toLowerCase()
            let { tableDataTotalItem, pageNumber } = this.state
            tableDataTotalItem = tableData[category]?.length
            const limit = 10
            let offset = (pageNumber - 1) * limit
            let start = offset + 1
            let lastItemCount = offset + limit
            let end = Math.min(lastItemCount, tableDataTotalItem)

            let temporaryContainer = []

            if (tableData[category]?.length) {
                tableData[category].forEach((value, index) => {
                    if (index + 1 >= start && index + 1 <= end) {
                        temporaryContainer.push(value)
                    } else {
                        temporaryContainer.splice(index, 1)
                    }
                })
            }
            this.manageIdState(temporaryContainer)
            this.setState({
                startItemCount: start,
                lastItemCount: end,
                tableDataTotalItem,
                tableDataContent: temporaryContainer
            })
        }, 200)
    }

    handleStartDate = (event, date) => { }

    handleEndDate = date => {
        const { filterTable } = this.state

        filterTable.endDateFilter = date
        this.setState({ ...filterTable })
    }

    onClickIncrementPage = () => {
        let { pageNumber, tableDataTotalItem } = this.state
        const limit = 10
        let totalPages = Math.ceil(tableDataTotalItem / limit)

        if (pageNumber <= 1) {
            pageNumber = pageNumber + 1
            this.setState({ pageNumber }, () => {
                this.fetchData()
            })
        } else if (pageNumber > 1) {
            if (pageNumber > 1) {
                pageNumber = pageNumber - 1
                this.setState({ pageNumber }, () => {
                    this.fetchData()
                })
            }
        }
    }

    onClickDecrementPage = () => {
        let { pageNumber } = this.state

        if (pageNumber > 1) {
            pageNumber = pageNumber - 1
            this.setState({ pageNumber }, () => {
                this.fetchData()
            })
        }
    }

    onClickMarkCheckbox = index => {

        let { isMarkAllCheckbox, tableDataContent } = this.state

        if (index !== undefined) {
            tableDataContent[index].isChecked = !tableDataContent[index].isChecked

            this.setState({ ...tableDataContent })
            this.manageIdState(tableDataContent)
            this.setState({ isMarkAllCheckbox: false })
            const show = tableDataContent.some(item => item.isChecked === true)

            if (show) {
                this.setState({ showExport: true })
            } else {
                this.setState({ showExport: false })
            }
            const dataFilter = tableDataContent?.filter(
                item => item.isChecked === true
            )
            this.setState({ countItem: dataFilter?.length, exportData: dataFilter })
        }
    }

    manageIdState = data => {
        this.setState({
            selectedRecordIds: data
                ?.filter(list => {
                    if (list?.isChecked === true) {
                        return list?.id?.substring(1)
                    }
                })
                ?.map(value => {
                    return value?.id?.substring(1)
                })
        })
    }

    onClickMarkAllCheckbox = () => {
        let { isMarkAllCheckbox, tableDataContent } = this.state

        isMarkAllCheckbox = !isMarkAllCheckbox

        if (isMarkAllCheckbox) {
            tableDataContent.map(data => {
                return (data.isChecked = true)
            })
            this.manageIdState(tableDataContent)
            this.setState({ isMarkAllCheckbox, ...tableDataContent })
        } else {
            tableDataContent.map(data => {
                return (data.isChecked = false)
            })
            this.manageIdState(tableDataContent)
            this.setState({ isMarkAllCheckbox, ...tableDataContent })
        }

        const show = tableDataContent.some(item => item.isChecked === true)
        if (show) {
            this.setState({ showExport: true })
        } else {
            this.setState({ showExport: false })
        }

        const dataFilter = tableDataContent?.filter(item => item.isChecked === true)

        this.setState({ countItem: dataFilter?.length, exportData: dataFilter })
    }

    onChangeSelectFilter = e => {
        let { filterTable } = this.state
        const type = e.target.innerHTML
        const name = e.target.parentNode.parentNode.parentNode.getAttribute('name')

        filterTable[name] = type
        this.setState({ ...filterTable })

        const datePicker = document.querySelector('.holder-date')

        if (filterTable.dateRange === 'Custom Date') {
            this.setState({ showDatePicker: true })
        } else {
            this.setState({ showDatePicker: false })
        }
    }

    onChangeInputFilter = (e, data) => {
        //alert(data.value);

        const { filterCondition } = this.state
        this.setState(
            {
                ...this.state,
                filterCondition: 'is'
            },
            () => this.selectFilter(e, data)
        )
    }

    onClickMark = () => {
        let { isMarkOpen } = this.state
        const body = document.querySelector('body')

        isMarkOpen = !isMarkOpen
        body.classList.add('overflow-hide')

        this.setState({ isMarkOpen })
    }

    onClickDay = () => {
        let { callLog } = this.state

        const day = dayLabels
        const data = dayData

        callLog.labels = day
        callLog.datasets[0].data = data
        callLog.isDayActive = true
        callLog.isWeekActive = false
        callLog.isMonthActive = false

        this.setState({ callLog })
    }

    onClickWeek = () => {
        let { callLog } = this.state

        const week = weekLabels
        const data = weekData

        callLog.labels = week
        callLog.datasets[0].data = data
        callLog.isDayActive = false
        callLog.isWeekActive = true
        callLog.isMonthActive = false

        this.setState({ callLog })
    }

    onClickMonth = () => {
        let { callLog } = this.state

        const month = monthLabels
        const data = monthData

        callLog.labels = month
        callLog.datasets[0].data = data
        callLog.isDayActive = false
        callLog.isWeekActive = false
        callLog.isMonthActive = true

        this.setState({ callLog })
    }

    getLeadType = () => {
        let { tableData } = this.state

        if (tableData.isDigital) return 'digital'
        if (tableData.isCall) return 'call'
        if (tableData.isMessages) return 'message'
        if (tableData.isSchedules) return 'SCHEDULE_CALL'
        if (tableData.isOutBound) return 'OUTBOUND_CALL'
    }

    getLeadTypeNew = () => {
        let { tableData } = this.state
        if (tableData.isDigital) return 'DIGITAL_CALL'
        if (tableData.isCall) return 'LIVE_CALL'
        if (tableData.isMessages) return 'LEAVE_MESSAGE'
        if (tableData.isSchedules) return 'SCHEDULE_CALL'
        if (tableData.isOutBound) return 'OUTBOUND_CALL'
    }

    getLeadCount = type => {
        localStorage?.setItem('pendingLeads', this.state.totalPendingLeads || 0)
        this.props.loadPendingList({ pendingLeads: this.state.totalPendingLeads || 0 })

        if (type == 'all') {
            return this.state.totalLeads
        } else if (type == 'pending') {
            return this.state.totalPendingLeads
        } else if (type == 'qualified') {
            return this.state.totalQualifiedLeads
        } else if (type == 'in-progress') {
            return this.state.totalinprogressLeads
        } else {
            return this.state.totalUnassignedLeads
        }
    }

    onClickTableCategory = e => {
        let {
            tableData,
            tableDataContent,
            pageNumber,
            allLeadSubStatus
        } = this.state
        var target = e.target.innerHTML
        this.setState({ mainTab: target })
        if (target !== 'Outbound Calls') {
            this.setState({
                allLeadSubStatus: leadSubStatus,
                currentLeadSubStatus: leadSubStatus[0].value
            })
        } else {
            this.setState({
                allLeadSubStatus: leadStatusOutboundCalls,
                currentLeadSubStatus: leadStatusOutboundCalls[3].value
            })
        }
        var status1st = allLeadSubStatus[3].value
        if (
            target === 'Calls' ||
            target === 'Leads' ||
            target === 'Scheduled Calls'
        ) {
            this.setState({
                allLeadSubStatus: leadSubStatus,
                currentLeadSubStatus: leadSubStatus[0].value
            })
        } else {
            this.setState({
                allLeadSubStatus: leadSubStatus,
                currentLeadSubStatus: status1st
            })
        }

        if (target === 'Calls') {
            tableData.isCall = true
            tableData.isMessages = false
            tableData.isSchedules = false
            tableData.isDigital = false
            tableData.isOutBound = false
        } else if (target === 'Leads') {
            tableData.isCall = false
            tableData.isMessages = true
            tableData.isSchedules = false
            tableData.isDigital = false
            tableData.isOutBound = false
        } else if (target === 'Digital') {
            tableData.isCall = false
            tableData.isMessages = false
            tableData.isSchedules = false
            tableData.isDigital = true
            tableData.isOutBound = false
        } else if (target === 'Outbound Calls') {
            this.setState({
                allLeadSubStatus: leadStatusOutboundCalls
            })
            tableData.isCall = false
            tableData.isMessages = false
            tableData.isSchedules = false
            tableData.isDigital = false
            tableData.isOutBound = true
        } else {
            target = 'schedules'
            tableData.isCall = false
            tableData.isMessages = false
            tableData.isSchedules = true
            tableData.isOutBound = false
        }

        pageNumber = 1
        this.filterDataPerPage(target)
        this.manageIdState(tableDataContent)
        this.setState(
            {
                tableDataContent,
                isMarkAllCheckbox: false,
                pageNumber,
                sortField: 'id',
                sortValue: 'DESC'
            },
            () => {
                this.fetchData()
            }
        )
    }

    handleScore = e => {
        let { tableDataContent } = this.state
        const dataId = e.target.parentNode.parentNode.parentNode.querySelectorAll(
            'td'
        )[1].innerText

        const score = e.target.getAttribute('data-score')

        tableDataContent.forEach(value => {
            if (value.id === dataId) {
                value.score = score
            }
        })
        this.manageIdState(tableDataContent)
        this.setState({ tableDataContent })
    }

    toggleTags = e => {
        const dropdown = e.target.querySelector('.holder-dropdown')
        const allDropdown = document.querySelectorAll('.holder-dropdown')

        if (dropdown) {
            allDropdown.forEach(value => {
                value.classList.remove('holder-dropdown-active')
            })

            if (dropdown?.classList) {
                dropdown.classList.add('holder-dropdown-active')
            }
        } else {
            return
        }
    }

    handleTagsData = e => {
        let { tableDataContent } = this.state
        const tag = e.target.innerText
        const dataId = e.target.parentNode.parentNode.parentNode.querySelectorAll(
            'td'
        )[1].innerText

        tableDataContent.forEach(value => {
            if (value.id === dataId) {
                const index = value.tag.indexOf(tag)

                if (index === -1) {
                    value.tag.push(tag)
                } else {
                    value.tag.splice(index, 1)
                }
            }
        })
        this.manageIdState(tableDataContent)
        this.setState({ tableDataContent })
    }

    show = size => () => this.setState({ size, open: true })
    close = () => this.setState({ open: false })

    closeDropdown = e => {
        const { isMarkOpen } = this.state
        const target = e.target.className
        const body = document.querySelector('body')
        const allDropdown = document.querySelectorAll('.holder-dropdown')
        const dropDown = document.querySelector('.holder-export-options')

        if (
            e.target?.classList[0] !== 'btn-export' &&
            this.state.showExport === true
        ) {
            if (dropDown?.classList) {
                dropDown.classList.remove('show')
            }
        }

        if (e.target.classList[0] !== 'tags') {
            allDropdown.forEach(value => {
                value.classList.remove('holder-dropdown-active')
            })
        }

        if (isMarkOpen) {
            if (target !== 'checkbox-item' || target !== 'mark active') {
                body.classList.remove('overflow-hide')
                this.setState({ isMarkOpen: false })
            }
        }
    }

    openExportFile = e => {
        const dropDown = document.querySelector('.holder-export-options')

        if (dropDown?.classList) {
            dropDown.classList.toggle('show')
        }
    }

    handleChange = (event, data) => {
        this.setState({ selectedValue: data.value })
    }

    getTagValue = data => {
        if (Array.isArray(data)) {
            return data
        }
        return data.split(',')
    }

    handleChangeNew = (e, data, param) => {
        const qValue = { ...this.state.queryValue }

        if (param == 'tags') {
            if (data.value[0] == '') {
                data.value.splice(0, 1)
            }
            data.value = data.value?.join(',')
        }

        qValue[param].value = data.value
        this.setState({ queryValue: qValue }, () => {
            this.fetchFilterData()
        })

    }

    handleChangeNew2 = (data, param) => {
        const qValue = { ...this.state.queryValue }

        qValue[param].value = data
        this.setState({ queryValue: qValue })
        this.setState({ selectedValue: param })
        // this.fetchFilterData()
        // this.onChangeInputFilter(e, data)
    }

    handleChangeNew3 = e => {
        if (
            e.key === 'Enter' ||
            this.state.queryValue['phone_number'].value == ''
        ) {
            this.fetchFilterData()
        }
    }

    selectFilter = (e, data) => {
        const { query } = this.state
        this.setState(
            {
                ...this.state,
                query: data.value
            },
            () => this.fetchFilterData()
        )
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    handleAnimationChange = animation =>
        this.setState(prevState => ({ animation, visible: !prevState.visible }))

    //Used for to delete the selected LEAD
    onDeleteLeadHandler = () => {
        const { exportData } = this.state
        if (exportData?.length === 0) {
            CommonNotify('Select lead first.')
        } else {
            const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-leads`
            const postId = this.state.selectedRecordIds
            const postData = {
                ids: postId
            }

            axios
                .post(URL, postData)
                .then(res => {
                    this.setState({
                        exportData: [],
                        countItem: 0,
                        deleteModal: false,
                        selectedRecordIds: []
                    })
                    CommonNotify('Lead Deleted Successfully', 'success')
                    this.reloadLeads()
                })
                .catch(err => {
                    CommonNotify('Not able to Delete Lead')
                    if (err?.response?.status === 401) {
                        return
                    }
                    if (!err?.response) {
                    }
                })
        }
    }

    onPageChange = (e, a) => {
        this.setState(
            {
                ...this.state,
                pageNumber: a.activePage
            },
            () => {
                this.fetchData()
            }
        )
    }

    handleCancel = () => { }

    changeSegment = value => {
        this.setState({ segmentName: value })
    }

    changeList = value => {
        this.setState({ listName: value })
    }

    saveSegment = async () => {
        let { segmentName, segmentLeads } = this.state

        const apiToken = await localStorage.getItem('access_token')
        if (segmentName === '') {
            CommonNotify('Please Enter Segment Name')
        }
        if (!segmentLeads?.length) {
            CommonNotify('leads is empty')
        }
        const leads = segmentLeads
        let data = { name: segmentName, lead_ids: leads }

        this.setState({ isLoading: true })
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists`
        axios
            .post(url, data, {
                headers: {
                    Authorization: `Bearer ${apiToken}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                this.setState({ isLoading: false })
                this.closeSegment()
                this.fetchSegments()
                CommonNotify('Segment Saved', 'success')
            })
            .catch(err => {
                this.setState({ isLoading: false })
                CommonNotify('Segment Name Exists / not available')
                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                }
            })
    }

    saveList = async () => {
        let { listName, exportData } = this.state

        const apiToken = await localStorage.getItem('access_token')
        if (listName === '') {
            CommonNotify('Please Enter List Name')
        }

        var leads = []
        exportData.map(data => {
            leads.push(data.id.replace('#', ''))
        })

        let data = { name: listName, lead_ids: leads }

        this.setState({ isLoading: true })
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists`
        axios
            .post(url, data, {
                headers: {
                    Authorization: `Bearer ${apiToken}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                this.setState({ isLoading: false })
                this.closeList()
                this.fetchSegments()
                CommonNotify('Segment Saved', 'success')
            })
            .catch(err => {
                this.setState({ isLoading: false })
                CommonNotify('Segment Name Exists / not available')
                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                    //logout
                }
            })
    }

    closeSegment = () => {
        this.setState({ segmentModal: false })
    }

    openSegment = () => {
        this.setState({ segmentModal: true })
    }

    closeList = () => {
        this.setState({ listModal: false })
    }

    openList = () => {
        this.setState({ listModal: true })
    }

    fetchSegments = () => {
        this.setState({ fetchSegments: true })
        /* const apiToken = await localStorage.getItem('access_token')
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists`
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          })
          .then(res => {
            this.setState({isLoading: false})
            
            this.storeSegments(res.data.data)
          })
          .catch(err => {
            this.setState({isLoading: false})
            if (err?.response?.status === 401) {
             
              return
            }
            if (!err?.response) {
           
            }
          }) */
    }

    setSegmentsStates = res => {
        this.storeSegments(res.data.data)
    }

    storeSegments = data => {
        const list = data.map((segment, key, data) => {
            return {
                key: key,
                text: segment.name,
                value: key,
                id: segment.id
            }
        })


        //list.unshift({key:1, text: "Add New List", value: -1})
        //list.unshift({key:0, text: "All List", value: -2})

        this.setState({
            listSegments: list,
            segments: data,
            isLoading: false,
            fetchSegments: false
        })
    }

    loadSegment = data => {
        const { segments } = this.state

        if (data === -2) {
            this.setState({ selectedList: data })
            this.fetchData()
            return
        }
        if (data === -1) {
            this.onClickMarkAllCheckbox()
            this.setState({ selectedList: data })
            this.openList()
            return
        }
        const lead = segments[data].leads
        this.setFilterDropDownOption(lead)
        this.resetPage()
        this.storeTableData2(lead)
        this.setState({ selectedList: data })
    }

    deleteList = async id => {
        this.setState({ isLoading: true })
        const apiToken = await localStorage.getItem('access_token')
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/delete-list`
        axios
            .delete(url, {
                data: { list_id: id },
                headers: {
                    Authorization: 'Bearer ' + apiToken
                }
            })
            .then(res => {
                this.setState({ isLoading: false, settingModal: false })
                //console.info(res.data.data)
                this.fetchSegments()
            })
            .catch(err => {
                this.setState({ isLoading: false })
                if (err?.response?.status === 401) {
                    //logOut();
                    return
                }
                if (!err?.response) {
                    //logOut();
                }
            })
    }

    listUpdate = async id => {
        let { listName, exportData } = this.state

        const apiToken = await localStorage.getItem('access_token')
        if (!exportData?.length) {
            //CommonNotify('select lead first')
        }

        var leads = []
        exportData.map(data => {
            leads.push(data.id.replace('#', ''))
        })

        let data = { name: listName, lead_ids: leads }

        this.setState({ isLoading: true })
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists/${id}/leads`
        axios
            .post(url, data, {
                headers: {
                    Authorization: `Bearer ${apiToken}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                this.setState({ isLoading: false })
                this.closeList()
                this.fetchSegments()
                CommonNotify('Segment Saved', 'success')
            })
            .catch(err => {
                this.setState({ isLoading: false })
                CommonNotify('Segment Name Exists / not available')
                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                    //logout
                }
            })
    }

    render() {
        const {
            tableDataContent,
            tableData,
            isMarkOpen,

            isChecked,
            isMarkAllCheckbox,
            tableDataTotalItem,
            startItemCount,
            lastItemCount,
            pageNumber,
            open,
            data,
            activeIndex,
            animation,
            vertical,
            direction,
            directionMobile,
            visible,
            size
        } = this.state
        const title = {
            type: 'image',
            titleOne: leadLogo,
            titleTwo: 'Leads'
        }

        const tagOptions = [
            {
                key: 'ID',
                text: 'ID',
                value: 'id'
            },

            {
                key: 'phone_number',
                text: 'Phone Number',
                value: 'phone_number'
            },
            {
                key: 'Type',
                text: 'Type',
                value: 'type'
            },

            {
                key: 'Call Status',
                text: 'Call Status',
                value: 'final_status'
            },
            {
                key: 'status',
                text: 'status',
                value: 'status'
            },
            {
                key: 'Agent',
                text: 'Agent',
                value: 'agent'
            },
            {
                key: 'Score',
                text: 'Score',
                value: 'score'
            },
            {
                key: 'Owner',
                text: 'Owner',
                value: 'owner'
            }
        ]

        const conditionOptions = [
            {
                key: 'equals',
                text: 'equals',
                value: 'is'
            },
            {
                key: 'not equal to',
                text: 'not equal to',
                value: '!is'
            },
            {
                key: 'starts with',
                text: 'contains',
                value: 'begin'
            }
        ]

        const conditionOptions2 = [
            {
                key: 'equals',
                text: 'equals',
                value: 'is'
            },
            {
                key: 'not equal to',
                text: 'not equal to',
                value: '!is'
            }
        ]

        const filter = {
            type: ['Type', 'Existing', 'Meeting', 'Opportunity'],
            status: ['Civil Status', 'Online', 'Offline'],
            score: ['Score', 'All', 'Qualified', 'Bad Fit'],
            owner: ['Owner', 'All', 'Developer AIPXPERTS'],
            agent: ['Agent', 'All', 'Developer AIPXPI'],
            dateRange: ['Date', 'Today', 'Last 7 Days', 'Last 30 Days', 'Custom Date']
        }
        const type = [
            {
                key: 0,
                text: 'All Call Type',
                value: ''
            },
            {
                key: 1,
                text: 'SCHEDULE_CALL',
                value: 'SCHEDULE_CALL'
            },
            {
                key: 2,
                text: 'LEAVE_MESSAGE',
                value: 'LEAVE_MESSAGE'
            },
            {
                key: 3,
                text: 'LIVE_CALL',
                value: 'LIVE_CALL'
            },
            {
                key: 4,
                text: 'DIGITAL_CALL',
                value: 'DIGITAL_CALL'
            },
            {
                key: 5,
                text: 'UNSUPPORTED_COUNTRY',
                value: 'UNSUPPORTED_COUNTRY'
            },
            {
                key: 6,
                text: 'USER_BLACKLISTED',
                value: 'USER_BLACKLISTED'
            },
            {
                key: 7,
                text: 'FORWARDED_CALL',
                value: 'FORWARDED_CALL'
            }
        ]
        const isMobile = window.innerWidth < 992



        return (
            <>
                {this.state.id ? (
                    <RightPane
                        reloadTable={this.reloadLeads}
                        allTags={this.state.allTags}
                        setUpdatedPane={this.setUpdatedPane}
                        updatedTable={this.state.updatedTable}
                        leadStage={this.state.leadStage}
                        rightPane={this.state.rightPane}
                        handleRightClose={this.handleRightClose}
                        id={this.state.id}
                        leadData={this.state.leadData}
                        nextLeadId={this.state.nextLeadId}
                        prevLeadId={this.state.prevLeadId}
                        leadOwner={this.state.leadOwner}
                        leadScore={this.state.leadScore}
                        handleRightPaneOpen={this.handleRightPaneOpen}
                        handleLoading={this.handleLoading}
                    />
                ) : (
                    ''
                )}
            </>
        )
    }
}

const mapState = (state, ownProps) => ({
    widget: state.home.widget,

})

const mapDispatch = (dispatch) => ({
    getLeads: endpoints.getLeads.initiate,
    loadPendingList: (data) => dispatch({
        type: SET_FLAG,
        payload: data
    })
})


const connector = connect(mapState, mapDispatch)

export default withRouter(connector(RightPaneContainer))
