import React, { useState } from 'react'
import classnames from 'classnames'

const tabsTitle = [ 
  {
    id: 1,
    title: 'Configure Working hours',
    class: ''
  },
  {
    id: 2,
    title: ' Go live',
    class: ''
  }
]

const WizardBoxMember = props => {
  const { step } = props

  return (
    <div className="quicksetup-wizard">
      <div className="wizard">
        <h2 className="wizard-title">Quick Setup</h2>
        <div className="wizard-holder">
          {tabsTitle.map((item, index) => {
            return (
              <span
                className={
                  step >= index + 1
                    ? classnames(`wizard-item active`)
                    : 'wizard-item'
                }
                key={item.id}
              >
                {item.title}
              </span>
            )
          })}
        </div>
        
      </div>
    </div>
  )
}

export default WizardBoxMember
