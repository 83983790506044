import React, { useEffect } from 'react'
import {Button, SelectMenu} from 'evergreen-ui'

function EvergreenSingleSelect({options, onUpdateValues, defaultOptions, title}) {
  const [selected, setSelected] = React.useState(defaultOptions)
  const [name, setName]= React.useState('')

  useEffect(() => {
  var d = options.find(e => e.value === selected );
  if(d)
  {
    setName(d.label)
  }
  },[selected]);
        
  return (
    <SelectMenu
      title={title}
      options={options}
      selected={selected}
      onCloseComplete={() => {
         
         onUpdateValues(selected)
         }}
      onSelect={(item) => setSelected(item.value)}
    >
    <Button>{name || title}</Button>
    </SelectMenu>
  )
}

  export default EvergreenSingleSelect