import React, { useState, useEffect } from 'react'

import CommonSelect from '../../common/CommonSelect'

import { Grid, Dropdown, Button } from 'semantic-ui-react'
import { TextInput } from 'evergreen-ui'
import axios from 'axios'
import BackIcon from '../../assets/images/backPage.png'
import { Autocomplete, Chip, TextField } from '@mui/material'


const bussinessLimecall = [
  'Industry',
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel/Fashion',
  'Architecture/Planning',
  'Arts/Crafts',
  'Automotive',
  'Aviation/Aerospace',
  'Banking/Mortgage',
  'Biotechnology/Greentech',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies/Equipment',
  'Capital Markets/Hedge Fund/Private Equity',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software/Engineering',
  'Computer/Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense/Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment/Movie Production',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Fundraising',
  'Furniture',
  'Gambling/Casinos',
  'Glass/Ceramics/Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design/Web Design',
  'Health/Fitness',
  'Higher Education/Acadamia',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Individual/Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology/IT',
  'Insurance',
  'International Affairs',
  'International Trade/Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management/Hedge Fund/Private Equity',
  'Judiciary',
  'Law Enforcement',
  'Law Practice/Law Firms',
  'Legal Services',
  'Legislative Office',
  'Leisure/Travel',
  'Library',
  'Logistics/Procurement',
  'Luxury Goods/Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing/Advertising/Sales',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Mental Health Care',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Museums/Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers/Journalism',
  'Non-Profit/Volunteering',
  'Oil/Energy/Solar/Greentech',
  'Online Publishing',
  'Other Industry',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Relations/PR',
  'Public Safety',
  'Publishing Industry',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate/Mortgage',
  'Recreational Facilities/Services',
  'Religious Institutions',
  'Renewables/Environment',
  'Research Industry',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing/Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation/Localization',
  'Transportation',
  'Transport',
  'Utilities',
  'Venture Capital/VC',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine/Spirits',
  'Wireless',
  'Writing/Editing'
]

const ownerDropdown = [
  {
    key: 1,
    text: 'Lead',
    value: 'Lead'
  },
  {
    key: 2,
    text: 'Customer',
    value: 'Customer'
  },
  {
    key: 3,
    text: 'Partner',
    value: 'Partner'
  },
  {
    key: 4,
    text: 'Other',
    value: 'Other'
  }
]
const Tags = [
  {
    key: 1,
    text: 'New',
    value: 1
  },
  {
    key: 2,
    text: 'VIP',
    value: 2
  },
  {
    key: 3,
    text: 'Something',
    value: 3
  }
]
const scoreDropdown = [
  {
    key: 1,
    text: 'Cold',
    value: 'Cold'
  },
  {
    key: 2,
    text: 'Warm',
    value: 'Warm'
  },
  {
    key: 3,
    text: 'Hot',
    value: 'Hot'
  }
]

const DetailsPanel = ({ getData }) => {
  const data = getData?.getData

  useEffect(() => {
    const foodBar = ownerDropdown.find(item =>
      item.key === data.source ? data.source : 'Other'
    )
    setContactStatus(foodBar ? foodBar.value : 'Other')
  }, [data, boolData])
  const [segmentData, setSegmentData] = useState([])

  const [contactStatus, setContactStatus] = useState('Others')
  const [boolData, setBoolData] = useState({
    source: false,
    tags: false,
    status: false,
    score: false,
    company: false,
    industry: false,
    company_size: false,
    role: false
  })
const [list, setList] = useState('')


  const handleInput = e => {
    setBoolData({
      ...boolData,
      [e]: true
    })
    
  }
  useEffect(() => {
    fetchSegments()
  }, [])

  const handleLists = (value) => { 
    setList(value)
   }

   const submitLists = (value) => { 
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list`
    axios
      .post
      (url,{name:value?.name,contactIds:[getData?.getData?.id]})
      .then(res => {
      })
      .catch(error => {})
   }

  const handleTags = (value) => { 
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${getData?.getData?.id}/tags`
    axios
      .post(url,{tags:value})
      .then(res => {
      })
      .catch(error => {})
   }

   const fetchSegments = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/lists`
    axios
      .get(url)
      .then(res => {
        setSegmentData(res?.data?.data)
      })
      .catch(error => {})
  }

  const associateApi = (e, dat) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${data.id}/info`
    const formData = new FormData()
    if (dat?.placeholder == 'tags') {
      formData.append('tags', dat?.value)
    }
    if (dat?.placeholder == 'status') {
      formData.append('status', dat?.value)
    }
    if (dat?.placeholder == 'score') {
      formData.append('score', dat?.value)
    }

    axios
      .post(url, formData)
      .then(res => {})
      .catch(error => {})
  }
  const leadChange = (e, dat) => {
    associateApi(e, dat)
  }

  const setValue = (name, value) => {
    setBoolData({
      ...boolData,
      [name]: false
    })
  }

  return (
    <div className="contact-card">
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Source:</p>
            </Grid.Column>
            <Grid.Column onClick={() => handleInput('source')} width={10}>
              {boolData.source ? (
                <TextInput style={{ width: '100%' }} value={data?.source} />
              ) : (
                <div>
                  {' '}
                  <p>{data?.source}</p>
                </div>
              )}
            </Grid.Column>

            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Type:</p>
            </Grid.Column>
            <Grid.Column width={10}>

                <Dropdown
                  options={ownerDropdown}
                  placeholder="Select"
                  defaultValue={data?.status}
                  onChange={(e, dat) => leadChange(e, dat)}
                />
      
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Score:</p>
            </Grid.Column>
            <Grid.Column width={10}>

                <Dropdown
                  options={scoreDropdown}
                placeholder="Select"
                  onChange={(e, dat) => leadChange(e, dat)}
                  defaultValue={data.score ? data.score : null}
                />

            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Tags:</p>
            </Grid.Column>
            <Grid.Column width={10}>
            <Autocomplete
        multiple
        id="tags-filleds"
        options={data?.tags || []}
        defaultValue={data?.tags || []}
        freeSolo
        renderTags={(value, getTagProps) =>
           value?.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        onChange={(value,data)=> handleTags(data)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Add tags"
          />
        )}
      />
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Lists:</p>
            </Grid.Column>
            <Grid.Column width={10}>
       
            <Autocomplete
  disablePortal
  onChange={(e,value)=>submitLists(value)}
  id="combo-box-demo"
  options={[...segmentData?.map((obj)=>({...obj,label:obj?.name ||obj?.organization_name}))] }
  renderInput={(params) => <TextField {...params} label={ "Add Lists"}/>}
/>
     
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Company:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p style={{ fontSize: 14, marginTop: 7 }}>
                {data?.organization_name}
              </p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Industry:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              {' '}
              <p style={{ fontSize: 14, marginTop: 7 }}>{data?.industry}</p>
            </Grid.Column>{' '}
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Company Size:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p style={{ fontSize: 14, marginTop: 7 }}>{data?.employees}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Role:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p style={{ fontSize: 14, marginTop: 7 }}>{data?.role}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">City:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p style={{ fontSize: 14, marginTop: 7 }}>{data?.city}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Created on:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p style={{ fontSize: 14, marginTop: 7 }}>{data?.created_at}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">First Seen:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p style={{ fontSize: 14, marginTop: 7 }}>{data?.first_seen}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Last Seen:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p style={{ fontSize: 14, marginTop: 7 }}>{data?.last_seen}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Last Interaction:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p style={{ fontSize: 14, marginTop: 7 }}>
                {data?.lastInteraction}
              </p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Last Viewed URL:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p style={{ fontSize: 14, marginTop: 7 }}>
                {data?.last_viewed_url}
              </p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Source:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p style={{ fontSize: 14, marginTop: 7 }}>
                {data?.source}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

export default DetailsPanel
