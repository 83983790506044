import React from 'react'

const ToggleSwitch = ({name, checked, onChange}) => {


    const onChange2 = data => {
        onChange(data, data)
    }

    return (
        <div className="toggle-switch" onClick={e => onChange2(!checked)} data-tip="Set your availability for recieving calls" >
            <input
                type="checkbox"
                className="toggle-switch-checkbox"
                checked={checked}
                
             />
            <label className="toggle-switch-label" htmlFor={name}>
                <span className="toggle-switch-inner" data-yes="Online" data-no="Offline"/>
                <span className="toggle-switch-switch" />
            </label>
        </div>
    )
}

export default ToggleSwitch

