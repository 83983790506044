import React, { useEffect, useState } from 'react'
import { useGetLeadsQuery } from '../../features/api/apiSlice'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Title from '../../common/Title'
import leadLogo from '../../assets/images/lead-logo.svg'
import { CommonNotify } from '../../common/CommonNotify'
import LeadDetails from './components/LeadDetails'
import { getLeadOwner } from '../../config/leadAPI'
import axios from 'axios'
import {
  Menu,
  Tab,
  Icon,
  Dropdown,
  Input,
  TextArea,
  Sidebar,
  Segment,
  Popup,
  Accordion,
  Progress
} from 'semantic-ui-react'
import moment from 'moment'
import { AssignmentIndOutlined } from '@mui/icons-material'
import ContactDetails from './components/ContactDetail'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

const Inbound = () => {
  const [params, setParams] = useState({
    limit: 15,
    page: 1,
    sort_field: 'id',
    sort_value: 'DESC',
    sort: '-id',
    'filter[type][in]':
      'LIVE_CALL,DIGITAL_CALL,UNSUPPORTED_COUNTRY,USER_BLACKLISTED',
    'filter[direction][is]': 'Inbound',
    'filter[status][is]': 1
  })
  const [leadId, setLeadId] = useState(null)
  const [leads, setLeads] = useState([])
  const [dataLoaded, setDataLoaded] = useState([])
  const [leadData, setLeadData] = useState(null)
  const [membersList, setMemberList] = useState([])
  const [membersTeam, setMembersTeam] = useState([])
  const [members, setMembers] = useState([])
  const [leadOwner, setLeadOwner] = useState([
    {
      key: 'unassign owner',
      text: 'Unassign owner',
      value: null
    }
  ])
  const res = useGetLeadsQuery(params)
  const title = {
    type: 'image',
    titleOne: leadLogo,
    titleTwo: 'Inbound'
  }
  useEffect(() => {
    if (res.isSuccess) {
      setLeads(res?.data?.data?.leads?.data)
    }
  }, [res])

  useEffect(() => {
    fetchTeams()
    getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin

        setLeadOwner(prev => [
          ...prev,
          {
            key: admin.id,
            text: admin.first_name + ' ' + admin.last_name,
            value: admin.id
          }
        ])

        await res.data.data.members.map(team => {
          setLeadOwner(prev => [
            ...prev,
            {
              key: team.id,
              text: team.first_name + ' ' + team.last_name,
              value: team.id
            }
          ])
        })
      })
      .catch(err => {})
  }, [])

  useEffect(() => {
    processList()
  }, [members, membersTeam])

  const fetchTeams = async () => {
    const data = []
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/teams?limit=20000&offset=0`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          setMembersTeam(res.data.data)
          fetchMembers()
        }
      })
      .catch(err => {})
  }

  const processList = () => {
    const allList = [...members, ...membersTeam]
    const processList = allList.map(item => {
      return {
        key: item.first_name?.length ? item.id : item.id + '-team',
        value: item.id,
        text: item.first_name?.length ? item.first_name : item.name + ' team'
      }
    })
    setMemberList(processList)
  }

  const fetchMembers = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          const dt1 = [...res.data.data.members]
          dt1.push(res.data.data.admin)
          setMembers(dt1)
        }
      })
      .catch(error => {
        CommonNotify('cant fetch Members')
      })
  }

  const handleLeadClick = async id => {
    setDataLoaded(false)
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/lead/${id}`
    axios
      .get(url)
      .then(res => {
        setLeadData(res?.data)
        setDataLoaded(true)
      })
      .catch(err => {
        setDataLoaded(false)
        if (err.response?.data?.errors?.length) {
          CommonNotify('Id is not available / server error')
          return
        }
        if (err?.response?.status === 401) {
          CommonNotify('Id is not available / server error')
          return
        }
        if (!err?.response) {
          CommonNotify('Id is not available / server error')
          return
        }
      })
  }

  const isToday = date => {
    const today = new Date()

    if (today.toDateString() === date.toDateString()) {
      return true
    }

    return false
  }

  const messages = [
    {
      menuItem: 'All',
      render: () => (
        <div className="hover-item">
          <Menu fluid vertical tabular>
            {leads?.map((lead, i) => {
              return (
                <Menu.Item
                  active={leadId === lead.id}
                  onClick={() => setLeadId(lead.id)}
                  key={i}
                  id={i}
                >
                  <div className="message-holder">
                    <div className="message-content">
                      <div className="message-numdate" style={{ fontSize: 14 }}>
                        <p style={{ fontSize: 14 }}>{lead?.customer_name}</p>
                        <p className="message-date">
                          {isToday(new Date(lead?.created_at))
                            ? moment(lead.created_at).format('LT')
                            : moment(lead.created_at).format('MMM DD')}
                        </p>
                      </div>
                      <Box display="flex" justifyContent="space-between">
                        <span>{lead.phone_number}</span>
                      </Box>
                    </div>
                  </div>
                </Menu.Item>
              )
            })}
          </Menu>
        </div>
      )
    }
  ]

  return (
    <div className="conversation-container">
      <Title data={title} />
      <div className="widget-banner-container">
        <div className="conversation-content-holder content-left">
          <div className="conversation-content">
            <div className="conversation-content-holder ">
              <div className="content-header">
                <div className="justify-menu">
                  <Tab
                    menu={{ secondary: true, pointing: true }}
                    panes={messages}
                    onTabChange={() => {
                      setLeadId(null)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Sidebar.Pushable
            as={Segment}
            className="main-segments"
            style={{ width: '75%' }}
          >
            <div className="conversation-content-messages-info-holder">
              <Sidebar
                animation="overlay"
                direction="center"
                visible={true}
                className="sidebar-contact"
              >
                {leadId && <ContactDetails id={leadId} />}
              </Sidebar>
              <Sidebar.Pusher>
                {leadId ? (
                  <LeadDetails id={leadId} />
                ) : (
                  <Segment>
                    <div
                      style={{
                        width: '50%',
                        height: '100%',
                        marginTop: '20%',
                        textAlign: 'center'
                      }}
                    >
                      {' '}
                      <AssignmentIndOutlined /> No Lead selected{' '}
                    </div>
                  </Segment>
                )}
              </Sidebar.Pusher>
            </div>
          </Sidebar.Pushable>
        </div>
      </div>
    </div>
  )
}

export default Inbound
