import React, { useEffect, useState } from 'react'
import { Input, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import CommonButtons from '../../common/CommonButtons'
import setAvilablity from '../../assets/images/set-availability.png'
import { getCurrentTimeZoneName } from '../../helpers/commonFunction'
import { getProfile } from '../../actions/settings'
import { CommonNotify } from '../../common/CommonNotify'
import Axios from 'axios'
import TimezonePicker from 'react-timezone'

const WelcomeNew = props => {
  const [loading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState({
    firstName: '',
    lastName: '',
    personalLink: '',
    timezone: getCurrentTimeZoneName(),
    domain: '',
    updateTimeZone: false,
    updateURL: false,
    UpdateNames: false
  })

  useEffect(() => {
    setLoading(true)
    if (props?.profile) {
      setFormValue({
        ...formValue,
        firstName: props?.profile?.data.first_name,
        lastName: props?.profile?.data.last_name,
        timezone: props?.profile?.data.time_zone || getCurrentTimeZoneName(),
        personalLink: props?.profile?.data?.personal_link?.split('me/').pop()
      })
      setLoading(false)
    } else {
      props.getProfile && props.getProfile()
    }
  }, [props?.profile])

  const increaseStep = async () => {
    props.next()
  }

  const handleSubmit = async e => {
    if (formValue?.UpdateNames || formValue?.updateTimeZone) {
      await saveName(e)
    }
    if (formValue?.updateURL) {
      await onUpdate(e)
    }
    if (
      !formValue?.UpdateNames &&
      !formValue?.updateTimeZone &&
      !formValue?.updateURL
    ) {
      increaseStep()
    }
  }

  const saveName = () => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/update-profile-api`
    const form = new FormData()
    const full_name = formValue?.firstName + ' ' + formValue?.lastName
    if (!formValue?.firstName) {
      CommonNotify('First name is required.')
      return
    }
    if (!formValue?.lastName) {
      CommonNotify('Last name is required.')
      return
    }
    form.append('full_name', full_name)
    form.append('time_zone', formValue?.timezone || getCurrentTimeZoneName())
    Axios.post(URL, form)
      .then(res => {
        setLoading(false)
        CommonNotify('Details added successfully')
        setFormValue({
          ...formValue,
          updateURL: false
        })
        if (!formValue?.updateURL) {
          props.getProfile && props.getProfile()
          increaseStep()
        }
      })
      .catch(error => {
        CommonNotify(error?.response?.data?.errors?.[0])
        setLoading(false)
      })
  }

  const onUpdate = event => {
    event.preventDefault()
    if (formValue?.personalLink.length > 15) {
      return CommonNotify(
        'The personal URL may not be greater than 15 characters.'
      )
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/personal-link`
    const data = {
      code: formValue?.personalLink
    }
    setLoading(true)
    Axios.post(url, data)
      .then(res => {
        setLoading(false)
        CommonNotify('Personal Link added successfully')
        setFormValue({
          ...formValue,
          updateTimeZone: false,
          UpdateNames: false
        })
        props.getProfile && props.getProfile()
        increaseStep()
      })
      .catch(error => {
        CommonNotify(error?.response?.data?.errors?.[0])
        setLoading(false)
      })
  }

  const selectTimeZone = e => {
    setFormValue({
      ...formValue,
      timezone: e || getCurrentTimeZoneName(),
      updateTimeZone: true
    })
  }

  return (
    <>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      <div>
        <div className="welcome-box availability-box">
          <div className="box-heading row m-0">
            <div className="col-lg-8 px-0">
              <h3>Welcome to LimePhone!</h3>
              <p>
                We take the work out of connecting with others so you can
                accomplish more.
              </p>
            </div>
            <div className="col-lg-4 px-0">
              <img src={setAvilablity} />
            </div>
          </div>
          <div className="box-content">
            <h4>Create your LimePhone URL</h4>
            <p>
              Choose a URL that describes you or your business in a concise wey.
              Make it short and easy to remember so you can share links with
              ease.
            </p>
            <div className="d-flex mb-4">
              <Input
                placeholder="First Name"
                value={formValue?.firstName}
                onChange={e =>
                  setFormValue({
                    ...formValue,
                    firstName: e?.target?.value,
                    UpdateNames: true
                  })
                }
                className="w-50 mr-2"
              ></Input>
              <Input
                placeholder="Last Name"
                value={formValue?.lastName}
                className="w-50"
                onChange={e =>
                  setFormValue({
                    ...formValue,
                    lastName: e?.target?.value,
                    UpdateNames: true
                  })
                }
              ></Input>
            </div>
            <div className="d-flex mb-4">
              <Input
                placeholder="LimePhone.com/"
                value={
                  process.env.REACT_APP_BASE_APP_URL ===
                  'https://qa.limecall.com'
                    ? 'https://qa.limecall.me/'
                    : 'https://limecall.me/'
                }
                className="mr-2 bg-grey-color"
              ></Input>
              <Input
                placeholder="Your Name"
                value={formValue?.personalLink}
                className="w-75"
                onChange={e =>
                  setFormValue({
                    ...formValue,
                    personalLink: e?.target?.value,
                    updateURL: true
                  })
                }
              ></Input>
            </div>
            <h4>Time Zone</h4>

            <TimezonePicker
              value={formValue?.timezone || getCurrentTimeZoneName()}
              style={{ width: '275px' }}
              className="time-zone-dropdown"
              onChange={selectTimeZone}
              inputProps={{
                placeholder: 'Select Timezone...',
                name: 'timezone'
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="quicksetup-footer footer-button mt-auto"
        style={{ justifyContent: 'space-between' }}
      >
        {props?.btnBack === false ? (
          <span></span>
        ) : (
          <CommonButtons
            content="Back"
            btnClass="btn-modal-style btn-back"
            onClick={props.prev}
          />
        )}
        <CommonButtons
          type="button"
          content="Continue"
          background="blue"
          onClick={handleSubmit}
          loading={loading}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  profile: state.settings.profile
})

export default connect(mapStateToProps, { getProfile })(WelcomeNew)
