import React from 'react'
import { Button, Header, Image, Label, Modal } from 'semantic-ui-react'
import CommonButtons from '../../../common/CommonButtons'
import verificationLogo from '../../../assets/images/verificationLogo.png'


const VerificationStatus = ({
  open,
  isModalOpen,
  isModalClose,
  onOtpVerification,
  onConfirmCode
}) => {
  return (
    <div>
      <Modal onClose={() => isModalOpen()} open={open} size="mini">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px 18px'
          }}
        >
         <img style={{width: 100}} src={require('../../../assets/images/verificationLogo.png')}></img>
         <h4>Verification done</h4>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '11px 0'
          }}
        >
          <CommonButtons
            content="Next"
            background="blue"
            btnClass="btn-verify"
            onClick={() => onOtpVerification()}
            loading={onConfirmCode}
          />
         
        </div>
      </Modal>
    </div>
  )
}
export default VerificationStatus
