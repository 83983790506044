import React, { useState, useEffect } from 'react'

import { Link, Redirect } from 'react-router-dom'
import { Modal } from 'semantic-ui-react'


import avatar from '../assets/images/Dashboard-27.png'

import arrow from '../assets/images/Dashboard-33.png'
import notification from '../assets/images/icon-notification.png'
import gift from '../assets/images/icon-wedding-gift.png'

import axios from 'axios'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Avatar } from '@mui/material'
import helpinActive from '../assets/images/Asset 19.svg'

import knowledgeInactive from '../assets/images/Dashboard-15.png'

import writeInactive from '../assets/images/Dashboard-16.png'


import callbackInactive from '../assets/images/Dashboard-17.png'

import { getSubscription } from '../config/subscription'
import { useSelector } from 'react-redux'

export const logOut = async () => {
  window.location.href = '/logout'
}

const ProfileModal = props => {

  const [open, setOpen] = useState(false)
  const [image, setImage] = useState(avatar)
  const [nextBill, setNextBill] = useState('')
  const [tokenNA, setTokenNA] = useState(false)

  const first_name = localStorage.getItem('first_name')
  const last_name = localStorage.getItem('last_name')
  const plan_name = localStorage.getItem('plan_name')
  const role = localStorage.getItem('role')
  const apiToken = localStorage.getItem('access_token')

  const [help, setHelp] = useState(helpinActive)
  const [knowledge, setKnowledge] = useState(knowledgeInactive)
  const [write, setWrite] = useState(writeInactive)
  const [callback, setCallBack] = useState(callbackInactive)
  const [personalLink, setPersonalLink] = useState('')
  const personalLinkData = useSelector((state) => state.home.personalLink)

  useEffect(() => {
    if (personalLinkData) {
      setPersonalLink(personalLinkData)
    }
  }, [personalLinkData])

  useEffect(() => {
    if (props.name === 'Profile') setImage(avatar)
    if (props.name === 'Gift') setImage(gift)
    if (props.name === 'Notification') setImage(notification)
  }, [image])

  useEffect(() => {
    if (apiToken == null) {
      return <Redirect to="/logout" />
    }
    const subs = localStorage.getItem("subscription_nextbill")

    if (subs?.length) {
      setNextBill(subs)
      return
    }


    getSubscription()
      .then(res => {
        const obj = {}
        const d = res.data.data

        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]

        obj.ends = d.current_term_end
        if (d.status == 'in_trial') {
          obj.ends = d.trial_end
        }

        let dt = obj.ends.split(' ')
        dt = dt[0]

        let dts = dt.split('-')
        dts[1] = parseInt(dts[1]) - 1

        dt = dts[2] + '  ' + months[dts[1]] + ' ' + dts[0]
        localStorage.setItem("subscription_nextbill", dt)
        setNextBill(dt)
      })
      .catch(err => console.error())
  }, [])

  const close = () => setOpen(false)

  const onCloseModal = () => {
    const profile = document.querySelector('.hours-call-wrapper')
    if (profile) {
      profile.parentNode.parentNode.parentNode.click()
    }
  }

  const goingToAccount = (open = "") => {
    setOpen(!open)
    if (open != "" && typeof open === "string") {
      window.location.assign(open)
    }
  }

  const handleModalOpen = (openModal = true) => {
    onCloseModal()
    setOpen(openModal ? openModal : !open)
  }

  const profile = localStorage.getItem('profile_pic')

  return (
    <>
      <div className="mini-profile">
        <style>
          {open && (`.ui.dimmer {
        background-color: transparent;
      }`)}
        </style>
        <Modal
          className="mini-profile nav_mini_profile"
          size="mini"
          open={open}
          onClose={close}
          trigger={
            <div className='d-flex'>

              {/* <img
           
              src={profile ? profile : image}
             
              alt="img"
            /> */}
              <Avatar onMouseOver={() => handleModalOpen(true)} sx={{ bgcolor: '#1976d2', width: '25px', height: '25px' }} >{first_name?.[0]?.toUpperCase()}{last_name?.[0]?.toUpperCase()}</Avatar>
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </div>
          }
        >
          <Modal.Content>
            <div className="profile-wrapper">

              <div className="profile-content-wrapper">
                <div className="profile-avatar-holder">
                  <img
                    style={{ width: 60, height: 60, objectFit: 'contain' }}
                    src={profile ? profile : avatar}
                    alt="avatar"
                  />
                </div>{' '}
                <div className="profile-content-holder">
                  <p className="profile-name"> {first_name} </p>{' '}
                  <p className="profile-email" onClick={() => goingToAccount('/settings/users_&_teams/users')} > {role ? role : 'Admin'} </p>{' '}
                  <p className="edit-profile">
                    <div>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={{ pathname: `${personalLink}` }}
                        onClick={goingToAccount}
                      >
                        My Booking Page
                      </Link>
                    </div>
                    <div>
                      <Link onClick={() => goingToAccount('/settings')}>
                        {' '}
                        Edit Profile{' '}
                      </Link>
                    </div>
                  </p>
                </div>{' '}
              </div>{' '}
              <div className="profile-plan-wrapper">
                <p className="plan-avail"> Starter Plan </p>{' '}
                {plan_name === 'PRO' || plan_name === 'ADVANCED' ? (
                  <p className="plan-expiration">
                    {' '}
                    Next billing at {nextBill}{' '}
                  </p>
                ) : null}
                <p className="manage-profile">
                  {' '}
                  <span style={{ display: 'block' }}>
                    <Link onClick={() => goingToAccount('/settings')}>
                      {' '}
                      Manage Account{' '}
                    </Link>
                  </span>
                  <span style={{ display: 'block' }}>
                    <Link
                      onClick={() => goingToAccount('/settings/personal/working_hours')}
                    >
                      {' '}
                      Manage Working Hours{' '}
                    </Link>
                  </span>
                  <span style={{ display: 'block' }}>
                    <Link
                      onClick={() => goingToAccount('/settings/personal/call_forwarding')}
                    >
                      {' '}
                      Call Forwarding{' '}
                    </Link>
                  </span>
                  <span style={{ display: 'block' }}>
                    <Link
                      onClick={() => goingToAccount('/settings/personal/notifications')}
                    >
                      {' '}
                      Notifications{' '}
                    </Link>
                      
                  </span>
                  <span style={{ display: 'block' }}>
                  <Link
                   to={{ pathname: `https://play.google.com/store/apps/details?id=com.limecall&hl=en_GB&gl=US` }}
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.limecall&hl=en_GB&gl=US')}
                    >
                      {' '}
                      Download Mobile App{' '}
                    </Link>
                  </span>
                </p>
              </div>{' '}
              <div className="sign-out-wrapper">
                <img src={arrow} alt="arrow" />
                <div className="sign-out-text-holder">
                  <p className="sign-out-text">
                    {' '}
                    <Link onClick={logOut}>Log Out</Link>
                  </p>
                </div>{' '}
              </div>{' '}
            </div>{' '}
          </Modal.Content>{' '}
        </Modal>{' '}
      </div>
    </>
  )
}

export default ProfileModal
