import React, { useState, useEffect } from 'react'
import {Button, SelectMenu, Pane,Dialog} from 'evergreen-ui'
import { CommonNotify } from '../../common/CommonNotify'
import template from '../../template.csv'
import axios from 'axios'

function EverGreenUploadCsv({visible,closeUploadModal}) {

    const [selectedFile, setSelectedFile] =  React.useState('')



  const handleInputChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const submit = async () => {
    if(selectedFile === '' || selectedFile === null)
    {
      CommonNotify('Please select a File First')
      return
    }

      const data = new FormData() 
      data.append('have_header',0)
     
      data.append('file_cols',"name,email,phone_number,country,city")
      data.append('file', selectedFile)
      console.warn(selectedFile);
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/upload-contact-file`

      axios
      .post(url, data)
      .then(res => {
        CommonNotify('Contacts Imported successfully.','success')
        closeUploadModal()
      })
      .catch(error => {
        CommonNotify('Some error occured.')
        closeUploadModal()
      })
  }


      return (
        <Pane>
          <Dialog
            isShown={visible}
            title="Select a file"
            onCloseComplete={() => closeUploadModal()}
            onConfirm={()=> submit()}
            confirmLabel="Upload">
                <div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <input type="file" className="form-control" name="upload_file" onChange={handleInputChange} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                </div>
                            </div>
                            <a href={template} download="contact_template.csv" style={{fontSize: 12, color: 'blue'}}>Download template to import CSV</a>
                </div>
          </Dialog>
        </Pane>
      )

  }

  export default EverGreenUploadCsv
