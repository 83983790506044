import React from 'react'
import { Button, Dropdown, Input, Modal, TextArea, Image } from 'semantic-ui-react'
import moment from 'moment'

import ManualActivity from './ManualActivity';
import AutoActivity from './AutoActivity'


const NoteTab = ({ activity, color, FetchLead, onActivityDelete }) => {


  const diffDates = dt => {
    // const date1 = new Date(dt).getTime() / 1000
    // const date2 = Date.now() / 1000;
    // const diffTime = date1 - date2;

    dt = moment(dt)
    return dt.format("MMMM Do, YYYY HH:mm")
   
     //return  Math.ceil(diffTime / ( 60 * 60 * 24)); 
  }

  const clickLeadUpdate = (act) => {
    const payload = {
      title: act.title,
      due_date: act.due_date,
      type: act.type,
      summary: act.summary,
      actId: act.id
    }
    FetchLead(payload)
}

  return (
    <div className="activities-wrapper" style={{ paddingTop: "60px" }}>
    { activity?.map( act => {
        return (
          <div className="activities-main">
              { act.auto ? (<AutoActivity act={act} color={color} onActivityDelete={onActivityDelete} clickLeadUpdate={clickLeadUpdate} />)  : (<ManualActivity act={act} color={color} onActivityDelete={onActivityDelete} clickLeadUpdate={clickLeadUpdate} />) }
          </div>   
        )
      } ) }
     
     
      {/* <AddActivity/> */}
  </div>
  )
}

export default NoteTab