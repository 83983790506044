import React, { useState, useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
import axios from 'axios'
import { CommonNotify } from '../common/CommonNotify'
import close from '../assets/images/close.png'

const apiToken = localStorage.getItem('access_token')

const SlackSetting = props => {
  const items = [
    { text: 'Call failed', checked: false, name: 'call_failed' },
    { text: 'New message', checked: false, name: 'new_message' },
    { text: 'Widget closed', checked: false, name: 'widget_closed' },
    { text: 'Widget opened', checked: false, name: 'widget_opened' },
    { text: 'Button clicked', checked: false, name: 'button_clicked' },
    { text: 'Call completed', checked: false, name: 'call_completed' },
    { text: 'Call in progress', checked: false, name: 'call_in_progress' },
    { text: 'Live call created', checked: false, name: 'live_call_created' },
    { text: 'New scheduled call', checked: false, name: 'new_scheduled_call' },
    {
      text: 'Digital call created',
      checked: false,
      name: 'digital_call_created'
    }
  ]
  const [checkiteam, setCheckiteam] = useState(items)
  const [savedChannle, setSavedChannle] = useState({})
  const [channle, setChannle] = useState(null)
  const [Id, setId] = useState()
  const [channelIds, setChannelIds] = useState('')
  const [resultData, setResultData] = useState([])
  const [checkboxData, setCheckboxData] = useState()

  useEffect(() => {
    fetchSlackIntegrations()
  }, [])

  const onUpdateHandler = () => {
    const channels = [channelIds]

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/save-channels`
    axios
      .post(url, { channel: channels })
      .then(res => {
        props.closeSlackModal()
        CommonNotify('Slack Channel updated successfully', 'success')
      })
      .catch(error => {})
  }

  const onRemoveHandler = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/save-channels`
    axios
      .post(url, { channel: [] })
      .then(res => {
        props.closeSlackModal()
        CommonNotify('Slack Channel Removed successfully', 'success')
      })
      .catch(error => {})
  }

  const fetchSlackIntegrations = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/get-channels`

    axios
      .post(url, {})
      .then(res => {
        if (res.data) {
          setChannelIds(res.data.saved[0])
          setResultData(res.data.result)
        }
      })
      .catch(er => {})
  }

  const onCheckboxClickHandler = async ({ value }) => {
    setChannelIds(value)
    return
  }

  const isChecked = val => {
    return channelIds.includes(val)
  }

  const onRelaunchHandler = val => {
    window.open(
      `https://slack.com/oauth/authorize?scope=channels:history,channels:read,channels:write,groups:history,groups:write,reactions:read,reactions:write,groups:read,chat:write:bot&client_id=${process.env.REACT_APP_SLACK_ID}&redirect_uri=${process.env.REACT_APP_ROOT_URL}/redirect-slack`,
      '_blank'
    )
  }

  return (
    <>
      <div
        style={{
          overflowY: 'scroll',
          minHeight: '600px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            borderBottom: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: 'grey',
            padding: 15,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h1
            style={{
              fontSize: 23,
              fontWeight: 'bold',
              margin: '0 auto',
              textAlign: 'center',
              borderBottomWidth: 1,
              borderBottomColor: 'gray'
            }}
          >
            Manage Slack Settings
          </h1>
          <div className="close-icon" onClick={() => props.closeSlackModal()}>
            <img src={close} alt="close" />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            flex: 1,
            padding: 15,
            position: 'relative',
            width: '100%',
            marginTop: 10,
            marginBottom: 20
          }}
        >
          <h1
            style={{
              fontSize: 18,
              fontWeight: 'normal',
              marginBottom: 20,
              textAlign: 'center'
            }}
          >
            Select your slack channel to receive notifications of inbound leads
            and activities
          </h1>
          <table
            style={{
              width: 'max-content',
              left: 0,
              right: 0,
              display: 'block',
              marginRight: 'auto',
              marginLeft: 'auto'
            }}
          >
            <tr>
              <td>
                <Dropdown
                  style={{ minWidth: '180px' }}
                  placeholder={'Select slack channel'}
                  onChange={(e, result) => onCheckboxClickHandler(result)}
                  fluid
                  search
                  selection
                  name={'slack-channel'}
                  value={channelIds}
                  options={resultData.map((option, index) => ({
                    key: option[0],
                    text: option[1],
                    value: option[0]
                  }))}
                />
              </td>
              <td>
                <button
                  style={{ padding: 7, width: '100%' }}
                  type="submit"
                  className="btn btn-primary"
                  onClick={onUpdateHandler}
                >
                  Update
                </button>
              </td>

              <td>
                <button
                  style={{ padding: 7, width: '100%' }}
                  type="submit"
                  className="btn btn-primary"
                  onClick={onRemoveHandler}
                >
                  Deselect Active Channel
                </button>
              </td>
              <td>
                <button
                  style={{ padding: 7, width: '100%' }}
                  type="submit"
                  className="btn btn-primary"
                  onClick={onRelaunchHandler}
                >
                  Relaunch
                </button>
              </td>
            </tr>
          </table>
          <div
            style={{ marginTop: 15, marginLeft: '50%', width: '100px' }}
            className="update-button"
          ></div>
        </div>
      </div>
    </>
  )
}
export default SlackSetting
