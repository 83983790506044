import React, { useState, useEffect } from 'react'
import { Tab, Checkbox, Select } from 'semantic-ui-react'
import { Dimmer, Loader } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import axios from 'axios'

import Title from '../common/Title'
import CreateMeetingType from '../components/meetings/createMeetingType'
import apiConfig from '../config/apiconfig.json'
import MeetingModalSide from '../components/meetings/detailSideModal'
import ScheduleModalSide from '../components/meetings/scheduleSideModal'
import DetailMeeting from '../components/meetings/detailsMeeting'
import filterIcon from '../assets/images/filter.png'
import circlePlus from '../assets/images/plusicon-set.png'
import MeetingTypeTeam from '../components/meetings/meetingTypeTeam'
import MeetingSideModal from '../components/meetings/MeetingsSideModal'
import { Box } from '@mui/system'
import CardMetting from './cardMeeting'
import EditMeetingPage from './EditMeetingPage'

const title = {
  titleTwo: 'Meetings'
}

const countryOptions = [
  { key: 'af', value: 'af', text: 'Afghanistan' },
  { key: 'ax', value: 'ax', text: 'Aland Islands' },
  { key: 'al', value: 'al', text: 'Albania' },
  { key: 'dz', value: 'dz', text: 'Algeria' },
  { key: 'as', value: 'as', text: 'American Samoa' },
  { key: 'ad', value: 'ad', text: 'Andorra' },
  { key: 'ao', value: 'ao', text: 'Angola' },
  { key: 'ai', value: 'ai', text: 'Anguilla' },
  { key: 'ag', value: 'ag', text: 'Antigua' },
  { key: 'ar', value: 'ar', text: 'Argentina' },
  { key: 'am', value: 'am', text: 'Armenia' },
  { key: 'aw', value: 'aw', text: 'Aruba' },
  { key: 'au', value: 'au', text: 'Australia' },
  { key: 'at', value: 'at', text: 'Austria' },
  { key: 'az', value: 'az', text: 'Azerbaijan' },
  { key: 'bs', value: 'bs', text: 'Bahamas' },
  { key: 'bh', value: 'bh', text: 'Bahrain' },
  { key: 'bd', value: 'bd', text: 'Bangladesh' },
  { key: 'bb', value: 'bb', text: 'Barbados' },
  { key: 'by', value: 'by', text: 'Belarus' },
  { key: 'be', value: 'be', text: 'Belgium' },
  { key: 'bz', value: 'bz', text: 'Belize' },
  { key: 'bj', value: 'bj', text: 'Benin' }
]



const Meeting = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setDate] = React.useState(new Date())
  const [detailPopUp, setDetailPopUp] = useState(false)
  const [evergreenModal, setEvergreenModal] = useState(false)
  const [sidePaneData, setSidePaneData] = useState(null)
const [tableFullData, setTableFullData] = useState({})
  const [sideTab, setSideTab] = useState(1)
  const today = new Date()
  const [schedulePopUp, setSchedulePopUp] = useState(false)
  const [editMeeting, setEditMeeting] = useState(false)
  const [createMeetingType, setMeetingType] = useState(false)
  const [menuOpen, setMenu] = useState(false)
  const [meetingData, setMeetData] = useState([])
  const [Events, setEvents] = useState([])

  const selectDateHandler = d => {
    setDate(d)
  }
  const panes = [
    {
      menuItem: 'Missed',
      render: ({ setDetailPopUp}) => (
        <MeetingTypeTeam setDetailPopUp={setDetailPopUp} status={'missed'}/>

      )
    },
    {
      menuItem: 'Answered',
      render: ({ setDetailPopUp, meetingData, setModalData, setEditMeeting }) => (
        <MeetingTypeTeam setDetailPopUp={setDetailPopUp} status={'completed'}/>

      )
    },
    {
      menuItem: 'Voicemail',
      render: ({ setDetailPopUp,meetingData, setEditMeeting }) => (
        <MeetingTypeTeam setDetailPopUp={setDetailPopUp} status={'voicemail'}/>


      )
    },
    {
      menuItem: 'Scheduled',
      render: ({ setDetailPopUp,meetingData, setEditMeeting }) => (
        <MeetingTypeTeam setDetailPopUp={setDetailPopUp} status={'Scheduled'}/>


      )
    },
    {
      menuItem: 'All',
      render: ({ setDetailPopUp,meetingData, setEditMeeting }) => (
        <MeetingTypeTeam setDetailPopUp={setDetailPopUp} />


      )
    },
  ]
  const panesType = [
    {
      menuItem: 'Team',
      render: ({ setDetailPopUp, detailPopUp }) =>   
      <Box display="flex" mt={1}>
       { Events?.map((event)=>
       
        
        event?.participation!=='one_to_one' &&

        <CardMetting event={event} setEditMeeting={setEditMeeting} />
        ) }
           </Box>
        
    },
    {
      menuItem: 'Personal',
      render: ({ setDetailPopUp, detailPopUp }) =>  
      
      
      <Box display="flex" mt={1}>
        {Events?.map((event)=>
       
        
      event?.participation==='one_to_one' &&

      <CardMetting event={event} setEditMeeting={setEditMeeting} />
      ) }
      </Box>
      
    }
  ]
  
 
  const setModalData = async data => {
    setEvergreenModal(true)
    setSidePaneData(data)
  }

  const setEvergreenModalStatus = async data => {
    setEvergreenModal(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        var bodyFormData = new FormData()
        bodyFormData.append('email', 'app@limecall.com')
        bodyFormData.append('password', 'limecalladmin')
        const user_id = localStorage.getItem('id')

        const result1 = await axios
          .post(`${apiConfig.calenderURL}/api/login`, bodyFormData)
          .then(response => {
            return response.data.token
          })
        localStorage.setItem('meeting_token', result1)

        const result2 = await axios.get(
          `${apiConfig.calenderURL}/api/event/retrieve/${user_id}`,
          {
            headers: {
              Authorization: 'Bearer ' + result1
            }
          }
        )
        setIsLoading(false)
        setMeetData(result2?.data?.data)
      } catch (e) {
        setIsLoading(false)
      }
    }

    fetchData()
    fetchEvents()
  
  }, [])


const fetchEvents = async() => { 
await axios?.get(`${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/calendar/events?status=1`).then((res)=>{
  if(res.data.data){
    setEvents(res.data.data)
  }
}).catch((e)=>{

})
  
 }
  return (
    <>
      <div className="sidebarWrap">
        <div className="meetingSidebar">
          <div className="sideHeader">
            <h1>Meeting</h1>
          </div>
          <label
            className={sideTab == 1 ? 'sideTab' : 'none'}
            onClick={() => setSideTab(1)}
          >
            Meetings
          </label>
          <label
            className={sideTab == 0 ? 'sideTab' : 'none'}
            onClick={() => setSideTab(0)}
          >
            Manage
          </label>
        </div>
        {!editMeeting ? <div className="meetWrapper">
          <div className="meet-container">
            <Dimmer active={isLoading} style={{ position: 'fixed' }}>
              <Loader />
            </Dimmer>
            <Title
              data={title}
              setMenu={setMenu}
              menuOpen={menuOpen}
              detailPopUp={detailPopUp}
              schedulePopUp={schedulePopUp}
            />
          </div>
          {sideTab == 1 ? (
            <div>
              <div className="meet-holder">
                <div className="meet-title-holder">
                  <div className="schedule_meeting">
                    <div className="meeting-time">
                      {/* <button className="">Meet Now</button> */}
                    </div>
                  </div>
                  <div className="metting-filter-section">
                    <Checkbox label="My Meetings only" />
                    <div className="date-selct-metting">
                      <DatePicker
                        dateFormat="yyyy/MM/dd"
                        selected={startDate}
                        onChange={selectDateHandler}
                        minDate={today}
                        todayButton={'Today'}
                      />
                    </div>
                    <div className="filter-metting">
                      <Select
                        style={{ margin: '5px' }}
                        placeholder="Filter"
                        options={countryOptions}
                      />
                      <img style={{ marginLeft: '10px' }} src={filterIcon} />
                    </div>
                  </div>
                </div>
              </div>
              {detailPopUp ? (
                <MeetingModalSide
                  detailPopUp={detailPopUp}
                  setDetailPopUp={setDetailPopUp}
                />
              ) : (
                ''
              )}
              {schedulePopUp ? (
                <ScheduleModalSide
                  schedulePopUp={schedulePopUp}
                  setSchedulePopUp={setSchedulePopUp}
                />
              ) : (
                ''
              )}
              <div className="metting-event">
                <Tab
                  menu={{ secondary: true, pointing: true }}
                  panes={panes}
                  setModalData={setModalData}
                  setDetailPopUp={setDetailPopUp}
                  detailPopUp={detailPopUp}
                  meetingData={meetingData}
                  setEditMeeting={() => setEditMeeting(true)}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          {sideTab == 0 ? (
            <div>
              <div className="meet-holder">
                <div className="meet-title-holder">
                  <div className="schedule_meeting">
                    <button className="" onClick={() => setMeetingType(true)}>
                      Create Meeting Type <img src={circlePlus} />
                    </button>
                  </div>
                </div>
              </div>

              {createMeetingType ? (
                <CreateMeetingType
                  createMeetingType={createMeetingType}
                  setMeetingType={setMeetingType}
                />
              ) : (
                ''
              )}
              <div className="metting-event">
                <Tab
                  menu={{ secondary: true, pointing: true }}
                  panes={panesType}
                  setDetailPopUp={setDetailPopUp}
                  detailPopUp={detailPopUp}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="metting-table"></div>{' '}
          <MeetingSideModal
            sidePaneData={sidePaneData}
            setEvergreenModalStatus={setEvergreenModalStatus}
            visibility={evergreenModal}
          ></MeetingSideModal>
        </div> : <EditMeetingPage handleBackClick={() => setEditMeeting(false)} /> }

        
      </div>
    </>
  )
}

export default Meeting
