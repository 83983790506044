import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'
import LeadTableHeaders from './LeadTableHeaders'
import LeadTableHeaders2 from './LeadTableHeaders2'
import LeadTableContent from './LeadTableContent'
import LeadTableContent2 from './LeadTableContent2'
import {
  useGetLeadsQuery,
  useGetUserIdQuery,
  useGetWidgetQuery
} from '../../../features/api/apiSlice'
import { CommonNotify } from "../../../common/CommonNotify"
import Axios from "axios"
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const LeadTable = ({
  outboundCall,
  leadScore,
  tableDataContent,
  setUpdatedTable,
  updatedPane,
  isMarkAllCheckbox,
  isMarkOpen,
  onClickMarkAllCheckbox,
  onClickMark,
  onClickMarkCheckbox,
  handleScore,
  star,
  mouseEnter,
  mouseLeave,
  onHoverScore,
  toggleTags,
  handleTagsData,
  setSortField,
  leadType,
  dropDownData,
  leadStage,
  leadOwner,
  rightPane,
  handleRightPaneOpen,
  allTags,
  reloadTable,
  deleteLead,
  params,
  setLeadsStates,
  setUserId,
  setAllTags,
  handleLoading,
  setWidgets,
  tableFullData,
  selectedRecordIds
}) => {
  const w_id = localStorage.getItem('widget_id')
  const res = useGetLeadsQuery(params)
  const user = useGetUserIdQuery()
  const widgets = useGetWidgetQuery(w_id)
  const [fvLeadList, setFvLeadList] = useState([])

  useEffect(() => {
    fetchFLead()
  }, [])

  useEffect(() => {
    handleLoading(true)
    if (res.isSuccess) {
      setLeadsStates(res)
    }
  }, [res])

  useEffect(() => {
    if (user.isSuccess) {
      setUserId(user)
    }
  }, [user])

  useEffect(() => {
    if (widgets.isSuccess) {
      setWidgets(widgets)
      if (widgets.data && widgets.data.data && widgets.data.data.scores_tags) {
        setAllTags(widgets.data.data.scores_tags.allowed_tags)
      }
    }
  }, [widgets])

  const fetchFLead = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/fav`
    Axios
      .get(url)
      .then(res => {
        setFvLeadList(res?.data?.data || [])
      })
      .catch(error => {
        CommonNotify(error?.response?.data?.errors?.[0], 'warning')
      })
  } 
   const getPlaceholder={
    1:{title:'No pending calls',subTitle:'There are no pending calls to be reviewed. The pending calls will show up here'},
    2:{title:'No assigned calls',subTitle:'There are no assigned calls.  All assigned calls that have not been closed will show up here.'},
  'default':{title:'No Calls',subTitle:'Any calls will show up here'}

  }


  return (
    <Table>
      <Table.Header>
        <Table.Row>
          {outboundCall ? (
            <LeadTableHeaders2
              isMarkAllCheckbox={isMarkAllCheckbox}
              leadType={leadType}
              isMarkOpen={isMarkOpen}
              onClickMarkAllCheckbox={onClickMarkAllCheckbox}
              onClickMark={onClickMark}
              setSortField={setSortField}
              outboundCall={outboundCall}
            />
          ) : (
            <LeadTableHeaders
              isMarkAllCheckbox={isMarkAllCheckbox}
              leadType={leadType}
              isMarkOpen={isMarkOpen}
              onClickMarkAllCheckbox={onClickMarkAllCheckbox}
              onClickMark={onClickMark}
              setSortField={setSortField}
              outboundCall={outboundCall}
            />
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
      {tableDataContent?.length > 0 ?
        
        tableDataContent.map((data, index) => (
        <>
            {outboundCall ? (
              <LeadTableContent2
                reloadTable={reloadTable}
                deleteLead={deleteLead}
                allTags={allTags}
                updatedPane={updatedPane}
                setUpdatedTable={setUpdatedTable}
                leadScore={leadScore}
                key={index}
                index={index}
                data={data}
                tableFullData={tableFullData}
                dropDownData={dropDownData}
                leadStage={leadStage}
                leadOwner={leadOwner}
                leadType={leadType}
                star={star}
                mouseEnter={mouseEnter}
                mouseLeave={mouseLeave}
                onClickMarkCheckbox={onClickMarkCheckbox}
                handleScore={handleScore}
                onHoverScore={onHoverScore}
                toggleTags={toggleTags}
                handleTagsData={handleTagsData}
                rightPane={rightPane}
                handleRightPaneOpen={handleRightPaneOpen}
                outboundCall={outboundCall}
                selectedRecordIds={selectedRecordIds}
                fvLeadList={fvLeadList}
                fetchFLead={fetchFLead}
              />
            ) : (
              <LeadTableContent
                reloadTable={reloadTable}
                deleteLead={deleteLead}
                allTags={allTags}
                updatedPane={updatedPane}
                setUpdatedTable={setUpdatedTable}
                leadScore={leadScore}
                key={index}
                index={index}
                data={data}
                tableFullData={tableFullData}
                dropDownData={dropDownData}
                leadStage={leadStage}
                leadOwner={leadOwner}
                leadType={leadType}
                star={star}
                mouseEnter={mouseEnter}
                mouseLeave={mouseLeave}
                onClickMarkCheckbox={onClickMarkCheckbox}
                handleScore={handleScore}
                onHoverScore={onHoverScore}
                toggleTags={toggleTags}
                handleTagsData={handleTagsData}
                rightPane={rightPane}
                handleRightPaneOpen={handleRightPaneOpen}
                outboundCall={outboundCall}
                selectedRecordIds={selectedRecordIds}
                fvLeadList={fvLeadList}
                fetchFLead={fetchFLead}
              />
            )}
          </> 
           )): 
          <Table.Row>
            <Table.Cell colspan="12" height="400px" style={{verticalAlign:'middle'}}>
              <div className="no-leads">
                <div className='icon'>
                  <CalendarTodayIcon/>
                </div>
                <h5>{ getPlaceholder?.[params?.[`filter[final_status][is]`] || 'default']?.title}</h5>
                <p>{getPlaceholder?.[params?.[`filter[final_status][is]`] || 'default']?.subTitle}</p>
              </div>
          </Table.Cell>
        </Table.Row>
      }
        
    
      </Table.Body>
    </Table>
  )
}

export default LeadTable
