/** 
 *  two sub compoment WidgetFromPreview, WidgetButtonPreview
 *   create a scss file
 **/


import React from 'react'
import Form from './FormPreview'
import Button from './ButtonPreview'

const WidgetPreview = ( { widgetBubbleColor, bubbleSettData, bubbleSett,  handleTabActive, widget, allowedTabs, widgetStyledData, widgetSettData, widgetObject, loading, onClickTableCategory} ) => {
  return (      
    <div id="widget-preview-page">
        <div className='fixed-holder'>           
            <div className='row'>
                <Form widgetStyledData={widgetStyledData} widgetSettData={widgetSettData}  widgetObject={widgetObject} onClickTableCategory={onClickTableCategory}   widgetBubbleColor={ widgetBubbleColor} loading={loading} handleTabActive={handleTabActive} widget={widget} allowedTabs={allowedTabs} />
                <Button bubbleSettData={bubbleSettData} bubbleSett={bubbleSett} widgetObject={widgetObject} widgetBubbleColor={widgetBubbleColor} />
            </div>
        </div>       
    </div>
  )
}

export default WidgetPreview