import React, { Component, Fragment } from 'react'

import CallForwardingToogle from '../../common/CallFowardingToggle'
import CommonGroupButton from '../../common/CommonGroupButton'
import { CommonNotify } from '../../common/CommonNotify'
import CommonButton from '../../common/CommonButtons'
import plus from '../../assets/images/plus.png'
import {Dimmer, Loader} from 'semantic-ui-react'

import axios from 'axios'

//redux
import { connect } from 'react-redux'
import { getCallForwadingSettings, getTeamMember, getWidgetCountries  } from '../../actions/settings'

import InvertedModal from '../../common/VerifyModal2'
import { Button } from 'semantic-ui-react'


const webAppData = {
  forwardingTitle: [],
  callId: 'webAppToggle',
  forwardRef: 'webApp',
  phoneValue: 'web',
  enabled: true,
  timeoutRef: 'webAppTime',
  active: false
}

const mobileAppData = {
  callId: 'mobileAppToggle',
  forwardRef: 'mobileApp',
  phoneValue: 'mobile',
  enabled: true,
  timeoutRef: 'mobileappTime'
}

const numberData = {
  type: 'select',
  callId: 'numberToggle',
  forwardRef: 'numberApp',
  enabled: false,
  timeoutRef: 'numberAppTime'
}

const sipData = {
  callId: 'sipToggle',
  forwardRef: 'sip',
  phoneValue: 'SIP',
  number: '',
  enabled: false,
  timeoutRef: 'sipAppTime',
  noToggle: true
}


class CallForwarding extends Component {
  constructor(props) {
    super(props)
    const apiToken = localStorage.getItem('access_token')
    const userId = localStorage.getItem('id')
    this.state = {
      sipLoaded: true,
      numberApp: false,
      addForwardNumber: '',
      mobileAppTime: '',
      webAppTime: '',
      sipData: sipData,
      numberAppTime: '',
      isLoading: false,
      sipAppTime: '',
      verifyCode: false,
      verifyCodeDigit: '',
      verifyNum: '',
      verifyObj: null,
      webApp: webAppData,
      mobileApp: mobileAppData,
      forwardNo: [],
      webLoaded: false,
      isTextButton: false,
      openAddNumber: false,
      openDeleteNumber: false,
      apiToken,
      userId,
      modalLoadingButton: false
    }
  }

  handleDataRef = (DataRef, DataState) => {
    this.setState({ [DataRef]: DataState })
  }

  onChangeCountry = (value, object) => {
    this.setState({ verifyNum: value, verifyObj: object })
  }

  handleGroupBtnData = (toggleBtn, id) => {
    this.setState({ [id]: toggleBtn })
  }

  handleModalClose = () => {
    this.setState({
      verifyCode: false,
      openDeleteNumber: false,
      openAddNumber: false
    })
    this.setState({isLoading: false})
  }

  componentDidUpdate(prevProps) {
    if (this.props.callForwadingSettings !== prevProps.callForwadingSettings) {
      this.setState({isLoading: false})


      this.setNumbers(this.props.callForwadingSettings.data.available_forward_no)
      this.setWeb(this.props.callForwadingSettings.data.receive_call_browser)

          
    }
    if (this.props.teamMember !== prevProps.teamMember) {
      this.setState({isLoading: false})
      this.setSip(this.props.teamMember.data.call_forwarding_to)    
    }
    if(this.props.widgetCountries !== prevProps.widgetCountries){

      if(this.props.widgetCountries.data?.allowed_countries?.length){
        this.setState({allowedCountries: this.props.widgetCountries.data.allowed_countries})
      }

       
    }
  }

  componentDidMount = () => {
    this.fetchSettings()
    this.fetchSip()
    this.fetchCountry()
  }

  setWeb = data => {
    let webApp = { ...this.state.webApp }
    webApp.active = data
    this.setState({ webApp, webLoaded: true })   
  }

  fetchSip = async () => {
   
    const uid =  await localStorage.getItem('id')
    if(!uid) return;
    if (this.props.teamMember) {
      this.setState({isLoading: false})
      this.setSip(this.props.teamMember.data.call_forwarding_to)    
    } else {
      this.setState({isLoading: true})
      this.props.getTeamMember()
    }
    /* const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/member/${uid}`

    

    axios
      .get(url)
      .then(res => {
        this.setState({isLoading: false})
        if (res.data.data) {
          this.setSip(res.data.data.call_forwarding_to)         
        }
      })
      .catch(error => {
        this.setState({isLoading: false})
        const errors = { ...error }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
          } else {
            CommonNotify('Some Thing went wrong')
          }
        }
      }) */
  }

  setSip = data => {
  
    const ar = data.find( item => {
      if(item.toLowerCase().includes("sip:")){
        return item
      }
    } )
    if(typeof ar !== 'undefined'){     
        const obj = {...this.state.sipData}
        obj.enabled = true 
        obj.number = ar
        this.setState({sipData: obj}, () => { this.setState({sipLoaded: true}) })
    }
  }

  fetchSettings = () => {
    this.setState({isLoading: false})
    if (this.props.callForwadingSettings) {
      this.setNumbers(this.props.callForwadingSettings.data.available_forward_no)
      this.setWeb(this.props.callForwadingSettings.data.receive_call_browser)
    } else {
      this.setState({isLoading: true})
      this.props.getCallForwadingSettings()
    }
    /* 

    const w_id = await localStorage.getItem('widget_id');
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-call-settings?widget_id=${w_id}`

    axios
      .get(url)
      .then(res => {
        this.setState({isLoading: false})
        if (res.data.data) {
          this.setNumbers(res.data.data.available_forward_no)
          this.setWeb(res.data.data.receive_call_browser)
         
        }
      })
      .catch(error => {
        this.setState({isLoading: false})
        const errors = { ...error }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
          } else {
            CommonNotify('Some Thing went wrong')
          }
        }
      }) */
  }


  fetchCountry = () => {
    this.setState({isLoading: false})

    if (this.props?.widgetCountries) {
      this.setState({  allowedCountries: this.props.getWidgetCountries?.data?.allowed_countries})
    } else {
      this.setState({isLoading: true})
      this.props.getWidgetCountries()
    }
   
  }

  setNumbers = data => {
    
    let updateForwardNo = []
    updateForwardNo = data.map((row, index) => {
     
      return {
        phone_number: row.phone_number,
        id: row.id,
        active: row.active,
        phoneValue: 'mobile',
        forwardRef: 'sip_' + row.id,
        callId: 'sip_' + row.id,
        primary: true
      }
      
    })
    this.setState({ forwardNo: updateForwardNo })
  }

  addNumforVerify = () => {
    if (!this.state.verifyObj) {
      CommonNotify('Please Add Number for Forwarding')
      return
    }

    this.setState({isLoading: true})
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/send-verification-phone-number`

    var data = {
      user_id: this.state.userId,
      phone_country: this.state.verifyObj.countryCode,
      full_number: this.state.verifyNum,
      phone_number: this.state.verifyNum
    }

    axios
      .post(url, data)
      .then(res => {
        this.setState({isLoading: false})
        if (res.data.message == 'Successfully') {
          this.setState({ openAddNumber: true })
          CommonNotify('Verification code sent to Phone', 'success')
        } else {
          CommonNotify('Can`t Send Verification Code', 'error')
        }
      })
      .catch(er => {
        const errors = { ...er }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({isLoading: false})
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({isLoading: false})
          }
        }
      })
  }

  verifyNumber = () => {
    this.setState({ openAddNumber: true, modalLoadingButton: true })
    if (!this.state.verifyCodeDigit) {
      CommonNotify('Please Enter Verification Code', 'warning')
      this.setState({ modalLoadingButton: false })
      return
    }

    this.setState({isLoading: true})
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/verify-user-phone-number`

    var data = {
      verification_code: this.state.verifyCodeDigit
    }

    axios
      .post(url, data)
      .then(res => {
        this.setState({isLoading: false})
        this.setState({ verifyCode: false })
        if (res.data.message == 'Successfully') {
          CommonNotify('Phone Number Added', 'success')
          this.setState({
            verifyCodeDigit: '',
            verifyCode: false,
            verifyNum: '',
            openAddNumber: false,
            modalLoadingButton: false,
            isTextButton: false
          })
          this.fetchSettings()
        } else {
          CommonNotify('Cant Add Phone Number')
          this.setState({ modalLoadingButton: false })
        }
      })
      .catch(er => {
        const errors = { ...er }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({ modalLoadingButton: false })
            this.setState({isLoading: false})
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({ modalLoadingButton: false })
            this.setState({isLoading: false})
          }
        }
      })
  }

  saveSetting = (val, index, number) => {
    
    if (index == 'webApp') {
      this.toggleWeb(val)
      return
    }
    this.togglePhone(val, number)
  }

  toggleWeb = val => {
   

    //this.setState({isLoading: true})
    

    const data = val == true ? 1 : 0

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/update-browser-availability/${this.state.userId}/${data}`
    axios
      .get(url)
      .then(res => {
        this.setState({isLoading: false})
        if (res.data.message.success) {
         
        } else {
          CommonNotify(res.data.message.error[0])
        }
      })
      .catch(error => {
        const errors = { ...error }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({isLoading: false})
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({isLoading: false})
          }
        }
      })
  }

  togglePhone = (val, number) => {
    //this.setState({isLoading: true})
    

    const data = {
      phone_number: number,
      status: val == true ? 1 : 0
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/change-number-status`
    axios
      .post(url, data)
      .then(res => {
        this.setState({isLoading: false})
        if (res.data.message.success) {
         

        } else {
          CommonNotify(res.data.message.error[0])
        }
      })
      .catch(error => {
        const errors = { ...error }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({isLoading: false})
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({isLoading: false})
          }
        }
      })
  }

  addTextButton = () => {
    this.setState({ isTextButton: true })
  }

  deleteContactNumber = rowData => {
    this.setState({isLoading: true})
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/delete-phone-number`
    const data = {
      phone_number: rowData.phone_number,
    }
    axios
      .post(url, data)
      .then(res => {
        this.setState(
          {
            verifyCodeDigit: '',
            verifyCode: false,
            verifyNum: '',
            openDeleteNumber: false,
            isTextButton: false,
            modalLoadingButton: false
          },
          () => this.fetchSettings()
        )
      })
      .catch(err => {
        const errors = { ...err }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({isLoading: false})
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({isLoading: false})
          }
        }
      })
  }

  submitDeleteHandle = () => {
    this.setState({ modalLoadingButton: true })
    if (!this.state.verifyCodeDigit) {
      CommonNotify('Please Enter Verification Code')
      this.setState({ modalLoadingButton: false })
      return
    }

    this.setState({isLoading: true})
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/delete-phone-number`

    var data = {
      confirmation_code: this.state.verifyCodeDigit,
      phone_number: this.state.verifyNum
    }

    axios
      .post(url, data)
      .then(res => {
        this.setState({isLoading: false})
       
        if (res.data.message == 'Successfully') {
          CommonNotify('Phone number deleted successfully', 'success')
          this.setState(
            {
              verifyCodeDigit: '',
              verifyCode: false,
              verifyNum: '',
              openDeleteNumber: false,
              isTextButton: false,
              modalLoadingButton: false
            },
            () => this.fetchSettings()
          )
        } else {
          CommonNotify('Cant Add Phone Number')
          this.setState({ modalLoadingButton: false })
        }
      })
      .catch(er => {
        const errors = { ...er }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({ modalLoadingButton: false })
            this.setState({isLoading: false})
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({ modalLoadingButton: false })
            this.setState({isLoading: false})
          }
        }
      })
  }

  render() {
    return (
      <>
      <Dimmer active={this.state.isLoading}>
        <Loader></Loader>
      </Dimmer>
      <div className="call-forwarding-wrapper call_rorwarding_main">
          <p className="title-label" 
                      style ={{ fontSize: '16px', marginTop: 52,fontWeight:600}}>
            Choose how you want to receive incoming calls 
          </p>
        <div className="call-forwarding-holder call_rorwarding">
          {this.state.webLoaded && (
            <CallForwardingToogle
              handleDataRef={this.saveSetting}
              dataToggle={this.state.webApp}
              active={this.state.webApp.active === 1 ? true : false}
            />
          )}

          {this.state.sipLoaded && (
            <CallForwardingToogle
             noToggle={true}
              handleDataRef={this.saveSetting}
              dataToggle={this.state.sipData}
              active={this.state.sipData.enabled}
            />
          )}

          {this.state.forwardNo.map(row => (
            <div className="call_forwarding_toogle">
              <CallForwardingToogle
                handleDataRef={this.saveSetting}
                dataToggle={row}
                number={row.phone_number}
                active={row.active == 1 ? true : false}
               
              />
              <i
                className="fas fa-trash-alt delete_icon"
                onClick={() => this.deleteContactNumber(row)}
              ></i>
            </div>
          ))}
          
          {this.state.isTextButton ? (
            <CallForwardingToogle
              dataToggle={numberData}
              noToggle
              isButton={true}
              onChangeCountry={this.onChangeCountry}
              dataNumber={this.state.verifyNum}
              addNumforVerify={this.addNumforVerify}
              whitelist={this.state.allowedCountries}
            />
          ) : (
            <div className="add-forwarding-wrapper">
              <Button
                className="add-number"
                onClick={this.addTextButton}
              >
                + Add Number
              </Button>
            </div>
          )}
        </div>
       

        <Fragment>
         
          <InvertedModal
            closeHandle={this.handleModalClose}
            submitHandle={this.verifyNumber}
            changeHandle={e =>
              this.setState({ verifyCodeDigit: e.target.value })
            }
            Number={this.state.verifyNum}
            VerifyCode={this.state.verifyCode}
            open={this.state.openAddNumber}
            contentData="addNumber"
            modalLoadingButton={this.state.modalLoadingButton}
          />
          <InvertedModal
            closeHandle={this.handleModalClose}
            changeHandle={e =>
              this.setState({ verifyCodeDigit: e.target.value })
            }
            Number={this.state.verifyNum}
            VerifyCode={this.state.verifyCode}
            open={this.state.openDeleteNumber}
            contentData="deleteNumber"
            submitDeleteHandle={this.submitDeleteHandle}
            modalLoadingButton={this.state.modalLoadingButton}
          />         
        </Fragment>      
       
      </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  callForwadingSettings: state.settings.callForwadingSettings,
  teamMember: state.settings.teamMember,
  widgetCountries: state.settings.widgetCountries
});

export default connect(
  mapStateToProps,
  { getCallForwadingSettings, getTeamMember, getWidgetCountries  }
)(CallForwarding)
