import React, { useEffect, useState } from 'react'
import close from '../assets/images/close.png'
import {
  Modal,
  Dropdown,
  Input,
  TextArea,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import PhoneInput from 'react-phone-input-2'
import CommonButtons from '../common/CommonButtons'
import Datetime from 'react-datetime'
import { Col, Row } from 'react-bootstrap'
import AddIcon from '@mui/icons-material/Add'
import countries from '../lib/Country'
import { CommonNotify } from '../common/CommonNotify'
import Axios from 'axios'
import moment from "moment"
import { connect } from 'react-redux'
const leadType = [
  'Message',
  'Chat',
  'Email',
  'Web Call',
  'Phone Call',
  'WhatsApp',
  'Other',
  'Web Form',
  'Call'
]

const quatobleLead = ['Yes', 'No']

function AddNewLead({ setModal, reloadTable, hideScreens, widget, pipeline }) {
  const [steps, setSteps] = useState(1)
  const [loading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState({
    quotable: 1
  })
  const [refresh, setRefresh] = useState(false)
  const [funnel, setFunnel] = useState(null)
  const [customeField, setCustomeField] = useState([
    {
      name: '',
      value: ''
    }
  ])


  useEffect(() => {
    if (refresh) {
      setRefresh(false)
    }
  }, [refresh])

  useEffect(() => {
    setRefresh(true)
  }, [steps])

  const next = () => {
    setSteps(+steps + 1)
  }

  const prev = () => {
    setSteps(+steps - 1)
  }

  const handleChange = (name, value, index) => {
    let cloneFormValue = { ...formValue }
    if (index || index === 0) {
      if (!cloneFormValue?.customeField) cloneFormValue.customeField = []
      if (cloneFormValue['customeField'][index]) {
        cloneFormValue['customeField'][index][name] = value
      } else {
        cloneFormValue['customeField'][index] = {}
        cloneFormValue['customeField'][index][name] = value
      }
    } else {
      cloneFormValue[name] = value
    }
    setFormValue(cloneFormValue)
  }

  const handleLoadSteps = () => {
    switch (steps) {
      case 1:
        return stepIntial()
      case 2:
        return stepOne()
      case 3:
        return stepTwo()
      case 4:
        return stepThree()
      case 5:
        return stepFour()

      default:
        return stepOne()
    }
  }
useEffect(() => {
  if (widget?.data?.[0]?.id){
    
    // setStages(widget?.data?.[0]?.stages)
  }
}, [widget])
  const stepIntial = () => {
    return (
      <>
        <div>
          <h3>
            <b>Tell us about this Lead</b>
          </h3>
          <label className="mt-3">Name*</label>
          <Input
            placeholder="Name"
            onChange={e => handleChange('customer_name', e?.target?.value)}
            fluid
            value={formValue?.customer_name}
          />
          <label className="mt-3">Email</label>
          <Input
            placeholder="email"
            onChange={e => handleChange('email', e?.target?.value)}
            fluid
            value={formValue?.email}
          />
          <label className="mt-3">Phone</label>
          <PhoneInput
            international
            className="text-color"
            country={'us'}
            value={formValue?.phone_number}
            onChange={value => handleChange('phone_number', value)}
          />
          <label className="mt-3">Country</label>
          <Dropdown
            placeholder="Select Country"
            fluid
            search
            selection
            value={formValue?.country}
            onChange={(e, result) => handleChange('country', result?.value)}
            options={countries?.map((option, index) => ({
              key: index,
              text: option?.name,
              value: option?.name?.toLocaleLowerCase()
            }))}
          />
          {!hideScreens ?
          <>
          <label className="mt-3">Company</label>
          <Input
            placeholder="Company"
            onChange={e => handleChange('company_name', e?.target?.value)}
            fluid
            value={formValue?.company_name}
          />
          </>
          :
            <>
              <label className="mt-3">Add to Pipeline</label>
              <Dropdown
                fluid
                search
                selection
                options={[{
                  key: 0,
                  text: 'Unassign',
                  value: '',
                }, ...(pipeline?.map((obj, index) => ({
                  key: index + 1,
                  text: obj?.name,
                  value: obj?.name,
                })))]}
                placeholder="Add to Pipeline"
                defaultValue={
                  funnel?.name}
                value={funnel?.name}
                onChange={(e, dat) =>
                 {
                  const ff = pipeline?.filter((obj) => obj?.name === dat?.value)?.[0]
                  setFunnel(ff)
                  handleChange('funnel', dat?.value)
                  }
                }
              />
              { funnel && 
              <>
              <label className="mt-3">Lead Stage</label>
              <Dropdown
                placeholder="Select Stages"
                onChange={(e, result) => handleChange('stage', result?.value)}
                fluid
                search
                selection
                value={formValue?.stage}
                options={[{
                  key: 0,
                  text: 'Unassign',
                  value: '',
                }, ...(funnel?.stages?.map((item, index) => ({
                  key: index,
                  text: item,
                  value: item,
                })))]}
              />
              </>
              }
            </>
          }
          
          {hideScreens ? 
          <>
           <label className="mt-3">Lead Type</label>
            <Dropdown
              placeholder="Select Type"
              onChange={(e, result) => handleChange('lead_type', result?.value)}
              fluid
              search
              selection
              value={formValue?.lead_type}
              options={leadType?.map((option, index) => ({
                key: index,
                text: option,
                value: option?.toLocaleLowerCase()
              }))}
            />
            </>
            :null  
        
        }
        </div>
      </>
    )
  }

  const stepOne = () => {
    return (
      <>
        <div>
          <h3>
            <b>Tell us about this Lead</b>
          </h3>
          <label className="mt-3">Lead Type*</label>
          <Dropdown
            placeholder="Select Type"
            onChange={(e, result) => handleChange('lead_type', result?.value)}
            fluid
            search
            selection
            value={formValue?.lead_type}
            options={leadType?.map((option, index) => ({
              key: index,
              text: option,
              value: option?.toLocaleLowerCase()
            }))}
          />
          <label className="mt-3">Date and Time</label>
          <Datetime
            value={formValue?.created_at}
            placeholder="Select Date and Time"
            onChange={e => handleChange('created_at', e)}
          />
          <label className="mt-3">Quotable Lead</label>
          <Dropdown
            placeholder="Select Quatable"
            fluid
            search
            selection
            value={+formValue?.quotable === 1 ? 'yes' : 'no'}
            onChange={(e, result) =>
              handleChange('quotable', result?.value === 'yes' ? 1 : 0)
            }
            options={quatobleLead?.map((option, index) => ({
              key: index,
              text: option,
              value: option?.toLocaleLowerCase()
            }))}
          />
          <label className="mt-3">Quote Value($)</label>
          <Input
            type="number"
            placeholder="$ 0"
            onChange={e => handleChange('quote_value', e?.target?.value)}
            fluid
            value={formValue?.quote_value}
          />
          <label className="mt-3">Sales Value($)</label>
          <Input
            placeholder="$ 0"
            onChange={e => handleChange('sales_value', e?.target?.value)}
            fluid
            value={formValue?.sales_value}
            type="number"
          />
          <label className="mt-3">Form Name</label>
          <Input
            placeholder="Enter Form Name"
            onChange={e => handleChange('form_name', e?.target?.value)}
            fluid
            value={formValue?.form_name}
          />
        </div>
      </>
    )
  }
  const stepTwo = () => {
    return (
      <>
        <div>
          <h3>
            <b>Tell us where this lead came from?</b>
          </h3>
          <label className="mt-3">Source</label>
          <Input
            placeholder="Enter Trafic Source"
            onChange={e => handleChange('source', e?.target?.value)}
            fluid
            value={formValue?.source}
          />
          <label className="mt-3">Medium</label>
          <Input
            placeholder="Enter Trafic Medium"
            onChange={e => handleChange('medium', e?.target?.value)}
            fluid
            value={formValue?.medium}
          />
          <label className="mt-3">Campaign</label>
          <Input
            placeholder="Enter Trafic Campaign"
            onChange={e => handleChange('campaign', e?.target?.value)}
            fluid
            value={formValue?.campaign}
          />
          <label className="mt-3">Content</label>
          <Input
            placeholder="Enter Campaign Content"
            onChange={e => handleChange('content', e?.target?.value)}
            fluid
            value={formValue?.content}
          />
          <label className="mt-3">Keyword</label>
          <Input
            placeholder="Enter Campaign Keyword"
            onChange={e => handleChange('keyword', e?.target?.value)}
            fluid
            value={formValue?.keyword}
          />
          <label className="mt-3">IP Address</label>
          <Input
            placeholder="Enter IP Address"
            onChange={e => handleChange('ip_address', e?.target?.value)}
            fluid
            value={formValue?.ip_address}
          />
        </div>
      </>
    )
  }
  const stepThree = () => {
    return (
      <>
        <div>
          <h3>
            <b>Tell us where this lead came from?</b>
          </h3>
          <label className="mt-3">GCLID</label>
          <Input
            placeholder="Enter Google Click Indentifier"
            onChange={e => handleChange('gclid', e?.target?.value)}
            fluid
            value={formValue?.gclid}
          />
          <label className="mt-3">MSCLKID</label>
          <Input
            placeholder="Enter Microsoft Click Indentifier"
            onChange={e => handleChange('msclkid', e?.target?.value)}
            fluid
            value={formValue?.msclkid}
          />
          <label className="mt-3">Lead Page</label>
          <Input
            placeholder="Enter URL Where Lead Took Place"
            onChange={e => handleChange('lead_page', e?.target?.value)}
            fluid
            value={formValue?.lead_page}
          />
          <label className="mt-3">Landing Page</label>
          <Input
            placeholder="Enter URL Where user Arrived"
            onChange={e => handleChange('landing_page', e?.target?.value)}
            fluid
            value={formValue?.landing_page}
          />
          <label className="mt-3">Operating System</label>
          <Input
            placeholder="Enter Operating System Used By User"
            onChange={e => handleChange('os', e?.target?.value)}
            fluid
            value={formValue?.os}
          />
          <label className="mt-3">Browser</label>
          <Input
            placeholder="Enter Browser Used By User"
            onChange={e => handleChange('browser', e?.target?.value)}
            fluid
            value={formValue?.browser}
          />
          <label className="mt-3">Device Type</label>
          <Input
            placeholder="Enter Device Type Used By User"
            onChange={e => handleChange('device_type', e?.target?.value)}
            fluid
            value={formValue?.device_type}
          />
        </div>
      </>
    )
  }
  const stepFour = () => {
    return (
      <>
        <div>
          <h3>
            <b>Add additional data to this lead</b>
          </h3>
          {customeField &&
            customeField?.length > 0 &&
            customeField?.map((list, index) => {
              return (
                <Row
                  key={index}
                  className="banner-description-wrapper planinfo-box justify-content-start align-items-stretch"
                >
                  <Col md={6}>
                    <label className="mt-3">Field Name</label>
                    <Input
                      placeholder="Enter Field Name"
                      onChange={e =>
                        handleChange('name', e?.target?.value, index)
                      }
                      fluid
                      value={formValue?.customeField?.[index]?.['name']}
                    />
                  </Col>

                  <Col md={6}>
                    <label className="mt-3">Field Value</label>
                    <TextArea
                      name="message"
                      placeholder="Enter Field Value"
                      value={formValue?.customeField?.[index]?.['value']}
                      onChange={e =>
                        handleChange('value', e?.target?.value, index)
                      }
                      rows={6}
                    />
                  </Col>
                </Row>
              )
            })}

          <Row className="pr-5 pl-3 mt-3">
            <Col
              md={12}
              className="add-more-field cursor-pointer"
              onClick={handleAddMoreField}
            >
              <AddIcon /> Add More Fields
            </Col>
          </Row>
        </div>
      </>
    )
  }

  const handleAddMoreField = () => {
    let cloneField = customeField.slice()
    cloneField.push({
      name: '',
      value: ''
    })
    setCustomeField(cloneField)
  }

  const handleSaveNewLead = () => {
    if (!formValue?.customer_name) {
      CommonNotify('Name is required.')
      return
    }

    if ( !formValue?.lead_type) {
      CommonNotify('Lead Type is required.')
      return
    }

    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/lead-form`
    let payload = {
      ...formValue
    }
    delete payload.customeField
    let custom_fields = {}
    if (formValue?.customeField && formValue?.customeField?.length) {
      const val = formValue?.customeField?.forEach(element => {
        custom_fields[`custom_fields[${element?.name}]`] = element?.value
      })
    }
    payload = {
      ...payload,
      ...custom_fields
    }
    
    if (payload?.created_at) {
      payload.created_at = moment(payload.created_at).format("YYYY-MM-DD HH:MM:SS")
    }

    setLoading(true)
    Axios.post(URL, payload)
      .then(res => {
        setLoading(false)
        CommonNotify('Lead added successfully')
        reloadTable()
        setModal(false)
      })
      .catch(err => {
        setLoading(false)
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Something went to wrong!'
        )
      })
  }

  return (
    <div>
      <Modal
        onClose={() => setModal(false)}
        onOpen={() => setModal(true)}
        open={true}
      >
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <div
          style={{
            overflowY: 'scroll',
            minHeight: '760px',
            maxHeight: '760px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              borderBottom: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: 'grey',
              padding: 15,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <h1
              style={{
                fontSize: 23,
                fontWeight: 'bold',
                margin: '0 auto',
                textAlign: 'center',
                borderBottomWidth: 1,
                borderBottomColor: 'gray'
              }}
            >
              Add New Lead
            </h1>
            <div className="close-icon" onClick={() => setModal(false)}>
              <img src={close} alt="close" />
            </div>
          </div>

          <div className="content-new-lead">
            <Row>
              <Col md={4}>
                <ul className="lead-step p-5 mt-3">
                  <li
                    className={
                      steps === 1 ? 'current' : steps > 1 ? 'selected' : ''
                    }
                  >
                    Tell us about this Lead
                  </li>
                 {!hideScreens && 
                 
                 <>
                 <li
                    className={
                      steps === 2 ? 'current' : steps > 2 ? 'selected' : ''
                    }
                  >
                    Tell us about this Lead
                  </li>
                  <li
                    className={
                      steps === 3 ? 'current' : steps > 3 ? 'selected' : ''
                    }
                  >
                    Tell us where this lead came from?
                  </li>
                  <li
                    className={
                      steps === 4 ? 'current' : steps > 4 ? 'selected' : ''
                    }
                  >
                    Tell us where this lead came from?
                  </li>
                  <li
                    className={
                      steps === 5 ? 'current' : steps > 5 ? 'selected' : ''
                    }
                  >
                    Add additional data to this lead
                  </li>
                  </>
                  }
                </ul>
              </Col>
              <Col md={6}>
                <div className="py-5">{!refresh && handleLoadSteps()}</div>
              </Col>
            </Row>
          </div>
          <div
            className="quicksetup-footer mt-auto"
            style={{ justifyContent: 'space-between' }}
          >
            {+steps !== 1 ? (
              <CommonButtons
                content="Prev"
                background="grey"
                style={{ height: '35px' }}
                onClick={prev}
              />
            ) : (
              <p></p>
            )}
            <span>
              {+steps !== 5 && !hideScreens && (
                <CommonButtons
                  content="Skip"
                  background="grey"
                  style={{ height: '35px' }}
                  onClick={next}
                />
              )}

              {+steps !== 5 ? (
                <CommonButtons
                  content={hideScreens ? "Add Lead":"Next"}
                  background="blue"
                  style={{ height: '35px' }}
                  onClick={hideScreens ? handleSaveNewLead :next}
                />
              ) : (
                <CommonButtons
                  content="Add Lead"
                  background="blue"
                  style={{ height: '35px' }}
                  onClick={handleSaveNewLead}
                />
              )}
            </span>
          </div>
        </div>
      </Modal>
    </div>
  )
}
const mapStateToProps = state => ({
  widget: state.home.widget,
  subscription: state.home.subscription,
  dialerStatus: state.dialer.currentStatus,
  pipeline: state.home.pipeline

})

export default connect(mapStateToProps, {

})(AddNewLead)