import React, { useState } from 'react'
import { Button, Input, Modal } from 'semantic-ui-react'
import { CommonNotify } from './CommonNotify'
import Axios from 'axios'

const EmailSend = ({open,onClose,script}) => {
    const [devEmail, setDevEmail] = useState('')

 const sendCode = () => {
    if (devEmail == '') {
      CommonNotify('Please Enter Email Id', 'warning')
      return
    }


    let data = {
      developer_email: devEmail,
      widget_script: script
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/send-code-to-developer`

    Axios
      .post(url, data)
      .then(res => {
        if (res.data.errors?.length == 0) {
            setDevEmail('')
            onClose()
          CommonNotify('Js Code snippet Send successfully', 'success')
        }
      })
      .catch(err => {
        CommonNotify('Cant Send Email System Error occured')
      })
  }

  return (
    <Modal
    onClose={onClose}
    // onOpen={() => this.setState({ open: true })}
    open={open}
    className="send-code-dialog"
  >
    <Modal.Header>Developer Email ID</Modal.Header>
    <Modal.Content image>
      <Modal.Description>
        <Input
          placeholder="Email Id"
          className="call-textarea"
          onChange={e => setDevEmail(e?.target?.value)}
          value={devEmail}
        />
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button
        color="black"
        onClick={onClose}
      >
        Close
      </Button>
      <Button
        content="Send Code"
        labelPosition="right"
        icon="checkmark"
        onClick={() => sendCode()}
        positive
      />
    </Modal.Actions>
  </Modal>
  )
}

export default EmailSend