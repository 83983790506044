import React, { useEffect, useState } from 'react'
import assignAgentIcon from '../../../../assets/images/add-contact.png'
import QualifyFooter from './QualifyFooter'
import Avatar from 'react-avatar'
import { _createActivity, _getLeadNotes } from '../../../../config/leadAPI'
import { CommonNotify } from '../../../../common/CommonNotify'
import Axios from 'axios'
import { Button, TextArea } from 'semantic-ui-react'
import moment from 'moment'

const LeadAssociate = ({ leadData, leadScore }) => {
  const leads = leadData?.lead?.member

  const contact = leadData?.lead.contact
  const [summary, setSummary] = useState('')
  const [getNotes, setGetNotes] = useState('')

  useEffect(() => {
    getLeadNotes(leadData.lead.id)
  }, [leadData])

  const getLeadNotes = callId => {
    _getLeadNotes(callId)
      .then(res => {
        setGetNotes(Object.entries(res))
      })
      .catch(err => {})
  }

  const createNotes = async () => {
    if (summary === '') {
      CommonNotify('Please enter text first.')
    } else {
      const lead_Id = leadData.lead.id

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${lead_Id}/add-note`

      const postData = {
        content: summary
      }
      Axios.post(url, postData)
        .then(res => {
          setSummary('')
          getLeadNotes(lead_Id)
        })
        .catch(error => {})
    }
  }

  return (
    <div className="associationsTab" style={{ height: 'calc(100vh - 48px)' }}>
      {/*  { contact?.name && (
          <>
          <div className="associationBoxes">
           <div className="associationWrap">
          <div className="profileContain">
           
             
            <div className="profileAssociation">             
              <Avatar name={contact?.name} style={{ maxWidth: "80px", borderRadius: "40px" }}/>
            </div>
            <div className="detailAssociation">
              <h4>{contact?.name}</h4>
              <p>{contact?.phone_number}</p>
              <p>{contact?.email}</p>
            </div>
              
           
           
          </div>

          <div className="leadAssociation">
            <div className="leadeDetail">
              <span></span>
              <label>{!contact?.score ? 'N.A' : contact?.score }</label>
            </div>
            <div className="leadIcon">
              <img className="" src={assignAgentIcon} alt="" />
            </div>
          </div>
        </div></div>
        </>)}
      
      <QualifyFooter leadData={leadData.lead} leadScore={leadScore}/> */}
      <div style={{ margin: '10px' }}>
        {/* <SemanticDatepicker placeholder="Enter activity date" style={{ width:'100%' }} showToday={true} format="YYYY-MM-DD" onChange={(event, data) => setStartDate(data.value)} />
         */}
        <TextArea
          onChange={e => setSummary(e.target.value)}
          value={summary}
          placeholder="Start typing to leave a note"
        />
        <Button
          style={{ margin: 'auto' }}
          primary
          onClick={() => createNotes()}
        >
          Add note
        </Button>{' '}
      </div>
      {getNotes.length > 0 ? (
        <div
          className="activities-wrapper"
          style={{ height: '100%', paddingBottom: '50px' }}
        >
          {getNotes &&
            getNotes?.map((data, index) => {
              return (
                <div className="activities-main">
                  <style>
                    {`.activities-wrapper .item-green {
                                    border-left-style: none !important;
                                }
                                .activities-wrapper .sl-content .ui.button.circular {
                                  padding: .78571429em .78571429em .78571429em !important;
                                }
                                `}
                  </style>
                  <div className={`sl-activities-item item-green`}>
                    <div className="sl-icon">
                      <i className="fas fa-sticky-note"></i>
                    </div>
                    <div
                      className="sl-content px-3 py-2"
                      style={{
                        backgroundColor: 'white',
                        marginTop: 6,
                        minHeight: '80px'
                      }}
                    >
                      <div className="notes-wrapper">
                        <p style={{ fontSize: 15, color: 'grey' }}>
                          {' '}
                          <span
                            style={{
                              fontWeight: 600,
                              color: 'black'
                            }}
                          >
                            Note{' '}
                          </span>{' '}
                        </p>

                        <div>
                          <p
                            style={{ fontSize: 10, padding: 8, color: 'gray' }}
                          >
                            {moment
                              .utc(data[1].date)
                              .local()
                              .startOf('seconds')
                              .fromNow()}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          minHeight: '50px',
                          position: 'relative'
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            marginTop: -10
                          }}
                        >
                          &nbsp; <span>{data[1].note}</span>
                        </p>
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          height: '20px'
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            right: 0,
                            color: 'gray',
                            fontSize: 10,
                            padding: 8
                          }}
                        >
                          <span>by</span> {data[1].userName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default LeadAssociate
