import React from 'react'

const AddTagPopup = ({ tags, addTag }) => {     
    return (
      <>
        <div className="dispaly_profile1" style={ { zIndex: "2000 !important" } }>         
          <div className="dispaly_profile_detail1" style={{ minWidth: "300px", maxHeight: "250px", overflow: "auto" }}>
              <div className="display_button1">
                <div className="row" style={{ display: "block" }}> 
                    { tags.map( (data, index) => 
                        (
                            <div onClick={ () => addTag(data.tag, index) } style={{ backgroundColor: data.color,  minWidth:"200px", padding: "4px 10px", color: "#fff", borderRadius: "3px", marginBottom: "10px" }}> {data.tag} </div>
                        ))
                    } 
                </div>
              </div>        
          </div>          
        </div>
      </>
    )
  }
     

export default AddTagPopup
