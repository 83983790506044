import React, { useState, useEffect } from 'react'
import { Button } from 'semantic-ui-react'
import classnames from 'classnames'

const CustomizeGroupButton = ({
  identity,
  top_left,
  top_right,
  bottom_left,
  bottom_right,
  handleGroupBtnData,
  title,
  groupStyle,
  description,
  active = 'btnTopRight'
}) => {
  const [toggleBtn, setToggleBtn] = useState(top_right)
  const [id, setId] = useState(identity)
  const [activeDirection, setActiveDirection] = useState(active)

 

  useEffect(() => {
     
  }, [active])


  const onClickToggle = e => {
    const attr = e.target.getAttribute('name')

    if (attr === 'top_left') {
      setToggleBtn(top_left)
      setId(identity)
      setActiveDirection(attr)

      handleGroupBtnData(toggleBtn, id, attr)
    } else if (attr === 'top_right') {
      setToggleBtn(top_right)
      setId(identity)
      setActiveDirection(attr)

      handleGroupBtnData(toggleBtn, id, attr)
    } else if (attr === 'bottom_left') {
      setToggleBtn(bottom_left)
      setId(identity)
      setActiveDirection(attr)

      handleGroupBtnData(toggleBtn, id, attr)
    } else if (attr === 'bottom_right') {
      setToggleBtn(bottom_right)
      setId(identity)
      setActiveDirection(attr)

      handleGroupBtnData(toggleBtn, id, attr)
    }
  }

  return (
    <div className={`${groupStyle} groupbtn-holder`}>
      <div className="groupbtn-text">
        <p className="groupbtn-title widget-sub-heading">{title}</p>
        {description ? <p className="groupbtn-desc">{description}</p> : ''}
      </div>
      <div className="groupbtn-wrapper">
        <Button
          onClick={onClickToggle}
          name="top_left"
          className={classnames('group-left', {
            activeBtn: active === 'top_left'
          })}
          attached="left"
        >
          {top_left}
        </Button>
        <Button
          onClick={onClickToggle}
          name="top_right"
          attached="right"
          className={classnames('group-left', {
            activeBtn: active === 'top_right'
          })}
        >
          {top_right}
        </Button>
        <Button
          onClick={onClickToggle}
          name="bottom_left"
          className={classnames('group-left', {
            activeBtn: active === 'bottom_left'
          })}
          attached="left"
        >
          {bottom_left}
        </Button>
        <Button
          onClick={onClickToggle}
          name="bottom_right"
          attached="right"
          className={classnames('group-left', {
            activeBtn: active === 'bottom_right'
          })}
        >
          {bottom_right}
        </Button>
      </div>
      
    </div>
  )
}

export default CustomizeGroupButton
