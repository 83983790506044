import React, { Component } from 'react'

import InnerNavigation from '../common/InnerNavigation'

import { withRouter } from "react-router";

import Title from '../common/Title'
import icon from '../assets/images/settingIcon.png'

const titleContent = {
  type: 'image',
  titleOne: icon,
  titleTwo: 'Manage Number'
}

const settingsData = [
  {
    mainSidebar: 'SetCampaign',    
    innerTabs: ['Configure']
  }
]

class SetCampaign extends Component {
   
  state = {
    numId: 0
  }
  

  componentDidMount = () => {
    document.title = 'Manage Number'; 
    const numId = this.props.match.params.id;
    this.setState({numId})
  }
  render() {
    return (
      <div className="fullwidth-container number-container">
        <Title data={titleContent} />
        <div className="numbers-banner-container">
          
          <InnerNavigation
            dataComponent={settingsData}
            activeTab={this.props.activeTab}
            activeComponent={this.props.activeComponent}
            dataTitle={titleContent}
            paramId={this.state.numId}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(SetCampaign) 
