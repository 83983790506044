import React, { useState, useEffect } from 'react'
import { TextArea } from 'semantic-ui-react'
import _ from 'lodash'

import NodeToggle from '../../common/NodeToggle'
import NodeToggle2 from '../../common/NodeToggle2'
import CommonColor from '../../common/CommonColor'
import CommonSelect3 from '../../common/CommonSelect3'
import CommonSelect from '../../common/CommonSelect'
import CommonInput from '../../common/CommonInput'
import { Accordion, Rail, Ref, Sticky } from 'semantic-ui-react'
import CommonButton from '../../common/CommonButtons'

import iconStyle from '../../assets/images/Dashboard 2-07.png'
import callMeBack from '../../assets/images/phone-black.svg'
import callMeBackRound from '../../assets/images/call-back.png'
import callMeLetter from '../../assets/images/mini-clock.png'
import leaveMessage from '../../assets/images/message.png'

import deleteIcon from '../../assets/images/delete-icon.png'
import plusIcon from '../../assets/images/plus.png'

import axios from 'axios'
import { CommonNotify } from '../../common/CommonNotify'

import {
  liveCallBackDisplay,
  lstCustomFieldTypeOptions,
  widgetCustomFieldDisplayOnCallNow,
  modernTitle,
  modernSubTitle,
  contactUs,
  callMeLaterCompanySize,
  callMeLaterMessage
} from '../../lib/WidgetData'
import CommonButtons from '../../common/CommonButtons'
import { useSelector } from 'react-redux'
import Toggle from '../../common/CommonToggle'
import PhoneInput from 'react-phone-input-2'

let widgetDefaultSetting = {
  template: 'Classic'
}

const apiToken = localStorage.getItem('access_token')

const bubbleDefaultSetting = {
  color: '#0080FF',
  shadow: false,
  textOnline: 'Would you like us to call you now ?',
  textOffline: 'Hey there! We are offline now ?',
  position: '',
  labelColor: '#2B2B2C',
  shape: 'default'
}

const bussinessLimecall = [
  'Industry',
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel/Fashion',
  'Architecture/Planning',
  'Arts/Crafts',
  'Automotive',
  'Aviation/Aerospace',
  'Banking/Mortgage',
  'Biotechnology/Greentech',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies/Equipment',
  'Capital Markets/Hedge Fund/Private Equity',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software/Engineering',
  'Computer/Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense/Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment/Movie Production',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Fundraising',
  'Furniture',
  'Gambling/Casinos',
  'Glass/Ceramics/Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design/Web Design',
  'Health/Fitness',
  'Higher Education/Acadamia',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Individual/Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology/IT',
  'Insurance',
  'International Affairs',
  'International Trade/Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management/Hedge Fund/Private Equity',
  'Judiciary',
  'Law Enforcement',
  'Law Practice/Law Firms',
  'Legal Services',
  'Legislative Office',
  'Leisure/Travel',
  'Library',
  'Logistics/Procurement',
  'Luxury Goods/Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing/Advertising/Sales',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Mental Health Care',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Museums/Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers/Journalism',
  'Non-Profit/Volunteering',
  'Oil/Energy/Solar/Greentech',
  'Online Publishing',
  'Other Industry',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Relations/PR',
  'Public Safety',
  'Publishing Industry',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate/Mortgage',
  'Recreational Facilities/Services',
  'Religious Institutions',
  'Renewables/Environment',
  'Research Industry',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing/Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation/Localization',
  'Transportation',
  'Utilities',
  'Venture Capital/VC',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine/Spirits',
  'Wireless',
  'Writing/Editing'
]
const roleLimeCall = [
  'Sale Manager',
  'Marketing / PR Manager',
  'Customer Services Manager',
  'CXO / General Manager',
  'It Manager',
  'Operation Manager',
  'Developer / software Engineer / Analyst',
  'Student / Personal Interest',
  'other'
]
const orgLimecall = [
  '1-10',
  '11-50',
  '51-250',
  '251-1K',
  '1k-5k',
  '5K-10K',
  '10K-50K',
  '50K-100K',
  '100K+'
]

export const WidgetStyleTitle = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconStyle} alt="logo" />
    </div>
    <div className="accordion-title-holder">
      <h2 className="accordion-title">Widget Templates</h2>
      <p className="accordion-description">
       Personalize your website widget templates.
      </p>
    </div>
  </div>
)

export const WidgetStyleContent = ({
  reloadWidget,
  handleTabActive,
  widget,  
  styleRef,
  closeWidgetStyle,
  handleDataRef,
  handleToggleData,
  clickToCallName,
  clickToCallNameToggle,
  clickToCallEmail,
  clickToCallEmailToggle,
  clickToCallTeam,
  clickToCallInterest,
  clickToCallIndustry,
  clickToCallEmployee,
  clickToCallRevenue,
  clickToCallRole,
  clickToCallComment,
  clickToCallTeamToggle,
  clickToCallCustomFields,

  widgetSettDataUpdate,
  widgetStyledDataUpdate,

  clickToCallCustomFieldsToggle,
  lstCustomFields,
  onUpdateCustomeFields,
  handleQualification,
  onAddRemoveCustomeFields,
  onSubmitCustomFields,
  callMeBackName,
  callMeBackEmail,
  callMeBackTeam,
  callMeBackInterest,
  callMeBackIndustry,
  callMeBackEmployee,
  callMeBackRevenue,
  callMeBackRole,
  callMeBackComment,
  callMeBackCustomFields,
  callMeBackCustomFieldsToggle,
  callMeLaterName,
  callMeLaterNameToggle,
  callMeLaterEmail,
  callMeLaterEmailToggle,
  callMeLaterTeam,
  callMeLaterInterest,
  callMeLaterIndustry,
  callMeLaterEmployee,
  callMeLaterRevenue,
  callMeLaterRole,
  callMeLaterComment,
  callMeLaterTeamToggle,
  callMeLaterCustomFields,
  callMeLaterCustomFieldsToggle,
  leaveMessageName,
  leaveMessageEmail,
  leaveMessageTeam,
  leaveMessageInterest,
  leaveMessageIndustry,
  leaveMessageComment,
  leaveMessageEmployee,
  leaveMessageRevenue,
  leaveMessageRole,  
  leaveMessageCustomFields,
  leaveMessageCustomFieldsToggle,
  classicSmart,
  contextRef,
  legalWidget,
  legalToggle,
  socialWidget,
  privacyWidget,
  privacyToggle,
  onClickTableCategory,
  widgetStyleTabs,
  activeIndexWidget,
  handleClickWidget,
  allowedTabs,
  webCallDisplay,
  callMeBackDisplay,
  callMeLaterDisplay,
  leaveMessageDisplay,
  loading,
  fullNameToggle,
  emailToggle,
  onClickAllowedTabs,
  widgetObject,
  intilizeTab
}) => {
  const [buttonColor, setButtonColor] = useState('#662D91')
  const [isInputChange, setIsInputChange] = useState(false)
  

  const [clickToCallTabText, setClickToCallTabText] = useState(
    'Click to connect to agent!'
  )
  const [clickToCallButtonText, setClickToCallButtonText] = useState(
    'Start Call'
  )

  const [callMeLaterTabText, setCallMeLaterTabText] = useState(
    'Would you like us to call you later ?'
  )
  const [callMeLaterButtonText, setCallMeLaterButtonText] = useState('Schedule')

  const [leaveMessageButtonText, setLeaveMessageButtonText] = useState(
    'Send Message'
  )
  const [widgetApiData, setWidgetApiData] = useState(widgetDefaultSetting)
  

  const [legalText, setLegalText] = useState('Terms')

  const [widgetAllSetting, setWidgetALlSetting] = useState(null)
  const [widgetSetting, setWidgetSetting] = useState(widgetDefaultSetting)
  const [bubbleSetting, setBubbleSetting] = useState(bubbleDefaultSetting)
  const [widgetStyleData, setWidgetStyleData] = useState({})
  const [apiLoaded, setApiLoaded] = useState(false)
  const [widgetApiResData, setWidgetApiResData] = useState({})

  const [interestData, setInterestData] = useState([])
  const [teamData, setTeamData] = useState([])
  const teamsData = useSelector((state) => state.home?.teamsData)

  useEffect(() => {
    
    if (!widget.id) return   
    fetchSetting()
     
  }, [widget, widgetObject])

  useEffect(() => {
    if (teamsData){
      const teams = teamsData?.map(team => {
        return team.name
      })
      setTeamData(teams);
    }
  
  }, [teamsData])

  useEffect(() => {
    if (bubbleSetting.position == '') return
    setApiLoaded(true)
  }, [bubbleSetting.position])

  
  const fetchSetting = async () => {  

          
          await setWidgetALlSetting(widgetObject)
          await setWidgetApiData(widgetObject)
          await setWidget(widgetObject)
          await setBubble(widgetObject)
          let widgetData = widgetObject;
          widgetData["full_name_display_on_call_now"] = "on_call_now_screen";
          widgetData["full_name_display_on_digital_calls"] = "before_call";
          widgetData["full_name_display_on_call_later"] = "before_schedule";
          widgetData["email_display_on_call_now"] = "on_call_now_screen";
          widgetData["email_display_on_digital_calls"] = "before_call";
          widgetData["email_display_on"] = "before_schedule";
          widgetData["email_display_on_call_later"] = "before_schedule";

         
          setInterestData(widgetObject.interested)
         

          await setWidgetStyleData(widgetData)
          await setWidgetApiResData(widgetData)  
           
           handleTabActive()   
           
           
          
             
  }

  const postSetting = data => {
    loading(true)
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/set-customizations`
    return axios.post(url, data)
    
  }
  const templateSetting = data => {
    loading(true)
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/callback-template-config`
      return axios.put(url, data)
    
 
    
  }

  const setWidget = data => {
    let widgetSettingData = { ...widgetSetting }
    const dataTemplateType = parseInt(data.template_type);
    if (dataTemplateType == 2) {
      widgetSettingData.template = 'Smart'
    } else if (dataTemplateType == 1) {
      widgetSettingData.template = 'Classic'
    } else if (dataTemplateType == 3) {
      widgetSettingData.template = 'Modern'
    }

    widgetSettingData.classicType =
      data.classic_template_design_type == 1 ? false : true
    widgetSettingData.font = data.font

    widgetSettingData.timerColor = data.popup_timer_color
    widgetSettingData.callColor = data.submit_request_call_color

    widgetSettingData.socialProof = data.social_proof
    widgetSettingData.legalNote = data.terms_and_conditions
    widgetSettingData.legalType = data.terms_type
    widgetSettingData.legalStatus = data.terms_and_conditions_status
    widgetSettingData.privateStatus = data.privacy_note_status
    widgetSettingData.privatePolicy = data.privacy_note

    if (widgetSettingData.legalType == 0) {
      widgetSettingData.legalType = 'Text'
    } else if (widgetSettingData.legalType == 1) {
      widgetSettingData.legalType = 'URL'
    } else {
      widgetSettingData.legalType = 'HTML'
    }

    widgetSettingData.webCall = data.digital_call
    widgetSettingData.callLeter = data.call_me_later
    widgetSettingData.callBack = data.call_now
    widgetSettingData.leaveMessage = data.leave_message

    widgetSettingData.webCallHeader = data.digital_call_tab_text
    widgetSettingData.webCallButton = data.digital_call_button_text
    widgetSettingData.webCallName = data.full_name_in_digital_calls_tab
    widgetSettingData.webCallEmail = data.email_in_digital_calls_tab    
    widgetSettingData.webCallTeam = data.team_in_digital_calls_tab

    widgetSettingData.callLeterHeader = data.schedule_call_tab_text
    widgetSettingData.callLeterButton = data.offline_schedule_call_message
    widgetSettingData.callLeterName = data.full_name_in_call_later_tab

    widgetSettingData.callLeterEmail = data.email_in_call_later_tab
    widgetSettingData.callLeterTeam = data.team_in_call_later_tab

    widgetSettingData.callBackHeader = data.widget_text
    widgetSettingData.callBackButton = data.submit_request_call_text
    widgetSettingData.callBackName = data.full_name_in_call_now_tab
    widgetSettingData.callBackEmail = data.email_in_call_now_tab
    widgetSettingData.callBackTeam = data.team_in_call_now_tab

    widgetSettingData.callBackCompHeader = data.headline_in_callback_template
    widgetSettingData.callBackCompButton = data.cta_btn_callback_template
    widgetSettingData.callBackCompName = data.name_in_callback_template
    widgetSettingData.callBackCompEmail = data.email_in_callback_template
    widgetSettingData.callBackCompTeam = data.team_in_call_now_tab
    widgetSettingData.callBackCompComment = data.comment_in_callback_template




    widgetSettingData.leaveMessageHeader = data.leave_message_tab_text
    widgetSettingData.leaveMessageName = data.full_name_in_leave_message_tab
    widgetSettingData.leaveMessageEmail = data.email_in_leave_message_tab
    widgetSettingData.leaveMessageTeam = data.team_in_leave_message

    setWidgetSetting(widgetSettingData)
    setWidgetApiData(widgetSettingData)
  }

  const submitWidget = () => {
    const data = widgetStyleData        

    if(data.shape === 'btnRight'){
      data.shape = 'square'
    }else{
      data.shape = 'default'
    }    
    if(parseInt(widgetStyleData.template_type) === parseInt(6)){
      templateSetting(data)
    }
    postSetting(data)
      .then(res => {
        reloadWidget()
        loading(false)
        if (res.data.message[0] === 'Successfully') {
          CommonNotify('Updated Widget Setting', 'success')
          handleTabActive()
          setIsInputChange(false)
          reloadWidget()
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })
  }

  const resetWidget = data => {
    let widgetSetting = { ...widgetAllSetting }
    widgetSetting.template_type = 3
    if (data.template == 'Smart') {
      widgetSetting.template_type = 2
    } else if (data.template == 'Classic') {
      widgetSetting.template_type = 1
    }


    widgetSetting.classic_template_design_type =
    data.classicType == true ? 1 : 0
      

    widgetSetting.font = data.font
    widgetSetting.popup_timer_color = data.timerColor
    widgetSetting.submit_request_call_color = data.callColor

    widgetSetting.social_proof = data.socialProof
    widgetSetting.terms_and_conditions = data.legalNote
    widgetSetting.terms_type = data.legalType
    widgetSetting.terms_and_conditions_status = data.legalStatus
    widgetSetting.privacy_note_status = data.privateStatus
    widgetSetting.privacy_note = data.privatePolicy

    if (widgetSetting.terms_type == 'Text') {
      widgetSetting.terms_type = 0
    } else if (widgetSetting.terms_type == 'URL') {
      widgetSetting.terms_type = 1
    } else {
      widgetSetting.terms_type = 2
    }

    widgetSetting.digital_call = data.webCall
    widgetSetting.call_me_later = data.callLeter
    widgetSetting.call_now = data.callBack
    widgetSetting.leave_message = data.leaveMessage

    widgetSetting.digital_call_tab_text = data.webCallHeader
    widgetSetting.digital_call_button_text = data.webCallButton
    widgetSetting.full_name_in_digital_calls_tab = data.webCallName
    widgetSetting.email_in_digital_calls_tab = data.webCallEmail
    widgetSetting.team_in_digital_calls_tab = data.webCallTeam

    widgetSetting.schedule_call_tab_text = data.callLeterHeader
    widgetSetting.offline_schedule_call_message = data.callLeterButton
    widgetSetting.full_name_in_call_later_tab = data.callLeterName

    widgetSetting.email_in_call_later_tab = data.callLeterEmail
    widgetSetting.team_in_call_later_tab = data.callLeterTeam

    widgetSetting.widget_text = data.callBackHeader
    widgetSetting.submit_request_call_text = data.callBackButton
    widgetSetting.full_name_in_call_now_tab = data.callBackName
    widgetSetting.email_in_call_now_tab = data.callBackEmail
    widgetSetting.team_in_call_now_tab = data.callBackTeam


    widgetSetting.headline_in_callback_template = data.callBackCompHeader
    widgetSetting.cta_btn_callback_template = data.callBackCompButton
    widgetSetting.name_in_callback_template = data.callBackCompName
    widgetSetting.email_in_callback_template = data.callBackCompEmail
    widgetSetting.comment_in_callback_template = data.callBackCompTeam

    widgetSetting.leave_message_tab_text = data.leaveMessageHeader
    widgetSetting.full_name_in_leave_message_tab = data.leaveMessageName
    widgetSetting.email_in_leave_message_tab = data.leaveMessageEmail
    widgetSetting.team_in_leave_message = data.leaveMessageTeam
    setWidgetALlSetting(widgetSetting)
    return widgetSetting
  }

  const changeWidget = (index, value) => {

     
    let widgetSettingNew = { ...widgetSetting }
    let widgetStyleDataNew = { ...widgetStyleData }
    if (value === true) {
      value = 1
    }
    if (value === false) {
      value = 0
    }
    widgetSettingNew[index] = value
    widgetStyleDataNew[index] = value   
    if (index == 'template_type'){
      widgetSettingNew['template_type'] = String(value)
      widgetStyleDataNew['template_type'] = String(value)
      if (value == 1) widgetSettingNew['template'] = 'Classic'      
      else if (value == 2) widgetSettingNew['template'] = 'Smart'
      else if (value == 3) widgetSettingNew['template'] = 'Modern'
    }
    setWidgetSetting({ ...widgetSettingNew })
    setWidgetStyleData({ ...widgetStyleDataNew })

    widgetSettDataUpdate({ ...widgetSettingNew })
    widgetStyledDataUpdate({...widgetStyleDataNew})

    const check = _.isEqual(widgetSettingNew, widgetApiData)

    if (check === false) {
      setIsInputChange(true)
    } else {
      setIsInputChange(false)
    }
  }

  const changeWidgetToggle = async (index, value) => {  
    
     loading(true)

    let widgetStyleDataNew = { ...widgetStyleData }    


    if(index == 'privacy_note_status' || index == 'call_me_later' || index == 'call_now' || index == 'digital_call'){
      widgetStyleDataNew[index] = value;
    } else {
      widgetStyleDataNew[index] = value ? 1 : 0
    }   

    
    if(widgetStyleDataNew.shape === 'btnRight'){
      widgetStyleDataNew.shape = 'square'
    }else{
      widgetStyleDataNew.shape = 'default'
    }

    await setWidgetStyleData({ ...widgetStyleDataNew })   
    widgetStyledDataUpdate({...widgetStyleDataNew})

    if(index?.includes('template')){

      templateSetting(widgetStyleDataNew).then(res => {
         reloadWidget()
         loading(false)
         if (res.data.message[0] === 'Successfully') {
           CommonNotify('Updated Widget Setting', 'success')         
         }
       })
       .catch(error => {
         loading(false)      
       })
    }
     else{ postSetting(widgetStyleDataNew).then(res => {
         reloadWidget()
         loading(false)
         if (res.data.message[0] === 'Successfully') {
           CommonNotify('Updated Widget Setting', 'success')         
         }
       })
       .catch(error => {
         loading(false)      
       })
     }
   }

  const setBubble = data => {
    let bubbleSetting1 = { ...bubbleSetting }

    bubbleSetting1.shadow = data.bubble_template == 2 ? true : false
    bubbleSetting1.color = data.circle_color
    bubbleSetting1.textOffline = data.bubble_offline_text
    bubbleSetting1.textOnline = data.bubble_text

    if (data.bubble_position == 'bottom_right') {
     
    } else if (data.bubble_position == 'bottom_left') {
    
    } else if (data.bubble_position == 'top_right') {
    
    } else if (data.bubble_position == 'top_left') {
     
    }

    if (data.shape == 'square') {
      data.shape = 'btnRight'
    } else {
      data.shape = 'btnLeft'
    }

    bubbleSetting1.position = data.bubble_position
    bubbleSetting1.shape = data.shape

    setBubbleSetting(bubbleSetting1)
  }
  const Options = ['Classic', 'Smart', 'Modern', 'Callback']
  const termOption = ['Text', 'URL', 'HTML']
  const popup_font = [
    'Roboto Slab',
    'Karma',
    'acumin-pro',
    'objektiv',
    'museo-sans',
    'Roboto',
    'Source Sans',
    'poppins',
    'new-hero',
    'proxima-nova',
    'expressway'   
  ]
  const onCancel =() => {
    setWidgetStyleData(widgetApiResData)
    setIsInputChange(false)
  } 
 
  return (
    <div className="style-widget-wrapper accordion-widget">
      <div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">Theme</h3>

        <CommonSelect
          name="popupFont"
          className="popup-font-select"
          placeholder="Select Template"
          options={Options}
          value={
            parseInt(widgetStyleData.template_type) === parseInt(1)
              ? 'Classic'
              : parseInt(widgetStyleData.template_type) === parseInt(2)
              ? 'Smart'
              : parseInt(widgetStyleData.template_type) === parseInt(3)
              ? 'Modern'
              :
              parseInt(widgetStyleData.template_type) === parseInt(6)?
              'Callback'
              : 'Ultimate'
          }
          onChange={(p1, p2, p3) => {
            changeWidget(
              'template_type',
              p2.value == 'Classic' ? 1 : p2.value === 'Smart' ? 2 : p2.value === 'Modern' ? 3 : p2.value === 'Callback' ?6:0
              )
          }
          }
        />

<CommonButton
              onClick={submitWidget}
              type="button"
              content="Save"
              background="blue"
            />
    
      </div>
    {parseInt(widgetStyleData.template_type) === parseInt(3) && 
    
    <><div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">Modern Header Title</h3>
        <div className="header-swich">
          <CommonInput
            className="popup-font-select"
                    placeholder="Call Me Now"
                    name="call-me-now"
                    type="text"
                    value={
                      widgetStyleData.modern_header_title

                        ? widgetStyleData.modern_header_title

                        : null
                    }
                    onChange={event =>
                      changeWidget(
                        'modern_header_title',
                        event.target.value
                      )
              }
            />
          <NodeToggle
            handleDataRef={e => changeWidgetToggle('modern_header_title_status', e)}
            dataToggle={modernTitle[0]}
            activeDefault={widgetStyleData.modern_header_title_status}
          />
        </div>                  
                    
      </div>
      <div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">Modern Header Subtitle</h3>
        <div className="header-swich">
        <CommonInput
               className="popup-font-select"
                        placeholder="Call Me Now"
                        name="call-me-now"
                        type="text"
                        value={
                          widgetStyleData.modern_header_subtitle

                            ? widgetStyleData.modern_header_subtitle

                            : null
                        }
                        onChange={event =>
                          changeWidget(
                            'modern_header_subtitle',
                            event.target.value
                          )
                        }
                      />
                       <NodeToggle
            handleDataRef={e => changeWidgetToggle('modern_header_subtitle_status', e)}
            dataToggle={modernSubTitle[0]}
            activeDefault={widgetStyleData.modern_header_subtitle_status}
          />
            </div>        
                    
      </div>
      </>
      }


      <div className="popup-font theme-selector">
        {parseInt(widgetStyleData.template_type) === parseInt(1) && (
          <>
            <h3 className="call-title widget-sub-heading">Widget Design Type</h3>

            <CommonSelect
              name="popupFont"
              className="popup-font-select"
              placeholder="Select Template"
              options={['Popup / modal style', 'Slide extension style']}
              value={
                widgetStyleData.classic_template_design_type === 0
                  ? 'Popup / modal style'
                  : 'Slide extension style'
              }
              onChange={(p1, p2, p3) =>
                changeWidget(
                  'classic_template_design_type',
                  p2.value === 'Popup / modal style' ? 0 : 1
                )
              }
            />
          </>
        )}
      </div>

     
      {parseInt(widgetStyleData.template_type) !== parseInt(6) && 
      <section className="card-manage-number">
          <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
            {/* <p className="style-widget-title style-switch widget-sub-heading">Pre-Call Form</p> */}
            <NodeToggle
              handleDataRef={e => changeWidgetToggle('digital_call', e)}
              dataToggle={webCallDisplay[0]}
              activeDefault={widgetStyleData.digital_call ? true : false}
            />
          </div>
          


          {widgetStyleData.digital_call ? (
            <>
              
              {widgetStyleData.template_type !== parseInt(1) ? (

<>
<>

{false &&
                    <>
              <div className="call-tab-text">
                  <h3 className="call-title widget-sub-heading">Modern Header</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"
                  
                    value={widgetStyleData.modern_header_title}
                    onChange={event =>
                      changeWidget(
                        'modern_header_title',
                        event.target.value
                      )
                    }
                  />
                </div>
                <div>
                    <div className="call-tab-text">
                  <h3 className="call-title widget-sub-heading">Sub Modern Header</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"
                  
                    value={widgetStyleData.modern_header_subtitle}
                    onChange={event =>
                      changeWidget(
                        'modern_header_subtitle',
                        event.target.value
                      )
                    }
                  />
                  </div>
                  </div>
                  </>
}
</>

                  <div className="call-tab-text">
              
                  <h3 className="call-title widget-sub-heading">{parseInt(widgetStyleData.template_type)  === parseInt(3) ?"Button Title":"Header text"}</h3>
               
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"
                  
                    value={widgetStyleData.digital_call_tab_text}
                    onChange={event =>
                      changeWidget(
                        'digital_call_tab_text',
                        event.target.value
                      )
                    }
                  />
                </div>
                </>
                ) : (
                  <div>
                    {widgetStyleData.digital_call && (
                      <div className="call-tab-text">
                        <h3 className="call-title widget-sub-heading">Header text</h3>
                        <CommonInput
                          placeholder="Do you want us to call you in 28 seconds?"
                          name="call-me-now"
                          type="text"
                        
                          value={widgetStyleData.digital_call_tab_text}
                          onChange={event =>
                            changeWidget(
                              'digital_call_tab_text',
                              event.target.value
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
                {widgetStyleData.template_type !== parseInt(1) ? (
                  <div>
                  {widgetSetting.webCall ? (
                    <div className="call-tab-text">
                      <h3 className="call-title widget-sub-heading">{parseInt(widgetStyleData.template_type)===parseInt(3) ? "Button Subtitle":"Call to action text"} </h3>
                      {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '}  */}
                     
                      <CommonInput
                        placeholder="Connect"
                        name="click-to-call"
                        type="text"                     
                        value={
                          parseInt(widgetStyleData.template_type) === parseInt(6) ? widgetStyleData.cta_btn_callback_template:widgetStyleData.digital_call_button_text
                            ? widgetStyleData.digital_call_button_text
                            : null
                        }
                        onChange={event =>
                          changeWidget(
                            parseInt(widgetStyleData.template_type) === parseInt(6) ? 'cta_btn_callback_template':  'digital_call_button_text',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  ) : null }
                </div>
                ) : (
                  <div>
                    {widgetSetting.webCall && (
                      <div className="call-tab-text">
                        <h3 className="call-title widget-sub-heading">Call to action text</h3>
                        {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '}  */}
                     
                        <CommonInput
                          placeholder="Connect"
                          name="click-to-call"
                          type="text"
                        
                          value={
                            widgetStyleData.digital_call_button_text
                              ? widgetStyleData.digital_call_button_text
                              : null
                          }
                          onChange={event =>
                            changeWidget(
                              'digital_call_button_text',
                              event.target.value
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                <h3 className="call-title widget-sub-heading">Pre-call Form </h3>
                      <p className="accordion-description" style={{color: '#9a9a9a'}}> If enabled, this form is shown before audio calls start. </p>{' '} 
                     

                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle('full_name_in_digital_calls_tab', e)
                      }
                      dataToggle={clickToCallName[0]}
                      activeDefault={widgetStyleData.full_name_in_digital_calls_tab}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle('email_in_digital_calls_tab', e)
                      }
                      dataToggle={clickToCallEmail[0]}
                      activeDefault={widgetStyleData.email_in_digital_calls_tab}
                      dataToggleActive={widgetStyleData.email_in_digital_calls_tab}
                      emailToggle={emailToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle('team_in_digital_calls_tab', e)
                      }
                      dataToggle={clickToCallTeam[0]}
                      activeDefault={widgetStyleData.team_in_digital_calls_tab}
                      dataToggleActive={widgetStyleData.team_in_digital_calls_tab}
                    />
                  </div>
                </div>   


                {/* <div className="display-content">
                  <p className="style-widget-title widget-sub-heading">Interest</p>
                  <NodeToggle
                    handleDataRef={e =>
                      changeWidgetToggle('interest_in_digital_calls_tab', e)
                    }
                    dataToggle={clickToCallInterest[0]}
                    activeDefault={widgetStyleData.interest_in_digital_calls_tab}
                    dataToggleActive={widgetStyleData.interest_in_digital_calls_tab}
                  />
                </div> */}

                {/* <div className="display-content">
                  <p className="style-widget-title widget-sub-heading">Industry</p>
                  <NodeToggle
                    handleDataRef={e =>
                      changeWidgetToggle('industry_in_digital_calls_tab', e)
                    }
                    dataToggle={clickToCallIndustry[0]}
                    activeDefault={widgetStyleData.industry_in_digital_calls_tab}                 
                  />
                </div>

                <div className="display-content">
                  <p className="style-widget-title widget-sub-heading">Employees</p>
                  <NodeToggle
                    handleDataRef={e =>
                      changeWidgetToggle('employee_in_digital_calls_tab', e)
                    }
                    dataToggle={clickToCallEmployee[0]}
                    activeDefault={widgetStyleData.employee_in_digital_calls_tab}
                    dataToggleActive={widgetStyleData.employee_in_digital_calls_tab}
                  />
                </div> */}

                {/* <div className="display-content">
                  <p className="style-widget-title widget-sub-heading">Budget</p>
                  <NodeToggle
                    handleDataRef={e =>
                      changeWidgetToggle('revenue_in_digital_calls_tab', e)
                    }
                    dataToggle={clickToCallRevenue[0]}
                    activeDefault={widgetStyleData.revenue_in_digital_calls_tab}
                    dataToggleActive={widgetStyleData.revenue_in_digital_calls_tab}
                  />
                </div>

                <div className="display-content">
                  <p className="style-widget-title widget-sub-heading">Role</p>
                  <NodeToggle
                    handleDataRef={e =>
                      changeWidgetToggle('role_in_digital_calls_tab', e)
                    }
                    dataToggle={clickToCallRole[0]}
                    activeDefault={widgetStyleData.role_in_digital_calls_tab}
                    dataToggleActive={widgetStyleData.role_in_digital_calls_tab}
                  />
                </div>

                <div className="display-content">
                  <p className="style-widget-title widget-sub-heading">Comments</p>
                  <NodeToggle
                    handleDataRef={e =>
                      changeWidgetToggle('comment_in_digital_calls_tab', e)
                    }
                    dataToggle={clickToCallComment[0]}
                    activeDefault={widgetStyleData.comment_in_digital_calls_tab}
                    dataToggleActive={widgetStyleData.comment_in_digital_calls_tab}
                  />
                </div> */}


              
                {/* {clickToCallCustomFieldsToggle && (
                  <div className="custom-fields-wrapper">
                    {lstCustomFields.map((item, index) => {
                      return (
                        <div className="custom-fields-head" key={index}>
                          <CommonSelect
                            isGray
                            name="fieldType"
                            options={lstCustomFieldTypeOptions}
                            defaultValue={
                              item.fieldType || lstCustomFieldTypeOptions[1]
                            }
                            onChange={(e, result) =>
                              onUpdateCustomeFields(index, result)
                            }
                          />
                          <CommonInput
                            placeholder="Label"
                            name="label"
                            type="text"
                            onChange={(e, result) =>
                              onUpdateCustomeFields(index, result)
                            }
                          />
                          <CommonInput
                            placeholder="values comma separated"
                            name="value"
                            type="text"
                            onChange={(e, result) =>
                              onUpdateCustomeFields(index, result)
                            }
                          />
                          <NodeToggle
                            handleDataRef={value => {
                              handleQualification(
                                index,
                                'showQualificationButton',
                                value
                              )
                            }}
                            dataToggle={{
                              callTitle: 'Enabled',
                              callId: `eQualification${index}`,
                              callRef: `showEQualificationButton${index}`
                            }}
                          />
                          <NodeToggle
                            handleDataRef={value => {
                              handleQualification(
                                index,
                                'showRequiredQualificationButton',
                                value
                              )
                            }}
                            dataToggle={{
                              callTitle: 'Required',
                              callId: `rQualification${index}`,
                              callRef: `showRQualificationButton${index}`
                            }}
                          />
                          <CommonSelect
                            isGray
                            name="displayOnCallMeNowWidget"
                            options={widgetCustomFieldDisplayOnCallNow}
                            defaultValue={
                              item.displayOnCallMeNowWidget ||
                              lstCustomFieldTypeOptions[0]
                            }
                            onChange={(e, result) =>
                              onUpdateCustomeFields(index, result)
                            }
                          />
                          <div>
                            {index === 0 ? (
                              <img
                                src={plusIcon}
                                height="15"
                                alt="logo"
                                onClick={() => onAddRemoveCustomeFields({ item })}
                              />
                            ) : null}
                            <img
                              src={deleteIcon}
                              height="15"
                              alt="logo"
                              onClick={() => onAddRemoveCustomeFields({ index })}
                            />
                          </div>
                        </div>
                      )
                    })}
                    <div className="save-cancel">
                      <CommonButton
                        content="Save"
                        btnClass="btn-blue"
                        onClick={() => onSubmitCustomFields()}
                      />
                      <CommonButtons
                        onClick={onCancel}
                        type="reset"
                        content="Cancel"
                        background="grey"
                      />
                    </div>
                  </div>
                )} */}

            </>
          ) : null}
      </section>}


      

      {parseInt(widgetStyleData.template_type) === parseInt(2) || parseInt(widgetStyleData.template_type) === parseInt(6)  ? (
        <div></div>
      ) : ( 


        <section className="card-manage-number">
         <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
          {/* <p className="style-widget-title style-switch widget-sub-heading">Pre-Call Form</p> */}
          <NodeToggle
            handleDataRef={e => changeWidgetToggle('call_now', e)}
            dataToggle={callMeBackDisplay[0]}
            activeDefault={widgetStyleData.call_now}
          />
        </div>

        { widgetStyleData.call_now ? (
        <>
        {parseInt(widgetStyleData.template_type) === parseInt(2)  || parseInt(widgetStyleData.template_type) === parseInt(6)? (
            <div></div>
        ) : (

           <>
           <div>
                {parseInt(widgetStyleData.template_type) === parseInt(3) &&
               
                    <div className="call-tab-text">
                  <h3 className="call-title widget-sub-heading">Modern Header</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"
                  
                    value={widgetStyleData.modern_header_title}
                    onChange={event =>
                      changeWidget(
                        'modern_header_title',
                        event.target.value
                      )
                    }
                  />
                   </div>
}
</div>
<div>
{false &&
                  <div className="call-tab-text">
                  <h3 className="call-title widget-sub-heading">Sub Modern Header</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"
                  
                    value={widgetStyleData.modern_header_subtitle}
                    onChange={event =>
                      changeWidget(
                        'modern_header_subtitle',
                        event.target.value
                      )
                    }
                  />
                  </div>
               
}
</div>

                 
                    <div className="call-tab-text">
                     
                    <h3 className="call-title widget-sub-heading">{parseInt(widgetStyleData.template_type)  === parseInt(3) ?"Button Title":"Header text"}</h3>
                      {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                      <CommonInput
                        placeholder="Do you want us to call you in 28 seconds?"
                        name="call-me-back"
                        type="text"
                      
                        value={
                          widgetStyleData.widget_text
                            ? widgetStyleData.widget_text
                            : null
                        }
                        onChange={event =>
                          changeWidget('widget_text', event.target.value)
                        }
                      />
                    </div>                 
                  
                    <div className="call-tab-text">
                     
                      <h3 className="call-title widget-sub-heading">Call to action text</h3>
                      {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                      */}
                      <CommonInput
                        placeholder="Call Me Now"
                        name="call-me-now"
                        type="text"
                        value={
                          widgetStyleData.submit_request_call_text
                            ? widgetStyleData.submit_request_call_text
                            : null
                        }
                        onChange={event =>
                          changeWidget(
                            'submit_request_call_text',
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                    <h3 className="call-title widget-sub-heading">Pre-call Form </h3>
                      <p className="accordion-description" style={{color: '#9a9a9a'}}> If enabled, this form is shown before audio calls start. </p>{' '} 
                        


                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle('full_name_in_call_now_tab', e)
                          }
                          dataToggle={callMeBackName[0]}
                          activeDefault={widgetStyleData.full_name_in_call_now_tab}
                          fullNameToggle={fullNameToggle}
                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle('email_in_call_now_tab', e)
                          }
                          dataToggle={callMeBackEmail[0]}
                          activeDefault={widgetStyleData.email_in_call_now_tab}
                          fullNameToggle={fullNameToggle}
                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle('team_in_call_now_tab', e)
                          }
                          dataToggle={callMeBackTeam[0]}
                          activeDefault={widgetStyleData.team_in_call_now_tab}
                          fullNameToggle={fullNameToggle}
                        />
                      </div>
                    </div>    
                  {/* <div className="display-content">
                    <p className="style-widget-title widget-sub-heading">Interest</p>
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle('interest_in_call_now_tab', e)
                      }
                      dataToggle={callMeBackInterest[0]}
                      activeDefault={widgetStyleData.interest_in_call_now_tab}
                    />
                  </div> */}



                  {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Industry</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('industry_in_call_now_tab', e)
                  }
                  dataToggle={callMeBackIndustry[0]}
                  activeDefault={widgetStyleData.industry_in_call_now_tab}              
                />
              </div> */}

              {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Employees</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('employee_in_call_now_tab', e)
                  }
                  dataToggle={callMeBackEmployee[0]}
                  activeDefault={widgetStyleData.employee_in_call_now_tab}                  
                />
              </div> */}

              {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Budget</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('revenue_in_call_now_tab', e)
                  }
                  dataToggle={callMeBackRevenue[0]}
                  activeDefault={widgetStyleData.revenue_in_call_now_tab}                 
                />
              </div> */}

              {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Role</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('role_in_call_now_tab', e)
                  }
                  dataToggle={callMeBackRole[0]}
                  activeDefault={widgetStyleData.role_in_call_now_tab}                 
                />
              </div>

              <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Comments</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('comment_in_call_now_tab', e)
                  }
                  dataToggle={callMeBackComment[0]}
                  activeDefault={widgetStyleData.comment_in_call_now_tab}                
                />
              </div> */}
                
                  {/* {callMeBackCustomFieldsToggle && (
                    <div className="custom-fields-wrapper">
                      {lstCustomFields.map((item, index) => {
                        return (
                          <div className="custom-fields-head" key={index}>
                            <CommonSelect
                              isGray
                              name="fieldType"
                              options={lstCustomFieldTypeOptions}
                              defaultValue={
                                item.fieldType || lstCustomFieldTypeOptions[1]
                              }
                              onChange={(e, result) =>
                                onUpdateCustomeFields(index, result)
                              }
                            />
                            <CommonInput
                              placeholder="Label"
                              name="label"
                              type="text"
                              onChange={(e, result) =>
                                onUpdateCustomeFields(index, result)
                              }
                            />
                            <CommonInput
                              placeholder="values comma separated"
                              name="value"
                              type="text"
                              onChange={(e, result) =>
                                onUpdateCustomeFields(index, result)
                              }
                            />
                            <NodeToggle
                              handleDataRef={value => {
                                handleQualification(
                                  index,
                                  'showQualificationButton',
                                  value
                                )
                              }}
                              dataToggle={{
                                callTitle: 'Enabled',
                                callId: `eQualification${index}`,
                                callRef: `showEQualificationButton${index}`
                              }}
                            />
                            <NodeToggle
                              handleDataRef={value => {
                                handleQualification(
                                  index,
                                  'showRequiredQualificationButton',
                                  value
                                )
                              }}
                              dataToggle={{
                                callTitle: 'Required',
                                callId: `rQualification${index}`,
                                callRef: `showRQualificationButton${index}`
                              }}
                            />
                            <CommonSelect
                              isGray
                              name="displayOnCallMeNowWidget"
                              options={widgetCustomFieldDisplayOnCallNow}
                              defaultValue={
                                item.displayOnCallMeNowWidget ||
                                lstCustomFieldTypeOptions[0]
                              }
                              onChange={(e, result) =>
                                onUpdateCustomeFields(index, result)
                              }
                            />
                            <div>
                              {index === 0 ? (
                                <img
                                  src={plusIcon}
                                  height="15"
                                  alt="logo"
                                  onClick={() =>
                                    onAddRemoveCustomeFields({ item })
                                  }
                                />
                              ) : null}
                              <img
                                src={deleteIcon}
                                height="15"
                                alt="logo"
                                onClick={() =>
                                  onAddRemoveCustomeFields({ index })
                                }
                              />
                            </div>
                          </div>
                        )
                      })}
                      <div className="save-cancel">
                        <CommonButton
                          content="Save"
                          btnClass="btn-blue"
                          onClick={() => onSubmitCustomFields()}
                        />
                        <CommonButtons
                          onClick={onCancel}
                          type="reset"
                          content="Cancel"
                          background="grey"
                        />
                      </div>
                    </div>
                  )} */}
           </>
        ) }
         </>) : null}

        </section>          
      )}   
    

      


      <section className="card-manage-number">
        <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
          {/* <p className="style-widget-title style-switch widget-sub-heading">Pre-Call Form</p> */}
          <NodeToggle
            handleDataRef={e => changeWidgetToggle('call_me_later', e)}
            dataToggle={callMeLaterDisplay[0]}
            activeDefault={widgetStyleData.call_me_later}
          />
        
        </div>
        <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
          {/* <p className="style-widget-title style-switch widget-sub-heading">Pre-Call Form</p> */}
          <NodeToggle
            handleDataRef={e => changeWidgetToggle('call_now', e)}
            dataToggle={liveCallBackDisplay[0]}
            activeDefault={widgetStyleData.call_now}
          />
        
        </div>
        {widgetStyleData.call_me_later ? (
           <>
           
           {widgetStyleData.template_type !== parseInt(1) ? (
                <div>
                <div className="call-tab-text">
                <h3 className="call-title widget-sub-heading">{parseInt(widgetStyleData.template_type) === parseInt(6) ? "Headline Text" :
                  parseInt(widgetStyleData.template_type)  === parseInt(3) ?"Button Title":"Header text"}</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to schedule a meeting </p>{' '}
                   */}
                 
                  <CommonInput
                    placeholder="Would you like us to call you later ?"
                    name="call-me-now"
                    type="text"
                    defaultValue={ parseInt(widgetStyleData.template_type) === parseInt(6) ?widgetSetting.headline_in_callback_template: widgetSetting.schedule_call_tab_text}
                    value={
                      parseInt(widgetStyleData.template_type) === parseInt(6) ?widgetSetting.headline_in_callback_template: widgetStyleData.schedule_call_tab_text
                        ? widgetStyleData.schedule_call_tab_text
                        : null
                    }
                    onChange={event =>
                      changeWidget(
                        parseInt(widgetStyleData.template_type) === parseInt(6) ?'headline_in_callback_template': 'schedule_call_tab_text',
                        event.target.value
                      )
                    }
                  />
                </div>
               {parseInt(widgetStyleData.template_type) === parseInt(6) &&  <div className="call-tab-text">
                  <h3 className="call-title widget-sub-heading">Subheadline Text</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to schedule a meeting </p>{' '}
                   */}
                 
                  <CommonInput
                    placeholder="Would you like us to call you later ?"
                    name="call-me-now"
                    type="text"
                    defaultValue={widgetSetting.sub_headline_in_callback_template}
                    value={
                      widgetStyleData.sub_headline_in_callback_template
                        ? widgetStyleData.sub_headline_in_callback_template
                        : null
                    }
                    onChange={event =>
                      changeWidget(
                        'sub_headline_in_callback_template',
                        event.target.value
                      )
                    }
                  />
                </div>}

              </div>
              ) : (
                <div>
                  <div className="call-tab-text">
                    
                    <h3 className="call-title widget-sub-heading">Header text</h3>
                    {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to schedule a meeting </p>{' '}
                    */}                    
                    
                    <CommonInput
                      placeholder="Would you like us to call you later ?"
                      name="call-me-now"
                      type="text"
                      defaultValue={widgetSetting.offline_schedule_call_message}
                      value={
                        widgetStyleData.schedule_call_tab_text
                          ? widgetStyleData.schedule_call_tab_text
                          : null
                      }
                      onChange={event =>
                        changeWidget(
                          'schedule_call_tab_text',
                          event.target.value
                        )
                      }
                    />
                  </div>
                </div>
              )}
                      {widgetStyleData.template_type === parseInt(1) ? (
               <div>
               <div className="call-tab-text">
                 <h3 className="call-title widget-sub-heading">Call to action text</h3>
                 {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                   */}
                 <CommonInput
                   placeholder="Call Me Now"
                   name="call-me-now"
                   type="text"
                   
                   value={
                     parseInt(widgetStyleData.template_type) === parseInt(6) ?widgetSetting.cta_btn_callback_template:  widgetStyleData.offline_schedule_call_message
                       ? widgetStyleData.call_latter_button
                       : null
                   }
                   onChange={event =>
                     changeWidget(
                       parseInt(widgetStyleData.template_type) === parseInt(6)?'cta_btn_callback_template':'offline_schedule_call_message',
                       event.target.value
                     )
                   }
                 />
               </div>
             </div>
              ) : (
                <div>
                  <div className="call-tab-text">
                    <h3 className="call-title widget-sub-heading">Call to action text</h3>
                    {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                      */}
                    <CommonInput
                      placeholder="Call Me Now"
                      name="call-me-now"
                      type="text"
                      
                      value={
                        parseInt(widgetStyleData.template_type) === parseInt(6) ?widgetSetting.cta_btn_callback_template:  widgetStyleData.offline_schedule_call_message
                          ? widgetStyleData.offline_schedule_call_message
                          : null
                      }
                      onChange={event =>
                        changeWidget(
                          parseInt(widgetStyleData.template_type) === parseInt(6)?'cta_btn_callback_template':'offline_schedule_call_message',
                          event.target.value
                        )
                      }
                    />
                  </div>
                </div>
              )}

              <>

              <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>  
              <h3 className="call-title widget-sub-heading">Lead form </h3>
                      <p className="accordion-description" style={{color: '#9a9a9a'}}> This form collects user data when scheduling a meeting.</p>{' '} 
                          
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(widgetStyleData.template_type === parseInt(6) ? `name_in_callback_template` : 'full_name_in_call_later_tab', e)
                      }
                      dataToggle={callMeLaterName[0]}
                      activeDefault={widgetStyleData.template_type === parseInt(6) ? widgetStyleData.name_in_callback_template :widgetStyleData.full_name_in_call_later_tab}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(widgetStyleData.template_type === parseInt(6) ? `email_in_callback_template` :'email_in_call_later_tab', e)
                      }
                      dataToggle={callMeLaterEmail[0]}
                      activeDefault={widgetStyleData.template_type === parseInt(6) ? widgetStyleData.email_in_callback_template : widgetStyleData.email_in_call_later_tab}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(widgetStyleData.template_type === parseInt(6) ? `team_in_callback_template` :'team_in_call_later_tab', e)
                      }
                      dataToggle={callMeLaterTeam[0]}
                      activeDefault={widgetStyleData.template_type === parseInt(6) ? widgetStyleData.team_in_callback_template : widgetStyleData.team_in_call_later_tab}
                    />
                  </div>
                  {parseInt(widgetStyleData.template_type) === parseInt(1) || parseInt(widgetStyleData.template_type) === parseInt(6) && 
                 
        <div className="display-content">
                 <NodeToggle
                   handleDataRef={e =>
                     changeWidgetToggle(widgetStyleData.template_type === parseInt(6) ? `comment_in_callback_template` :`comment_in_call_later_tab`, e)
                   }
                   dataToggle={callMeLaterComment[0]}
                   activeDefault={ widgetStyleData.template_type === parseInt(6) ? widgetStyleData.comment_in_callback_template :  widgetStyleData.comment_in_call_later_tab}                
                 />
               </div>     
}

{(parseInt(widgetStyleData.template_type) === parseInt(1))&& 
                 <>
        <div className="display-content">
                 <NodeToggle
               handleDataRef={e =>
                changeWidgetToggle('industry_in_call_later_tab', e)
              }
              dataToggle={callMeLaterIndustry[0]}
              activeDefault={widgetStyleData.industry_in_call_later_tab}           
                 />
               </div>     
        <div className="display-content">
                 <NodeToggle
                   handleDataRef={e =>
                     changeWidgetToggle(`company_size_in_call_later_tab`, e)
                   }
                   dataToggle={callMeLaterCompanySize[0]}
                   activeDefault={widgetStyleData.company_size_in_call_later_tab}                
                 />
               </div>     
        <div className="display-content">
                 <NodeToggle
                   handleDataRef={e =>
                     changeWidgetToggle(`message_in_call_later_tab`, e)
                   }
                   dataToggle={callMeLaterMessage[0]}
                   activeDefault={widgetStyleData.message_in_call_later_tab}                
                 />
               </div>     
               </>
}
                </div>

                {/* <div className="display-content">
                  <p className="style-widget-title widget-sub-heading">Interest</p>
                  <NodeToggle
                    handleDataRef={e =>
                      changeWidgetToggle('interest_in_call_later_tab', e)
                    }
                    dataToggle={callMeLaterInterest[0]}
                    activeDefault={widgetStyleData?.interest_in_call_later_tab}
                  />
                </div> */}
               

                {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Industry</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('industry_in_call_later_tab', e)
                  }
                  dataToggle={callMeLaterIndustry[0]}
                  activeDefault={widgetStyleData.industry_in_call_later_tab}                
                />
              </div> */}

              {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Employees</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('employee_in_call_later_tab', e)
                  }
                  dataToggle={callMeLaterEmployee[0]}
                  activeDefault={widgetStyleData.employee_in_call_later_tab}                  
                />
              </div> */}

              {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Budget</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('revenue_in_call_later_tab', e)
                  }
                  dataToggle={callMeLaterRevenue[0]}
                  activeDefault={widgetStyleData.revenue_in_call_later_tab}                
                />
              </div>

              <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Role</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('role_in_call_later_tab', e)
                  }
                  dataToggle={callMeLaterRole[0]}
                  activeDefault={widgetStyleData.role_in_call_later_tab}                
                />
              </div> */}

              {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Comments</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('comment_in_call_later_tab', e)
                  }
                  dataToggle={callMeLaterComment[0]}
                  activeDefault={widgetStyleData.comment_in_call_later_tab}                
                />
              </div>     */}

              </>

             
              {/* {callMeLaterCustomFieldsToggle && (
                <div className="custom-fields-wrapper">
                  {lstCustomFields.map((item, index) => {
                    return (
                      <div className="custom-fields-head" key={index}>
                        <CommonSelect
                          isGray
                          name="fieldType"
                          options={lstCustomFieldTypeOptions}
                          defaultValue={
                            item.fieldType || lstCustomFieldTypeOptions[1]
                          }
                          onChange={(e, result) =>
                            onUpdateCustomeFields(index, result)
                          }
                        />
                        <CommonInput
                          placeholder="Label"
                          name="label"
                          type="text"
                          onChange={(e, result) =>
                            onUpdateCustomeFields(index, result)
                          }
                        />
                        <CommonInput
                          placeholder="values comma separated"
                          name="value"
                          type="text"
                          onChange={(e, result) =>
                            onUpdateCustomeFields(index, result)
                          }
                        />
                        <NodeToggle
                          handleDataRef={value => {
                            handleQualification(
                              index,
                              'showQualificationButton',
                              value
                            )
                          }}
                          dataToggle={{
                            callTitle: 'Enabled',
                            callId: `eQualification${index}`,
                            callRef: `showEQualificationButton${index}`
                          }}
                        />
                        <NodeToggle
                          handleDataRef={value => {
                            handleQualification(
                              index,
                              'showRequiredQualificationButton',
                              value
                            )
                          }}
                          dataToggle={{
                            callTitle: 'Required',
                            callId: `rQualification${index}`,
                            callRef: `showRQualificationButton${index}`
                          }}
                        />
                        <CommonSelect
                          isGray
                          name="displayOnCallMeNowWidget"
                          options={widgetCustomFieldDisplayOnCallNow}
                          defaultValue={
                            item.displayOnCallMeNowWidget ||
                            lstCustomFieldTypeOptions[0]
                          }
                          onChange={(e, result) =>
                            onUpdateCustomeFields(index, result)
                          }
                        />
                        <div>
                          {index === 0 ? (
                            <img
                              src={plusIcon}
                              height="15"
                              alt="logo"
                              onClick={() => onAddRemoveCustomeFields({ item })}
                            />
                          ) : null}
                          <img
                            src={deleteIcon}
                            height="15"
                            alt="logo"
                            onClick={() => onAddRemoveCustomeFields({ index })}
                          />
                        </div>
                      </div>
                    )
                  })}
                  <div className="save-cancel">
                    <CommonButton
                      content="Save"
                      btnClass="btn-blue"
                      onClick={() => onSubmitCustomFields()}
                    />
                    <CommonButtons
                      onClick={onCancel}
                      type="reset"
                      content="Cancel"
                      background="grey"
                    />
                  </div>
                </div>
              )} */}
           
           
           </>

        ) : null}

        </section>



      
       
     
      {parseInt(widgetStyleData.template_type) !== parseInt(1) || parseInt(widgetStyleData.template_type) === parseInt(3)  ? (
        <div></div>
      ) : (
        <section className="card-manage-number">
          <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
          <NodeToggle
            handleDataRef={e => changeWidgetToggle('leave_message', e)}
            dataToggle={leaveMessageDisplay[0]}
            activeDefault={widgetStyleData.leave_message}
          />
          </div>

        {widgetStyleData.leave_message ? (
          <>
            
                 
                    <div className="call-tab-text">
                      
                    <h3 className="call-title widget-sub-heading">{parseInt(widgetStyleData.template_type) === parseInt(3) ?"Button Title":"Header text"}</h3>
                    
                       {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to send a message </p>{' '}
                       */}
                     
                      <CommonInput
                        placeholder="Leave your Message"
                        name="call-me-now"
                        type="text"
                       
                        value={
                          widgetStyleData.leave_message_tab_text
                            ? widgetStyleData.leave_message_tab_text
                            : null
                        }
                        onChange={e =>
                          changeWidget('leave_message_tab_text', e.target.value)
                        }
                      />
                    </div>
                    <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>
                        
                    <h3 className="call-title widget-sub-heading">Pre contact form </h3>
                      <p className="accordion-description" style={{color: '#9a9a9a'}}> This form collects user data before the form is submitted. </p>{' '} 
                 




                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle('full_name_in_leave_message_tab', e)
                          }
                          dataToggle={leaveMessageName[0]}
                          activeDefault={
                            widgetStyleData.full_name_in_leave_message_tab
                          }
                          fullNameToggle={leaveMessageName}
                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle('email_in_leave_message_tab', e)
                          }
                          dataToggle={leaveMessageEmail[0]}
                          activeDefault={widgetStyleData.email_in_leave_message_tab}
                          fullNameToggle={leaveMessageName}
                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle('team_in_leave_message', e)
                          }
                          dataToggle={leaveMessageTeam[0]}
                          activeDefault={widgetStyleData.team_in_leave_message}
                          fullNameToggle={leaveMessageName}
                        />
                      </div>
                    </div>
                  {/* <div className="display-content">
                    <p className="style-widget-title widget-sub-heading">Interest</p>
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle('interest_in_leave_message', e)
                      }
                      dataToggle={leaveMessageInterest[0]}
                      activeDefault={widgetStyleData.interest_in_leave_message}
                    />
                  </div>

                  <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Industry</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('industry_in_leave_message', e)
                  }
                  dataToggle={leaveMessageIndustry[0]}
                  activeDefault={widgetStyleData.industry_in_leave_message}                 
                />
              </div> */}

              {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Employees</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('employee_in_leave_message', e)
                  }
                  dataToggle={leaveMessageEmployee[0]}
                  activeDefault={widgetStyleData.employee_in_leave_message}
                 
                />
              </div> */}

              {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Budget</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('revenue_in_leave_message', e)
                  }
                  dataToggle={leaveMessageRevenue[0]}
                  activeDefault={widgetStyleData.revenue_in_leave_message}                
                />
              </div> */}

              {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Role</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('role_in_leave_message', e)
                  }
                  dataToggle={leaveMessageRole[0]}
                  activeDefault={widgetStyleData.role_in_leave_message}               
                />
              </div> */}

              {/* <div className="display-content">
                <p className="style-widget-title widget-sub-heading">Comments</p>
                <NodeToggle
                  handleDataRef={e =>
                    changeWidgetToggle('comment_in_leave_message', e)
                  }
                  dataToggle={leaveMessageComment[0]}
                  activeDefault={widgetStyleData.comment_in_leave_message}                
                />
              </div> */}

                 
                  {/* {leaveMessageCustomFieldsToggle && (
                    <div className="custom-fields-wrapper">
                      {lstCustomFields.map((item, index) => {
                        return (
                          <div className="custom-fields-head" key={index}>
                            <CommonSelect
                              isGray
                              name="fieldType"
                              options={lstCustomFieldTypeOptions}
                              defaultValue={
                                item.fieldType || lstCustomFieldTypeOptions[1]
                              }
                              onChange={(e, result) =>
                                onUpdateCustomeFields(index, result)
                              }
                            />
                            <CommonInput
                              placeholder="Label"
                              name="label"
                              type="text"
                              onChange={(e, result) =>
                                onUpdateCustomeFields(index, result)
                              }
                            />
                            <CommonInput
                              placeholder="values comma separated"
                              name="value"
                              type="text"
                              onChange={(e, result) =>
                                onUpdateCustomeFields(index, result)
                              }
                            />
                            <NodeToggle
                              handleDataRef={value => {
                                handleQualification(
                                  index,
                                  'showQualificationButton',
                                  value
                                )
                              }}
                              dataToggle={{
                                callTitle: 'Enabled',
                                callId: `eQualification${index}`,
                                callRef: `showEQualificationButton${index}`
                              }}
                            />
                            <NodeToggle
                              handleDataRef={value => {
                                handleQualification(
                                  index,
                                  'showRequiredQualificationButton',
                                  value
                                )
                              }}
                              dataToggle={{
                                callTitle: 'Required',
                                callId: `rQualification${index}`,
                                callRef: `showRQualificationButton${index}`
                              }}
                            />
                            <CommonSelect
                              isGray
                              name="displayOnCallMeNowWidget"
                              options={widgetCustomFieldDisplayOnCallNow}
                              defaultValue={
                                item.displayOnCallMeNowWidget ||
                                lstCustomFieldTypeOptions[0]
                              }
                              onChange={(e, result) =>
                                onUpdateCustomeFields(index, result)
                              }
                            />
                            <div>
                              {index === 0 ? (
                                <img
                                  src={plusIcon}
                                  height="15"
                                  alt="logo"
                                  onClick={() =>
                                    onAddRemoveCustomeFields({ item })
                                  }
                                />
                              ) : null}
                              <img
                                src={deleteIcon}
                                height="15"
                                alt="logo"
                                onClick={() =>
                                  onAddRemoveCustomeFields({ index })
                                }
                              />
                            </div>
                          </div>
                        )
                      })}
                      <div className="save-cancel">
                        <CommonButton
                          content="Save"
                          btnClass="btn-blue"
                          onClick={() => onSubmitCustomFields()}
                        />
                        <CommonButtons
                          onClick={onCancel}
                          type="reset"
                          content="Cancel"
                          background="grey"
                        />
                      </div>
                    </div>
                  )} */}
                
          </>
        ) : null}

        </section>


      )}







     
      
     
      <div className="allowed-tabs">
       
        <div className="allowed-tabs-checkboxs">
         
        </div>
      </div>
      
     
      
      {parseInt(widgetStyleData.template_type) === parseInt(1) || parseInt(widgetStyleData.template_type) === parseInt(6) && 
    
    <>
    <div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">WhatsApp</h3>
        <div className="header-swich">
          <CommonInput
            className="popup-font-select"
                    placeholder="Call Me Now"
                    name="call-me-now"
                    type="text"
                    value={
                      widgetStyleData.whatsapp_text

                        ? widgetStyleData.whatsapp_text

                        : null
                    }
                    onChange={event =>
                      changeWidget(
                        'whatsapp_text',
                        event.target.value
                      )
              }
            />
          <NodeToggle
            handleDataRef={e => changeWidgetToggle('widget_call_whatsapp', e)}
            dataToggle={modernTitle[0]}
            activeDefault={widgetStyleData.widget_call_whatsapp}
          />
        </div>                  
                    
      </div>
      <div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">Instagram</h3>
        <div className="header-swich">
        <CommonInput
               className="popup-font-select"
                        placeholder="Call Me Now"
                        name="call-me-now"
                        type="text"
                        value={
                          widgetStyleData.instagram_text

                            ? widgetStyleData.instagram_text

                            : null
                        }
                        onChange={event =>
                          changeWidget(
                            'instagram_text',
                            event.target.value
                          )
                        }
                      />
                       <NodeToggle
            handleDataRef={e => changeWidgetToggle('widget_call_instagram', e)}
            dataToggle={modernSubTitle[0]}
            activeDefault={widgetStyleData.widget_call_instagram}
          />
            </div>        
                    
      </div>
      <div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">linkedin</h3>
        <div className="header-swich">
        <CommonInput
               className="popup-font-select"
                        placeholder="Call Me Now"
                        name="call-me-now"
                        type="text"
                        value={
                          widgetStyleData.linkedin_text

                            ? widgetStyleData.linkedin_text

                            : null
                        }
                        onChange={event =>
                          changeWidget(
                            'linkedin_text',
                            event.target.value
                          )
                        }
                      />
                       <NodeToggle
            handleDataRef={e => changeWidgetToggle('widget_call_linkedin', e)}
            dataToggle={modernSubTitle[0]}
            activeDefault={widgetStyleData.widget_call_linkedin}
          />
            </div>        
                    
      </div>
      <div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">Telegram</h3>
        <div className="header-swich">
        <CommonInput
               className="popup-font-select"
                        placeholder="Call Me Now"
                        name="call-me-now"
                        type="text"
                        value={
                          widgetStyleData.telegram_text

                            ? widgetStyleData.telegram_text

                            : null
                        }
                        onChange={event =>
                          changeWidget(
                            'telegram_text',
                            event.target.value
                          )
                        }
                      />
                       <NodeToggle
            handleDataRef={e => changeWidgetToggle('widget_call_telegram', e)}
            dataToggle={modernSubTitle[0]}
            activeDefault={widgetStyleData.widget_call_telegram}
          />
            </div>        
                    
      </div>
      <div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">FB Messenger</h3>
        <div className="header-swich">
        <CommonInput
               className="popup-font-select"
                        placeholder="Call Me Now"
                        name="call-me-now"
                        type="text"
                        value={
                          widgetStyleData.fb_messanger_text

                            ? widgetStyleData.fb_messanger_text

                            : null
                        }
                        onChange={event =>
                          changeWidget(
                            'fb_messanger_text',
                            event.target.value
                          )
                        }
                      />
                       <NodeToggle
            handleDataRef={e => changeWidgetToggle('widget_call_fb_messanger', e)}
            dataToggle={modernSubTitle[0]}
            activeDefault={widgetStyleData.widget_call_fb_messanger}
          />
            </div>        
                    
      </div>
      </>
      }
      <div className="legal-wrapper">
        <div className="legal-head">
          <div style={{maxWidth:'45%'}}>
          <p className="style-widget-title widget-sub-heading">Social Proof</p>
          <p className="general-content-desc">
          Get real-time stats on how many call requests have been made today.{' '}
          </p>
          </div>
          <NodeToggle
            handleDataRef={e => changeWidgetToggle('social_proof', e)}
            dataToggle={socialWidget[0]}
            activeDefault={widgetStyleData.social_proof}
          />
        </div>

        <div className="legal-head">
            <div style={{maxWidth:'45%'}}>
            <p className="style-widget-title widget-sub-heading">Legal</p>
            <p style={{ display: "block" }} className="general-content-desc">
                                Configure Your Terms &amp; Conditions. 
          </p>
              </div>
         
          
         

          <NodeToggle
            handleDataRef={e =>
              changeWidgetToggle('terms_and_conditions_status', e)
            }
            dataToggle={legalWidget[0]}
            activeDefault={widgetStyleData.terms_and_conditions_status}
          />
        </div>
        {widgetStyleData.terms_and_conditions_status ? (
          <div className="legal-content legal-dropdown">
            <div className="legal-col">
              <label>Configure Your Terms &amp; Conditions</label>
              <CommonSelect
                name="config"
                className="popup-font-select"
                placeholder="Text"
                value={
                  widgetStyleData.terms_type == '0'
                    ? 'Text'
                    : widgetStyleData.terms_type == '1'
                    ? 'URL'
                    : 'HTML'
                }
                options={termOption}
                onChange={(e, data) =>
                  changeWidget(
                    'terms_type',
                    `${
                      data.value === 'Text'
                        ? '0'
                        : data.value === 'URL'
                        ? '1'
                        : '2'
                    }`
                  )
                }
              />
            
            </div>
            <div className="legal-col">
              <TextArea
                // defaultValue={widgetSetting.legalNote}
                value={widgetStyleData.terms_and_conditions}
                onChange={event =>
                  changeWidget('terms_and_conditions', event.target.value)
                }
              />
            </div>
          </div>
        ) : null}
        <div className="legal-head">
           <div style={{maxWidth:'45%'}}>
              <p className="style-widget-title widget-sub-heading">Privacy Note</p>
              <p className="general-content-desc">
                Embed your privacy note on the widget.{' '}
              </p>
          </div>

          <NodeToggle
            handleDataRef={e => {
               changeWidgetToggle(parseInt(widgetStyleData.template_type) === parseInt(6) ? 'terms_privacy_in_callback_template' :'privacy_note_status', e)}}
            dataToggle={privacyWidget[0]}
            activeDefault={parseInt(widgetStyleData.template_type) === parseInt(6) ? widgetStyleData.terms_privacy_in_callback_template :widgetStyleData.privacy_note_status}
            />
        </div>
           
        {widgetStyleData.privacy_note_status ? (
          <div className="legal-content private-text-area">
            <div className="legal-col">
              <TextArea
                placeholder="Your number will not be used for marketing purposes"
               
                value={widgetStyleData.privacy_note}
                onChange={event =>
                  changeWidget('privacy_note', event.target.value)
                }
               
              />
            </div>
          </div>
        ) : null}
        {isInputChange ? (
          <div className="isInputChange">
            <CommonButton
              onClick={submitWidget}
              type="button"
              content="Save"
              background="blue"
            />
            <CommonButtons
              onClick={onCancel}
              type="reset"
              content="Cancel"
              background="grey"
            />
          </div>
        ) : null}
      </div>

      <Ref className="widget-style-mobile" innerRef={contextRef}>
        <Rail position="right" style={{minHeight:"2000px"}}>
          {/* <Sticky context={contextRef} >
            {parseInt(widgetStyleData.template_type) !== parseInt(1) ? (
                parseInt(widgetStyleData.template_type) === parseInt(3) ? (
                <div className="custom-widget-wrapper custom-widget-wrapper-smart modern-widget-container-main">
                  <div className="hor-row ">
                    <div className='modern-widget-container'>
                      <div className='hor-row lime-modern-componey-logo-container'>
                        <img
                          src="https://app.limecall.com/storage/company_logo/605a6f3c0e7d9/z4FKg65lUgEr4DXda28YGVqFBiXc7A4MtrbUXBzA.png"/>
                      </div>
                      <h3 style={{ fontFamily: widgetStyleData.font }}>
                         Hey 👋
                      </h3>
                      <div className='hor-row lime-modern-greeting-body' style={{ fontFamily: widgetStyleData.font }}>
                        Need help? We're here!
                      </div>
                    </div>
                    {widgetStyleData.digital_call ? <div className = 'hor-row lime-modern-selector-button' style={{ fontFamily: widgetStyleData.font }}>
                      <i className="material-icons" style={{color:"#25ae88",verticalAlign: 'middle', paddingRight: '2px'}}>phone_in_talk</i>
                       Talk to us now
                    </div> : null}
                    {(widgetStyleData.call_now || widgetStyleData.call_me_later) ? <div style={{ fontFamily: widgetStyleData.font }} className = 'hor-row lime-modern-selector-button'>
                      <img src = {callMeBackRound}
                        style={{width: '28px', marginRight: '5px'}}/>
                       Call me back
                    </div> : null}                   


                    <div className='hor-row lime-modern-powered-section'>

                        <div>{widgetStyleData.social_proof ? (
                                  <label style={{ marginBottom: ".5rem;", display:"block", fontFamily: widgetStyleData.font, fontSize: "14px", color: "#828181" }}>
                                    You are already the 1st person who has
                                    requested a call
                                  </label>
                                ) : null}
                                {widgetStyleData.terms_and_conditions_status ? (
                                  <label  style={{ marginBottom: ".5rem;", display:"block", fontFamily: widgetStyleData.font, fontSize: "14px", color: "#828181"   }}>{widgetStyleData.terms_and_conditions}</label>
                                ) : null}
                                {widgetStyleData.privacy_note_status ? (
                                  <label  style={{ marginBottom: ".5rem;", display:"block", fontFamily: widgetStyleData.font, fontSize: "14px", color: "#828181"   }}>{widgetStyleData.privacy_note}</label>
                                ) : null}
                        </div>


                      We run on 
                      <img src="https://limecall.com/wp-content/uploads/2019/12/logo_blue.png"
                        style={{marginLeft: '2px', height: '10px'}}/>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="custom-widget-wrapper custom-widget-wrapper-smart">
                   {Boolean(widgetStyleData.digital_call) && Boolean(allowedTabs.clickToCall) && (
                     <>
                    <div className="custom-widget-body">
                      <h4 style={{ color: widgetSetting.timerColor, fontFamily: widgetStyleData.font }}>
                        {clickToCallTabText}
                      </h4>
                      <div className="custom-widget-content">
                        {widgetStyleData.full_name_in_digital_calls_tab ? (
                          <div className="widget-input">Joe Doe</div>
                        ) : null}
                        {widgetStyleData.email_in_digital_calls_tab ? (
                          <div className="widget-input">jon@email.com</div>
                        ) : null}
                        {widgetStyleData.team_in_digital_calls_tab ? (
                          <div className="custom-widget-content">
                            <label style={{ fontFamily: widgetStyleData.font }}>Select a team to connect with:</label>
                            <CommonSelect
                              name="config"
                              className="popup-font-select"
                              placeholder="Sales"
                              options={teamData}
                            />
                          </div>
                        ) : null}


                        {widgetStyleData.interest_in_digital_calls_tab ? (
                          <div className="custom-widget-content">
                            <label>Select interest</label>
                            <CommonSelect
                              name="config"
                              className="popup-font-select"
                              placeholder="Interest"
                              options={interestData}
                            />
                          </div>
                        ) : null}




                          {widgetStyleData.industry_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                            ) : null}


                              {widgetStyleData.employee_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={orgLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.revenue_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Budget"
                                    options={['< 100K', '100K - 1M', '1M - 10M', '10M - 100M', '> 100M']}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.role_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Role"
                                    options={roleLimeCall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                   <TextArea rows={2} placeholder='Tell us more' />
                                </div>
                              ) : null}



                            


                        <div
                          className="call-me-btn"
                          style={{ backgroundColor: buttonColor }}
                        >
                          {clickToCallButtonText}
                        </div>
                      </div>
                    </div>

                    <div className='smart-widget-button-container'>
                      <div className='button-connecting'>
                        Connecting
                        <i className="material-icons smart-call-cut animated infinite pulse">call_end</i>
                      </div>
                    </div>
                    </>
                  )}

                  {(widgetStyleData.call_me_later  && allowedTabs.callMeLater  && (widgetStyleData.team_in_call_later_tab || widgetStyleData.email_in_call_later_tab || widgetStyleData.full_name_in_call_later_tab)) ? 
                    (<div className='smart-widget-container-main'>
                      {(widgetStyleData.email_in_call_later_tab || widgetStyleData.full_name_in_call_later_tab) ? (<div className= 'hor-row info-text'>
                        Let us know a few details about you
                      </div>): null}
                      {widgetStyleData.email_in_call_later_tab ? <div className='hor-row input-container-main'>
                        <input type='text' placeholder='Email*'/>
                      </div> : null}
                      {widgetStyleData.full_name_in_call_later_tab ? <div className='hor-row input-container-main'>
                        <input type='text' placeholder='Name*'/>
                      </div> : null}
                     
                      {widgetStyleData.team_in_call_later_tab ? <div className='hor-row team-container-main'>
                        <div className='hor-row team-text-main'>
                          I want to speak to:
                        </div>
                        { teamData.map( index => {
                          return (
                            <div className='team-button-container'>
                              {index}
                            </div>
                          )
                        } ) }                        
                        
                      </div> : null}

                      {widgetStyleData.interest_in_call_later_tab ? <div className='hor-row team-container-main'>
                        <div className='hor-row team-text-main'>
                          Interest:
                        </div>
                        { interestData.map( index => {
                          return (
                            <div className='team-button-container'>
                              {index}
                            </div>
                          )
                        } ) }                        
                        
                      </div> : null}




                      {widgetStyleData.industry_in_call_later_tab ? (
                                <div className="hor-row input-container-main"
                                  style={{marginTop: '10px', textAlign: "center"}}>
                                  <CommonSelect
                                   style={{ maxWidth: "200px", display: "block"}}
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.employee_in_call_later_tab ? (
                              
                                    <div className="hor-row input-container-main" style={{marginTop: '10px'}}>
                                      <CommonSelect
                                        style={{ maxWidth: "200px", textAlign: "center"}}
                                        name="config"
                                        className="popup-font-select"
                                        placeholder="Select No Of Employee"
                                        options={orgLimecall}
                                      />
                                    </div>
                               
                              ) : null}

                              {widgetStyleData.revenue_in_call_later_tab ? (
                               
                                    <div className="hor-row input-container-main" style={{marginTop: '10px'}}>
                                      <CommonSelect
                                       style={{ maxWidth: "200px", textAlign: "center"}}
                                        name="config"
                                        className="popup-font-select"
                                        placeholder="Select Budget"
                                        options={['< 100K', '100K - 1M', '1M - 10M', '> 10M']}
                                      />
                                    </div>
                                
                              ) : null}

                              {widgetStyleData.role_in_call_later_tab ? (                                
                                    <div className="hor-row input-container-main" style={{marginTop: '10px'}}>
                                  <CommonSelect
                                   style={{ maxWidth: "200px", textAlign: "center"}}
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={roleLimeCall}
                                  />
                                
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_call_later_tab ? (
                               
                                    <div className="hor-row input-container-main" style={{marginTop: '10px', textAlign: "center"}}>
                                      <TextArea rows={2}  style={{ maxWidth: "200px"}} placeholder='Tell us more' />
                                    </div>
                               
                              ) : null}   

                    {(widgetStyleData.email_in_call_later_tab || widgetStyleData.full_name_in_call_later_tab) ? 
                        <div className='hor-row input-container-main'>
                          <div className='submit-button'>
                            { widgetStyleData.offline_schedule_call_message }
                          </div>
                        </div> : null
                      }

                      



                      <div className='hor-row smart-widget-footer'>
                          <a href="http://limecall.com/pages/?utm_source=https://app.limecall.com" target="_blank" rel="noopener noreferrer" className="poweredby-section">
                            <img src="https://limecall.com/wp-content/uploads/2020/01/favicon.png" alt="" className="poweredby-icon"/>
                            We run on LimeCall
                          </a>
                      </div>
                    </div>
                  ) : null
                  }
                  
                  

                  
                </div>
               
              )
            ) : (
              <div className='classic-widget-outer-container-main'>
                <div>
                  <h1 className="classic-text"
                    style={{marginBottom: '30px'}}>Classic Widget</h1>
                </div>
                <div className='classic-widget-container-main'>
                  <div className="custom-widget-wrapper hor-row">
                    <div className="custom-widget-header">
                      {widgetStyleData.digital_call ? (
                        <div
                          id="clickToCall"
                          onClick={e => onClickTableCategory(e)}
                          
                        >
                          <p
                            id="clickToCall"
                            onClick={e => onClickTableCategory(e)}
                          >
                            <i className="material-icons"
                              style={{ fontSize: '12px', paddingRight: '3px'}}>
                              headset_mic
                            </i>
                            Web Call
                          </p>
                          <div className="close" id="clickToCall"></div>
                        </div>
                      ) : null}
                      {widgetStyleData.call_now ? (
                        <div>
                          <div
                            id="callMeBack"
                            onClick={e => onClickTableCategory(e)}
                          >
                            <img
                              src={callMeBack}
                              alt=""
                              id="callMeBack"
                              onClick={e => onClickTableCategory(e)}
                            />
                            <p
                              id="callMeBack"
                              onClick={e => onClickTableCategory(e)}
                            >
                            Call Back
                            </p>
                            <div className="close" id="callMeBack"></div>
                          </div>
                        </div>
                      ) : null}
                      {widgetStyleData.call_me_later ? (
                        <div>                         
                            <div
                              id="callMeLater"
                              onClick={e => onClickTableCategory(e)}
                            >
                              <img
                                src={callMeLetter}
                                alt=""
                                id="callMeLater"
                                onClick={e => onClickTableCategory(e)}
                              />
                              <p
                                id="callMeLater"
                                onClick={e => onClickTableCategory(e)}
                              >
                                Appointment Scheduling
                              </p>
                              <div className="close" id="callMeLater"></div>
                            </div>                        
                        </div>
                      ) : null}
                      {widgetStyleData.leave_message ? (
                        <div>
                          <div
                            id="leaveMessage"
                           onClick={e => onClickTableCategory(e, widgetSetting)}
                          >
                            <p
                              id="leaveMessage"
                              onClick={e => onClickTableCategory(e, widgetSetting)}
                            >
                              <i className="material-icons"
                                style={{ fontSize: '12px', paddingRight: '3px'}}>
                                message
                              </i>
                              Contact form
                            </p>
                            <div className="close" id="leaveMessage"> </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="custom-widget-wrapper custom-widget-wrapper-head hor-row">
                    <div className="custom-widget-wrapper">
                      {allowedTabs.clickToCall && widgetStyleData.digital_call && (
                        <>
                          <div className="custom-widget-body">
                            <h4 className = 'custom-widget-body-h1'
                              style={{fontFamily: widgetStyleData.font}}>
                              {widgetStyleData.digital_call_tab_text}
                            </h4>
                            <div className="custom-widget-content">
                              {widgetStyleData.email_in_digital_calls_tab ? (
                                <div className="widget-input">
                                  <i className="material-icons icon-class">email</i>
                                  Email
                                </div>
                              ) : null}
                              {widgetStyleData.full_name_in_digital_calls_tab ? (
                                <div className="widget-input">
                                  <i className="material-icons icon-class">account_circle</i>
                                  Name*
                                </div>
                              ) : null}
                              {widgetStyleData.team_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="I want to talk to"
                                    options={teamData}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.interest_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Interest"
                                    options={interestData}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.industry_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.employee_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={orgLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.revenue_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Budget"
                                    options={['< 100K', '100K - 1M', '1M - 10M', '10M - 100M', '> 100M']}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.role_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Role"
                                    options={roleLimeCall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_digital_calls_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                   <TextArea rows={2} placeholder='Tell us more' />
                                </div>
                              ) : null}


                              <div
                                className="call-me-btn"
                                style={{
                                  backgroundColor: widgetStyleData.submit_request_call_color, borderRadius: '5px'
                                }}
                              >
                                {widgetStyleData.digital_call_button_text}
                              </div>
                            </div>
                          </div>
                          <div className="custom-widget-footer">
                            {widgetStyleData.social_proof ? (
                              <label>
                                You are already the 1st person who has requested a
                                call
                              </label>
                            ) : null}
                            {widgetStyleData.terms_and_conditions_status ? (
                              <label>{widgetStyleData.terms_and_conditions}</label>
                            ) : null}
                            {widgetStyleData.privacy_note_status ? (
                              <label>{widgetStyleData.privacy_note}</label>
                            ) : null}
                            <p className='lime-call-footer-text-container'>
                              <a href="http://limecall.com/pages/?utm_source=https://app.limecall.com" target="_blank" rel="noopener noreferrer" className="poweredby-section">
                                <img src="https://limecall.com/wp-content/uploads/2020/01/favicon.png" alt="" className="poweredby-icon"/>
                                We run on LimeCall
                              </a>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                 
                      <div className="custom-widget-wrapper custom-widget-wrapper-option">
                        {allowedTabs.callMeBack && widgetStyleData.call_now && (
                          <>
                            <div className="custom-widget-body">
                              <h4  className = 'custom-widget-body-h1'
                                style={{fontFamily: widgetStyleData.font}}>
                                {widgetStyleData.widget_text}
                              </h4>
                              <div className="custom-widget-content">
                                <div className="widget-drop">
                                  <div className="widget-drop-inner">
                                    <img src={callMeBack} alt="" />
                                    <span>+91</span>
                                  </div>
                                  <p>81234 58963</p>
                                </div>
                                {widgetStyleData.full_name_in_call_now_tab ? (
                                  <div className="widget-input">Joe Doe</div>
                                ) : null}
                                {widgetStyleData.email_in_call_now_tab ? (
                                  <div className="widget-input">
                                    jon@email.com
                                  </div>
                                ) : null}
                                {widgetStyleData.team_in_call_now_tab ? (
                                  <div className="custom-widget-content"
                                    style={{marginTop: '10px'}}>
                                    <CommonSelect
                                      name="config"
                                      className="popup-font-select"
                                      placeholder="I want to talk to"
                                      options={teamData}
                                    />
                                  </div>
                                ) : null}

                                {widgetStyleData.interest_in_call_now_tab ? (
                                  <div className="custom-widget-content"
                                    style={{marginTop: '10px'}}>
                                    <CommonSelect
                                      name="config"
                                      className="popup-font-select"
                                      placeholder="Interest"
                                      options={interestData}
                                    />
                                  </div>
                                ) : null}

                              {widgetStyleData.industry_in_call_now_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.employee_in_call_now_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={orgLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.revenue_in_call_now_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Budget"
                                    options={['< 100K', '100K - 1M', '1M - 10M', '> 10M']}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.role_in_call_now_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Role"
                                    options={roleLimeCall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_call_now_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                   <TextArea rows={2} placeholder='Tell us more' />
                                </div>
                              ) : null}     


                              </div>
                              <div
                                className="call-me-btn"
                                style={{
                                  backgroundColor: widgetStyleData.submit_request_call_color, borderRadius: '5px'
                                }}
                              >
                                
                                { widgetStyleData.submit_request_call_text
                                  ?  widgetStyleData.submit_request_call_text
                                  : null}
                              </div>
                            </div>
                            <div className="custom-widget-footer">
                              {widgetStyleData.social_proof ? (
                                <label>
                                  You are already the 1st person who has requested
                                  a call
                                </label>
                              ) : null}
                              {widgetStyleData.terms_and_conditions_status ? (
                                <label>{widgetStyleData.terms_and_conditions}</label>
                              ) : null}
                              {widgetStyleData.privacy_note_status ? (
                                <label>{widgetStyleData.privacy_note}</label>
                              ) : null}
                            </div>
                          </>
                        )}
                      </div>
                  
                   
                      <div className="custom-widget-wrapper custom-widget-wrapper-option not">
                        {allowedTabs.callMeLater && widgetStyleData.call_me_later && (
                          <>
                            <div className="custom-widget-body">
                              <h4  className = 'custom-widget-body-h1'
                                style={{fontFamily: widgetStyleData.font}}>
                                {widgetStyleData.schedule_call_tab_text}
                              </h4>
                              <div className="call-me-latter">
                                <CommonSelect
                                  name="config"
                                  className="popup-font-select"
                                  placeholder="Date"
                                  options={['Date']}
                                />
                                <CommonSelect
                                  name="config"
                                  className="popup-font-select"
                                  placeholder="Time"
                                  options={['Time']}
                                />
                              </div>
                              <div className="custom-widget-content">
                                <div className="widget-drop">
                                  <div className="widget-drop-inner">
                                    <img src={callMeBack} alt="" />
                                    <span>+91</span>
                                  </div>
                                  <p>81234 58963</p>
                                </div>
                                {widgetStyleData.full_name_in_call_later_tab ? (
                                  <div className="widget-input">Joe Doe</div>
                                ) : null}
                                {widgetStyleData.email_in_call_later_tab ? (
                                  <div className="widget-input">
                                    jon@email.com
                                  </div>
                                ) : null}
                                {widgetStyleData.interest_in_call_later_tab ? (
                                  <div className="custom-widget-content"
                                    style={{marginTop: '10px'}}>
                                    <CommonSelect
                                      name="config"
                                      className="popup-font-select"
                                      placeholder="Interest"
                                      options={interestData}
                                    />
                                  </div>
                                ) : null}

                              {widgetStyleData.industry_in_call_later_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.employee_in_call_later_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={orgLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.revenue_in_call_later_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Budget"
                                    options={['< 100K', '100K - 1M', '1M - 10M', '> 10M']}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.role_in_call_later_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={roleLimeCall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_call_later_tab ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                   <TextArea rows={2} placeholder='Tell us more' />
                                </div>
                              ) : null}   


                              </div>
                              <div
                                className="call-me-btn"
                                style={{
                                  backgroundColor: widgetStyleData.submit_request_call_color, borderRadius: '5px'
                                }}
                              >
                                {widgetSetting.callLeterButton
                                  ? widgetSetting.callLeterButton
                                  : null}
                              </div>
                            </div>
                            <div className="custom-widget-footer">
                              {widgetStyleData.social_proof ? (
                                <label>
                                  You are already the 1st person who has requested
                                  a call
                                </label>
                              ) : null}
                              {widgetStyleData.terms_and_conditions_status ? (
                                <label>{widgetStyleData.terms_and_conditions}</label>
                              ) : null}
                              {widgetStyleData.privacy_note_status ? (
                                <label>{widgetStyleData.privacy_note}</label>
                              ) : null}
                            </div>
                          </>
                        )}
                      </div>                   
                   
                    
                      <div className="custom-widget-wrapper custom-widget-wrapper-option someting">
                        {
                          allowedTabs.leaveMessage && widgetStyleData.leave_message && (
                            <>
                              <div className="custom-widget-body">
                                <h4  className = 'custom-widget-body-h1'
                                  style={{fontFamily: widgetStyleData.font}}>
                                  {widgetStyleData.leave_message_tab_text}
                                </h4>
                                <div className="custom-widget-content">
                                  { <TextArea placeholder="Your message" />}

                                  {widgetStyleData.full_name_in_leave_message_tab ? (
                                    <div className="widget-input">Joe Doe</div>
                                  ) : null}
                                  {widgetStyleData.email_in_leave_message_tab ? (
                                    <div className="widget-input">
                                      jon@email.com
                                    </div>
                                  ) : null}
                                  {widgetStyleData.interest_in_leave_message ? (
                                    <div className="custom-widget-content"
                                      style={{marginTop: '10px'}}>
                                      <CommonSelect
                                        name="config"
                                        className="popup-font-select"
                                        placeholder="I want to talk to"
                                        options={interestData}
                                      />
                                    </div>
                                  ) : null}

                                  
                              {widgetStyleData.industry_in_leave_message ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Industry"
                                    options={bussinessLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.employee_in_leave_message ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={orgLimecall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.revenue_in_leave_message ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select Budget"
                                    options={['< 100K', '100K - 1M', '1M - 10M', '> 10M']}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.role_in_leave_message ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                  <CommonSelect
                                    name="config"
                                    className="popup-font-select"
                                    placeholder="Select No Of Employee"
                                    options={roleLimeCall}
                                  />
                                </div>
                              ) : null}

                              {widgetStyleData.comment_in_leave_message ? (
                                <div className="custom-widget-content"
                                  style={{marginTop: '10px'}}>
                                   <TextArea rows={2} placeholder='Tell us more' />
                                </div>
                              ) : null} 


                                </div>
                                <div
                                  className="call-me-btn"
                                  style={{
                                    backgroundColor: widgetStyleData.submit_request_call_color, borderRadius: '5px'
                                  }}
                                >
                                  {leaveMessageButtonText
                                    ? leaveMessageButtonText
                                    : null}
                                </div>
                              </div>
                              <div className="custom-widget-footer">
                                {widgetStyleData.social_proof ? (
                                  <label>
                                    You are already the 1st person who has
                                    requested a call
                                  </label>
                                ) : null}
                                {widgetStyleData.terms_and_conditions_status ? (
                                  <label>{widgetStyleData.terms_and_conditions}</label>
                                ) : null}
                                {widgetStyleData.privacy_note_status ? (
                                  <label>{widgetStyleData.privacy_note}</label>
                                ) : null}
                              </div>
                            </>

                          )}
                      </div>
                  
                  </div>
                </div>
              </div>
            )}
          </Sticky> */}
        </Rail>
      </Ref>
    </div>
  )
}
