import Axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'
import MissedCall from '../../assets/images/orangeCall.png'
import PickedCall from '../../assets/images/greenCall.png'
import cutCall from '../../assets/images/callRed.jpeg'

const successIcon = [
  'completed',
  'agent_call_completed',
  'customer_call_completed'
]
const warningIcon = [
  'in-progress',
  'waiting_for_agent',
  'busy',
  'no-answer',
  'customer_connected',
  'scheduled',
  'rescheduled',
  'ringing',
  'agent_connected',
  'connecting_agent',
  'connecting_customer'
]

const checkIcon = status => {
  if (successIcon.includes(status)) {
    return PickedCall
  }
  if (warningIcon.includes(status)) {
    return MissedCall
  }
  return cutCall
}

export default function LogsPanel({ getData }) {
  const [logList, setLogList] = useState([])
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (getData?.getData?.id) {
      fetchContactLog()
    }
  }, [getData?.getData?.id])

  const fetchContactLog = () => {
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${getData?.getData?.id}/leads-activities`
    Axios.get(url)
      .then(res => {
        setLogList(res?.data?.data || [])
        setLoader(false)
      })
      .catch(error => {
        setLoader(false)
      })
  }

  return (
    <div className="contact-card logTab">
      <Dimmer active={loader} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      {logList &&
        logList?.length > 0 &&
        logList?.map((data, index) => {
          return data?.final_status ? (
            <React.Fragment key={index}>
              <div className="contact-card-boxes logs justify-content-between d-flex">
                <div className="d-flex">
                  <div className="icon">
                    <img src={checkIcon(data?.final_status)} alt="icon" />
                  </div>
                  <div>
                    <p>
                      {data?.type}{' '}
                      {data?.duration ? `(${data?.duration} Sec)` : ''}
                    </p>
                    <p>
                      Received on: {'  '} {data?.source}
                    </p>
                    <p>
                      Received from:{'  '} {data?.phone_number}
                    </p>
                    <p>
                      Notes:{'  '} {data?.note}
                    </p>
                    <p>Disposition code:{'  '}</p>
                    {data?.recording_url && data?.recording_url !== 'null' && (
                      <audio controls>
                        <source src={data?.recording_url}></source>
                      </audio>
                    )}
                    <p>
                      Assigned to:{'  '} {data?.agent_name}
                    </p>
                    {data?.failure_reason && (
                      <p>
                        Failure Reason:{'  '} {data?.failure_reason}
                      </p>
                    )}
                    {data?.final_status && (
                      <p>
                        Final Status:{'  '} {data?.final_status}
                      </p>
                    )}
                    {data?.lead_id && (
                      <p>
                        Lead Id:{' '}
                        <Link
                          to={`/leads/${data?.lead_id}`}
                          className="w-auto d-inline"
                          target="_blank"
                        >
                          {'  '} {data?.lead_id}
                        </Link>
                      </p>
                    )}
                  </div>
                </div>
                <span className="date-content">
                  {data?.created_at ? (
                    <>
                      {moment(data?.created_at).format('ddd')},
                      {moment(data?.created_at).format('DD MMM YYYY, h:mm a')}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              </div>
            </React.Fragment>
          ) : (
            ''
          )
        })}
      {logList && !logList?.length > 0 && (
        <div className="contact-card-boxes logs justify-content-between d-flex">
          Logs are not available yet.
        </div>
      )}
    </div>
  )
}
