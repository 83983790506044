import React, { useState } from 'react'

import {
  Button,  
  Header,
  Modal,
  Input,
  
} from 'semantic-ui-react'


import CommonButtons from './CommonButtons'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactFlagsSelect from 'react-flags-select';
import CommonSelect from './CommonSelect'

const bussinessLimecall = [
  'Industry',
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel/Fashion',
  'Architecture/Planning',
  'Arts/Crafts',
  'Automotive',
  'Aviation/Aerospace',
  'Banking/Mortgage',
  'Biotechnology/Greentech',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies/Equipment',
  'Capital Markets/Hedge Fund/Private Equity',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software/Engineering',
  'Computer/Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense/Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment/Movie Production',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Fundraising',
  'Furniture',
  'Gambling/Casinos',
  'Glass/Ceramics/Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design/Web Design',
  'Health/Fitness',
  'Higher Education/Acadamia',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Individual/Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology/IT',
  'Insurance',
  'International Affairs',
  'International Trade/Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management/Hedge Fund/Private Equity',
  'Judiciary',
  'Law Enforcement',
  'Law Practice/Law Firms',
  'Legal Services',
  'Legislative Office',
  'Leisure/Travel',
  'Library',
  'Logistics/Procurement',
  'Luxury Goods/Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing/Advertising/Sales',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Mental Health Care',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Museums/Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers/Journalism',
  'Non-Profit/Volunteering',
  'Oil/Energy/Solar/Greentech',
  'Online Publishing',
  'Other Industry',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Relations/PR',
  'Public Safety',
  'Publishing Industry',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate/Mortgage',
  'Recreational Facilities/Services',
  'Religious Institutions',
  'Renewables/Environment',
  'Research Industry',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing/Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation/Localization',
  'Transportation',
  'Utilities',
  'Venture Capital/VC',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine/Spirits',
  'Wireless',
  'Writing/Editing'
]

const roleLimeCall = [
  'Sale Manager',
  'Marketing / PR Manager',
  'Customer Services Manager',
  'CXO / General Manager',
  'It Manager',
  'Operation Manager',
  'Developer / software Engineer / Analyst',
  'Student / Personal Interest',
  'other'
]

const orgLImecall = [
  '1-10',
  '11-50',
  '51-250',
  '251-1K',
  '1k-5k',
  '5K-10K',
  '10K-50K',
  '50K-100K',
  '100K+'
]



const UpdateLeadModal = ({ open, isModalClose, dropDownData, leadData, onUpdateRequest, leadId, interested, lead_interest }) => {

  

  const [ldData, setLdData] = useState({
    customer_name:leadData?.customer_name,   
    email: leadData?.email,
    phone_number: leadData?.phone_number,
    country: leadData?.country,
    role: leadData?.role,
    company: leadData?.company_name,
    interest: leadData?.interest,
    industry: leadData?.industry,
    companySize: leadData?.employees,
    lead_interest: lead_interest,

  })

  const updateText = (val, key) => {
    const data = {...ldData}
    data[key] = val
    setLdData(data)

   
  }
  


  return (
    <Modal
      className="send-message-modal"
      onClose={isModalClose}
      closeIcon
      open={open}
      size="tiny"   
      style={{ maxWidth: "460px" }}   
    >
      <Header content="Update Lead" />
      <Modal.Content  style={{ padding: "" }}>
       
     
        <div style={{ display: "flex",flexWrap:"wrap" , padding: "2px 15px"}}>

          <div className="send-message__input" style={{ flex: "50%", padding: "2px 10px" }}>
            <label>Name </label>
            <Input style={{  width: "180px" }} name="to" onChange={ e => updateText(e.target.value, 'customer_name')} value={ldData.customer_name}  title="Name" />
          </div>

          <div className="send-message__input"  style={{ flex: "50%", padding: "2px 10px" }}>
            <label>Email</label>
            <Input style={{  width: "180px" }} name="to" onChange={e => updateText(e.target.value, 'email')} value={ldData.email}  title="Email" />
          </div>

        </div>


        <div style={{ display: "flex", flexWrap:"wrap" , padding: "2px 15px"}}>
             <div className="send-message__input" style={{flex: "50%",  padding: "2px 10px" }}>
                <label>Phone Number</label>
                  <PhoneInput
              country={'eg'}
              className="text-color"
                  value={ldData.phone_number}
                  onChange={phone  => updateText(phone, 'phone_number')}
                  style={{  width: "180px" }}
                />
              </div>
              <div className="send-message__input" style={{ flex: "50%", padding: "2px 10px"}}>
                <label>Company Name</label>
                <Input style={{  width: "180px" }} name="to" onChange={e => updateText(e.target.value, 'company')} value={ldData.company}  title="Email" />
              </div>
        </div>

        <div style={{ display: "flex", flexWrap:"wrap" , padding: "2px 15px"}}>
          <div className="send-message__input"  style={{ flex: "50%", padding: "2px 10px"}}>
            <label>Industry</label>
            <CommonSelect
                                  name="industry"
                                  className="popup-font-select"
                                  placeholder="Select industry"
                                  options={bussinessLimecall}
                                  style={{height: 'auto'}}
                                  defaultValue={ldData.industry}
                                  onChange={e => updateText(e.target.innerText, 'industry')}
                                />
          </div>

          
          <div className="send-message__input"  style={{ flex: "50%", flexWrap:"wrap" , padding: "2px 10px"}}>
            <label>Role</label>
            <CommonSelect
                                  name="role"
                                  className="popup-font-select"
                                  placeholder="Select role"
                                  options={roleLimeCall}
                                  defaultValue={ldData.role}
                                  style={{height: 'auto'}}
                                  onChange={e => updateText(e.target.innerText, 'role')}
                                />
          </div>
        </div>

         <div style={{ display: "flex", flexWrap:"wrap" , padding: "2px 15px"}}>
          <div className="send-message__input"  style={{ flex: "50%", padding: "2px 10px"}}>
            <label>Country</label>
            <ReactFlagsSelect
              selected={ldData.country}
              onSelect={code => updateText(code, 'country')}
              style={{  width: "180px", padding: "0px 3px", fontSize: "14px !important" }}
            />
          </div>
          <div className="send-message__input"  style={{ flex: "50%", flexWrap:"wrap" , padding: "2px 10px"}}>
            <label>Company Size</label>
            <CommonSelect
                                  name="company-size"
                                  className="popup-font-select"
                                  placeholder="Select size"
                                  options={orgLImecall}   
                                  defaultValue={ldData.companySize}                              
                                  onChange={e => updateText(e.target.innerText, 'companySize')}
                                />
          </div>
        </div>    

        <div style={{ padding: "2px 15px"}}>          
          <div className="send-message__input"  style={{  padding: "2px 10px"}}>
            <label>Interested in</label>
            <CommonSelect
                                  
                                  name="config"
                                  className="popup-font-select"
                                  placeholder="select interest"
                                  defaultValue={lead_interest}   
                                  options={interested}
                                  onChange={e => updateText(e.target.innerText, 'lead_interest')}
                                />
          </div>
        </div>    

        <div style={{ height: "40px" }}></div>
       
      </Modal.Content>
      <Modal.Actions className="send-message__btn">
        <Button onClick={isModalClose} className="send-message__btn-grey">
          close
        </Button>
        <CommonButtons
          content="Update"
          btnClass="send-message__btn-blue"
          background="#007bff"
          style={{ color: 'white' }}
          onClick={() => onUpdateRequest(ldData)}
        />
      </Modal.Actions>
    </Modal>
  )
}
export default UpdateLeadModal
