import React, { useState } from 'react'
import ColorPickerQuick from './ColorPickerQuick'
import { TextArea, Rail, Ref, Button, Tab } from 'semantic-ui-react'
import CommonWorkingHour from '../../../common/CommonWorkingHour'
import QuickSetupWorkingHour from '../../../common/QuickSetupWorkingHour'
import WorkingHours from '../../settings/WorkingHours'

import CommonInput from '../../../common/CommonInput'
import CommonSelect from '../../../common/CommonSelect'
import NodeToggle from '../../../common/NodeToggle'
import ContentFooter from '../ContentFooter'
// import modernTemplate from '../../../assets/images/modern_template.png'
import smartTemplate from '../../../assets/images/smart-template.png'
import updateHourStatus from '../../../config/togglehoursStatus'
import classicTemplate from '../../../assets/images/Classic.png'
import modernTemplate from '../../../assets/images/modern.png'
import TimezonePicker from 'react-timezone'
import iconStyle from '../../../assets/images/Dashboard 2-07.png'
import callMeBack from '../../../assets/images/phone-black.svg'
import callMeLetter from '../../../assets/images/mini-clock.png'
import leaveMessage from '../../../assets/images/message.png'
import axios from 'axios'
import { CommonNotify } from '../../../common/CommonNotify'


const content = {
  header: 'When are you available for meetings?',
  p:
    `Set your company's business hours to let your customers know about your availability`
}

const URL = {
  get: 'user/working-hours',
  post: 'user/working-hours',
  delete: 'user/working-hours'
}

const panes = [
  { menuItem: 'Web Call', render: () => <Tab.Pane>Tab 1 Content</Tab.Pane> },
  {
    menuItem: 'Call Me Back',
    render: () => <Tab.Pane>Tab 2 Content</Tab.Pane>
  },
  {
    menuItem: 'Call Me Later',
    render: () => <Tab.Pane>Tab 3 Content</Tab.Pane>
  },
  {
    menuItem: 'Leave A Message',
    render: () => <Tab.Pane>Tab 3 Content</Tab.Pane>
  }
]
const ConfigureWorkingHour = props => {
  const { prev, next } = props
  const [language, setLanguage] = useState('English')
  const [companyName, setCompanyName] = useState('')
  const [message, setMessage] = useState('')
  const [theme, setTheme] = useState('Classic')
  const [color, setColor] = useState('#662D91')
  const [business_hours_status, setBuisnessHourStatus] = useState(0)
  const [selectedTimeZone, setSelectedTimezone]= useState('UTC')
  const [tabs, setTabs] = useState({
    clickToCall: true,
    callMeBack: false,
    callMeLater: false,
    leaveMessage: false
  })
  const [onContinueButton, setOnContinueButton] = useState(false)

  const increaseStep = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/increase-onboarding`
    axios
      .post(url, {})
      .then(res => {
        setOnContinueButton(false)       
        next()
      })
      .catch(err => { })
  }

  const setLoader = async () => {
   
  }


  return (
      <div>
        <WorkingHours  nextStep={true} increaseStep={increaseStep} activeTab="Business Hours" loading={false}></WorkingHours>
      </div>
  )
}

export default ConfigureWorkingHour
