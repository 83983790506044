import React, { Component } from 'react'
import axios from 'axios'
import CommonTable from '../../common/CommonTable'
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
// redux
import { connect } from 'react-redux'
import { getRecentChargeLog } from '../../actions/settings'

class RecentChargeslog extends Component {
  state = {
    dataTable: {
      type: '8',
      header: [
        {
          headerTitle: 'ID'
        },
        {
          headerTitle: 'Time'
        },
        {
          headerTitle: 'Details'
        },
        {
          headerTitle: 'Category'
        },
        {
          headerTitle: 'Event Name'
        }
      ],
      tableContentData: []
    },
    isLoading: true
  }

  componentDidUpdate(prevProps) {
    if (this.props.recentChargeLog !== prevProps.recentChargeLog) {
      const d = this.props.recentChargeLog.data       

      const { dataTable } = this.state
      let allData = dataTable.tableContentData

      for (let index = 0; index < d.length; index++) {
        const result = {
            columnOne: d[index].id,
            columnTwo: d[index].log.time,
            columnThree: d[index].log.details,
            columnFour: d[index].log.category,
            columnFive: d[index].log.event_name,        
            action: 'edit'         
        }
        allData.push(result)
      }

      this.setState({ tableContentData: allData, isLoading: false })   
    }
  }

  componentDidMount() {
    if (this.props.recentChargeLog) {
      const d = this.props.recentChargeLog.data       

      const { dataTable } = this.state
      let allData = dataTable.tableContentData

      for (let index = 0; index < d.length; index++) {
        const result = {
          columnOne: d[index].id,
          columnTwo: d[index].log.time,
          columnThree: d[index].log.details,
          columnFour: d[index].log.category,
          columnFive: d[index].log.event_name     
          
        }
        allData.push(result)
      }

      this.setState({ tableContentData: allData, isLoading: false })   
    } else {
      this.setState({ isLoading: true })   
      this.props.getRecentChargeLog()
    }

    
    
  }

  firstLetterCap = (string) =>{
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    return (
      <>
        <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>
        <div className="billing-invoice-margin invoices">
          <CommonTable dataTable={this.state.dataTable} type="8" />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  recentChargeLog: state.settings.recentChargeLog,
});

export default connect(
  mapStateToProps,
  { getRecentChargeLog }
)(RecentChargeslog)