import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, IconButton, Typography } from '@mui/material'
import axios from 'axios'
import PhoneInput from 'react-phone-input-2'
import { Modal } from 'semantic-ui-react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom/cjs/react-router-dom.min'


const AdminVerification = (props) => {
  const { open, onClose } = props
  const suspend = props.suspend
  const closed = props.closed
  const [data, setData] = useState({ phone: '', code: '' })
  const [askPhone, setAskPhone] = useState(false)
  const history = useHistory()
  const handleSupport = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/webhook/wordpress`
    await axios
      .post(url, { ...data })
      .then(res => {
        if (res.data.data) {
        }
      })
  }
  const userData = JSON.parse(localStorage?.getItem('__user_traits'))

  return (
    <Modal
      open={open}
      onClose={onClose}
      // size={size}
      className="verification-modal"
    >
      <Modal.Header>{' '}</Modal.Header>
      <Modal.Content >
        {!askPhone &&
          <> <AccessTimeIcon />
            {suspend ?
              <>
                <Typography variant="h4">Your account is currently suspended</Typography>
                <Typography variant='body1' >Please contact support to reactivate your account.</Typography>
              </> :
              closed ?
                <>
                  <Typography variant="h4">Your account is currently closed</Typography>
                  <Typography variant='body1' >Please contact support to reactivate your account.</Typography>
                </>
                :
                <>
                  <Typography variant="h4">Account under review</Typography>
                  <Typography variant='body1' >Your account is pending verification. It will be reviewed shortly by our team.</Typography>
                  <Typography variant='body1' >We will be calling you from  +1-928-236-5243  on your registered number {userData?.phone}   to activate your account.</Typography>
                  <Typography variant='body1' >Note: You wont be able to use calling/texting/callback feature until your account is reviewed and activated.</Typography>
                </>
            }



            {/* {!suspend && !closed &&
                <div> <a href='https://limecall.com/activate' style={{color: '#fff',backgroundColor: '#1976d2',
                   padding: '6px 16px', borderRadius: 4, textDecoration:'none', boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'}} >Request a callback now</a></div>} */}
            <div style={{ marginTop: '15px' }}>

            <a href="https://help.limephone.io" target='_blank' style={{
                color: '#fff', backgroundColor: '#1976d2',
                padding: '6px 16px', borderRadius: 4, textDecoration: 'none', boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
              }}>Helpdesk</a>
            <a href='mailto:support@limephone.io' style={{
                color: '#fff', backgroundColor: '#1976d2', marginLeft: '10px',
                padding: '6px 16px', borderRadius: 4, textDecoration: 'none', boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
              }}>Email Support</a>
            </div>

          </>
        }

        {askPhone &&
          <> <div className="send-message__input" style={{ flex: "50%", padding: "2px 10px" }}>
            <label>Phone Number</label>
            <PhoneInput
              country={'eg'}
              className="text-color"
              // value={ldData.phone_number}
              onChange={(phone, code) => {
                setData({ ...data, phone: phone, code: `+${code?.dialCode}` })
              }}
              style={{ width: "180px" }}
            />
          </div>
            <Button variant="contained" onClick={() => handleSupport()} >Verify</Button>
          </>
        }
      </Modal.Content>
      <Modal.Actions>{' '}{/* <Button>Request</Button> */}</Modal.Actions>
    </Modal>
  )
}



export default AdminVerification