import React, { useState, useEffect } from 'react'
import { Form, Input } from 'semantic-ui-react'

import Autosuggest from 'react-autosuggest';

import CameraIcon from '../../assets/images/camera.png'
import ReactPhoneInput from 'react-phone-input-2'
import CommonInput from '../../common/CommonInput'
import { billingCountryList } from '../../helpers/billingCountryList'

const AddContactDetails = ({
  setcreateContact,
  createContact,
  handleInputChange,
  fileChangedHandler,
  members,
  companyList
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [value, setValue] = useState("")

  useEffect(() => {

    setcreateContact((prev) => ({ ...prev, company: value }))


  }, [value])

  const getSuggestions = value => {
    const inputValue = value?.trim().toLowerCase()
    const inputLength = inputValue?.length
    return inputLength === 0 ? [] : companyList?.filter(lang =>
      (lang?.name || lang?.organization_name)?.toLowerCase().slice(0, inputLength) === inputValue
    )
  }

  const getSuggestionValue = suggestion => suggestion.name || suggestion?.organization_name

  const renderSuggestion = suggestion => (
    <div className="autocomplete-items">
      {suggestion.name || suggestion?.organization_name}
    </div>
  )

  const onChange = (event, { newValue }) => {

    setValue(newValue)
  }


  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const inputProps = {
    placeholder: 'Company name',
    value,
    onChange: onChange
  }

  return (
    <>
      <Form>
        <div className="contactWrap">
          <div className="profileMain">
            <div style={{ display: 'none' }} className="profileWrap">
              <Input
                type="file"
                name="image"
                autoComplete="off"
                onChange={e => fileChangedHandler(e)}
              />
              <img src={CameraIcon} className="" />
            </div>

          </div>
          <div className="companyDetail">
            <Form.Field>
              <label>Customer Name</label>
              <CommonInput
                autocomplete="off"
                type="text"
                className="form-control"
                onChange={e => handleInputChange(e)}
                name="name"
                placeholder="Name"
                value={createContact.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Customer Email</label>
              <CommonInput
                autocomplete="off"
                onChange={e => handleInputChange(e)}
                placeholder="email"
                name="email"
                type="email"
                value={createContact.email}
              />
            </Form.Field>
            <Form.Field>
              <label>Company</label>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />

            </Form.Field>
            <Form.Field>
              <label>Owner</label>
              <select
                name="owner"
                value={createContact.owner}
                placeholder="Owner list"
                onChange={e => handleInputChange(e)}
              >
                {members?.map((e, key) => {
                  return (
                    <option key={key} value={e.value}>
                      {e.value}
                    </option>
                  )
                })}
              </select>
            </Form.Field>{' '}
            <label>Phone number</label>
            <Form.Field>
              <ReactPhoneInput
                inputExtraProps={{
                  name: 'phone_number',
                  required: true,
                  autoFocus: true
                }}
                country={'us'}
                value={createContact.phone_number}
                onChange={(e, c) => handleInputChange(e, c, 'phone_number')}
              />
            </Form.Field>
            <Form.Field>
              <label>Country</label>

              <select
                name="country"
                onChange={e => handleInputChange(e)}
                value={createContact.country}
                placeholder="country"
              >
                {billingCountryList.map((country, key) => (
                  <option key={key} value={country.text}>
                    {country.text}
                  </option>
                ))}
              </select>
            </Form.Field>
            {/* <Form.Field>
              <label>City</label>
              <CommonInput
                autocomplete="off"
                type="text"
                className="form-control"
                onChange={e => handleInputChange(e)}
                name="city"
                placeholder="city"
                value={createContact.city}
              />{' '}
            </Form.Field> */}
          </div>
        </div>
      </Form>
    </>
  )
}

export default AddContactDetails
