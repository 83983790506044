import React from 'react'
import { Tab } from 'semantic-ui-react'

import notificationIcon from '../../assets/images/notification.png'
import meetingIcon from '../../assets/images/meeting.png'
import loaderIcon from '../../assets/images/loader.png'
import calendermeetIcon from '../../assets/images/calendermeet.png'
import time from '../../assets/images/time.png'
import closeIcon from '../../assets/images/closeGray.png'

import DetailPane from './detailsPane'
const panes = [
  {
    menuItem: 'Details',
    render: () => <DetailPane />
  },

  {
    menuItem: 'Notes',
    render: () => <h1></h1>
  },
  {
    menuItem: 'Tags',
    render: () => <h1></h1>
  }
]

const MeetingModalSide = ({ setDetailPopUp, detailPopUp }) => {
  return (
    <div
      className={detailPopUp == true ? 'details-popup active' : 'details-popup'}
    >
      <div className="details-title">
        <p>Meeting details</p>
        <img
          className=""
          src={closeIcon}
          onClick={() => setDetailPopUp(false)}
          alt=""
        />
      </div>
      <div className="details-popup-profile">
        <div className="details-title-icon">Livevpn with Steve</div>
        <div className="calenderIcon">
          <img className="" src={calendermeetIcon} alt="" />
          <label>Mon,21 Apr</label>
        </div>
        <div className="timeIcon">
          <img className="" src={time} alt="" />
          <label>09:00 - 09:30</label>
        </div>
        <div className="details-popup-profile-detail">
          <div className="social-box-wrapper">
            <div className="social-boxes">
              <img className="" src={notificationIcon} alt="" />
            </div>
            <div className="social-boxes">
              <img className="" src={meetingIcon} alt="" />
            </div>
            <div className="social-boxes">
              <img className="" src={loaderIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="contact-popup-detail">
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </div>
    </div>
  )
}
export default MeetingModalSide
