import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Dimmer,
  Form,
  Loader,
  Radio
} from 'semantic-ui-react'
import axios from 'axios'
import googleCalenderIcon from '../../assets/images/google-calendar.png'
import ZoomVideoCall from '../../assets/images/video-player.png'
import WarningSignIcon from '../../assets/images/warning-sign.png'
import CommonButtons from '../../common/CommonButtons'
import CalendarIntegrationModal from '../../common/CalendarIntegrationModal'
import {
  disConnectGoogleCalendarApi,
  getGoogleCalendarURLApi,
  getUserIntegrationsApi,
  updateCalendarSettingsApi
} from '../../api/addConnectionsApi'
import { CommonNotify } from '../../common/CommonNotify'
import { Dropdown } from 'semantic-ui-react'

//redux
import { connect } from 'react-redux'
import { getUserIntegrations } from '../../actions/settings'
import { Col, Row } from 'react-bootstrap'

const AddConnections = props => {
  const [conflictsModal, setConflictsModal] = useState(false)
  const [addCalendarModal, setAddCalendarModal] = useState(false)
  const [userGoogleCalendar, setUserGoogleCalendar] = useState([])
  const [selectedConflicts, setSelectedConflicts] = useState({})
  const [selectedAddToCalendar, setSelectedAddToCalendar] = useState({})
  const [defaultEventCheckBox, setDefaultEventCheckBox] = useState('')
  const [loading, setLoading] = useState(false)
  const [googleLoading, setGoogleLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [disconnectLoading, setDisconnectLoading] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)

  useEffect(() => {
    if (props.userIntegrations) fetchAddConnections(props.userIntegrations)
  }, [props.userIntegrations])

  useEffect(() => {
    if (props.userIntegrations) {
      fetchAddConnections(props.userIntegrations)
    } else {
      props.getUserIntegrations()
    }
    const query = window?.location?.href
    const code = query?.split('code=')[1]?.split('&')[0]

    if (!!code) {
      integrateGoogleCalender()
    }
  }, [])

  const fetchAddConnections = async response => {
    setLoading(true)
    //const response = await getUserIntegrationsApi()
    setLoading(false)
    if (response) {
      const { data = [] } = response
      const filterData = data.filter(
        item => item.integration === 'Google Calendar'
      )
      setUserGoogleCalendar(filterData)
      setDefaultEventCheckBox(filterData.length ? filterData?.[0]?.email : '')
      setSelectedAccount(filterData?.[0] || null)
      setSelectedAddToCalendar(filterData?.[0] || {})
    }
  }

  const handleSelectAccount = value => {
    const select = userGoogleCalendar.find(
      ({ email }) => email === value?.value
    )
    setDefaultEventCheckBox(value?.value)
    setSelectedAccount(select)
  }

  const integrateGoogleCalender = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/save-google-calendar-user`
    const apiToken = await localStorage.getItem('access_token')

    const query = window?.location?.href
    const state = decodeURIComponent(query?.split('state=')[1]?.split('&')[0])
    const code = decodeURIComponent(query?.split('code=')[1]?.split('&')[0])
    const scope = decodeURIComponent(
      query?.split('scope=')[1]?.split('&')[0]
    ).replaceAll('+', ' ')
    const authuser = query?.split('authuser=')[1]?.split('&')[0]
    const prompt = query?.split('prompt=')[1]?.split('&')[0]
    var bodyFormData = new FormData()
    bodyFormData.append('code', code)

    axios
      .post(url, bodyFormData)
      .then(res => {
        props.getUserIntegrations()
        fetchAddConnections()
      })
      .catch(error => {})
  }

  const onConnectGoogleCalendar = async () => {
    window.open(
      `https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=offline&client_id=561194678958-bpdro47f9k0vub52o06rsiisqbqt2n8i.apps.googleusercontent.com&redirect_uri=${process.env.REACT_APP_ROOT_URL}/settings/personal/integrations&state&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar &approval_prompt=force`,
      'rightFrame'
    )
  }

  const onClickConflictModal = data => {
    setSelectedConflicts(data)
    setConflictsModal(true)
  }

  const onClickAddToCalendarModal = () => {
    setAddCalendarModal(true)
  }

  const onChangeCheckConflicts = () => {
    setSelectedConflicts({
      ...selectedConflicts,
      check_conflict: selectedConflicts.check_conflict === 1 ? 0 : 1
    })
  }

  const onUpdatingConflicts = async () => {
    try {
      const { check_conflict, push_events } = selectedConflicts
      const formData = new FormData()
      formData.append('check_conflicts', check_conflict)
      formData.append('push_events', push_events)
      setUpdateLoading(true)
      await updateCalendarSettingsApi(selectedConflicts.id, formData)
      props.getUserIntegrations()
      await fetchAddConnections()
      setConflictsModal(false)
      setUpdateLoading(false)
    } catch (error) {
      setUpdateLoading(false)
      const { errors = [] } = error || {}
      if (errors.length) {
        CommonNotify(errors[0])
      } else {
        CommonNotify('Something went wrong!')
      }
    }
  }

  const renderConflictsHeader = () => {
    return (
      <div className="conflicts-header">
        <div className="heading">How should we check for conflicts?</div>
        <div className="google-email">
          <span className="google-logo">
            <img src={googleCalenderIcon} alt="google-calendar" />{' '}
          </span>
          <span className="email">{selectedConflicts.email}</span>
        </div>
      </div>
    )
  }

  const renderConflictsContent = () => {
    return (
      <div className="conflicts-content">
        <div className="check">Check these places for conflicts</div>
        <Checkbox
          label={selectedConflicts.email}
          checked={selectedConflicts.check_conflict === 1 ? true : false}
          onChange={onChangeCheckConflicts}
        />
      </div>
    )
  }

  const renderConflictsFooter = () => {
    return (
      <div className="conflicts-footer">
        <CommonButtons
          onClick={() => setConflictsModal(false)}
          content="Cancel"
          background="gray"
          btnClass="btn-modal-style"
        />
        <CommonButtons
          onClick={onUpdatingConflicts}
          content="Update"
          background="blue"
          btnClass="btn-modal-style"
          loading={updateLoading}
        />
      </div>
    )
  }

  const handleChangeCheckBox = (e, { value }) => {
    if (value === 'Do not add new events to a calendar') {
      setDefaultEventCheckBox(value)
      setSelectedAddToCalendar({
        check_conflict: null,
        email: 'Do not add new events to a calendar',
        id: null,
        integration: null,
        push_events: null,
        status: null
      })
      return
    }
    const findSelectedCalendar = userGoogleCalendar.find(
      item => item.email === value
    )
    setSelectedAddToCalendar(findSelectedCalendar)
    setDefaultEventCheckBox(value)
  }

  const onChangePushEvent = () => {
    setSelectedAddToCalendar({
      ...selectedAddToCalendar,
      push_events: selectedAddToCalendar.push_events ? 0 : 1
    })
  }

  const onUpdatingAddCalendar = async () => {
    if (selectedAddToCalendar.email !== 'Do not add new events to a calendar') {
      try {
        const { check_conflict, push_events } = selectedAddToCalendar
        const formData = new FormData()
        formData.append('check_conflicts', check_conflict)
        formData.append('push_events', push_events)
        setUpdateLoading(true)
        await updateCalendarSettingsApi(selectedAddToCalendar.id, formData)
        await fetchAddConnections()
        props.getUserIntegrations()
        setUpdateLoading(false)
        setAddCalendarModal(false)
      } catch (error) {
        setUpdateLoading(false)
        const { errors = [] } = error || {}
        if (errors.length) {
          CommonNotify(errors[0])
        } else {
          CommonNotify('Something went wrong!')
        }
      }
    } else {
      try {
        const modifiedPayload = userGoogleCalendar.filter(
          item => item.email !== 'Do not add new events to a calendar'
        )
        setUpdateLoading(true)
        await Promise.all(
          modifiedPayload.map(async data => {
            const formData = new FormData()
            formData.append('check_conflicts', data.check_conflict)
            formData.append('push_events', 0)
            try {
              await updateCalendarSettingsApi(data.id, formData)
              return Promise.resolve()
            } catch (error) {
              return Promise.reject()
            }
          })
        )
      } catch (error) {
        setUpdateLoading(false)
        const { errors = [] } = error || {}
        if (errors.length) {
          CommonNotify(errors[0])
        } else {
          CommonNotify('Something went wrong!')
        }
      } finally {
        props.getUserIntegrations()
        await fetchAddConnections()
        setUpdateLoading(false)
        setAddCalendarModal(false)
      }
    }
  }

  const renderAddCalendarHeader = () => {
    const modifiedOption = [
      ...userGoogleCalendar,
      {
        check_conflict: null,
        email: 'Do not add new events to a calendar',
        id: null,
        integration: null,
        push_events: null,
        status: null
      }
    ]
    return (
      <div className="conflicts-header add-calendar">
        <div className="heading">
          Which calendar should we add new events to?
        </div>
        <Form>
          <Form.Group className="row mt-3">
            {modifiedOption.length
              ? modifiedOption.map((data, index) => {
                  if (selectedAccount?.email === data?.email || !data?.id) {
                    return (
                      <Form.Field
                        key={index}
                        control={Radio}
                        label={data.email}
                        value={data.email}
                        className="col-12"
                        checked={defaultEventCheckBox === data.email}
                        onChange={handleChangeCheckBox}
                      />
                    )
                  } else {
                    return null
                  }
                })
              : null}
          </Form.Group>
        </Form>
      </div>
    )
  }

  const renderAddCalendarContent = () => {
    return (
      <div className="conflicts-content">
        {selectedAddToCalendar.email !==
        'Do not add new events to a calendar' ? (
          <>
            <h6>Add to calendar</h6>
            <Dropdown
              placeholder={'Select Account'}
              fluid
              search
              selection
              name="Google account"
              className="mb-3"
              value={selectedAccount?.email || ''}
              options={
                selectedAccount &&
                [selectedAccount].map(option => ({
                  key: option?.id,
                  text: option?.email,
                  value: option?.email
                }))
              }
            />
            <div className="check">Sync cancellations</div>
            <Checkbox
              label="Deleting or declining an event in your calendar will also cancel it in LimePhone."
              onChange={onChangePushEvent}
              checked={selectedAddToCalendar.push_events === 1 ? true : false}
            />
          </>
        ) : null}
      </div>
    )
  }

  const renderAddCalendarFooter = () => {
    return (
      <div className="conflicts-footer">
        <CommonButtons
          onClick={() => setAddCalendarModal(false)}
          content="Cancel"
          background="gray"
          btnClass="btn-modal-style"
        />
        <CommonButtons
          onClick={() => onUpdatingAddCalendar()}
          content="Update"
          background="blue"
          btnClass="btn-modal-style"
          loading={updateLoading}
        />
      </div>
    )
  }

  const onDisconnectGoogleCalendar = async () => {
    try {
      setDisconnectLoading(true)
      await disConnectGoogleCalendarApi()
      setSelectedAccount(null)
      await fetchAddConnections()
      props.getUserIntegrations()
      setDisconnectLoading(false)
    } catch (error) {
      setDisconnectLoading(false)
      const { errors = [] } = error || {}
      if (errors.length) {
        CommonNotify(errors[0])
      } else {
        CommonNotify('Something went wrong!')
      }
    }
  }

  return (
    <>
      <div className="share-your-link-wrapper integrations">
        {!loading ? (
          <div className="share-your-link">
            {userGoogleCalendar.length ? (
              <div className="my-calendar">
                <div>
                  My Calendar Accounts {userGoogleCalendar.length}/
                  {userGoogleCalendar.length}
                </div>
              </div>
            ) : null}

            <div>
              <div className="connect-block">
                <h2 className="accordion-title">Google Calendar</h2>
                <CommonButtons
                  type="button"
                  content="+ Connect calendar"
                  background="blue"
                  onClick={e => onConnectGoogleCalendar(e, 'sign-in')}
                  loading={googleLoading}
                />
              </div>
              {userGoogleCalendar?.length > 0 && (
                <Row>
                  <Col md={4}>
                    <Dropdown
                      placeholder={'Select Account'}
                      onChange={(e, result) => handleSelectAccount(result)}
                      fluid
                      search
                      selection
                      name="Google account"
                      className="mb-3"
                      value={selectedAccount?.email || ''}
                      options={userGoogleCalendar?.map(option => ({
                        key: option?.id,
                        text: option?.email,
                        value: option?.email
                      }))}
                    />
                  </Col>
                </Row>
              )}
              {!userGoogleCalendar?.length > 0 && (
                <>
                  <div
                    className="accordion-description"
                    style={{
                      fontSize: '14px',
                      fontWeight: 300,
                      color: '#c1c1c1',
                      marginBottom: '30px',
                      margin: '10px',
                      fontWeight: '600'
                    }}
                  >
                    Set the calendar(s) to check for conflicts to prevent double
                    bookings or if you would like to add newly scheduled events.
                  </div>
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 300,
                      color: '#212529',
                      marginBottom: '30px',
                      margin: '10px',
                      fontWeight: '600'
                    }}
                  >
                    Connected accounts
                  </span>
                  <div className="card-manage-number" style={{ width: '100%' }}>
                    <div
                      className="accordion-description"
                      style={{
                        fontSize: '16px',
                        fontWeight: 300,
                        color: '#c1c1c1',
                        marginBottom: '10px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        fontWeight: '600'
                      }}
                    >
                      You don't have any calendars connected.
                    </div>
                    <span>
                      To connect a new calendar,press the + Connect calendar
                      button.
                    </span>
                  </div>
                </>
              )}
            </div>

            {userGoogleCalendar.length > 0 &&
              selectedAccount &&
              Object.keys(selectedAccount)?.length > 0 && (
                <>
                  <div>
                    {userGoogleCalendar.length
                      ? userGoogleCalendar.map((data, index) => {
                          if (selectedAccount?.email === data?.email) {
                            return (
                              <div className="disconnect-block" key={index}>
                                <div className="text-wrapper">
                                  <span className="google-logo">
                                    <img
                                      src={googleCalenderIcon}
                                      alt="google-calendar"
                                    />{' '}
                                  </span>
                                  <div className="google-email">
                                    <span>Google Calendar</span>
                                    <span>{data.email}</span>
                                  </div>
                                </div>
                                <div className="drop-down">
                                  <Button
                                    onClick={onDisconnectGoogleCalendar}
                                    loading={disconnectLoading}
                                  >
                                    Disconnect
                                  </Button>
                                </div>
                              </div>
                            )
                          } else {
                            return null
                          }
                        })
                      : null}
                  </div>
                  <div className="configuration">
                    <h2>Configuration</h2>
                    <div className="config-content">
                      <div className="config-left">
                        <img src={googleCalenderIcon} alt="google-calendar" />
                        <div className="check-conflicts">
                          <b>Check for conflicts</b>
                          <p>
                            Set the calendar(s) to check for conflicts to
                            prevent double bookings.
                          </p>
                        </div>
                      </div>
                      <div className="config-right-list">
                        {userGoogleCalendar.length
                          ? userGoogleCalendar.map((data, index) => {
                              if (selectedAccount?.email === data?.email) {
                                return (
                                  <div
                                    className="config-right"
                                    onClick={() => onClickConflictModal(data)}
                                    key={index}
                                  >
                                    <div className="inner">
                                      <span className="google-logo">
                                        <img
                                          src={googleCalenderIcon}
                                          alt="google-calendar"
                                        />{' '}
                                      </span>
                                      <div className="check-email">
                                        <span>Check {data.email}</span>
                                        <li>{data.email}</li>
                                      </div>
                                    </div>
                                    <span className="edit">Edit</span>
                                  </div>
                                )
                              } else {
                                return null
                              }
                            })
                          : null}
                      </div>
                    </div>
                    {selectedAccount &&
                      Object.keys(selectedAccount)?.length > 0 && (
                        <div className="config-content add-calendar">
                          <div className="add-calendar-block">
                            <div className="config-left">
                              <img
                                src={googleCalenderIcon}
                                alt="google-calendar"
                              />
                              <div className="check-conflicts">
                                <b>Add to calendar</b>
                                <p>
                                  Set the calendar you would like to add new
                                  events to as they’re scheduled.
                                </p>
                              </div>
                            </div>
                            <div className="config-right-list">
                              {userGoogleCalendar.length > 0
                                ? userGoogleCalendar?.map((list, index) => {
                                    if (
                                      selectedAccount?.email === list?.email
                                    ) {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className="config-right"
                                            onClick={() =>
                                              onClickAddToCalendarModal()
                                            }
                                          >
                                            <>
                                              {list?.push_events ? (
                                                <div className="inner">
                                                  <span className="google-logo">
                                                    <img
                                                      src={googleCalenderIcon}
                                                      alt="google-calendar"
                                                    />{' '}
                                                  </span>
                                                  <div className="check-email">
                                                    <span>
                                                      Add {list?.email}
                                                    </span>
                                                    <li>{list?.email}</li>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="inner">
                                                  <span className="google-logo">
                                                    <img
                                                      src={WarningSignIcon}
                                                      alt="google-calendar"
                                                    />{' '}
                                                  </span>
                                                  <div className="check-email">
                                                    <span>
                                                      Calendar not selected
                                                    </span>
                                                    <span>
                                                      Do not add new events to
                                                      any calendar
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                            <span className="edit">Edit</span>
                                          </div>
                                        </>
                                      )
                                    } else {
                                      return null
                                    }
                                  })
                                : null}
                            </div>
                          </div>
                          {!userGoogleCalendar[0].push_events ? (
                            <div className="container-no-calendar">
                              <div className="no-calendar">
                                There is no calendar selected to add events to.
                                Events booked will not be automatically added to
                                your calendar.
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                  </div>
                </>
              )}
          </div>
        ) : (
          <Dimmer active={loading}>
            <Loader />
          </Dimmer>
        )}

        <CalendarIntegrationModal
          className="conflicts-modal"
          dimmer="inverted"
          size="tiny"
          open={conflictsModal}
          onClose={() => setConflictsModal(false)}
          renderHeader={() => renderConflictsHeader()}
          renderContent={() => renderConflictsContent()}
          renderFooter={() => renderConflictsFooter()}
        />
        <CalendarIntegrationModal
          className="conflicts-modal"
          dimmer="inverted"
          size="tiny"
          open={addCalendarModal}
          onClose={() => setAddCalendarModal(false)}
          renderHeader={() => renderAddCalendarHeader()}
          renderContent={() => renderAddCalendarContent()}
          renderFooter={() => renderAddCalendarFooter()}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  userIntegrations: state.settings.userIntegrations
})

export default connect(mapStateToProps, { getUserIntegrations })(AddConnections)
