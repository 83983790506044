import React, { Fragment } from 'react'
import { Table } from 'semantic-ui-react'
import { Dimmer, Loader } from 'semantic-ui-react'


import EditModal from '../common/EditModal'
import deleteIcon from '../assets/images/delete-icon.png'
import axios from 'axios'

const CommonTable = props => {
 
  const { deleteTeam, loadTeam } = props

  const handleUpdatedData = data => {
    return props.handleUpdatedInfo(data)
  }

  const downloadInvoice = id => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/download-invoice/${id}`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {         
          window.open(res.data.data)
        }
      })
      .catch(function(error) {
       
      })
  }

  return (
    <div className="common-table-wrapper">
      <Dimmer active={props.tableLoading}>
        <Loader />
      </Dimmer>
      <Table singleLine>
        <Table.Header className="top-table-header">
          <Table.Row>
            {props.dataTable.header.map((data, i) => {
              return (
                <Table.HeaderCell
                  key={i}
                  className="default-text header-modal-style"
                  style={{ fontSize: '16px', fontWeight: 600 }}
                >
                  {data.headerTitle}
                </Table.HeaderCell>
              )
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Fragment>
            {props.dataTable.tableContentData.map((data, i) => {
              return (
                <Table.Row key={i} className="table-content-row">
                  {data.id && <Table.Cell>{data.id}</Table.Cell>}
                  <Table.Cell>{data.name}</Table.Cell>
                  <Table.Cell>
                    {data.members}
                  </Table.Cell>
                  <Table.Cell>
                    <Fragment>
                      <EditModal
                        id={data.id}
                        teamName={data.name}
                        loadTeam={loadTeam}
                        // teamType={data.default}
                        handleUpdatedData={handleUpdatedData}
                      />
                    </Fragment>
                    <Fragment>
                      <div
                        style={{ marginLeft: '10px', display: 'inline-block' }}
                        className="delete-icon"
                      >
                        <img
                          onClick={e => deleteTeam(data.id, data.name)}
                          src={deleteIcon}
                          alt="delete"
                        />
                      </div>
                    </Fragment>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Fragment>
        </Table.Body>
      </Table>
    </div>
  )
}

export default CommonTable
