import axios from 'axios'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { CommonNotify } from '../../common/CommonNotify'
import CommonSubscriptionModal from '../../common/CommonSubscriptionModal'

export default class HomeTrial extends Component {
  state = {
    phoneLength: 0,
    subscriptionModal: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.personalLink !== prevProps.personalLink) {
      this.setState({
        personalLink: this.props.personalLink
      })
    }
  }
  fetchLeads() {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`
    const uFirstName = localStorage.getItem('first_name')
    const uLastName = localStorage.getItem('last_name')

    var params = {
      page: 1,
      sort: '-id'
    }
    params['filter[agent][is]'] = `${uFirstName} ${uLastName}`

    axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: params
      })
      .then(res => {
        this.setState({ isLoading: false, exportData: [], countItem: 0 })
        this.setState({
          totalInboundVoicemail: res?.data?.data?.totalInboundVoicemail,
          totalAgentLeads: res?.data?.data?.totalAssignedLeads
        })
      })
      .catch(err => {
        this.setState({ isLoading: false })

        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
    const taskURL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/filter-activity`
    var param = {
      user: localStorage.getItem('id')
    }
    axios
      .get(taskURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: param
      })
      .then(res => {
        this.setState({
          task: res?.data?.data?.length || 0
        })
      })
      .catch(err => {
      })
  }

  componentDidMount = async () => {
    const n = JSON.parse(localStorage.getItem('phone_numbers'))
    if (n !== null && n !== undefined) {
      this.setState({ phoneLength: n.length })
    }
    this.setState({ personalLink: this.props.personalLink })
    this.fetchLeads()
  }

  modalClick = async () => {
    this.setState({ subscriptionModal: true })
  }

  closeSubscriptionModal = async () => {
    this.setState({ subscriptionModal: false })
  }

  renderlist() {
    const n = JSON.parse(localStorage.getItem('phone_numbers'))
    // let list = []
    // if (localStorage.getItem('phone_numbers') !== null) {
    //   for (let i = 0; i < (n.length > 2 ? 2 : n.length); i++) {
    //     list.push(
    //       <>
          return   <div className="trial-box-main d-flex border border-1 mb-2">
              <div className="row justify-space-between w-100">
                <div className="col  border-right">
                  <div className="d-flex align-items-center">
                    <i
                      className="fa fa-phone fa-rotate-90 phon-icon"
                      aria-hidden="true"
                    ></i>
                    <div className="ml-4">
                      <p className="phone"> Phone Number</p>
                      <h4> {n[0]?.phone_number}</h4>
                    </div>
                  </div>
                </div>
                <div className="col border-right">
                  <p className="phone"> Date Activated</p>
                  <p>
                    {(n[0]?.purchased_at &&
                      moment(n[0]?.purchased_at).format('DD-MM-YYYY')) ||
                      '-'}
                  </p>
                </div>
                <div className="col">
                  <p className="phone"> Status</p>
                  <p className={+n[0]?.status === 1 ? 'active' : 'trial'}>
                    <span></span>
                    {+n[0]?.status === 1 ? 'Active' : 'Trial'}
                  </p>
                </div>
              </div>
            </div>
    //       </>
    //     )
    //   }
    // }
    // return list
  }

  renderDemo() {
    return (
      <div
        className="trial-box"
        style={{ border: '1px solid rgb(0, 113, 235)' }}
      >
        <div className="banner-icon"></div>
        <div className="banner-description-wrapper">
          <div className="booking-link">
            <p className="fontize">{this.state.personalLink} </p>
          </div>
          <Button.Group>
            <Button onClick={() => this.onCopyText(this.state.personalLink)}>
              Copy
            </Button>
            <Button.Or />
            <Button
              onClick={() => window.location.assign(this.state.personalLink)}
              style={{ backgroundColor: '#0071eb', color: 'white' }}
            >
              Preview
            </Button>
          </Button.Group>
        </div>
      </div>
    )
  }

  /*   getPersonalLink = async () => {

    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/profile`
   
    
    axios
      .get(url)
      .then(res => {
        const urlData = res.data.data.personal_link.split('me/').pop()
        const personalData = res.data.data
        const roleData = res.data.data.role.name
        const full_name = res.data.data.first_name + ' ' + res.data.data.last_name          
        this.setState({personalLink: res.data.data.personal_link})      
      })
      .catch(error => {       
       
      })
  } */

  onCopyText = async jsCode => {
    try {
      await navigator.clipboard.writeText(jsCode)
      CommonNotify('Copied!', 'success')
    } catch (err) {
      CommonNotify('Failed to copy!')
    }
  }

  render() {
    return (
      <div className="cardbox-wrapper deatil_box">
        <div className="container-trial">
          <div className="banner-description-wrapper">
            {/* <p className="trial-headline"> {this.props.titleLine} <a className="link-color" onClick={this.modalClick}>{this.props.pleaseUpgrade}</a>{this.props.upgradeLine}</p> */}
            <div className="trial-back">
              <CommonSubscriptionModal
                open={this.state.subscriptionModal}
                onClose={this.closeSubscriptionModal}
                currentPlan={
                  this.props.subscriptionData.tableContentData[0].plan_name
                }
                dataTable={this.props.subscriptionData}
              />
              <div className="row" style={{ width: 'auto' }}>
                {/* ----------------------------- First Row -------------------------- */}
                {
                  this.state.phoneLength ? 
                  <div class="col-md-6">
                  <p class="fontsize-label">
                    YOU HAVE GOT 
                    <Link to="/numbers">{` ${this.state.phoneLength || 0}
                       PHONE NUMBERS.`}</Link>
                  </p>

                  <div>
                    {this.renderlist()}
                    {this.state.phoneLength > 3 ? (
                      <p
                        onClick={() => (window.location.href = '/numbers')}
                        className="view-more"
                      >
                        <a>Show More</a>
                      </p>
                    ) : null}
                    {this.state.phoneLength === 0 ? (
                      <div class="add-number-btn">
                        <div class="add-numbercta">
                          <button
                            onClick={() =>
                              (window.location.href = '/addNumbers')
                            }
                            class="ui button btn-blue"
                          >
                            {' '}
                            <p class="wrap-text">Add Number&nbsp;&nbsp;</p>
                          </button>
                        </div>
                        <p class="btn-title">Get a number for your business.</p>
                      </div>
                    ) : null}
                  </div>
                </div>: null}
                {/* <div className="col-lg-6">
                  <p className="fontsize-label">
                    Your personal booking page
                    <Link to="/settings/personal/my_page"> Manage </Link>{' '}
                  </p>
                  <p
                    className="fontsize-label"
                    style={{ fontSize: 14, color: '#c2c2c2' }}
                  >
                    Copy the link to the booking page and share it with customer
                    so they can choose their preferred time slot or add it to
                    your email signature
                  </p>

                  <div>{this.renderDemo()}</div>
                </div> */}
                <div className="col-lg-6 lead-wrapper">
                  <Link to="/manageleads">
                    <h4 className="mb-4 text-center fontsize-label">
                      My Stats
                    </h4>
                  </Link>
                  <Row className="justify-content-center">
                    <Col md={11}>
                      <Row>
                        <Col md={4}>
                          <div className="my-leads text-center">
                            <p className="mb-3 ml-0 ">
                              Assigned Calls
                            </p>
                            <p
                              style={{ fontSize: 14 }}
                              className=""
                            >
                              {this.state?.totalAgentLeads || 0}
                            </p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="my-leads text-center">
                            <p className="mb-3 ml-0 ">
                              Voicemail
                            </p>
                            <p
                              style={{ fontSize: 14 }}
                              className=""
                            >
                              {this.state?.totalInboundVoicemail || 0}
                            </p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="my-leads text-center">
                            <p className="mb-3 ml-0 ">
                              Tasks
                            </p>
                            {/* <label style={{ fontSize: 14 }}></label> */}
                            <p
                              style={{ fontSize: 14 }}
                              className=""
                            >
                              {this.state?.task || 0}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                {/* ----------------------------- Second Row ---------------------------- */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
