import React, { useState, useEffect } from 'react'
import { Modal, Button, Form  } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'

import { getLeadOwner } from '../../config/leadAPI'

import CommonButtons from '../../common/CommonButtons'
import CommonInput from '../../common/CommonInput'

import rescheduleclose from '../../assets/images/rescheduleclose.png'

const AddGuestModal = ({ open, setOpen }) => {  

  const [startDate, setStartDate] = useState(new Date())
 
  const onSave = () => {
     
  }

 


  return (
    <Modal
      className="modal-wrapper create-new-team-popup"           
      open={open}
      size="mini"
      onClose={ () => setOpen(false)}
      closeIcon
    >
    <Modal.Header className="modal-header">Add Guest</Modal.Header>
    <Modal.Content open={open} className="rescheduleWrap Reschedule-popup">      
      
        <div className="modal-input-wrapper">
          <p className="modal-input-text default-text">Name</p>
          <CommonInput            
            inputStyle="modal-input"
            name="name"
            placeholder='First name' 
          />
        </div>

        <div className="modal-input-wrapper">
          <p className="modal-input-text default-text">Email</p>
          <CommonInput          
            inputStyle="modal-input"
            name="name"
            placeholder='Email id' 
          />
        </div>

        <div className="modal-input-wrapper">
          <p className="modal-input-text default-text">Phone</p>
          <CommonInput            
            inputStyle="modal-input"
            name="name"
            placeholder="Phone number"
          />
        </div> 
       
           
    </Modal.Content>
    <Modal.Actions className="modal-button-wrapper">
      <CommonButtons
        onClick={() => setOpen(false)}
        content="Close"
        background="gray"
        btnClass="btn-modal-style"
      />
      <CommonButtons
        onClick={onSave}
        content="Save"
        background="blue"
        btnClass="btn-modal-style"
      />
    </Modal.Actions>

    </Modal>
  )
}

export default AddGuestModal
