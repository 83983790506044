import React, { useState } from 'react'
import { Dimmer, Loader, Dropdown, Modal, TextArea } from 'semantic-ui-react'
import CommonButtons from '../../../common/CommonButtons'
import close from '../../../assets/images/close.png'
import { CommonNotify } from '../../../common/CommonNotify'
import Axios from 'axios'
const reasonList = ['spam', 'unable to qualify', 'not a good fit', 'others']

export default function RejectPopup({ setRejectModal, handleSave, id }) {
  const [formValue, setFormValue] = useState({})
  const [loading, setLoading] = useState(false)

  const handleRejectAPI = async () => {
    if (!formValue?.reject_reason) {
      CommonNotify('Please Select Reason')
      return
    }
    if (!formValue?.reject_comment) {
      CommonNotify('Reason Comment Is Required')
      return
    }
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${id}/reject-reason`
    await Axios.put(url, formValue)
      .then(res => {
        setLoading(false)
        CommonNotify('Lead reason added successfully')
        setRejectModal(false)
        handleSave()
      })
      .catch(err => {
        setLoading(false)
        CommonNotify(
          err?.response?.data?.errors[0] || 'Somethign went to wrong!'
        )
      })
  }

  return (
    <div>
      {' '}
      <Modal
        className="local-number-modal"
        open={true}
        onClose={() => setRejectModal(false)}
      >
        <div
          style={{
            maxHeight: '700px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Dimmer active={loading}>
            <Loader />
          </Dimmer>
          <div
            style={{
              borderBottom: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: 'grey',
              padding: 15,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <h1
              style={{
                fontSize: 23,
                fontWeight: 'bold',
                margin: '0 auto',
                textAlign: 'center',
                borderBottomWidth: 1,
                borderBottomColor: 'gray'
              }}
            >
              Reject
            </h1>
            <div className="close-icon" onClick={() => setRejectModal(false)}>
              <img src={close} alt="close" />
            </div>
          </div>
          <Modal.Content>
            <div
              className="modal-content px-2 py-2"
              style={{ textAlign: 'inherit' }}
            >
              <label className="mt-3 mb-3">Reject Reason*</label>
              <div>
                <Dropdown
                  placeholder="Select Reason"
                  onChange={(e, result) =>
                    setFormValue({
                      ...formValue,
                      reject_reason: result?.value
                    })
                  }
                  fluid
                  search
                  selection
                  value={formValue?.reject_reason || ''}
                  options={reasonList.map((option, index) => ({
                    key: index + 1,
                    text: option,
                    value: option
                  }))}
                />
              </div>
              <label className="mb-2 mt-4">Reject Comment*</label>
              <div className="mt-2">
                <TextArea
                  name="message"
                  className="mb-4"
                  value={formValue?.reject_comment || ''}
                  onChange={e =>
                    setFormValue({
                      ...formValue,
                      reject_comment: e?.target?.value
                    })
                  }
                  rows={8}
                  placeholder="Enter Reason Comment"
                />
                <br />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                  paddingTop: 10,
                  paddingBottom: 10
                }}
              >
                <CommonButtons
                  onClick={() => setRejectModal(false)}
                  type="button"
                  content="Cancel"
                  background="grey"
                  style={{
                    borderRadius: 0,
                    backgroundColor: '#e0e1e2',
                    color: '#5a5a5a'
                  }}
                />

                <CommonButtons
                  loading={loading}
                  onClick={handleRejectAPI}
                  type="button"
                  content="Reject"
                  background="blue"
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    borderRadius: 0,
                    backgroundColor: '#1071eb',
                    color: '#ffffff'
                  }}
                />
              </div>
            </div>
          </Modal.Content>
        </div>
      </Modal>
    </div>
  )
}
