import React from 'react'
import {Pane, Dialog} from 'evergreen-ui'

function ModalEverGreen({title, content, status}) {
    const [isShown, setIsShown] = React.useState(status)
  
    return (
      <Pane>
        <Dialog
          isShown={isShown}
          title={title}
          onCloseComplete={() => setIsShown(false)}
          confirmLabel="confirm"
          onConfirm
        >
          {content}
        </Dialog>
      </Pane>
    )
  }

  export default ModalEverGreen