import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
// import rg4js from 'raygun4js'
import { Dimmer, Loader } from 'semantic-ui-react'

const Logout = props => {
  const history = useHistory()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    checkStatus()
  }, [])

  const checkStatus = async () => {
    const apiToken = await localStorage.getItem('access_token')
    window.$isloogedOut = true
    if (apiToken) {
      onLogOut(apiToken)
    } else {
      localStorage.clear()
      history.push('/login')
    }
  }

  const onLogOut = async () => {
    // rg4js('apiKey', '71pSno4MdPm3xJPkPP7fGA')
    // rg4js('enablePulse', true)
    // rg4js('enableCrashReporting', true)

    // rg4js('endSession')
    // rg4js('setUser', {
    //   isAnonymous: true
    // })
    localStorage.clear()

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/logout`

    axios
      .post(url, {})
      .then(res => {
        history.push('/login')
      })
      .catch(err => {
        history.push('/login')
      })
  }

  return (
    <>
      <Dimmer active={isLoading} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
    </>
  )
}

export default Logout
