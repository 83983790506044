import React from 'react'
import { Grid, Dropdown, Checkbox } from 'semantic-ui-react'

import emailIcon from '../../assets/images/mail.png'
import flagIcon from '../../assets/images/flag.png'
import copyIcon from '../../assets/images/copy-icon.png'
import hostIcon from '../../assets/images/host-img.png'
import zoomIcon from '../../assets/images/Zoom.png'
import graybellIcon from '../../assets/images/graybell.png'

const options = [
  { key: 1, text: 'Choice 1', value: 1 },
  { key: 2, text: 'Choice 2', value: 2 },
  { key: 3, text: 'Choice 3', value: 3 }
]
const DetailPane = () => {
  return (
    <div className="contact-card">
      <div className="contact-card-boxes">
        <p className="card-boxes-title">HOST</p>
        <Grid className="card-boxes-text">
          <Grid.Row>
            <Grid.Column>
              <div className="host-profile">
                {' '}
                <img className="ui medium circular image" src={hostIcon} alt="" />
                <p>Steve Cooper</p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="contact-card-boxes guestWrap">
        <p className="card-boxes-title">GUESTS</p>
        <div className="card-boxes-text">
          <div className="host-profile">
            {' '}
            <img className="ui medium circular image" src={hostIcon} alt="" />
            <p>George Black</p>
          </div>
          <div className="flagmailWrap">
            <div className="salesWrap">
              <p className="card-boxes-right-text">
                Sales Manager, Pentolla Inc.
              </p>
            </div>
            <div className="flagWrap">
              <img className="ui medium  image" src={flagIcon} alt="" />
              <p>+1 888 255 8990</p>
            </div>
            <div className="mailWrap">
              <img className="ui medium  image" src={emailIcon} alt="" />
              <p>georgeblack@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-card-boxes">
        <p className="card-boxes-title">LEAD INFO</p>
        <Grid className="card-boxes-text">
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Company:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">Pentolla Inc.</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Employees size:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">450</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Revenue:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">$2M +</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="contact-card-boxes locationWraper">
        <p className="card-boxes-title">LOCATION</p>
        <div className="locationMain">
          <div className="zoomWrap">
            <img className="ui medium circular image" src={zoomIcon} alt="" />
            <p>Zoom</p>
          </div>
          <div className="linkWrap">
            <p>/LC85090WS</p>
            <img className="ui medium  image" src={copyIcon} alt="" />
          </div>
        </div>
      </div>
      <div className="contact-card-boxes sendingmeetWraper">
        <p className="card-boxes-title">SEND MEETING REMINDER</p>
        <div className="sendmeetingWrap">
          <div className="senddropdown">
            <img className="ui medium  image" src={graybellIcon} alt="" />
            <Dropdown text="Before 1 hour" options={options} simple item />
          </div>
          <div className="sendswitch">
            <Checkbox toggle />
          </div>
        </div>
      </div>
    </div>
  )
}
export default DetailPane
