import React, { useState, useEffect, useRef, Profiler } from 'react'

import CommonInput from '../../common/CommonInput'
import CommonSelect3 from '../../common/CommonSelect3'
import CommonSelect from '../../common/CommonSelect'
import CommonButton from '../../common/CommonButtons'
import CommonGroupButton from '../../common/CommonGroupButton'

import CommonButtons from '../../common/CommonButtons'

import axios from 'axios'

import iconEmail from '../../assets/images/Dashboard 2-08.png'

import Toggle from '../../common/CommonToggle'
import Dropzone from 'react-dropzone-uploader'
import { Icon } from 'semantic-ui-react'
import { CommonNotify } from '../../common/CommonNotify'

const apiToken = localStorage.getItem('access_token')

const defaultSetting = {
  name: '',
  allow_business_only: false,
  time_zone: 'UTC',
  language: 'en'
}

const callToggle = {
  callTitle: 'Business email only',
  callDesc:
    'When turned on, our system is permitted to make automated calls to your customers when requited',
  callId: 'Business_email_only',
  callref: 'Business_email_only'
}

const callRecord = {
  callTitle: 'Call record',
  callDesc:
    'When turned on, our system is permitted to make automated calls to your customers when requited',
  callId: 'Call_record',
  callref: 'Call_record'
}


const callTimeZ = {
  callTitle: 'Visitor Timezone',
  callDesc:
    'When turned on, our system is permitted to make automated calls to your customers when requited',
  callId: 'visitor_timezone',
  callref: 'visitor_timezone'
}

const status = {
  callTitle: 'Status',
  callDesc:
    'When turned on, our system is permitted to make automated calls to your customers when requited',
  callId: 'active',
  callref: 'active'
}

const deleteLogoURL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/remove-company-logo`;

export const WidgetGeneralTitle = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconEmail} alt="logo" />
    </div>{' '}
    <div className="accordion-title-holder">
      <h2 className="accordion-title"> Widget Settings </h2>{' '}
      <p className="accordion-description">
      By embedding the web widget on your website, your website visitors and customers can initiate call back requests, book a meeting and initiate a web call.{' '}
      </p>{' '}
    </div>{' '}
  </div>
)

export const WidgetGeneralContent = ({
  widget,
  onChangeSelect,
  handleGroupBtnData,
  onChangeInput,
  onChangeTimeZone,
  state,
  loading,
  widgetObject,
  reloadWidget
}) => {
  const fileInputRef = useRef(null)
  const languageRef = useRef('')

  const languagesOptions = ['English', 'French', 'Germany']
  const languagesValues = ['en', 'fr', 'gr']

  const languages = [
    {
      text: 'English',
      value: 'en'
    },
    {
      text: 'French',
      value: 'fr'
    },
    {
      text: 'Germany',
      value: 'gr'
    }
  ]

  const timeFormats = [
    {
      text: '12h Format',
      value: '12'
    },
    {
      text: '24h Format',
      value: '24'
    }
  ]

  const dateFormats = [
    {
      text: 'dd/mm/yyyy',
      value: 'dd/mm/yyyy'
    },
    {
      text: 'mm/dd/yyyy',
      value: 'mm/dd/yyyy'
    }
  ]


  const [widgetName, setWidgetName] = useState('')
  const [emailNotification, setEmailNotification] = useState('')
  const [companyBrand, setCompanyBrand] = useState(0)
  const [companyLogo, setCompanyLogo] = useState(null)

  const [companyLogoImg, setCompanyLogoImg] = useState('')
  const [timeZone, setTimeZone] = useState('')
  const [widgetLanguage, setWidgetLanguage] = useState(null)
  //const [isDataChange, setIsDataChange] = useState(false)
  const [widgetSetting, setWidgetSetting] = useState(null)
  const [onSaveCancel, setOnSaveCancel] = useState(false)
  const [isFileSelected, setIsFileSelected] = useState(false)
  const [isLanguageSelected, setIsLanguageSelected] = useState(false)
  const [isTimeSelected, setIsTimeSelected] = useState(false)
  const [isMailSelected, setIsMailSelected] = useState(false)
  const [isWidgetSettingSelected, setIsWidgetSettingSelected] = useState(false)
  const [logo, setLogo] = useState(null)
  const [getLogoApi, setGetLogoApi] = useState(null)
  const [apiResponseSetting, setApiResponseSetting] = useState({})

  const [callRecording, setCallRecording] = useState(false)
  const [callTimezone, setCallTimezone] = useState(false)
  const [statusData, setStatus] = useState(false)

  
  const selectLanguage = (e, data) => {
    if (data) {
      setOnSaveCancel(true)
    } else {
      setIsLanguageSelected(false)
    }
    let settings = { ...widgetSetting }
    settings.language = data.value
    setWidgetSetting(settings)
  }

  const selectTimeFormat = (e, data) => {
    if (data) {
      setOnSaveCancel(true)
    } else {
      setIsLanguageSelected(false)
    }   

    let settings = { ...widgetSetting }
    settings.time_format = data.value
    setWidgetSetting(settings)
  }

  const selectDateFormat = (e, data) => {
    if (data) {
      setOnSaveCancel(true)
    } else {
      setIsLanguageSelected(false)
    }
    let settings = { ...widgetSetting }
    settings.date_format = data.value
    setWidgetSetting(settings)
  }

  const selectTimeZone = e => {
    if (e) {
      setOnSaveCancel(true)
    } else {
      setIsTimeSelected(false)
    }
    let settings = { ...widgetSetting }
    settings.time_zone = e
    setWidgetSetting(settings)
  }

  const selectMail = e => {
    if (e) {
      setIsMailSelected(true)
    } else {
      setIsMailSelected(false)
    }
    let settings = { ...widgetSetting }
    if (e == 'Yes') {
      settings.allow_business_only = true
    } else {
      settings.allow_business_only = false
    }
    setWidgetSetting(settings)
  }

  useEffect(() => {
    
    if (!widget.id  || !widgetObject.id ) return
    getSettings()
    getRecorderValue()
    getTimezoneValue()
    getCompany()
  }, [widget, widgetObject])

  const changeWidgetSetting = (key, value) => {
    if (value) {
      setOnSaveCancel(true)
    } else {
      setIsWidgetSettingSelected(false)
    }
    let setting = { ...widgetSetting }
    setting[key] = value
    setWidgetSetting(setting)
  }

  const setData = data => {
    setWidgetName(data.widget_name)
    setEmailNotification(data.email_notifications)
    setCompanyBrand(data.display_company_name)
    setCompanyLogoImg(data.company_logo)
    setTimeZone(data.timezone)
    setWidgetLanguage(data.language)

  }

  const getSettings = () => { 
          setData(widgetObject)
          setWidgetSetting(widgetObject)
          setApiResponseSetting(widgetObject)
  } 

  const getRecorderValue = () => {   
          const result = widgetObject.record_calls == 1 ? true : false 
          setCallRecording(result)
  }

  const getTimezoneValue = () => {
    const result = widgetObject.user_time_zone === 1 ? true : false   

   
  
    setCallTimezone(result)
  }

  const getCompany = async() => {
    loading(true)
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/profile`
    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          loading(false)
          setLogo(res.data.data.company.company_logo)
          setGetLogoApi(res.data.data.company.company_logo)
        }
      })
      .catch (er => {
        loading(false)
      })
  }

   const updateLogo = async() => {
    if (getLogoApi === logo) {
      getSettings()
      return
    }
    loading(true)
    
    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/company-logo`
    const form = new FormData()
    form.append('company_logo', logo)
   
    axios
      .post(url, form)
      .then(res => {       
        loading(false)
        if (res.data.message == 'Successfully') {
          CommonNotify('Logo successfully updated...', 'success')
          getSettings()
          getCompany()
        } else {
          CommonNotify('Data cannot saved System error occurred...')
        }
      })
      .catch(err => {
        loading(false)
      })
  }

  const updateGenSetting = async() => {

   
    loading(true)
    let reload = false;
    
    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/general-settings`
    const wSetting = {...widgetSetting}
     wSetting.user_time_zone =  wSetting.user_time_zone ? 1 : 0      


     console.log(typeof logo)




     if(typeof logo == 'object'){
       updateLogo();      
       reload = true;
     }  
    
    axios
      .post(url, wSetting)
      .then(res => {
        loading(false)            
       
        if (res.data.message == 'Successfully') {
          CommonNotify('Data successfully updated...', 'success')
          setOnSaveCancel(false)
          if(reload){
            reloadWidget()
          }         
         
        } else {
          CommonNotify('Data cannot saved System error occurred...')
        }
      })
      .catch(error => {
       
        if (error.response) {
          loading(false)
         
        } else if (error.request) {
          
         
        } else {
          
        }
      })
  }

  const openFileDialog = e => {
    fileInputRef.current.click()
  }

  const handleFileSelected = e => {
    if (
      e.target.files[0].type === 'image/png' ||
      e.target.files[0].type === 'image/jpg' ||
      e.target.files[0].type === 'image/jpeg' ||
      e.target.files[0].type === 'image/svg'
    ) {
      CommonNotify('Company Logo uploaded successfully...', 'success')
    } else {
      CommonNotify('Company logo is not uploaded...')
    }
    if (e.target.files) {
      setOnSaveCancel(true)
    } else {
      setIsFileSelected(false)
    }
    const files = Array.from(e.target.files)
    setCompanyLogo(files[0])
  }

  const handleRecorderData = async toggleData => {
   
     let newData = toggleData ? 1 : 0

     

     const apiToken = await localStorage.getItem('access_token')
    loading(true)
    
    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/call-record` 
    axios
      .post(url, {widget_id: widgetSetting.id, status: newData})
      .then(res => {
        loading(false)        
        if (res.data.message == 'Successfully') {
          setCallRecording(toggleData);
          CommonNotify('Data successfully updated...', 'success')         
        } else {
          CommonNotify('Data cannot saved System error occurred...')
        }
      })
      .catch(error => {
        if (error.response) {
          loading(false)        
        } else if (error.request) {

         
         
        } else {
          
         
        }
      })
  }

  const handleTimezoneData = async toggleData => {

   
   
    let WSetting  =  {...widgetSetting} 
    let Wsetting2 = {...widgetSetting} 

    WSetting.user_time_zone = WSetting.user_time_zone ? 0 : 1
    Wsetting2.user_time_zone = !Wsetting2.user_time_zone
    
   

    setWidgetSetting(Wsetting2) 
   


     const apiToken = await localStorage.getItem('access_token')
    loading(true)
    
    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/general-settings`

    
      
   

    axios
      .post(url, WSetting)
      .then(res => {
        setCallTimezone(Wsetting2.user_time_zone)
        loading(false)        
        if (res.data.message == 'Successfully') {                   
          CommonNotify('Data successfully updated...', 'success')         
        } else {
          CommonNotify('Data cannot saved System error occurred...')
        }
      })
      .catch(error => {
        if (error.response) {
          loading(false)        
        } else if (error.request) {

         
         
        } else {
        
         
        }
      })
  }
  const handleStatusData = async toggleData => {

   
   
    let WSetting  =  {...widgetSetting} 
    let Wsetting2 = {...widgetSetting} 

    WSetting.active = WSetting.active ? 0 : 1
    Wsetting2.active = !Wsetting2.active
    
   

    setWidgetSetting(Wsetting2) 
   


     const apiToken = await localStorage.getItem('access_token')
    loading(true)
    
    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/general-settings`

    
      
   

    axios
      .post(url, WSetting)
      .then(res => {

        setStatus(Wsetting2.active)
        loading(false)        
        if (res.data.message == 'Successfully') {                   
          CommonNotify('Data successfully updated...', 'success')         
        } else {
          CommonNotify('Data cannot saved System error occurred...')
        }
      })
      .catch(error => {
        if (error.response) {
          loading(false)        
        } else if (error.request) {

         
         
        } else {
        
         
        }
      })
  }

  const handleToggleData = async(toggleData, index) => {

   

    let updateWidgetSetting = { ...widgetSetting }
    updateWidgetSetting.allow_business_only = parseInt(`${toggleData ? 1 : 0}`)

     const apiToken = await localStorage.getItem('access_token')
    loading(true)
    

    var newData = toggleData ? 1 : 0
    setWidgetSetting(updateWidgetSetting)
    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/general-settings`
    axios
      .post(url, updateWidgetSetting)
      .then(res => {
        loading(false)        
        if (res.data.message == 'Successfully') {          
          CommonNotify('Data successfully updated...', 'success')         
        } else {
          CommonNotify('Data cannot saved System error occurred...')
        }
      })
      .catch(error => {
        if (error.response) {
          loading(false)        
        } else if (error.request) {
         
        } else {
         
         
        }
      })
  }

 

  

  const onUploadLogo = ({ file }, status) => {
    setLogo(file)
    setIsFileSelected(true)
    setOnSaveCancel(true)
  }
  const oncancel = () => {
    setWidgetSetting(apiResponseSetting)
    setOnSaveCancel(false)
  }
  return (
    <div className="email-timezone-wrapper">
      <div className="general-content-wrapper">
        <div className="general-content-holder">
          <p className="general-content-title"> Widget Name </p>{' '}
          <p className="general-content-desc">Set the name of your widget </p>{' '}
        </div>{' '}
        <div className="general-content-holder-right">
          <CommonInput
            onChange={e => changeWidgetSetting('name', e.target.value)}
            value={widgetSetting && widgetSetting.name}
            name="widgetName"
            type="text"
            placeholder="Enter a name"
          />
        </div>{' '}
      </div>{' '}
      <input
        type="file"
        onChange={e => handleFileSelected(e)}
        id="file"
        ref={fileInputRef}
        style={{
          display: 'none'
        }}
        accept="image/*"
      />{' '}
      <div className="general-content-wrapper">
        <div className="general-content-holder">
          <p className="general-content-title"> Email Notification </p>{' '}
          <p className="general-content-desc">Email notifications will be sent to this email</p>{' '}
        </div>{' '}
        <div className="general-content-holder-right">
          <CommonInput
            defaultValue={localStorage.getItem('email')}
            disabled
            name="widgetEmail"
            type="text"
          />
        </div>{' '}
      </div>{' '}
      <div className="general-content-wrapper general-content-wrapper-thrid">
        <div className="general-content-holder">
          <p className="general-content-title"> Company Logo </p>{' '}
          <p className="general-content-desc">
            Upload your company's logo and embed it in your widget.{' '}
          </p>
          {''}
        </div>{' '}
        <div className="general-content-holder-right">
          {logo ? (
          <Dropzone
            canCancel={true}
            canRemove={true}
            canRestart={true}
            inputContent={
                <img
                  className="companyLogoImage"
                  src={`${process.env.REACT_APP_BASE_APP_URL}/storage${logo}`}
                  alt=""
                />
            }
            onChangeStatus={(e, status) => onUploadLogo(e, status)}
            accept="image/*"
          />
          ) : (
          <Dropzone
            canCancel={true}
            canRemove={true}
            canRestart={true}
            inputContent={          
                <Icon name="upload" />      
            }
            onChangeStatus={(e, status) => onUploadLogo(e, status)}
            accept="image/*"
          />
          )}
          
          <p>Supports jpg, png, jpeg, svg</p>
          <p> Max File size 5 MB
            <Icon
              name="trash alternate"
              className="remove-logo"
              onClick={(e)=>{
                 
                if(!logo){
                  return;
                }
                const form = new FormData()
                form.append('time_zone', 'GMT')
                axios
                .post(deleteLogoURL, form)
                .then(res => {
                  
                  loading(false)
                  if (res.data.message == 'Successfully') {
                    setLogo(null)
                    CommonNotify('Logo successfully Removed...', 'success')
                    
                  } else {
                    CommonNotify('Data cannot saved System error occurred...')
                  }
                })
                .catch(err => {
                  loading(false)
                })
              }}
            >

            </Icon>
          </p>
        </div>{' '}
       
      </div>{' '}
      {' '}
      <div className="general-content-wrapper general-content-wrapper-fifth">
        <div className="general-content-holder">
          <p className="general-content-title"> Widget Language </p>{' '}
          <p className="general-content-desc"> Set your default language </p>{' '}
        </div>{' '}
        <div className="general-content-holder-right">
          <CommonSelect3
            isGray
            ref={languageRef}
            onChange={selectLanguage}
            
            value={widgetSetting && widgetSetting.language}
            name="widgetLanguage"
            
            options={languages}
            isMulti={false}
          />{' '}
        </div>{' '}
      </div>


      <div className="displayname-btn groupbtn-holder">
      <div className="groupbtn-text">
          <p className="groupbtn-title">Business email only</p>
          <p className="groupbtn-desc">
            Allow prospects to enter only business account in the widget
          </p>
        </div>
      
        <div className="groupbtn-wrapper">
          { widgetSetting && (
          <Toggle
            handleToggleData={(data) => handleToggleData(data, 'allow_business_only')}
            dataToggleActive={
              widgetSetting && widgetSetting.allow_business_only === 1 ? true : false
            }
            dataStateToggle={callToggle}
            activeDefault={ (widgetSetting && parseInt(widgetSetting.allow_business_only)) === 0 ? false : true}
          />) }
        </div>
      </div>  

      <div className="displayname-btn groupbtn-holder">
      <div className="groupbtn-text">
          <p className="groupbtn-title">Call Recording</p>
          <p className="groupbtn-desc">
            Enable / disable call recording feature
          </p>
        </div>
        <div className="groupbtn-wrapper">
          <Toggle
            handleToggleData={(data) => handleRecorderData(data)}
            dataToggleActive={
               callRecording 
            }
            dataStateToggle={callRecord}
            activeDefault={callRecording }
          />
        </div>
      </div>    

      
      <div className="displayname-btn groupbtn-holder">
      <div className="groupbtn-text">
          <p className="groupbtn-title">Visitor Timezone</p>
          <p className="groupbtn-desc">
           Show a timezones drop-down to the visitor ( it would effect the available time for call schedule ). 
          </p>
        </div>
        <div className="groupbtn-wrapper">
          <Toggle
            handleToggleData={(data) => handleTimezoneData(data)}          
            dataStateToggle={callTimeZ} 
            activeDefault={callTimezone }
          />
        </div>
        </div>   
<div className="displayname-btn groupbtn-holder">
<div className="groupbtn-text">
    <p className="groupbtn-title">Status</p>
    <p className="groupbtn-desc">
    Set status of the widget visibility on your website. 
    </p>
  </div>
  <div className="groupbtn-wrapper">
    <Toggle
      handleToggleData={(data) => handleStatusData(data)}          
      dataStateToggle={status} 
      activeDefault={statusData || widgetSetting?.active }
      setText={{on:"Enabled", off:"Disabled"}}
    />
  </div>
  
      </div>    


      <div className="general-content-wrapper general-content-wrapper-fifth">
        <div className="general-content-holder">
          <p className="general-content-title"> Date Format </p>{' '}
          <p className="general-content-desc"> Date format in schedule calls . . </p>{' '}
        </div>{' '}
        <div className="general-content-holder-right">


        
                 <CommonSelect3
                  isGray           
                  onChange={selectDateFormat}
                 
                  value={widgetSetting && widgetSetting.date_format}
                  name="widgetLanguage"
                  
                  options={dateFormats}
                  isMulti={false}
              />       



         
        </div>{' '}
      </div>

     


      <div className="general-content-wrapper general-content-wrapper-fifth">
        <div className="general-content-holder">
          <p className="general-content-title"> Time Format </p>{' '}
          <p className="general-content-desc"> Time format in schedule calls . . </p>{' '}
        </div>{' '}
        <div className="general-content-holder-right">
          <CommonSelect3
            isGray           
            onChange={selectTimeFormat}
            
            value={widgetSetting && widgetSetting.time_format}
            name="widgetLanguage"
            
            options={timeFormats}
            isMulti={false}
          />{' '}
        </div>{' '}
      </div>    



      {onSaveCancel && (
        <>
          <CommonButtons
            onClick={() => updateGenSetting()}
            type="button"
            content="Save"
            background="blue"
          />
          
          <CommonButtons
            onClick={()=> oncancel()}
            type="reset"
            content="Cancel"
            background="grey"
          />
        </>
      )}
    </div>
  )
}