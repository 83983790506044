import React from 'react'

const NoConversations = () => (
  <div className="no-conversation-container">
    <div className="text-empty">
      <h5>No Conversations</h5>
      This list will automatically refresh when a conversation is updated
    </div>
    <div
      className="message-holder conversation-empty"
      style={{
        top: '120px'
      }}
      key={`empty-1`}
      id={`empty-1`}
    >
      <div className="message-whatsapp" />
      <div className="message-content">
        <div
          style={{
            width: '150px'
          }}
          className="text-content"
        />
        <div
          style={{
            width: '100px'
          }}
          className="text-content"
        />
      </div>
    </div>
    <div
      key={`empty-2`}
      id={`empty-2`}
      className="message-holder conversation-empty"
      style={{
        zIndex: 50,
        left: 50,
        top: 175
      }}
    >
      <div className="message-whatsapp" />
      <div className="message-content">
        <div
          style={{
            width: '150px'
          }}
          className="text-content"
        />
        <div
          style={{
            width: '100px'
          }}
          className="text-content"
        />
      </div>
    </div>
  </div>
)

export default NoConversations
