import Axios from 'axios'
import React, { useState } from 'react'
import { Dropdown, Modal } from 'semantic-ui-react'
import CommonButton from '../../common/CommonButtons'
import CommonInput from '../../common/CommonInput'
import { CommonNotify } from '../../common/CommonNotify'

const resultData = [
  {
    key: 1,
    text: 'I no longer need to track leads.',
    value: 'I no longer need to track leads.'
  },
  {
    key: 2,
    text: 'I am switching to another provider',
    value: 'I am switching to another provider'
  },
  {
    key: 3,
    text: 'This platform is too expensive',
    value: 'This platform is too expensive'
  },
  {
    key: 4,
    text: 'This is not what I expected this platform to do',
    value: 'This is not what I expected this platform to do'
  },
  {
    key: 5,
    text: 'You do not have the feature(s) I need',
    value: 'You do not have the feature(s) I need'
  },
  {
    key: 6,
    text: 'Platform is not user frindly',
    value: 'Platform is not user frindly'
  }
]

export default function CloseAccountModal({ onClose, open }) {
  const [formValue, setFormValue] = useState({
    confirm_deletion: '',
    cancellation_reason: ''
  })

  const handleCloseAccount = async () => {
    if (!formValue?.cancellation_reason) {
      CommonNotify('Please select cancel reason')
      return
    }
    if (!formValue?.confirm_deletion) {
      CommonNotify('Please enter confirmation text')
      return
    }
    if (
      formValue?.confirm_deletion?.trim()?.toLocaleLowerCase() !== 'i confirm'
    ) {
      CommonNotify(
        'Confirmation text does not match with I confirm text, Please enter properly text'
      )
      return
    }
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/cancel-account`
    const payload = {
      ...formValue
    }
    Axios.post(url, payload)
      .then(res => {
        window.location.href = '/logout'
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Something went to wrong'
        )
      })
  }

  return (
    <div>
      <Modal
        className="add-credit-modal-wrapper cancel-subscription"
        open={open}
        onClose={onClose}
        style={{ maxWidth: '650px', minHeight: '550px' }}
      >
        <Modal.Header>
          <p className="cancel-title">
            <b>Close Master Account</b>
          </p>
          <i onClick={onClose} className="fa fa-times" aria-hidden="true"></i>
        </Modal.Header>
        <Modal.Content>
          <div className="modal-content" style={{ padding: '12px 15px' }}>
            <p className="mb-0 pl-3 pr-2">
              Before closing this account please be aware that all associated
              data, leads and logs will be deleted and you will no longer be
              able to reactivate this account. It will also remove all users,
              associated phone numbers and any tracking data. Your phone numbers
              will be released and will no longer be forwarded
            </p>
            <div style={{ padding: '12px 15px' }}>
              <label className="default-text input-title title-font">
                <b></b>Reason for Cancelling
              </label>
              <Dropdown
                style={{ minWidth: '180px' }}
                className="mt-1"
                placeholder="Select Reason"
                onChange={(e, result) =>
                  setFormValue({
                    ...formValue,
                    cancellation_reason: result?.value
                  })
                }
                fluid
                search
                selection
                name="Cancle Reason"
                value={formValue?.cancellation_reason}
                options={resultData}
              />
              <label className="default-text input-title mt-4 title-font">
                <b></b>Cancel Account Confirmation Text
              </label>
              <CommonInput
                placeholder="I Confirm"
                name="addAmount"
                className="mt-1"
                type="text"
                style={{ width: '586px' }}
                value={formValue?.confirm_deletion}
                onChange={e =>
                  setFormValue({
                    ...formValue,
                    confirm_deletion: e?.target?.value
                  })
                }
              />
            </div>
            <div
              className="quicksetup-footer"
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                padding: '5px 12px'
              }}
            >
              <CommonButton
                content="Cancel"
                background="grey"
                onClick={onClose}
                btnClass="mb-2"
              />
              <CommonButton
                content="Close Account"
                background="blue"
                onClick={handleCloseAccount}
                style={{ marginLeft: '15px' }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}
