import React, { useState, useEffect, useRef } from 'react'
// import loginLogo from '../assets/images/logo-limecall.svg'
import loginLogo from '../assets/images/limephone-logo.png'
import { Image } from 'semantic-ui-react'
import axios from 'axios'
import { CommonNotify } from '../common/CommonNotify'

const EmailOTP = () => {

    const [allDigit, setAllDigit] = useState(null)
    const [userEmail, setUserEmail] = useState("")
    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()


    useEffect(() => {
        getUEmail();
    }, [])

    const getUEmail = async () => {
        let email = await localStorage.getItem("email");
        setUserEmail(email)
    }

    const changeDigit = async (index, value) => {
        const digits = { ...allDigit }
        digits[index] = value
        await setAllDigit(digits)
        if (index == 'one') {
            ref2.current.focus();
        } else if (index == 'two') {
            ref3.current.focus();
        } else if (index == 'three') {
            ref4.current.focus();
        } else if (index == 'four') {
            ref5.current.focus();
        }
    }

    const resendCode = async () => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/resend-opt`
        const uId = await localStorage.getItem("id")
        const data = {
            user_id: uId
        }
        axios.post(url, data).then(res => {
            CommonNotify("New verification code send to your email", 'success')
        }).catch(err => {
            CommonNotify("Cant Verify the email right now.")
        })
    }

    const verifyMail = async () => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/verify-user`
        let digit = ""
        for (const item in allDigit) {
            digit = digit + allDigit[item]
        }
        if (digit.length != 5) {
            CommonNotify("Invalid email code")
            return;
        }
        const data = {
            verification_token: digit
        }
        axios.post(url, data).then(async res => {
            await localStorage.setItem('email_verification_status', '1')
            localStorage.setItem('quick_setup', 1)
            localStorage.setItem('flowType', 'virtualPhone')
            window.location.href = '/QuickSetup'
        }).catch(err => {
            CommonNotify("The selected verification token is invalid")
        })
    }

    return (
        <div id="signup-otp">
            <div className='logo-container'>
                <div className="logo"><Image src={loginLogo} /></div>
            </div>
            <div className='fullwidth-container'>

                <div id="confirm_email_form_body" className="fs_split_body">
                    <h1>Verify your email</h1>
                    <p className="desc">We have sent you a 5 digit code to {userEmail}. Verify your email address by entering the 5 digit code below.</p>

                    <label><span className="normal">Your </span>confirmation code</label>
                    <div className="flex-table">
                        <div className="confirmation_code split_input large_bottom_margin" data-multi-input-code="true">
                            <div className="confirmation_code_group">
                                <div className="split_input_item input_wrapper"><input ref={ref1} type="text" className="inline_input" onChange={(e) => changeDigit('one', e.target.value)} maxLength="1" /></div>
                                <div className="split_input_item input_wrapper"><input ref={ref2} type="text" className="inline_input" onChange={(e) => changeDigit('two', e.target.value)} maxLength="1" /></div>
                                <div className="split_input_item input_wrapper"><input ref={ref3} type="text" className="inline_input" onChange={(e) => changeDigit('three', e.target.value)} maxLength="1" /></div>
                                <div className="split_input_item input_wrapper"><input ref={ref4} type="text" className="inline_input" onChange={(e) => changeDigit('four', e.target.value)} maxLength="1" /></div>
                                <div className="split_input_item input_wrapper"><input ref={ref5} type="text" className="inline_input" onChange={(e) => changeDigit('five', e.target.value)} maxLength="1" /></div>
                            </div>
                        </div>
                    </div>


                    <div style={{ width: "50%", display: "flex" }}>
                        <button className="btn align_top" id="confirmButton" onClick={verifyMail}>Confirm</button>
                        <button className="btn align_top" id="resendButton" onClick={resendCode}>Resend</button>
                    </div>

                    {/* <div className="alert alert-danger"  id="resendConfirmationCode">
                    Your verification code has been sent again. Please check your inbox. If you didn't receive the code - you can always ping us at <a href="mailto:help@justcall.io">help@justcall.io</a>
                </div>
                <div className="alert alert-danger" id="codeError">
                    This is not the code that we were expecting. Click <a onclick="resendVerificationCode()">here</a> to resend the email with verification code.
                </div> */}


                    <div className="confirmation_code_checker" data-state="unchecked" >
                        <div className="confirmation_code_state_message unchecked subtle_silver">
                            <p>Please do not close or refresh this page while you check for your code.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailOTP