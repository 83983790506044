import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Button, TextArea } from 'semantic-ui-react'
import { CommonNotify } from '../../common/CommonNotify'

import addIcon from '../../assets/images/addIcon.png'

const ContactNoteData = ({ getData }) => {
  const [editContact, setEditContact] = useState([])
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true)
    if (getData?.getData?.id) {
      setLoading(false)
    }
    if (getData?.getData?.note) {
      setNote(getData?.getData?.note)
    }
  }, [getData?.getData])

  const handleInputChange = event => {
    setEditContact(event.target.value)
    setNote(event.target.value)
  }
  const onclickOwner = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${getData?.getData?.id}/info`
    axios
      .post(url, { note })
      .then(res => {
        CommonNotify('successFully updated!', 'success')
      })
      .catch(error => {
        CommonNotify(error)
      })
  }
  return (
    <div className="contact-card noteTab">
      {!loading ? (
        <div className="contact-card-boxes">
          <div className="noteLabel">
            <label></label>
            <label>Edit note</label>
          </div>
          <TextArea
            type="text"
            name="note"
            value={note}
            onChange={(e, c) => handleInputChange(e)}
          />
          <Button style={{ margin: 'auto' }} primary onClick={onclickOwner}>
            Edit note
          </Button>{' '}
        </div>
      ) : null}
    </div>
  )
}

export default ContactNoteData
