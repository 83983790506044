import { combineReducers } from 'redux';
import { CHANGE_DIALER_STATUS, SET_PHONE_NUMBER } from '../actions/types'

const getDialer = (currentStatus = false, action) => {
    if(action.type === CHANGE_DIALER_STATUS){
        return action.payload;
    }
    return currentStatus   
}
const setNumber = (PNumber = false, action) => {
   if (action.type===SET_PHONE_NUMBER){
        return action.payload;
    }
    return PNumber   
}

export default combineReducers({
    currentStatus: getDialer ,
    PNumber: setNumber 
});

