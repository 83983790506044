import React from 'react'
import './style.scss'
import ReactFlagsSelect from 'react-flags-select'
import { Dropdown } from 'semantic-ui-react'
import PhoneInput from 'react-phone-input-2'
import axios from 'axios'
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import moment from 'moment'

const bussinessLimecall = [
  'Industry',
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel/Fashion',
  'Architecture/Planning',
  'Arts/Crafts',
  'Automotive',
  'Aviation/Aerospace',
  'Banking/Mortgage',
  'Biotechnology/Greentech',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies/Equipment',
  'Capital Markets/Hedge Fund/Private Equity',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software/Engineering',
  'Computer/Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense/Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment/Movie Production',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Fundraising',
  'Furniture',
  'Gambling/Casinos',
  'Glass/Ceramics/Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design/Web Design',
  'Health/Fitness',
  'Higher Education/Acadamia',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Individual/Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology/IT',
  'Insurance',
  'International Affairs',
  'International Trade/Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management/Hedge Fund/Private Equity',
  'Judiciary',
  'Law Enforcement',
  'Law Practice/Law Firms',
  'Legal Services',
  'Legislative Office',
  'Leisure/Travel',
  'Library',
  'Logistics/Procurement',
  'Luxury Goods/Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing/Advertising/Sales',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Mental Health Care',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Museums/Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers/Journalism',
  'Non-Profit/Volunteering',
  'Oil/Energy/Solar/Greentech',
  'Online Publishing',
  'Other Industry',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Relations/PR',
  'Public Safety',
  'Publishing Industry',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate/Mortgage',
  'Recreational Facilities/Services',
  'Religious Institutions',
  'Renewables/Environment',
  'Research Industry',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing/Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation/Localization',
  'Transportation',
  'Utilities',
  'Venture Capital/VC',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine/Spirits',
  'Wireless',
  'Writing/Editing'
]

class NewAccountInfoComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentComponent: 'insights'
    }
  }

  componentDidMount = () => {
    this.setUpDropDown()
  }

  setUpDropDown = () => {
    let bussinessArr = []

    bussinessLimecall.forEach((score, index) => {
      const intObj = {
        key: index,
        text: score,
        value: score
      }
      bussinessArr.push(intObj)
    })

    this.setState({ bussinessOptionList: bussinessArr })
  }

  setCurrentComponent = data => {
    this.setState({ currentComponent: data })
  }

  getTimeOnSite = () => {
    const timer = this.props.currentCallData.trackingData
    if (timer && timer.time_on_site) {
      let seconds = parseInt(timer.time_on_site % 60, 10)
      let minutes = parseInt(timer.time_on_site / 60, 10)
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      return minutes + 'm ' + seconds + 's'
    }
    return ''
  }

  getBrowsingHistoryBlock = () => {
    const browserHistory = this.props.currentCallData.trackingData
      ? this.props.currentCallData.trackingData.browsing_history
      : null

    if (browserHistory) {
      const localHistory = browserHistory.slice(1).slice(-10)
      return localHistory.map(data => (
        <div className="info-label-full-width">
          <i className="material-icons call-made-icon">call_made</i>
          <a href={data.page_url}>{data.page_url}</a>
        </div>
      ))
    }
  }

  handleChangeInput = async (value, key = 'callDisposition') => {
    await this.setState(
      {
        [key]: value
      },
      () => {
        this.saveCallData()
      }
    )
  }

  saveCallData = () => {
    let token = localStorage.getItem('access_token')

    const lead_Id = this.props.currentCallData.id
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${lead_Id}/info`

    var re = /\S+@\S+\.\S+/
    var res = re.test(this.state.email)

    let email = ''
    if (res) {
      email = this.state.email
    }

    const postData = {
      customer_name: this.state.customerName,
      email,
      phone_number: this.state.phoneNumber,
      company_name: this.state.companyName,
      industry: this.state.industry,
      country: this.state.country
    }

    axios
      .post(url, postData)
      .then(res => {})
      .catch(err => {})
  }

  render = () => {
    const trackingData = this.props.currentCallData.trackingData
    const lastCall=this.props.currentCallData?.related_calls?.[-1] || null
    const contact=this.props.currentCallData?.contact
    return (
      <div className="hor-row account-info-container">
    

   {lastCall && <div className='last-call-container'>

        <p>Last call  <PhoneCallbackIcon/></p>
        <p>{lastCall?.agent_name ? `by ${lastCall?.agent_name} ${lastCall?.time  &&` |`}`:""} {lastCall?.time && `${moment(lastCall?.time)?.format('LLL')}`}</p>
      </div>}
            {/* {contact &&  */}
            <>
           { contact?.name &&  <div>
            <p>Contact Name: </p>
            <p>{contact?.name} </p>
            </div>}
           { contact?.industry  &&  <div>
            <p>Company Name: </p>
            <p>{contact?.industry } </p>
            </div>}
         {contact?.note &&    <div>
            <p>Notes: </p>
            <p>{contact?.note} </p>
            </div>}
            {this.props.currentCallData?.type &&    <div>
            <p>Type: </p>
            <p>{this.props.currentCallData?.type} </p>
            </div>}
         {this.props.currentCallData?.first_source &&    <div>
            <p>Source: </p>
            <p>{this.props.currentCallData?.first_source} </p>
            </div>}
         {this.props.currentCallData?.ip &&    <div>
            <p>IP: </p>
            <p>{this.props.currentCallData?.ip} </p>
            </div>}
         {this.props.currentCallData?.sim_call_id &&    <div>
            <p>Sim Call ID: </p>
            <p>{this.props.currentCallData?.sim_call_id} </p>
            </div>}
            </>
            {/* } */}
      
      </div>
    )
  }
}

export default NewAccountInfoComponent
