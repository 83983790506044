import React, { Component } from 'react'
import {
  Form,
  TextArea,
  Dimmer,
  Loader,
  Dropdown,
  Accordion
} from 'semantic-ui-react'

import NodeToggle from '../../common/NodeToggle3'
import CommonButtons from '../../common/CommonButtons'
import CommonButton from '../../common/CommonButtons'
import CommonRadio from '../../common/CommonRadio2'
import CommonInput from '../../common/CommonInput'

import { CommonNotify } from '../../common/CommonNotify'

import axios from 'axios'
import { getAudioFileName } from '../../helpers/common'
//redux
import { connect } from 'react-redux'
import { getCallForwadingSettings } from '../../actions/settings'

const callToggleDefault = [
  {
    callTitle: 'Welcome Greeting',
    callDesc:
      'Setup the very first greeting that the caller will hear when they call you',
    callId: 'toogleAutomated',
    callref: 0,
    status: true,
    file: null,
    url: '',
    path: 'call_start_pb_status',
    fileName: ''
  },
  {
    callTitle: 'Playbacks when the call ends',
    callDesc:
      'Setup the recording that the caller will hear when the call ends',
    callId: 'toggleRecord',
    callref: 1,
    status: true,
    file: null,
    url: '',
    path: 'call_end_pb_status',
    fileName: ''
  },
  {
    callTitle: 'Playbacks to notify your prospects',
    callDesc:
      'If turned off, customer will see the call rep’s number instead of LimePhone’s Number',
    callId: 'toggleMasking',
    callref: 2,
    status: true,
    file: null,
    url: '',
    path: 'notify_prospects_pb_status',
    fileName: ''
  },
  {
    callTitle: 'Call Record',
    callDesc: 'By activating this, you can record call and save it.',
    callId: 'toogleCallRecord',
    callref: 3,
    status: false,
    file: null,
    active: false,
    nofile: true,
    fileName: ''
  },
  {
    callTitle: 'Retry Failed Calls',
    callDesc:
      'By activating this, you can choose when/how to-retry failed calls.',
    callId: 'toogleFailedCalls',
    callref: 4,
    status: false,
    file: null,
    active: false,
    nofile: true,
    retry: false,
    fileName: ''
  },
  {
    callTitle: 'Allow speech to text',
    callDesc:
      'By activating this, you can show call records as text file and store it.',
    callId: 'toogleSpeechToText',
    callref: 5,
    status: false,
    file: null,
    active: false,
    nofile: true,
    fileName: ''
  },

  {
    callTitle: 'Busy Situation',
    callDesc:
      'Setup the voice message that the caller will hear when all your agents are busy',
    callId: 'toogleBusySituation',
    callref: 6,
    status: false,
    file: null,
    active: false,
    nofile: true,
    fileName: ''
  },
  {
    callTitle: 'Voice Mail',
    callDesc: 'Toggle to enable/disable voicemail.',
    callId: 'voiceMailToggle',
    callref: 7,
    status: true,
    file: null,
    url: '',
    path: 'voice_mail_pb_setting',
    fileName: ''
  }
]

const options = [
  { key: 1, text: 'Failed to Connect Agent', value: 'failed_to_connect_agent' },
  {
    key: 1,
    text: 'Failed To Connect Customer',
    value: 'failed_to_connect_customer'
  },
  {
    key: 2,
    text: 'Failed By Cutomer Or Agent',
    value: 'failed_to_connect_customer|failed_to_connect_agent'
  }
]

const optionMaxRetry = [
  { key: 1, text: '1', value: '1' },
  { key: 1, text: '2', value: '2' },
  { key: 2, text: '3', value: '3' }
]

const lstCallAlgorithm = [
  {
    label: 'Simultaneously',
    value: '0'
  },
  {
    label: 'In a sequence',
    value: '1'
  },
  {
    label: 'In a random sequence',
    value: '2'
  }
]
const lstCallDirection = [
  { label: 'Client to customer service', value: 'client_to_services' },
  { label: 'Service to Client', value: 'services_to_client' }
]

class Calls extends Component {
  constructor(props) {
    super(props)
    this.audio = React.createRef()
    this.upload = React.createRef()
  }
  state = {
    activeIndexs: [],
    duration_between_retries: '0',
    max_retry_attempts: '1',
    retry_when: 'failed_to_connect_agent',
    showRetry: false,
    selectedWhenToTry: 'All Time',
    isLoading: false,
    automatedCall: false,
    callRecording: false,
    callerId: false,
    voiceMessage: true,
    callAlgorithm: null,
    callDirection: '0',
    maxCallTime: 60,
    callToggle: callToggleDefault,
    apiCalled: false,
    callSetting: {
      callAlgorithm: '0',
      callDirection: 'client_to_services',
      maxCallTime: 60
    },
    isHandleRadio: false,
    isHandleDataTextAreaChange: false,
    isFileChange: false,
    isUpdateMaxCallTime: false,
    selectedFileName: '',
    file: '',
    isHandleBusyStation: false
  }

  handleDataTextAreaChange = e => {
    if (!e.target.value) {
      this.setState({ isHandleDataTextAreaChange: false })
    } else {
      this.setState({ isHandleDataTextAreaChange: true })
    }
    this.setState({ voiceMessage: e.target.value })
  }

  handleDataRef = (DataState, DataRef, callTitle) => {
    let callToggle = [...this.state.callToggle]
    callToggle[DataRef].status = DataState
    const path = callToggle[DataRef].path
    if (path && path !== 'voice_mail_pb_setting') {
      this.postMeetingSetting(path)
    }

    if (callTitle === 'Busy Situation') {
      this.setState({ isHandleBusyStation: true })
    } else if (callTitle === 'Allow speech to text') {
      this.setAllowToSpeech(DataState, DataRef)
    } else if (callTitle === 'Call Record') {
      this.setCallRecord(DataState, DataRef)
    } else if (callTitle === 'Retry Failed Calls') {
      callToggle[DataRef].retry = DataState
      this.setState({ showRetry: DataState })
      this.setRetryFailed(DataState, DataRef)
    } else if (callTitle === 'Voice Mail') {
      const formData = new FormData()
      formData.append('voicemail_status', DataState ? 1 : 0)
      this.updateVoiceMail(formData)
    }

    this.setState({ callToggle })
  }

  handleRadio = (index, value) => {
    if (!value) {
      this.setState({ isHandleRadio: false })
    } else {
      this.setState({ isHandleRadio: true })
    }
    let callSetting = { ...this.state.callSetting }
    callSetting[index] = value
    this.setState({ callSetting })
  }

  onFileChange = (event, index, DataRef) => {
    if (!event.target.files[0]) {
      CommonNotify('Select current file', 'error')
      this.setState({ isFileChange: false })
    } else {
      this.setState({
        ...this.state,
        isFileChange: true,
        selectedFileName: event.target.files[0].name,
        file: event.target.files[0].name
      })
    }
    var sound = document.getElementById('sound')
    var reader = new FileReader()
    reader.onload = function(e) {
      sound.src = this.result
      callToggle[index].url = this.result
    }
    reader.readAsDataURL(event.target.files[0])
    let callToggle = [...this.state.callToggle]
    callToggle[index].file = event.target.files[0]
    callToggle[index].fileName = event.target.files[0].name
    callToggle[index].url = event.target.files[0]
    this.setState({ callToggle }, () => this.onTrackChange())
  }

  componentDidUpdate(prevProps) {
    if (this.props.callForwadingSettings !== prevProps.callForwadingSettings) {
      this.setState({ isLoading: false })
      this.setSetting(this.props.callForwadingSettings.data)
    }
  }

  componentDidMount = () => {
    if (this.props.callForwadingSettings) {
      this.setState({ isLoading: false })
      this.setSetting(this.props.callForwadingSettings.data)
    } else {
      this.fetchSetting()
    }
  }

  setSetting = data => {
    let callToggle = [...this.state.callToggle]
    let callSetting = { ...this.state.callSetting }

    let file = this.state.file

    callToggle[0].status =
      data.playback_settings.call_start_pb_status == 1 ? true : false
    callToggle[0].url = `${process.env.REACT_APP_BASE_APP_URL}/storage/${data.playback_settings.call_start_pb_path}`

    callToggle[1].status =
      data.playback_settings.call_end_pb_status == 1 ? true : false
    callToggle[1].url = `${process.env.REACT_APP_BASE_APP_URL}/storage/${data.playback_settings.call_end_pb_path}`

    callToggle[2].status =
      data.playback_settings.notify_prospects_pb_status == 1 ? true : false
    callToggle[2].url = `${process.env.REACT_APP_BASE_APP_URL}/storage/${data.playback_settings.notify_prospects_pb_path}`

    callToggle[3].status = data.call_recording.status == 1 ? true : false

    callToggle[5].status =
      data.call_recording.speech_to_text_status == 1 ? true : false

    callToggle[4].status = data.failed_calls.status == 1 ? true : false

    callToggle[4].retry = data.failed_calls.status == 1 ? true : false

    callToggle[6].status = data.call_settings.busy_situation == 1 ? true : false

    callToggle[7].status = data.voicemail_status == 1 ? true : false
    callToggle[7].url = `${process.env.REACT_APP_BASE_APP_URL}/storage/${data.voicemail_url}`

    callToggle[0].fileName =
      data.playback_settings.call_start_pb_path &&
      getAudioFileName(data.playback_settings.call_start_pb_path)

    callToggle[1].fileName =
      data.playback_settings.call_end_pb_path &&
      getAudioFileName(data.playback_settings.call_end_pb_path)

    callToggle[2].fileName =
      data.playback_settings.notify_prospects_pb_path &&
      getAudioFileName(data.playback_settings.notify_prospects_pb_path)

    if (callToggle[0].url) {
      file = data.playback_settings.call_start_pb_path
    } else if (callToggle[1].url) {
      file = data.playback_settings.call_end_pb_path
    } else if (callToggle[2].url) {
      file = data.playback_settings.notify_prospects_pb_path
    }
    this.setState({ file })
    callSetting.callAlgorithm = data.call_settings.call_process.toString()

    callSetting.callDirection = data.call_settings.call_direction

    callSetting.maxCallTime = data.call_settings.max_call_time
    this.setState({
      callToggle,
      apiCalled: true,
      showRetry: data.failed_calls.status == 1 ? true : false,
      callSetting,
      duration_between_retries: data.failed_calls.duration_between_retries,
      retry_when: data.failed_calls.retry_when
    })
    this.onTrackChange()
  }

  fetchSetting = async () => {
    this.setState({ isLoading: true })

    this.props.getCallForwadingSettings()
    /* const Token = await localStorage.getItem('access_token')
    const widget_id = await localStorage.getItem('widget_id');

    let head = {
      headers: {
        Authorization: 'Bearer ' + Token
      }
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-call-settings?widget_id=${widget_id}`

    axios
      .get(url)
      .then(async res => {
        this.setState({isLoading: false})
          await this.setSetting(res.data.data)
      })
      .catch(error => {
        this.setState({isLoading: false})
        CommonNotify("Can't fetch call Settings")
      }) */
  }

  postMeetingSettingWav = async data => {
    this.setState({ isLoading: true })
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/upload-playbacks`
    axios
      .post(url, data)
      .then(res => {
        this.fetchSetting()
        this.setState({ isLoading: false })
        if (res.data.message[0] == 'Successfully') {
          CommonNotify('Call playback file successfully uploaded', 'success')
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        CommonNotify('Cant uploaded the call playback file', 'error')
      })
  }

  updateVoiceMail = async data => {
    const w_id = await localStorage.getItem('widget_id')
    const apiToken = await localStorage.getItem('access_token')

    this.setState({ isLoading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${w_id}/voicemail`
    axios
      .post(url, data)
      .then(res => {
        this.fetchSetting()
        this.setState({ isLoading: false })
        if (res.data.message[0] == 'Successfully') {
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        CommonNotify('Cant uploaded the voice playback file', 'error')
      })
  }

  setCallRecord = async (DataState, index) => {
    const w_id = await localStorage.getItem('widget_id')
    this.setState({ isLoading: true })
    const apiToken = await localStorage.getItem('access_token')

    const data = {
      widget_id: w_id,
      status: DataState ? 1 : 0
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/call-record`
    axios
      .post(url, data)
      .then(async res => {
        await this.fetchSetting()
      })
      .catch(error => {
        this.setState({ isLoading: false })
        CommonNotify("Can't Update Playback Settings")
      })
  }

  setAllowToSpeech = async (DataState, index) => {
    const w_id = await localStorage.getItem('widget_id')
    const apiToken = await localStorage.getItem('access_token')
    this.setState({ isLoading: true })

    const data = {
      widget_id: w_id,
      status: DataState ? 1 : 0
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/call-speech`
    axios
      .post(url, data)
      .then(async res => {
        await this.fetchSetting()
      })
      .catch(error => {
        this.setState({ isLoading: false })
        CommonNotify("Can't Update Playback Settings")
      })
  }

  setRetryData = async () => {
    const w_id = await localStorage.getItem('widget_id')
    this.setState({ isLoading: true })
    const apiToken = await localStorage.getItem('access_token')

    const data = {
      widget_id: w_id,
      duration_between_retries: this.state.duration_between_retries,
      max_retry_attempts: this.state.max_retry_attempts,
      retry_when: this.state.retry_when
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/retry-failed-calls`
    axios
      .post(url, data)
      .then(async res => {
        await this.fetchSetting()
      })
      .catch(error => {
        this.setState({ isLoading: false })
        CommonNotify("Can't Update Playback Settings")
      })
  }

  setRetryFailed = async (DataState, index) => {
    const w_id = await localStorage.getItem('widget_id')
    const apiToken = await localStorage.getItem('access_token')
    this.setState({ isLoading: true })

    const data = {
      widget_id: w_id,
      retry_option: DataState ? 1 : 0
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/retry-failed-status`
    axios
      .post(url, data)
      .then(async res => {
        await this.fetchSetting()
      })
      .catch(error => {
        this.setState({ isLoading: false })
        CommonNotify("Can't Update Playback Settings")
      })
  }

  postMeetingSetting = async path => {
    const w_id = await localStorage.getItem('widget_id')
    const apiToken = await localStorage.getItem('access_token')

    this.setState({ isLoading: true })

    const data = {
      widget_id: w_id,
      parameter: path
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/change-playback-status`
    axios
      .post(url, data)
      .then(async res => {
        await this.fetchSetting()
      })
      .catch(error => {
        this.setState({ isLoading: false })
        CommonNotify("Can't Update Playback Settings")
      })
  }

  postCallSetting = async data => {
    const apiToken = await localStorage.getItem('access_token')
    this.setState({ isLoading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/call-algorithm`
    axios
      .post(url, data)
      .then(res => {
        this.setState({ isLoading: false })
        if (res.data.message[0] == 'Successfully') {
          CommonNotify('Call Algorithm Successfully Updated', 'success')
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        CommonNotify('Cant update call algorithm ')
      })
  }

  onSave = async () => {
    const w_id = await localStorage.getItem('widget_id')
    let { callSetting, callToggle, isFileChange } = this.state

    let callPayload = {
      widget_id: w_id,
      busy_mode: callToggle[3].status == true ? '1' : '0',
      call_direction:
        callSetting.callDirection === 'client_to_services' ? '0' : '1',
      call_process: callSetting.callAlgorithm.toString(),
      max_call_time: callSetting.maxCallTime
    }
    this.postCallSetting(callPayload)
    const updateCallToggle = callToggle.filter(
      item => item.callTitle !== 'Busy Situation'
    )

    this.setState({
      callSetting: {
        callDirection: callSetting.callDirection,
        callAlgorithm: callSetting.callAlgorithm.toString()
      },
      isHandleDataTextAreaChange: false,
      isHandleRadio: false,
      isHandleDataTextAreaChange: false,
      isFileChange: false,
      isUpdateMaxCallTime: false,
      isHandleBusyStation: false
    })
    // upload wav file

    this.updateAudioFile()
  }

  updateAudioFile = async () => {
    const w_id = await localStorage.getItem('widget_id')
    let { callToggle, isFileChange } = this.state
    const updateCallToggle = callToggle.filter(
      item => item.callTitle !== 'Busy Situation'
    )
    var formData = new FormData()
    var t = false
    if (updateCallToggle.map(item => item.status).includes(true)) {
      formData.append('widget_id', w_id)
      formData.append('call_process', w_id)
      if (callToggle[0].file) {
        var t = true
        formData.append('call_start_playback', callToggle[0].file)
      }
      if (callToggle[1].file) {
        var t = true
        formData.append('call_end_playback', callToggle[1].file)
      }
      if (callToggle[2].file) {
        var t = true
        formData.append('notify_prospects', callToggle[2].file)
      }
    }
    if (callToggle[2].file) {
      var t = true
      formData.append('notify_prospects', callToggle[2].file)
    }

    if (callToggle[7].file) {
      var formData2 = new FormData()
      formData2.append('voicemail_url', callToggle[7].file)
      formData2.append('voicemail_status', callToggle[7].status ? 1 : 0)
      this.updateVoiceMail(formData2)
    }

    if (isFileChange && t === true) {
      this.postMeetingSettingWav(formData)
    }
  }

  updateMaxCallTime = e => {
    if (!e.target.value) {
      this.setState({ isUpdateMaxCallTime: false })
    } else {
      this.setState({ isUpdateMaxCallTime: true })
    }
    let callSetting = { ...this.state.callSetting }
    callSetting.maxCallTime = e.target.value
    this.setState({ callSetting })
  }

  onTrackChange = () => {
    if (this.audio.current) {
      this.audio.current.pause()
      this.audio.current.load()
    }
  }

  handleWhenToRetry = (e, { value }) => {
    this.setState({ retry_when: value })
  }

  handleMaxRetry = (e, { value }) => {
    this.setState({ max_retry_attempts: value })
  }

  handleDurationBetweenRetries = e => {
    this.setState({ duration_between_retries: e.target.value })
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndexs } = this.state
    const newIndex = activeIndexs
    const currentIndexPosition = activeIndexs.indexOf(index)

    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1)
    } else {
      newIndex.push(index)
    }

    this.setState({ activeIndexs: newIndex })
  }

  render() {
    const {
      isHandleRadio,
      isHandleDataTextAreaChange,
      isFileChange,
      isUpdateMaxCallTime,
      isHandleBusyStation,
      activeIndexs,
      callToggle
    } = this.state
    return (
      <>
        <Dimmer style={{ position: 'fixed' }} active={this.state.isLoading}>
          <Loader active={this.state.isLoading} />
        </Dimmer>
        <Accordion style={{ marginTop: 40 }} className="holder-account">
          {/* <div className="accordion-holder company-information company_main">
            <Accordion.Title
              active={activeIndexs.includes(0)}
              index={0}
              onClick={this.handleClick}
            >
              <p className="bold-text accordion-title">Greetings</p>
              <p className="subtext accordion-desc">
                Setup the greeting that the caller will hear.
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndexs.includes(0)}>
              <div className="callsettings-wrapper">
                {this.state.callToggle.slice(0, 3).map((item, i) => {
                  return (
                    <>
                      {this.state.apiCalled ? (
                        <NodeToggle
                          key={i}
                          handleDataRef={(DataState, DataRef) =>
                            this.handleDataRef(
                              DataState,
                              DataRef,
                              item.callTitle
                            )
                          }
                          dataToggle={item}
                          activeDefault={item.status}
                          checked={true}
                        />
                      ) : null}
                      {!item.nofile ? (
                        <>
                          {item.status ? (
                            <div className="call-bottom-wrapper">
                              <div className="call-bottom-holder">
                                <input
                                  type="file"
                                  onChange={e => this.onFileChange(e, i)}
                                  ref={this.upload}
                                  accept="audio/wav"
                                  className="file_select"
                                />
                                <div
                                  className="file__validation"
                                  style={{ marginBottom: '5px' }}
                                >
                                  <p>Please upload only .wav file</p>
                                </div>
                                <p>{item.fileName}</p>
                                {item.url && (
                                  <div style={{ marginTop: '30px' }}>
                                    <audio controls ref={this.audio} id="sound">
                                      <source src={item.url} type="audio/wav" />
                                    </audio>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )
                })}
              </div>
              <CommonButtons
                type="button"
                onClick={this.onSave}
                content="Save"
                background="blue"
              />
            </Accordion.Content>
          </div> */}

          <div className="accordion-holder company-information company_main">
            <Accordion.Title
              active={activeIndexs.includes(1)}
              index={1}
              onClick={this.handleClick}
            >
              <p className="bold-text accordion-title">Call Recording</p>
              <p className="subtext accordion-desc">
                By activating this, you can record call and save it.
              </p>
            </Accordion.Title>

            <Accordion.Content active={activeIndexs.includes(1)}>
              <div className="callsettings-wrapper">
                <NodeToggle
                  key={3}
                  handleDataRef={(DataState, DataRef) =>
                    this.handleDataRef(
                      DataState,
                      DataRef,
                      callToggle[3].callTitle
                    )
                  }
                  dataToggle={callToggle[3]}
                  activeDefault={callToggle[3].status}
                  checked={true}
                />
              </div>
            </Accordion.Content>
          </div>

          <div className="accordion-holder company-information company_main">
            <Accordion.Title
              active={activeIndexs.includes(2)}
              index={2}
              onClick={this.handleClick}
            >
              <p className="bold-text accordion-title">
                {' '}
                Premium Call Features
              </p>
              <p className="subtext accordion-desc">
                Setup the call features according to your convenience.
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndexs.includes(2)}>
              <div className="callsettings-wrapper">
                {this.state.callToggle.slice(4, 8).map((item, i) => {
                  return (
                    <>
                      {this.state.apiCalled && (
                        <NodeToggle
                          key={i}
                          handleDataRef={(DataState, DataRef) =>
                            this.handleDataRef(
                              DataState,
                              DataRef,
                              item.callTitle
                            )
                          }
                          dataToggle={item}
                          activeDefault={item.status}
                          checked={true}
                        />
                      )}
                      {!item.nofile && (
                        <>
                          {item.status && (
                            <div className="call-bottom-wrapper">
                              <div className="call-bottom-holder">
                                <input
                                  type="file"
                                  onChange={e => this.onFileChange(e, i)}
                                  ref={this.upload}
                                  accept="audio/wav"
                                  className="file_select"
                                />
                                <div
                                  className="file__validation"
                                  style={{ marginBottom: '5px' }}
                                >
                                  <p>Please upload only .wav file</p>
                                </div>
                                <p>{item.fileName}</p>
                                {item.url && (
                                  <div style={{ marginTop: '30px' }}>
                                    <audio controls ref={this.audio} id="sound">
                                      <source src={item.url} type="audio/wav" />
                                    </audio>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {(item.retry === true) &
                      (this.state.showRetry === true) ? (
                        <div>
                          <div
                            className="call-bottom-wrapper call-algorithm"
                            style={{ marginBottom: 20 }}
                          >
                            <div className="call-bottom-holder">
                              <h3 className="call-title">When To Try</h3>
                            </div>
                            <div className="">
                              <Dropdown
                                onChange={this.handleWhenToRetry}
                                options={options}
                                selection
                                style={{ marginRight: 40 }}
                                value={this.state.retry_when}
                              />
                            </div>
                          </div>
                          <div
                            className="call-bottom-wrapper call-algorithm"
                            style={{ marginBottom: 20 }}
                          >
                            <div className="call-bottom-holder">
                              <h3 className="call-title">Max Retry Attempt</h3>
                            </div>
                            <div className="">
                              <Dropdown
                                onChange={this.handleMaxRetry}
                                options={optionMaxRetry}
                                selection
                                style={{ marginRight: 40 }}
                                value={this.state.max_retry_attempts}
                              />
                            </div>
                          </div>
                          <div
                            className="call-bottom-wrapper call-algorithm"
                            style={{ marginBottom: 20 }}
                          >
                            <div className="call-bottom-holder">
                              <h3 className="call-title">
                                Duration between each try (in minutes)
                              </h3>
                            </div>
                            <div className="">
                              <CommonInput
                                onChange={e =>
                                  this.handleDurationBetweenRetries(e)
                                }
                                placeholder="Max Call Time"
                                name="maxCall"
                                type="number"
                                value={this.state.duration_between_retries}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 50 }}>
                            <CommonButtons
                              type="button"
                              onClick={this.setRetryData}
                              content="Save"
                              background="blue"
                            />
                          </div>
                        </div>
                      ) : null}
                    </>
                  )
                })}
              </div>
              <CommonButtons
                type="button"
                onClick={this.onSave}
                content="Save"
                background="blue"
              />
            </Accordion.Content>
          </div>

          <div className="accordion-holder company-information company_main">
            <Accordion.Title
              active={activeIndexs.includes(3)}
              index={3}
              onClick={this.handleClick}
            >
              <p className="bold-text accordion-title">
                Advanced call settings
              </p>
              <p className="subtext accordion-desc">
                Setup the settings for your call.
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndexs.includes(3)}>
              <div className="callsettings-wrapper">
                <div className="call-bottom-wrapper call-algorithm call_algorithm_main">
                  <div className="call-bottom-holder">
                    <h3 className="call-title">Call algorithm</h3>
                  </div>
                  <CommonRadio
                    radioList={lstCallAlgorithm}
                    onChange={(e, { value }) =>
                      this.handleRadio('callAlgorithm', value)
                    }
                    defaultValue={this.state.callSetting.callAlgorithm}
                    name={this.state.callSetting.callAlgorithm}
                  ></CommonRadio>
                </div>
                <div className="call-bottom-wrapper call-algorithm">
                  <div className="call-bottom-holder">
                    <h3 className="call-title">Call direction</h3>
                  </div>
                  <CommonRadio
                    radioList={lstCallDirection}
                    onChange={(e, { value }) =>
                      this.handleRadio('callDirection', value)
                    }
                    defaultValue={this.state.callSetting.callDirection}
                    name={this.state.callSetting.callDirection}
                  />
                </div>
                <div className="call-bottom-wrapper call-algorithm">
                  <div className="call-bottom-holder">
                    <h3 className="call-title">Max Call Time (in seconds)</h3>
                  </div>
                  <div className="">
                    <CommonInput
                      onChange={e => this.updateMaxCallTime(e)}
                      placeholder="Max Call Time"
                      name="maxCall"
                      type="number"
                      value={this.state.callSetting.maxCallTime}
                    />
                  </div>
                </div>

                <CommonButtons
                  type="button"
                  onClick={this.onSave}
                  content="Save"
                  background="blue"
                />
              </div>
            </Accordion.Content>
          </div>
        </Accordion>
      </>
    )
  }
}

const mapStateToProps = state => ({
  callForwadingSettings: state.settings.callForwadingSettings
})

export default connect(mapStateToProps, { getCallForwadingSettings })(Calls)
