import Axios from 'axios'
import React, { useState } from 'react'
import { Checkbox, Dropdown } from 'semantic-ui-react'
import setTimezone from '../../assets/images/set-timezone.png'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'

const availabilityTimeSlot = [
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00'
]

const Availablity = props => {
  const [loading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState({
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false
  })

  const increaseStep = async () => {
    props.next()
  }

  const handleSubmit = async e => {
    if (!formValue?.startTime) {
      CommonNotify('Start time required')
      return
    }
    if (!formValue?.endTime) {
      CommonNotify('End time required')
      return
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/working-hours`
    const formData = new FormData()
    formData.append(
      'day_ids[15]',
      `${formValue?.startTime}:00-${formValue?.endTime}:00`
    )
    formData.append(
      'day_ids[16]',
      `${formValue?.startTime}:00-${formValue?.endTime}:00`
    )
    formData.append(
      'day_ids[17]',
      `${formValue?.startTime}:00-${formValue?.endTime}:00`
    )
    formData.append(
      'day_ids[18]',
      `${formValue?.startTime}:00-${formValue?.endTime}:00`
    )
    formData.append(
      'day_ids[19]',
      `${formValue?.startTime}:00-${formValue?.endTime}:00`
    )
    formData.append(
      'day_ids[20]',
      `${formValue?.startTime}:00-${formValue?.endTime}:00`
    )
    formData.append(
      'day_ids[21]',
      `${formValue?.startTime}:00-${formValue?.endTime}:00`
    )
    !formValue.sunday && formData.delete('day_ids[15]')
    !formValue.monday && formData.delete('day_ids[16]')
    !formValue.tuesday && formData.delete('day_ids[17]')
    !formValue.wednesday && formData.delete('day_ids[18]')
    !formValue.thursday && formData.delete('day_ids[19]')
    !formValue.friday && formData.delete('day_ids[20]')
    !formValue.saturday && formData.delete('day_ids[21]')

    setLoading(true)
    Axios.post(url, formData)
      .then(res => {
        setLoading(false)
        CommonNotify('User Availability Added Successfully')
        increaseStep()
      })
      .catch(error => {
        CommonNotify(error?.response?.data?.errors?.[0])
        setLoading(false)
      })
  }

  return (
    <div className="h-100 d-flex flex-column bg-white">
      <div className="border p-0">
        <div className="welcome-box">
          <div className="box-heading row m-0">
            <div className="col-lg-8 px-0">
              <h3>Set your availability</h3>
              <p>
                Let LimePhone know when you're typically available to accept
                calls.
              </p>
            </div>
            <div className="col-lg-4 px-0">
              <img src={setTimezone} />
            </div>
          </div>
          <div className="box-content">
            <h4>Available Hours</h4>
            <div className="row mx-0 mb-4">
              <div className="col-lg-6 mb-3 mb-lg-0 pr-lg-2 px-0">
                <Dropdown
                  placeholder="Select Start Time"
                  onChange={(e, result) =>
                    setFormValue({
                      ...formValue,
                      startTime: result?.value
                    })
                  }
                  fluid
                  search
                  selection
                  name="startTime"
                  value={formValue?.startTime}
                  options={availabilityTimeSlot.map((option, index) => ({
                    key: index,
                    text: option,
                    value: option
                  }))}
                />
              </div>
              <div className="col-lg-6 pl-lg-2 px-0">
                <Dropdown
                  placeholder="Select End Time"
                  onChange={(e, result) =>
                    setFormValue({
                      ...formValue,
                      endTime: result?.value
                    })
                  }
                  fluid
                  search
                  selection
                  name="endTime"
                  value={formValue?.endTime}
                  options={availabilityTimeSlot.map((option, index) => ({
                    key: index,
                    text: option,
                    value: option
                  }))}
                />
              </div>
            </div>
            <h4>Available Days</h4>
            <ul className="d-flex mb-4">
              {/* sunday: false, monday: true, tuesday: true, wednesday: true,
              thursday: true, friday: true, saturday:false */}
              <li>
                <Checkbox
                  checked={formValue?.sunday}
                  onChange={(e, data) => {
                    setFormValue({
                      ...formValue,
                      sunday: !formValue?.sunday
                    })
                  }}
                />
                <p>Sundays</p>
              </li>
              <li>
                <Checkbox
                  checked={formValue?.monday}
                  onChange={(e, data) => {
                    setFormValue({
                      ...formValue,
                      monday: !formValue?.monday
                    })
                  }}
                />
                <p>Mondays</p>
              </li>
              <li>
                <Checkbox
                  checked={formValue?.tuesday}
                  onChange={(e, data) => {
                    setFormValue({
                      ...formValue,
                      tuesday: !formValue?.tuesday
                    })
                  }}
                />
                <p>Tuesdays</p>
              </li>
              <li>
                <Checkbox
                  checked={formValue?.wednesday}
                  onChange={(e, data) => {
                    setFormValue({
                      ...formValue,
                      wednesday: !formValue?.wednesday
                    })
                  }}
                />
                <p>Wednesdays</p>
              </li>
              <li>
                <Checkbox
                  checked={formValue?.thursday}
                  onChange={(e, data) => {
                    setFormValue({
                      ...formValue,
                      thursday: !formValue?.thursday
                    })
                  }}
                />
                <p>Thursdays</p>
              </li>
              <li>
                <Checkbox
                  checked={formValue?.friday}
                  onChange={(e, data) => {
                    setFormValue({
                      ...formValue,
                      friday: !formValue?.friday
                    })
                  }}
                />
                <p>Fridays</p>
              </li>
              <li>
                <Checkbox
                  checked={formValue?.saturday}
                  onChange={(e, data) => {
                    setFormValue({
                      ...formValue,
                      saturday: !formValue?.saturday
                    })
                  }}
                />
                <p>Saturdays</p>
              </li>
            </ul>
            <p className="text-center announce-text">
              <i class="fa fa-bullhorn mr-1"></i>Don't worry You'll be able to
              further customize your availability later on.
            </p>
          </div>
        </div>
      </div>

      <div
        className="quicksetup-footer footer-button mt-auto"
        style={{ justifyContent: 'space-between' }}
      >
        <CommonButtons
          content="Back"
          btnClass="btn-modal-style  btn-back"
          onClick={props.prev}
        />
        <CommonButtons
          content="Skip"
          btnClass="btn-modal-style btn-back"
          onClick={props.next}
        />
        <CommonButtons
          type="button"
          content="Continue"
          background="blue"
          onClick={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  )
}
export default Availablity
