import React, { Component } from 'react'

import axios from 'axios'
import { CommonNotify } from '../../common/CommonNotify'
//redux
import { connect } from 'react-redux'
import { getWidgetInstall } from '../../actions/settings'
import { Typography } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import practice1 from '../../assets/images/practice-1.jpg'
import practice2 from '../../assets/images/practice-2.jpg'
import practice3 from '../../assets/images/practice-3.jpg'


class ShareYourLinkSettings extends Component {
  state = {
  
    shareLink: null
  }

  componentDidUpdate(prevProps) {
    if (this.props.widgetInstall !== prevProps.widgetInstall) {
      this.props.loading(false)
      this.setState({ isLoading: false })
      this.setState({  
        shareLink: this.props.widgetInstall.data.shareable_link
      })
    }
  }

  componentDidMount() {
    this.loadWidget()
  }

  loadWidget = () => {

    if (this.props.widgetInstall) {
      this.props.loading(false)
      this.setState({ isLoading: false })
      this.setState({  
        shareLink: this.props.widgetInstall.data.shareable_link
      })
    } else {
      this.props.loading(true)
      this.setState({ isLoading: true })
      this.props.getWidgetInstall()
    }
    /* 
    const widget_id = localStorage.getItem('widget_id')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/install?widget_id=${widget_id}`

    axios
      .get(url)
      .then(res => {
        this.props.loading(false)
        if (res.data.data) {
          this.setState({
           
            shareLink: res.data.data.shareable_link
          })
        }
      })
      .catch(er => {
        this.props.loading(false)
        //CommonNotify('Cant Fetch Widget Link')     
      }) */
  }




  render() {
    return (
      <>
        <div className="share-your-link-wrapper share_your_link_fixed">
        <div className="share-your-link">
        <Typography variant='h4' className='practies-title'>Sharable Link</Typography>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_BASE_APP_URL}/widget/${this.state.shareLink &&
                this.state.shareLink.split('/widget/')[1]}`}
              className="link-holder"
            >
              <p className="profile-link-text">{`${process.env.REACT_APP_BASE_APP_URL}/widget/${this
                .state.shareLink &&
                this.state.shareLink.split('/widget/')[1]}`}</p>
            </a>
          </div>
          
        </div>
        <div className="share-your-link-wrapper share_your_link_fixed">
        <div className="share-your-link">
          <Typography variant='h4' className='practies-title'>Best practices</Typography>
          <div className="practices-list">
            <div className='icon'>
              <img src={practice1} alt='practice' />
            </div>
            <Typography variant='h5'>Create professional email signature</Typography>
            <Typography variant='body'>Adda call link to your email signature so customers can start a call with you directly from their inboxes.</Typography>
          </div>
          <div className="practices-list">
            <div className='icon'>
              <img src={practice2} alt='practice' />
            </div>
            <Typography variant='h5'>Grow newsletter engagement</Typography>
            <Typography variant='body'>Connect with your newsletter subscribers by adding the call page link to your campaigns.</Typography>
          </div>
          <div className="practices-list">
            <div className='icon'>
              <img src={practice3} alt='practice' />
            </div>
            <Typography variant='h5'>Share on social media</Typography>
            <Typography variant='body'>Let your customers know about your call and give them a quick way to contact you no matter the channel.</Typography> 
            <div className='social-icon'>
              <a href="https://www.facebook.com/"><FacebookIcon/></a>
              <a href="https://www.linkedin.com/"><TwitterIcon/></a>
            </div>
          </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  widgetInstall: state.settings.widgetInstall,
});

export default connect(
  mapStateToProps,
  { getWidgetInstall }
)(ShareYourLinkSettings)

