/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { Button, Image, Label, Modal } from 'semantic-ui-react'
import {
  Menu,
  Tab,
  Icon,
  Dropdown,
  Input,
  TextArea,
  Sidebar,
  Segment,
  Popup,
  Accordion,
  Progress
} from 'semantic-ui-react'
import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  IconButton,
  ListItem,
  Popover,
  Rating,
  TextField,
  Typography
} from '@mui/material'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import DraftsIcon from '@mui/icons-material/Drafts'
import SendIcon from '@mui/icons-material/Send'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarBorder from '@mui/icons-material/StarBorder'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorIcon from '@mui/icons-material/Error'
import PeopleIcon from '@mui/icons-material/People'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/Person'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PhoneIcon from '@mui/icons-material/Phone'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import ChatIcon from '@mui/icons-material/Chat'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';

import {
  AccountCircle,
  AlternateEmail,
  Delete,
  Group,
  MarkChatRead,
  MoreHoriz,
  NoAccounts,
  NotificationsPaused
} from '@mui/icons-material'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import {
  apiSlice,
  useGetConversationQuery
} from '../../../features/api/apiSlice'
import { CommonNotify } from '../../../common/CommonNotify'
import LeadNotes from './LeadNotes'

const Contact = ({
  id,
  getConversations,
  agentCode,
  activeTab,
  setActiveConvoId,
  membersList,
  res,
  getConvo

}) => {
  // const res = useGetConversationQuery(id)
  const dispatch = useDispatch()
  const [activeConversation, setActiveConversation] = useState(null)
  const [selectedValuePriority, setSelectedValuePriority] = useState(3)
  const [selectedValueAssign, setSelectedValueAssign] = useState('')
  const [selectedContact, setSelectedContact] = useState(null)
  const [selectedConversation, setSelectedConversation] = useState(null)
  const [editable, setEditable] = useState(false)
  const [stagesOptions, setStagesOptions] = useState([])
  const [selectedStage, setSelectedStage] = useState(null)
  const [funnel, setFunnel] = useState({})
  const [allFunnels, setAllFunnels] = useState([])
  console.log('stagesOptions', stagesOptions)
  const [updateContact, setUpdateContact] = useState({
    name: '',
    email: '',
    phone_number: '',
    country: 'India',
    city: '',
    title: '',
    phone_country: '',
    type: 'individual',
    company: ''
  })
  const [collapse, setCollapse] = useState({
    openAssigned: false,
    openChanells: true,
    openContact: true,
    openAttribute: false,
    openFeedback: false,
    openPrevConv: false,
    openLead: false,
    openDetails: true,
    openTags: false,
    openNotes: true

  })

  useEffect(() => {
    if (res?.status === 200) {
      setActiveConversation(res.data.data.conversation)
      setSelectedValuePriority(res.data.data.conversation.priority)
      setSelectedValueAssign(res.data.data.conversation.owner_id)
      setSelectedContact(res.data.data.conversation.contact)
      setSelectedConversation(res.data.data.conversation)
      if (res.data.data.conversation.contact) {
        setUpdateContact(res.data.data.conversation.contact)
      } else {
        setUpdateContact(current => ({
          ...current,
          phone_number: res.data.data.conversation?.friendly_name?.replace(
            'customer - ',
            ''
          )
        }))
      }
    }
    return () => {

      setUpdateContact({
        name: '',
        email: '',
        phone_number: '',
        country: 'India',
        city: 'New Delhi',
        title: '',
        phone_country: '',
        type: 'individual',
        company: ''
      })
    }
  }, [res])

  const priority = [
    {
      key: 1,
      text: 'Low',
      value: 1
    },
    {
      key: 3,
      text: 'Medium',
      value: 3
    },
    {
      key: 4,
      text: 'High',
      value: 4
    }
  ]

  const handleChangePriority = async (event, dt) => {
    const st = dt.value
    const data = {
      priority: st
    }
    setSelectedValuePriority(st)
    dispatch(
      apiSlice.util.updateQueryData(
        'getConversation',
        id,
        draftConversation => {
          draftConversation.data.conversation.priority = st
        }
      )
    )
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${id}/update`
    axios
      .put(url, data)
      .then(res => {
        CommonNotify('Priority updated', 'success')
      })
      .catch(error => {
        CommonNotify('Cant update ')
      })
  }

  const handleChangeAssign = index => {
    const widgetId = localStorage.getItem('widget_id')
    let type = 'App\\User'
    if (membersList[index]?.key.toString()?.includes('team')) {
      type = 'App\\Team'
    }
    const postData = {
      owner_type: type,
      owner_id: membersList[index]?.value,
      widget_id: widgetId,
      ids: [id]
    }
    setSelectedValueAssign(membersList[index]?.value)
    dispatch(
      apiSlice.util.updateQueryData(
        'getConversation',
        id,
        draftConversation => {
          draftConversation.data.conversation.owner_id =
            membersList[index]?.value
        }
      )
    )
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/assign`
    axios
      .post(url, postData)
      .then(res => {
        CommonNotify('Assign updated', 'success')
        getConversations()
      })
      .catch(err => {
        this.setState({ loading: false })
        CommonNotify('Cant assigned ')
      })
  }


  const handleFeedback = (value) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${activeConversation?.id}/feedback`

    axios
      .put(url, { feedback: activeConversation?.feedback || 'Good', feedback_rate: value })
      .then(res => {
        getConvo(activeConversation?.id)
      })
      .catch(error => { })
  }
  const handleTags = (value) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${activeConversation?.id}/tags`
    axios
      .put(url, { tags: value })
      .then(res => {
        getConvo(activeConversation?.id)
      })
      .catch(error => { })
  }

  const getFunnels = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/funnels`
    await axios
      .get(url)
      .then(res => {

        setAllFunnels(res?.data?.data)
        const fun = res?.data?.data?.filter((obj) => obj?.id === activeConversation?.funnel?.id)?.[0]
        setFunnel(fun)
        setStagesOptions([...fun?.stages])
        setSelectedStage(activeConversation?.stage)
      })
      .catch(error => { })
  }
  useEffect(() => {
    if (activeConversation?.id) {

      getFunnels()
    }

  }, [activeConversation?.id])

  const onLeadStageHandler = async (value, instance) => {
    if (instance === 'funnel') {

      const ff = allFunnels?.filter((obj) => obj?.name === value)?.[0]
      setFunnel(ff)
      setStagesOptions(ff?.stages)
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${id}/funnel`

      await axios
        .put(url, { funnel_id: ff?.id || null, stage: activeConversation?.stage || selectedStage })
        .then(res => {
          CommonNotify('Updated Successfully', 'sucess')
        })
        .catch(error => { })

    } else {
      console.log('funnel', funnel)
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${id}/funnel`
      setSelectedStage(value)
      await axios
        .put(url, { funnel_id: funnel?.id, stage: value })
        .then(res => {
CommonNotify('Updated Successfully','sucess')
        })
        .catch(error => { })
    }

  }
  const handleUpdateContact = () => {
    // Object?.keys(updateContact)?.forEach((val) => {
    //   if (!updateContact?.[val]) {
    //     delete updateContact?.[val]
    //   }
    // })
    if (!updateContact?.owner) {

      delete updateContact?.owner
    }
    if (selectedContact) {
      if (
        selectedContact !== updateContact &&
        selectedContact.id
      ) {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/update/${selectedContact.id}`
        axios
          .post(url, { ...updateContact, rating: 5, conversation_id: activeConversation?.id })
          .then(res => {
            getConvo(id)
            CommonNotify('Updated Successfully', 'success')
            setEditable(null)
            // dispatch(
            //   apiSlice.util.updateQueryData(
            //     'getConversation',
            //     id,
            //     draftConversation => {
            //       draftConversation.data.conversation.contact = res.data.data
            //     }
            //   )
            // )
          })
          .catch(error => {
            CommonNotify('Cant update Contact')
          })
      }

    } else if (updateContact.phone_number) {
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact`
      axios
        .post(url, { ...updateContact, conversation_id: `${activeConversation?.id}` })
        .then(res => {
          getConvo(id)
          setEditable(null)
          dispatch(
            apiSlice.util.updateQueryData(
              'getConversation',
              id,
              draftConversation => {
                draftConversation.data.conversation.contact = res.data.data
              }
            )
          )
        })
        .catch(error => {
          CommonNotify('Cant Add Contact')
        })
    }
  }

  const handleClickContacts = type => {
    setCollapse(current => ({ ...current, [type]: !collapse[type] }))
  }

  const deleteConversation = () => {
    const widgetScript = localStorage.getItem('widgetScript')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/delete`

    const data = {
      ids: [id],
      script_id: widgetScript
    }

    axios
      .delete(url, { data })
      .then(res => {
        getConversations()
        setActiveConvoId(null)
      })
      .catch(error => { })
  }

  return (
    <div
      className="conversation-content-info"
      style={{
        borderLeft: 'solid 0.5px #c0c0c0',
        overflow: 'auto',
        width: "100%",
        float: "right"
      }}
    >
      <div className="content-user">
        <div className="icon-holder">
          <Typography variant="h5">Details</Typography>
        </div>
        <div className="icon-holder-share">
          <div className="menu-holder">
            <Dropdown icon={<MoreHoriz />}>
              <Dropdown.Menu style={{ left: '-125px' }}>
                <Dropdown.Item
                  text="Ban User"
                /* onClick={() => deleteConversation(selectedContact?.id)} */
                />
                <Dropdown.Item
                  text="Delete Conversation"
                  onClick={() => deleteConversation()}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <Divider />
      <div className="contact-details">
        <div className="info-details">
          <div className="info-main">
            {/* <div className="info-item" style={{ margin: 0, fontSize: 13 }}>
              <p>Status</p>
              {activeConversation?.state && (
                <Chip
                  sx={{
                    minWidth: '100px',
                    '.MuiChip-label': {
                      textOverflow: 'inherit',
                      p: 0,
                      fontSize: 13,
                      textAlign: 'center'
                    },
                    color: '#1976d2',
                    backgroundColor: '#1976d21f'
                  }}
                  size="medium"
                  label={activeConversation?.state}
                />
              )}
            </div> */}
            <div className="info-item" style={{ margin: 0, fontSize: 13 }}>
              <p>Assignee</p>
              <Popup
                trigger={
                  <Dropdown
                    className="icon add-filter"
                    options={membersList}
                    value={selectedValueAssign}
                    onChange={(event, dt) => {
                      {
                        const index = membersList
                          .map(el => el.value)
                          .indexOf(dt.value)
                        handleChangeAssign(index)
                      }
                    }}
                    placeholder="Sales"
                  />
                }
                content="Choose Assignee"
                position="bottom center"
              />
            </div>
            {/* <div className="info-item" style={{ margin: 0, fontSize: 13 }}>
              <p>Team</p>
              {activeConversation?.owner_type === 'App\\Team'
                ? activeConversation?.owner_name
                : ''}
            </div> */}
            <div className="info-item">
              <p>Priority</p>
              <Popup
                trigger={
                  <Dropdown
                    className="icon"
                    options={priority}
                    value={selectedValuePriority}
                    onChange={handleChangePriority}
                    placeholder="Closed"
                  />
                }
                content="Change Priority"
                position="bottom center"
              />
            </div>
            <div className="info-item">
              <p>Source</p>
              {activeConversation?.participant
                ? activeConversation?.source
                : ''}
            </div>
            <div className="info-item">
              <p>Pipeline</p>
              <Dropdown
                className="card-boxes-right-text"
                options={[{
                  key: 0,
                  text: 'Unassign',
                  value: '',
                }, ...(allFunnels?.map((obj, index) => ({
                  key: index + 1,
                  text: obj?.name,
              value: obj?.name,
         })))]}
                placeholder="Add to Pipeline"
                defaultValue={
                  funnel?.name}
                value={funnel?.name}
                onChange={(e, dat) =>
                  onLeadStageHandler(dat.value, 'funnel')
                }
              />
            </div>
            {funnel && <div className="info-item">
              <p>Lead Stage</p>
              <Dropdown
                className="card-boxes-right-text"
                options={[{
                  key: 0,
                  text: 'Unassign',
                  value: '',
                }, ...(stagesOptions?.map((item, index) => ({
                  key: index,
                  text: item,
                  value: item,
                })))]}
                placeholder="Set lead stage"
                defaultValue={
                  selectedStage || 'null'
                }
                value={selectedStage}
                onChange={(e, dat) =>
                  onLeadStageHandler(dat.value)
                }
              />
            </div>}
         
          </div>
        </div>
      </div>
      <Divider />
      <List
        sx={{ width: '100%', py: 0 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton disableRipple onClick={() => handleClickContacts('openContact')}>
          <ListItemText primary="Contact Details" />
          {updateContact?.id}
          <span> <EditIcon onClick={(e) => {
            e.stopPropagation()
            setEditable(activeConversation?.id)
          }} /></span>
          <span>{collapse.openContact ? <ExpandLess /> : <ExpandMore />}</span>
        </ListItemButton>
        <Collapse in={collapse.openContact} timeout="auto" unmountOnExit>
          <div className="contact-details">
            <div className="info-details">
              <div className="info-main">
                <div className="info-item">
                  <p>
                    <PeopleIcon /> Name
                  </p>

                  {editable === activeConversation?.id ? <TextField
                    onChange={e => {
                      setUpdateContact({
                        ...updateContact,
                        name: e.target.value || ''
                      })
                    }}
                    variant="standard"
                    value={updateContact?.name}
                  /> : updateContact?.name}

                </div>
                <div className="info-item">
                  <p>
                    <PhoneIcon /> Phone
                  </p>


                  <span>{updateContact.phone_number}</span>
                </div>
                <div className="info-item">
                  <p>
                    <AlternateEmailIcon /> Email
                  </p>

                  {editable === activeConversation?.id ?
                    <TextField
                      // multiline
                      onChange={e => {
                        setUpdateContact({
                          ...updateContact,
                          email: e.target.value || ''
                        })
                      }}
                      variant="standard"
                      value={updateContact.email}
                    /> : updateContact.email}

                </div>
                {/* <div className="info-item">
                  <p>
                    <LocationOnIcon /> Location
                  </p>
                  {updateContact?.city && (
                    <p>
                      {updateContact?.city + ' ' + selectedContact?.country ? selectedContact?.country:""}
                    </p>
                  )}
                </div> */}
                <div className="info-item">
                  <p>
                    <BusinessIcon /> Company
                  </p>
                  {editable === activeConversation?.id ?

                    <TextField
                      onChange={e => {
                        setUpdateContact({
                          ...updateContact,
                          organization_name: e.target.value || ''
                        })
                      }}
                      variant="standard"
                      value={updateContact.organization_name}
                    />
                    : updateContact.organization_name}
                </div>
                {/* <div className="info-item">
                  <p>
                    <BusinessIcon /> 
                  </p>
                <span>{updateContact?.id}</span>
                </div> */}
                <div className="info-item">
                  <p>
                    <AccessTimeIcon /> Time
                  </p>
                  {/* <span>{updateContact?.id}</span> */}
                </div>
                <div className="info-item">
                  <p>
                    <LocationOnIcon /> Location
                  </p>
                  {/* <span>{updateContact?.id}</span> */}
                </div>
                {editable === activeConversation?.id ?
                  <div className="info-item">


                    <Button onClick={() => handleUpdateContact()} className='save-contact-btn'>Save</Button>

                  </div> : null}
              </div>
            </div>
          </div>
        </Collapse>
        <Divider />
        <ListItemButton onClick={() => handleClickContacts('openAttribute')}>
          <ListItemText primary="Conversation Info" />
          {activeConversation?.id} {" "}
          {collapse.openAttribute ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={collapse.openAttribute} timeout="auto" unmountOnExit>
          <div className="contact-details">
            <div className="info-details">
              <div className="info-main">
                <div className="info-item">
                  <p>First seen</p>
                  <span>{selectedConversation?.first_seen}</span>
                </div>
                <div className="info-item">
                  <p>Language</p>
                  <span>{selectedConversation?.language}</span>
                </div>
                <div className="info-item">
                  <p>Last seen</p>
                  <span>{selectedConversation?.last_seen}</span>
                </div>
                <div className="info-item">
                  <p>Created</p>
                  <span>{selectedConversation?.created_at}</span>
                </div>
                <div className="info-item">
                  <p>Local Time</p>
                  {selectedConversation?.local_time && (
                    <p>{selectedConversation?.local_time} - Local Time</p>
                  )}
                </div>
                <div className="info-item">
                  <p>Leads</p>
                  {selectedConversation?.leads?.length}
                </div>
                <div className="info-item">
                  <p>User Id</p>
                  {selectedConversation?.id}
                </div>
                <div className="info-item">
                  <p>Type</p>
                  {selectedConversation?.type}
                </div>
                {/* <div className="info-item">
                  <p>Conversation Id</p>
                  {activeConversation?.id}
                </div> */}
                <div className="info-item">
                  <p>Channel</p>
                </div>
                <div className="info-item">
                  <p>Visits</p>
                </div>
                <div className="info-item">
                  <p>Page impressions</p>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        <ListItemButton onClick={() => handleClickContacts('technology')}>
          <ListItemText primary="Technology" />
          {collapse.technology ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={collapse.technology} timeout="auto" unmountOnExit>
          <div className="contact-details">
            <div className="info-details">
              <div className="info-main">
                <div className="info-item">
                  <p>IP</p>
                  <span>{selectedConversation?.ip}</span>
                </div>
                <div className="info-item">
                  <p>Browser</p>
                  <span>{selectedConversation?.browser}</span>
                </div>
                <div className="info-item">
                  <p>UTM</p>
                  <span>{selectedConversation?.utm}</span>
                </div>
                <div className="info-item">
                  <p>Device</p>
                  <span>{selectedConversation?.device}</span>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        {activeConversation?.feedback_rate &&
          <ListItemButton onClick={() => handleClickContacts('openFeedback')}>
            <ListItemText primary="Feedback" />
            {collapse.openFeedback ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>}
        <Collapse in={collapse.openFeedback} timeout="auto" unmountOnExit>
          <Box
            sx={{
              '& > legend': { mt: 2 },
              px: 2
            }}
          >
            <Typography component="legend">Really Awesome Product!</Typography>
            <Rating name="read-only" value={activeConversation?.feedback_rate || 5} onChange={(e) => handleFeedback(e.target.value)} />
          </Box>
        </Collapse>
        <ListItemButton onClick={() => handleClickContacts('openTags')}>
          <ListItemText primary="Tags" />
          {collapse.openTags ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {/* } */}
        <Collapse in={collapse.openTags} timeout="auto" unmountOnExit>
          <Box
            sx={{
              '& > legend': { mt: 2 },
              px: 2
            }}
          >
            <Autocomplete
              multiple
              id="tags-filled"
              options={activeConversation?.tags || []}
              defaultValue={activeConversation?.tags || []}
              freeSolo
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              onChange={(value, data) => handleTags(data)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Add tags"
                />
              )}
            />
          </Box>
        </Collapse>
        {/* <ListItemButton onClick={() => handleClickContacts('openPrevConv')}>
          <ListItemText primary="Previous Conversations" />
          {collapse.openPrevConv ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={collapse.openPrevConv} timeout="auto" unmountOnExit>
          <ListItem>
            <ListItemText
              primary="Sample Conversation"
              secondary="Jan 7, 2022"
            />
          </ListItem>
        </Collapse> */}
      </List>

      <Divider />
      <ListItemButton onClick={() => handleClickContacts('openNotes')}>
        <ListItemText primary="Notes" />
        {collapse.openNotes ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {/* {activeConversation?.lead_id && ( */}
      <LeadNotes
        id={activeConversation?.lead_id}
        openLead={collapse.openNotes}
      />
      {/* )} */}
    </div>
  )
}

export default Contact
