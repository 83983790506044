import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useGetLeadQuery } from '../../../features/api/apiSlice'
import {
  Menu,
  Tab,
  Icon,
  Dropdown,
  Input,
  TextArea,
  Sidebar,
  Segment,
  Popup,
  Accordion,
  Progress
} from 'semantic-ui-react'
import { AssignmentIndOutlined } from '@mui/icons-material'
import CommonButtons from '../../../common/CommonButtons'
import { Box, Divider, Typography } from '@mui/material'
import { CommonNotify } from '../../../common/CommonNotify'

const ContactDetails = ({ id, membersList }) => {
  const res = useGetLeadQuery(id)
  const [lead, setLead] = useState(null)
  const [selectedValueAssign, setSelectedValueAssign] = useState(null)
  useEffect(() => {
    if (res.isSuccess) {
      setLead(res.data.lead)
    }
  }, [res])

  

  const handleChangeAssign = async index => {
    let type = 'App\\User'
    const widgetId = localStorage.getItem('widget_id')
    if (membersList[index]?.key.toString()?.includes('team')) {
      type = 'App\\Team'
    }

    const postData = {
      owner_type: type,
      owner_id: membersList[index]?.value,
      widget_id: widgetId,
      ids: [lead?.id]
    }


    /* const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/assign`

    axios
      .post(url, postData)
      .then(res => {
        if (res.data.errors.length >= 0) {
          setSelectedValueAssign(membersList[index]?.value)
        }
      })
      .catch(err => {
        CommonNotify('Cant assigned ')
      }) */
  }

  return (
    <div
      className="conversation-content-info"
      style={{
        borderLeft: 'solid 0.5px #c0c0c0',
        overflow: 'auto'
      }}
    >
      <div className="content-user">
        <div className="icon-holder">
          <Typography variant="h5">Contact Details</Typography>
        </div>
      </div>
      <Divider />
      {lead && (
        <div className="contact-details">
          <div className="info-details">
            <div className="info-main">
              <div className="info-item" style={{ margin: 0, fontSize: 13 }}>
                <p>Status</p>
              </div>
              <div className="info-item" style={{ margin: 0, fontSize: 13 }}>
                <p>Assignee</p>
                <Popup
                  trigger={
                    <Dropdown
                      className="icon add-filter"
                      options={membersList}
                      value={selectedValueAssign}
                      onChange={(event, dt) => {
                        {
                          const index = membersList
                            .map(el => el.value)
                            .indexOf(dt.value)
                          handleChangeAssign(index)
                        }
                      }}
                      placeholder="Sales"
                    />
                  }
                  content="Choose Assignee"
                  position="bottom center"
                />
              </div>
              <div className="info-item" style={{ margin: 0, fontSize: 13 }}>
                <p>Team</p>
                {lead.team}
              </div>
              <div className="info-item">
                <p>Priority</p>
              </div>
              <div className="info-item">
                <p>Source</p>
                {lead.source}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ContactDetails
