import React, { useState } from 'react'

import {
  Button,
  Dropdown,
  Header,
  Modal,
  Input  
} from 'semantic-ui-react'


import CommonButtons from './CommonButtons'

const CreateCallModal = ({ open, isModalClose, dropDownData, leadData, onCallRequest, leadId }) => {

 

  const [sendMsgData, setSendMsgData] = useState({
    from: null,
    lead_id: leadData.id.toString().replace('#', ''),
    message: null,
    to: leadData?.phone_number
  })

  return (
    <Modal
      className="send-message-modal"
      onClose={isModalClose}
      closeIcon
      open={open}
      size="tiny"      
    >
      <Header content="Create Call" />
      <Modal.Content  style={{ padding: "30px" }}>
        <div className="send-message__dropdown">
          <label>From *</label>
          <Dropdown
            name="from"
            placeholder="Select Number"
            selection
            options={dropDownData}
            onChange={(e, data) =>
              setSendMsgData({ ...sendMsgData, from: data.value })
            }
          />
        </div>
        <div className="send-message__input">
          <label>To </label>
          <Input name="to" value={sendMsgData.to} disabled title="To" />
        </div>
       
      </Modal.Content>
      <Modal.Actions className="send-message__btn">
        <Button onClick={isModalClose} className="send-message__btn-grey">
          close
        </Button>
        <CommonButtons
          content="Call Now"
          btnClass="send-message__btn-blue"
          background="#007bff"
          style={{ color: 'white' }}
          onClick={e => {onCallRequest(leadId); isModalClose()}}
        />
      </Modal.Actions>
    </Modal>
  )
}
export default CreateCallModal
