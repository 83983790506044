import React, { useState } from 'react'
import { Tab, Input } from 'semantic-ui-react'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import closeIcon from '../../assets/images/closeGray.png'
import ScheduleDetailPane from './scheduleTabdetail'
import apiConfig from '../../config/apiconfig.json'

const ScheduleModalSide = ({ schedulePopUp, setSchedulePopUp }) => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [Date, setData] = useState()

  const [meetingData, setMeetingData] = useState({
    user_id: parseInt(localStorage.getItem('id')),
    meeting_duration: 30,
    name: '',
    location: '',
    description: '',
    time_zone: 'GMT+2',
    buffer_time: 10,
    availability_type: 'simple_week',
    day_from: '',
    day_to: 'Thursday',
    time_from: '',
    time_to: ''
  })
  
  const handleChange = (field, value) => {
    setMeetingData({
      ...meetingData,
      [field]: value
    })
  }
  const handleChangeTime = (field, value) => {
    const time = moment(value).format('hh:mm')
  
    setMeetingData({
      ...meetingData,
      [field]: time
    })
  }
  const handleSubmit = () => {
    const URL = `${apiConfig.calenderURL}/api/event/create`
    const data = meetingData
    axios
      .post(URL, data)
      .then(data => {
        setSchedulePopUp(false)
       
      })
      .catch(err => {
      
      })
  }
  const panes = [
    {
      menuItem: 'Details',
      render: meetingData => (
        <ScheduleDetailPane
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setSchedulePopUp={setSchedulePopUp}
          meetingData={meetingData}
        />
      )
    }

   
  ]

  return (
    <div
      className={
        schedulePopUp == true ? 'details-popup active' : 'details-popup'
      }
    >
      <div className="details-title">
        <p>Schedule Meeting</p>
        <img
          className=""
          src={closeIcon}
          onClick={() => setSchedulePopUp(false)}
          alt=""
        />
      </div>
      <div className="scheduleHeadDetail">
        <div className="schedulename">
          <label>Meeting name</label>
          <Input
            placeholder="Livevpn with Steve"
            name="name"
            value={meetingData.name}
            onChange={e => handleChange('name', e.target.value)}
          />
        </div>
        <div className="scheduledate">
          <label>Meeting date</label>
         
          <DatePicker
            dateFormat="yyyy/MM/dd"
            selected={meetingData?.day_from}
            onChange={data => handleChange('day_from', data)}
            timeIntervals={15}
            timeCaption="date"
          />
        </div>
        <div className="fromtowrap">
          <div className="fromWrap">
            <label>From</label>
            <DatePicker
              selected={meetingData?.time_from}
              onChange={data => handleChangeTime('time_from', data)}
              showTimeSelect
              showTimeSelectOnly
              // timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className="fromdropdown"
            />
          </div>
          <div className="toWrap">
            <label>To</label>
            <DatePicker
              selected={meetingData?.time_to}
              onChange={data => handleChange('time_to', data)}
              showTimeSelect
              showTimeSelectOnly
              // timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className="fromdropdown"
            />
          </div>
        </div>
      </div>
      <div className="contact-popup-detail">
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          setSchedulePopUp={setSchedulePopUp}
          meetingData={meetingData}
        />
      </div>
    </div>
  )
}
export default ScheduleModalSide
