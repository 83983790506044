import React, {useState, useEffect} from 'react'
import widgetBubble from '../../assets/images/phone.svg'

const bubbleDefaultSetting = {
    color: '#0080FF',
    shadow: false,
    textOnline: 'Would you like us to call you now ?',
    textOffline: 'Hey there! We are offline now ?',
    position: '',
    labelColor: '#2B2B2C',
    shape: 'default'
  }

const ButtonPreview = ( {widgetObject, widgetBubbleColor, bubbleSettData, bubbleSett} ) => {
    const [bubbleSettingData, setBubbleSettingData] = useState({})
    const [bubbleApiSettingData, setBubbleApiSettingData] = useState({})
    const [apiLoaded, setApiLoaded] = useState(false)
    const [bubbleSetting, setBubbleSetting] = useState(bubbleDefaultSetting)

    useEffect(() => {
        fetchSetting()   
        setApiLoaded(true)
      }, [widgetObject])


      useEffect(() => {
        if(!bubbleSettData) return;
        setBubbleSettingData(bubbleSettData);          
      }, [bubbleSettData])

      useEffect(() => {
        if(!bubbleSett) return;
        setBubbleSetting(bubbleSett);          
      }, [bubbleSett])


    const fetchSetting = () => {
   
      let tmpData = widgetObject;     

      setBubbleSettingData(tmpData);             
      setBubbleApiSettingData(widgetObject)

    }


  return (
    <div id="button-preview">
         <div className="widget-bubble1 widget-bubble-wrapper" style={{ padding: "0 !important" }}>              
              <div className="greetings_side_popup" style={{ padding: "0 !important"}}>              
              { bubbleSettingData.bubble_template === parseInt(1) ? ( <div
                    className={`widget-bubble ${bubbleSettingData.bubble_position}`}
                  >
                    <div
                      className={`bubble-sec ${bubbleSettingData.shape}`}
                      style={{
                        backgroundColor: bubbleSettingData.circle_color,                        
                        boxShadow:
                          bubbleSetting.shadow &&
                          '0px 0px 0px 0px rgba(0,0,0,0.50)'
                      }}
                    >
                      <img src={widgetBubble} alt="" />
                    </div>
                    <div
                      className="bubble-text"
                      style={{ backgroundColor: widgetBubbleColor }}
                    >
                      {bubbleSettingData.bubble_text}
                    </div>
                  </div>)
                  :( <div style={ (bubbleSettingData.bubble_position === 'bottom_right' || bubbleSettingData.bubble_position === 'top_right') ? {backgroundColor: bubbleSettingData.circle_color} : { backgroundColor: bubbleSettingData.circle_color}} className={'template-2-button-container'  + ((bubbleSettingData.bubble_position === 'top_right' || bubbleSettingData.bubble_position === 'bottom_right') ? ' template-2-button-right-side-container': '')}>
                    {bubbleSettingData.bubble_text} 
                  </div>)
                  }       
               
              
              </div>

              
             
            </div>

    </div>
  )
}

export default ButtonPreview