import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { CommonNotify } from '../../../common/CommonNotify'
import CallLogs from '../../../containers/CallLogs'
import RecentVisits from '../../../containers/RecentVisits'
import moment from 'moment'

export default function TimeLine({ leadData }) {
  const [leadInfo, setLeadInfo] = useState(null)
  const [recentInfo, setRecentInfo] = useState([])

  useEffect(() => {
    if (leadData?.lead?.id) {
      fetchCallData()
    }
  }, [leadData?.lead?.id])

  const fetchCallData = async () => {

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/get-call-by-id?id=${leadData?.lead?.id}`
    await Axios.get(url)
      .then(res => {
        if (res.data.data) {
          let pageHistory = []
          if (
            res?.data?.data?.trackingData &&
            res?.data?.data?.trackingData?.length !== 0
          ) {
            pageHistory = res?.data?.data?.trackingData?.browsing_history
              ? res?.data?.data?.trackingData?.browsing_history?.sort((a, b) => moment(b?.visitied_on)?.valueOf() - moment(a?.visitied_on)?.valueOf())
              : []
          } else {
            pageHistory = []
          }
          setLeadInfo(res?.data?.data || null)
          setRecentInfo(pageHistory)
        }
      })
      .catch(error => {
        CommonNotify('Invalid Lead ID')
      })
  }

  return (
    <div className="contact-card insightTab">
      {/* <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <CallLogs data={leadInfo} limit={5} />
        </Grid>
      </div>{' '} */}
      <div className="contact-card-boxes contact-card-boxess">
        <RecentVisits data={leadInfo} history={recentInfo} open={true} />
      </div>{' '}
    </div>
  )
}
