import React, { Component } from 'react'
import axios from 'axios'
import CommonTable from '../../common/CommonTable'
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
// redux
import { connect } from 'react-redux'
import invoice from '../../assets/images/invoice.png'
import { getCustomerInvoices } from '../../actions/settings'
import { Typography } from '@mui/material'

class Invoice extends Component {
  state = {
    dataTable: {
      type: '5',
      header: [
        {
          headerTitle: 'Invoice-Id'
        },
        {
          headerTitle: 'Description'
        },
        {
          headerTitle: 'Price'
        },
        {
          headerTitle: 'Date'
        },
        {
          headerTitle: 'Status'
        },
        {
          headerTitle: 'Download'
        }
      ],
      tableContentData: []
    },
    isLoading: true
  }

  componentDidUpdate(prevProps) {
    if (this.props.customerInvoices !== prevProps.customerInvoices) {
      const d = this.props.customerInvoices.data       

      const { dataTable } = this.state
      let allData = dataTable.tableContentData

      for (let index = 0; index < d?.length; index++) {

        const result = {

          columnOne: d[index].id,
          // columnTwo: d[index]?.title || ' ',
          columnTwo: d[index]?.description || ' ',
          columnThree: `$ ${d[index].amount}`,
          columnFour: d[index].date,
          columnFive: this.firstLetterCap(d[index].status),
          action: 'edit'
        }
        allData.push(result)
      }

      this.setState({ tableContentData: allData, isLoading: false })   
    }
  }

  componentDidMount() {
    if (this.props.customerInvoices) {
      const d = this.props.customerInvoices.data       

      const { dataTable } = this.state
      let allData = dataTable.tableContentData

      for (let index = 0; index < d?.length; index++) {
        const result = {

          columnOne: d[index].id,
          // columnTwo: d[index]?.title || ' ',
          columnTwo: d[index]?.description || ' ',
          columnThree: `$ ${d[index].amount}`,
          columnFour: d[index].date,
          columnFive: this.firstLetterCap(d[index].status),

        }
        allData.push(result)
      }

      this.setState({ tableContentData: allData, isLoading: false })   
    } else {
      this.setState({ isLoading: true })   
      this.props.getCustomerInvoices()
    }

    
    
  }

  firstLetterCap = (string) =>{
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  render() {
    return (
      <>
        <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>
        <div className="billing-invoice-margin invoices">
   {(!this.state.isLoading &&  this.state.dataTable?.tableContentData?.length ===0) ?     <Segment className='no-invoice' >
                   
            <div>

                <img src={invoice} alt=''/>
                <Typography variant='h6' component='h6'> Nothing in your invoices </Typography>
            <Typography variant='body1' component='p'>After you subscribe, all your invoices will appear here</Typography>
              </div>
        </Segment>:
        <CommonTable dataTable={this.state.dataTable} type="5" />}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  customerInvoices: state.settings.customerInvoices,
});

export default connect(
  mapStateToProps,
  { getCustomerInvoices }
)(Invoice)
