import React from 'react'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'

import QualifyFooter from './QualifyFooter'
import LeadAnalytics from './LeadAnalytics'

const LeadInsight = ({ leadData, leadScore }) => {
  const enrichment = leadData?.enrichment_user_info
  const technology = leadData?.lead?.trackingData
  const ip_address = leadData?.lead?.ip_address || ''

  return (
    <div className="contact-card" >
      <LeadAnalytics leadData={leadData} />

    <div className="insightTab">
      {' '}
    
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Visitor Insight :</h4>

          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Company:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {enrichment?.company_domain}
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Location:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {enrichment?.company_domain}
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Type:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {enrichment?.company_domain}
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Role:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {enrichment?.company_domain}
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Industry:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {enrichment?.company_domain}
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Revenue:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text"></p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Employees:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text"></p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Hq:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text"></p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Website:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text"></p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Ip address:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text"></p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>{' '}
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Technology :</h4>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Ip-Address:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{ip_address}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">OS device:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{technology?.os || ''}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Browser:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {technology?.browser?.name}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>{' '}
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Acquisition :</h4>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Acquisition:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text"></p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>{' '}
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Visites Page: </h4>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Visited page:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <a
                className="card-boxes-right-text"
                href={
                  'https://' + technology?.browsing_history?.[0] &&
                  technology?.browsing_history?.[0]?.page_url
                }
                target="_blank"
              >
                <p className="card-boxes-right-text text-truncate">
                  {technology?.browsing_history?.[0] &&
                    technology?.browsing_history?.[0]?.page_url
                      ?.toString()
                      ?.substring(0, 35)}
                </p>
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Last Seen:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {technology?.last_interaction &&
                  moment(
                    technology?.last_interaction?.split(' ')[0],
                    'YYYY-MM-DD'
                  ).format('DD-MMM')}
                {'   '}
                {technology?.last_interaction &&
                  moment(
                    technology?.last_interaction?.split(' ')[1],
                    'HH'
                  ).fromNow()}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Visit:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {technology?.browsing_history?.[0] &&
                  technology?.browsing_history?.[0]?.visitied_on}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <QualifyFooter leadData={leadData.lead} leadScore={leadScore} />
    </div>
    </div>
  )
}

export default LeadInsight
