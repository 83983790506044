import React from 'react'
import { Tab, Table } from 'semantic-ui-react'
import messageIcon from '../../assets/images/messageIcon.png'
import celender from '../../assets/images/celender.png'

import contactprofile from '../../assets/images/contactprofile.png'
import UsaIcon from '../../assets/images/Usa.png'
import Ellipse from '../../assets/images/Ellipse-39.png'
import callIcon from '../../assets/images/call_icon.png'
import personIcon from '../../assets/images/person.png'

const VipFilterData = ({ dataVip, modalOpen, opendetails, onClickData }) => {
  return (
    <Tab.Pane attached={false} onClick={onClickData}>
      <Table singleLine className={opendetails == true ? 'tablepopup' : ''}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Contact</Table.HeaderCell>
            <Table.HeaderCell>Country</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {dataVip?.map(data => {
          return (
            <>
              <Table.Body>
                <Table.Row
                  onClick={() => {
                    modalOpen()
                    onClickData(data.id)
                  }}
                >
                  <Table.Cell className="date-set-table">
                    <div className="date-set-table-boxes">
                      <img
                        className="ui medium circular image"
                        src={contactprofile}
                        alt=""
                      />
                      <div>
                        <p>{data && data?.name}</p>
                        <p>{data && data?.organization_name}</p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="time-set-table">
                    <div className="phone-call-set">
                      <img
                        className="ui medium circular image"
                        src={UsaIcon}
                        alt=""
                      />
                      <p>{data?.country}</p>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="metting-name">
                    {data?.email}
                  </Table.Cell>

                  <Table.Cell className="link-table">
                    {data?.owner?.first_name}
                  </Table.Cell>

                  <Table.Cell className="host-table">
                    <div className="leadtable">
                      <img
                        className="ui medium circular image"
                        src={Ellipse}
                        alt=""
                      />
                      <p>LEAD</p>
                    </div>
                  </Table.Cell>
                  <Table.Cell>Monday , 11:00 PM</Table.Cell>
                  <Table.Cell className="social-icon-set">
                    <div className="social-box-wrapper">
                      <div className="social-boxes">
                        <img
                          className="ui medium circular image"
                          src={callIcon}
                          alt=""
                        />
                      </div>
                      <div className="social-boxes">
                        <img
                          className="ui medium circular image"
                          src={messageIcon}
                          alt=""
                        />
                      </div>
                      <div className="social-boxes">
                        <img
                          className="ui medium circular image"
                          src={celender}
                          alt=""
                        />
                      </div>
                      <div className="social-boxes">
                        <img
                          className="ui medium circular image"
                          src={personIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </>
          )
        })}
      </Table>
    </Tab.Pane>
  )
}
export default VipFilterData
