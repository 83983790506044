import React, { useEffect } from 'react'
import { Button, SelectMenu } from 'evergreen-ui'
var ops = null

function EverGreenSelect({
  allOptions,
  defaultValues,
  onUpdateValues,
  title,
  head,
  type,
  closeOnSelect,
  onlyTitle
}) {
  const [selectedItemsState, setSelectedItems] = React.useState(defaultValues)
  const [selectedItemNamesState, setSelectedItemNames] = React.useState(
    selectedItemsState.length === 0
      ? title
      : `${defaultValues.length} items selected`
  )

  useEffect(() => {}, [selectedItemsState])

  return (
    <SelectMenu
      isMultiSelect
      filterIcon
      title={title}
      options={allOptions}
      selected={selectedItemsState}
      closeOnSelect={closeOnSelect}
      onCloseComplete={() => {
        onUpdateValues(selectedItemsState)
      }}
      onSelect={item => {
        const selected = [...selectedItemsState, item.value]
        const selectedItems = selected
        const selectedItemsLength = selectedItems.length
        let selectedNames = ''
        if (selectedItemsLength === 0) {
          selectedNames = ''
        } else if (selectedItemsLength === 1) {
          selectedNames = selectedItems.toString()
        } else if (selectedItemsLength > 1) {
          selectedNames = selectedItemsLength.toString() + ' selected...'
        }
        setSelectedItems(selected)
        setSelectedItemNames(selectedNames)
      }}
      onDeselect={item => {
        const deselectedItemIndex = selectedItemsState.indexOf(item.value)
        const selectedItems = selectedItemsState.filter(
          (_item, i) => i !== deselectedItemIndex
        )
        const selectedItemsLength = selectedItems.length
        let selectedNames = ''
        if (selectedItemsLength === 0) {
          selectedNames = ''
        } else if (selectedItemsLength === 1) {
          selectedNames = selectedItems.toString()
        } else if (selectedItemsLength > 1) {
          selectedNames = selectedItemsLength.toString() + ' selected...'
        }

        setSelectedItems(selectedItems)
        setSelectedItemNames(selectedNames)
      }}
    >
      {onlyTitle ? (
        <button>{title}</button>
      ) : (
        <Button>
          {selectedItemsState.length === 0
            ? title
            : `${head} (${selectedItemsState.length} ${type} added)`}
        </Button>
      )}
    </SelectMenu>
  )
}

export default EverGreenSelect
