import axios from 'axios'
import React, {  useState } from 'react'
import { Modal,  Dimmer, Loader, Dropdown } from 'semantic-ui-react'

import CommonButtons from './CommonButtons'
import CommonSelect2 from './CommonSelect2'
import CommonInput from './CommonInput'

import { CommonNotify } from './CommonNotify'

const modalContent = [
  {
    text: 'Manager',
    value: 17
  },
  {
    text: 'Widget Manager',
    value: 18
  },
  {
    text: 'Team Leader',
    value: 19
  },
  {
    text: 'Team Member',
    value: 20
  },
  {
    text: 'Operation Manager',
    value: 21
  },
  {
    value: 16
  }
]

const lstScoredUser = [
  { value: 'Lead', label: 'Lead' },
  { value: 'MQL', label: 'MQL' },
  { value: 'SQL', label: 'SQL' },
  { value: 'Opportunity', label: 'Opportunity' },
  { value: 'Customer', label: 'Customer' },
  { value: 'Spam', label: 'Spam' },
  { value: 'Disqualified', label: 'Disqualified' }
]
const ManageTeamModal = prop => {

 
    const [open, setOpen] = useState(false)

  const [teams, setTeams] = useState(prop.allTeams)

  const [isTeam, setIsTeam] = useState(false)

  const [fteams,setFteams] = useState([])

  const [selectedTeams, setSelectedTeams] = useState(prop.data.userTeams)

  const [data, setData] = useState({
    id: prop.data.id,
    phone: prop.data.phone,
    name: prop.data.name,
    email: prop.data.email,
    role: { text: prop.data.role.text, value: prop.data.role.value },
    accountStatus: prop.data.accountStatus
  })

  const [loading, setLoading] = useState(false)

  const onChangeInput = e => {
    const { name, value } = e.target
    let data1 = { ...data }
    data1[name] = value
    setData(data1)
  }

  const handleModalOpen = () => {
    setOpen(true)
  }

  const handleModalClose = () => {
    setOpen(false)
  }
  const memberRoleUpdateNameUpdate = data => {
    switch (data) {
      case 16:
        return 'Owner'
      case 17:
        return 'Admin'
      case 19:
        return 'Member'
      default:
        return ''
    }
  }
  const onChangeSelectFilter = (e, element) => {
    let { name, value } = element
    let data1 = { ...data }
  
    data1.role = {
      name: memberRoleUpdateNameUpdate(value),
      value: value
    }
    
    setData(data1)
  }
  const handleRadioChange = (e, { value }) => {
    setData({ ...data, accountStatus: value })
  }

  const memberRoleUpdate = data => {
    switch (data) {
      case 'Admin':
        return 17
      case 'Member':
        return 19
      case 'Owner':
        return 16
      default:
        return ''
    }
  }

  const onSave = () => {
    setLoading(true)

    
    var bodyData = {
      user_id: data.id,
      full_name: data.name,
      role: data.role.value,
      
    }

    if(isTeam)
    {
      bodyData.teams = fteams
    }


    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/update-member`
    axios
      .post(url, bodyData)
      .then(res => {
        setOpen(false)
        CommonNotify(res.data.message[0], 'success')
        prop.fetchData()
        setLoading(false)
      })
      .catch(error => {       
        CommonNotify(
          error?.response?.data?.errors?.[0]
            ? error.response.data.errors[0]
            : 'Not able to Delete the Teammates'
        )
        setLoading(false)
      })    
  }

  const changeScoredLead = data => {  
    var f = []
    data && data.map(function(item,index){
    f.push(item.value)
    })
   setSelectedTeams(data)
   setFteams(f)
   setIsTeam(true)
  }
  return (
    <>
      <Modal
        className="modal-wrapper manageTeamModal"
        style={{padding: 18}}
        trigger={<i className="fa fa-edit" onClick={handleModalOpen} />}
        basic
        size="small"
        open={open}
        onClose={handleModalClose}
        style={{ padding: "10px 20px" }}
      >
        <Modal.Header className="modal-header">Edit Team Member</Modal.Header>
        <Modal.Content>
         
          <div className="modal-input-wrapper">
            <p className="modal-input-text default-text">Name:</p>
            <CommonInput
              defaultValue={data['name']}
              onChange={e => onChangeInput(e)}
              inputStyle="modal-input"
              name="name"
              className="manageteam"
            />
          </div>
          <div className="modal-input-wrapper">
            <p className="modal-input-text default-text">Email:</p>
            <CommonInput
              defaultValue={data['email']}            
              onChange={e => onChangeInput(e)}     
              inputStyle="modal-input"
              name="email"      
              disabled={true}       
              className="manageteam"
            />
          </div>

          {prop.data.role.text !== 'Owner' ? (
            <div className="modal-input-wrapper dropdown">
              <p className="modal-input-text default-text">Role:</p>
            
              <Dropdown
                placeholder={prop.data.role.text}
                name="role"
                selection
                style={{backgroundColor: "#f4f4f4", color: "#000"}}
                // disabled={prop.data.role.text === 'Client' ? true : false}
                options={prop.roleData}
                onChange={onChangeSelectFilter}
                className="manageteam"
              />

            </div>
          ) : null}

          
           <div className="modal-input-wrapper" style={{color: 'black'}}>
            <p className="modal-input-text default-text" >Teams:</p>
            <CommonSelect2           
            onChange={changeScoredLead}
            value={selectedTeams}
            options={prop.allTeams}
            title=""
            />
          </div> 
         

        </Modal.Content>
        <Modal.Actions className="modal-button-wrapper">
          <CommonButtons
            onClick={onSave}
            content={
              loading ? (
                <Dimmer active={loading}>
                  <Loader />
                </Dimmer>
              ) : (
                'Save'
              )
            }
            background="blue"
            btnClass="btn-modal-style"
          />
          <CommonButtons
            onClick={handleModalClose}
            type="reset"
            content="Close"
            background="grey"
          />
        </Modal.Actions>
      </Modal>
    </>
  )
}
export default ManageTeamModal
