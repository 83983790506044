import React, {useState} from 'react'
import { Button, Dropdown, Input, Modal, TextArea, Image } from 'semantic-ui-react'
import { flex } from 'ui-box'
import moment from 'moment'
import SemanticDatepicker from 'react-semantic-ui-datepickers';

const activitiesType = [
    {
      id: 0,
      text: 'Phone Call',
      value: 'Phone Call'
    },
    {
      id: 1,
      text: 'Message',
      value: 'Message'
    },
    {
      id: 2,
      text: 'Meeting',
      value: 'Meeting'
    },
    {
      id: 3,
      text: 'Note',
      value: 'Note'
    },
  ]

const AddActivity = () => {

  // const [filter, setFilter] = useState(datas)
  const [modalOpen, setModalOpen] = useState(false)
  const [activityType, setActivityType] = useState("Choose Activity Type")
  const [startDate, setStartDate] = useState(new Date());
  const [summary, setSummary] = useState("");
  const [title, setTitle] = useState("");

  const onActivityAdd = async() => {    
    
  }

  return (
    <>
         <Button primary style={{right: '46%', top: '15px' }} onClick={() => setModalOpen(true)}>
             <i className={`fa fa-plus`}></i> New Activity
          </Button>

        <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        style={{ zIndex: '1500 !important'}}
            >
              <Modal.Header>Add Activity</Modal.Header>
              <Modal.Content style={{padding: '50px', paddingBottom: '100px'}}>
                  <SemanticDatepicker showToday={true} format="YYYY-MM-DD" onChange={(date) => setStartDate(date)} />
                  <div style={{ display: 'flex', width: '100%', marginBottom: '20px', marginTop: '20px' }}>
                  <Dropdown
                    className="dropButton"
                    placeholder="choose activity type"
                    style={{ width: '40%' }}
                    onChange={(e, data) => setActivityType(data.value)}
                    selection
                    options={activitiesType}
                  />
                  <Input placeholder="Enter title" style={{ width:'50%', marginLeft: '10%' }} onChange={ e => setTitle(e.target.value) } value={title} ></Input>
                  </div> 
                  
                  <TextArea  onChange={ e => setSummary(e.target.value) } value={summary}  placeholder='Tell us more' />
              </Modal.Content>
              <Modal.Actions>
                  <Button primary  onClick={() => onActivityAdd()}>
                      save
                  </Button>
                  <Button onClick={() => setModalOpen(false)}>
                      cancel
                  </Button>
              </Modal.Actions>
      </Modal>
    </>
  )
}

export default AddActivity