import React, { useMemo, useState, useEffect } from 'react'
import { Button, Modal, Radio, Tab, Table } from 'semantic-ui-react'
import CommonCheckbox from '../../../common/CommonCheckbox'

const LeadPanels = ({ leadData, setLeadId, leadId }) => {
  const [assignedPopUp, setAssigned] = useState(false)
  const [radioState] = useState('yes')
  const [selected, setSelected] = useState(null)

  return (
    <Tab.Pane attached={false}>
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <CommonCheckbox name="isMarkAllCheckbox" />
            </Table.HeaderCell>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {leadData?.map((data, index) => {
            return (
              <Table.Row
                onClick={() => setLeadId(data.id)}
                data-key={index}
                active={leadId === data.id}
              >
                <Table.Cell data-key={index}>
                  <CommonCheckbox name="isChecked" checked={data.isChecked} />
                </Table.Cell>
                <Table.Cell className="time-set-table">
                  {data.id}
                </Table.Cell>
                <Table.Cell className="metting-name">
                  {data.phone_number}
                </Table.Cell>
                <Table.Cell className="link-table">
                  {data.state}
                </Table.Cell>
                <Table.Cell className="link-table">
                  {data.created_at}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </Tab.Pane>
  )
}
export default LeadPanels
