import { CHANGE_DIALER_STATUS, SET_PHONE_NUMBER } from './types'

export const getDialer = (status) => {
    // Return an action
    return {
      type: CHANGE_DIALER_STATUS,
      payload: status,
    };
  };

  export const setPhoneNumber = (status) => {
    // Return an action
    return {
      type: SET_PHONE_NUMBER,
      payload: status,
    };
  };
