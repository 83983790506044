import axios from 'axios'
import { CommonNotify } from '../common/CommonNotify'

export const onCallRequest = id => {
  const apiToken = localStorage.getItem('access_token')

  const leadID = id?.replace('#', '')
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/request-call-from-dashboard?lead_id=${leadID}`

  axios
    .get(url)
    .then(res => {
      CommonNotify('Call Requested', 'success')
    })
    .catch(err => {
      CommonNotify(
        err.response.data.errors
          ? err.response.data.errors[0]
          : 'Some error occured.'
      )
    })
}

export const sendMessage = async (sendMsgData, setSendMsgData) => {
  if (!sendMsgData.from) {
    return CommonNotify('Destination number missing', 'warning')
  }

  const ts = sendMsgData?.to?.charAt(0)
  if (ts != '+') {
    sendMsgData.to = '+' + sendMsgData?.to
  }

  if (sendMsgData?.message?.trim() === '') {
    return CommonNotify('The Message filed is required', 'warning')
  }

  if (sendMsgData?.message?.length < 3) {
    return CommonNotify('Message must be 3 characters long', 'warning')
  }

  // sendMsgData.lead_id =
  //   parseInt(
  //     sendMsgData?.lead_id
  //       ? sendMsgData.lead_id?.toString()?.replace('#', '')
  //       : sendMsgData?.lead_id
  //   ) || sendMsgData?.lead_id

  // const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/send-message`
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/send-sms`
  const paylaod = {
    from: sendMsgData?.from,
    to: sendMsgData?.to,
    message: sendMsgData?.message
  }
  axios
    .post(url, paylaod)
    .then(res => {
      setSendMsgData({
        ...sendMsgData,
        message: '',
        close: true
      })
      CommonNotify('Message Sent Successfully', 'success')
    })
    .catch(err => {
      CommonNotify(
        err?.response?.data?.errors?.[0] || 'Not able to Send Message!'
      )
    })
}

export const sendMessageUser = async (sendMsgData, onClose, scheduleInfo)  => {
  if (!sendMsgData.from) {
    return CommonNotify('Destination number missing', 'warning')
  }

  const ts = sendMsgData.to.charAt(0)
  if (ts != '+') {
    sendMsgData.to = '+' + sendMsgData.to
  }

  if (sendMsgData.message.trim() === '') {
    return CommonNotify('The Message filed is required', 'warning')
  }

  if (sendMsgData.message.length < 3) {
    return CommonNotify('Message must be 3 characters long', 'warning')
  }

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/send-sms`

  axios
    .post(url, sendMsgData)
    .then(res => {
      CommonNotify(scheduleInfo ? 'Message Scheduled Successfully':'Message Sent Successfully', 'success')
      onClose(res?.data?.data)
    })
    .catch(err => {
      CommonNotify(
        err?.response?.data?.errors?.[0] ||( scheduleInfo ? 'Not able to Schedule Message!':  'Not able to Send Message!')
      )
    })
}

export const _createActivity = async activityData => {
  const apiToken = await localStorage.getItem('access_token')
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/lead-activity`
    axios
      .post(url, activityData)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const _updateActivity = async (activityData, actId) => {
  const apiToken = await localStorage.getItem('access_token')
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/lead-activity/${actId}`
    axios
      .post(url, activityData)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const _deleteActivity = async actId => {
  const apiToken = await localStorage.getItem('access_token')
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/lead-activity/${actId}`
    axios
      .delete(url)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const _fetchActivity = async (lead, filter = '') => {
  const apiToken = await localStorage.getItem('access_token')

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/lead/${lead}/activities${filter}`
    axios
      .get(url)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const _fetchActivityAuto = async lead => {
  const apiToken = await localStorage.getItem('access_token')

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/logs/${lead}`
    axios
      .get(url)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const sendMessageToContact = (
  sendMsgData,
  contactID,
  setSendMsgData
) => {
  if (!sendMsgData.from) {
    return CommonNotify('Destination number missing', 'warning')
  }

  if (sendMsgData.message.trim() === '') {
    return CommonNotify('The Message filed is required', 'warning')
  }

  if (sendMsgData.message.length < 3) {
    return CommonNotify('Message must be 3 characters long', 'warning')
  }

  const apiToken = localStorage.getItem('access_token')

  const form = new FormData()
  form.append('message', sendMsgData.message)

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${contactID}/sms`

  axios
    .post(url, form)
    .then(res => {
      setSendMsgData({
        ...sendMsgData,
        message: ''
      })
      CommonNotify('Message Sent Successfully', 'success')
    })
    .catch(err => {
      CommonNotify('unable to Send Message, BE error')
    })
}

export const onChangeInterseted = (e, data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-interested`
  const postData = {
    lead_id: lead_id.slice(1),
    interested: data.value
  }

  axios
    .post(url, postData)
    .then(res => {
      CommonNotify('Updated successfully', 'success')
    })
    .catch(err => {
      CommonNotify('Not able to update Lead status ')
    })
}

export const onChangeInterseted2 = (e, data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-interested`
  const postData = {
    lead_id: lead_id,
    interested: data.value
  }

  axios
    .post(url, postData)
    .then(res => {
      CommonNotify('Updated successfully', 'success')
    })
    .catch(err => {
      CommonNotify('Not able to update Lead status ')
    })
}

export const onChangeIntersetedVal = (e, data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-interested`
  const postData = {
    lead_id: lead_id,
    interested: data.value
  }

  axios
    .post(url, postData)
    .then(res => {})
    .catch(err => {
      CommonNotify('Not able to update Lead status ')
    })
}

export const onStatusChangeHandler = (e, data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-status`
  const postData = {
    lead_id: lead_id.slice(1),
    status: data.value
  }

  axios
    .post(url, postData)
    .then(res => {
      CommonNotify('Lead status updated', 'success')
    })
    .catch(err => {
      CommonNotify('Not able to update lead status')
    })
}

export const onStatusChangeHandler2 = (e, data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-status`
  const postData = {
    lead_id: lead_id,
    status: data.value
  }

  axios
    .post(url, postData)
    .then(res => {})
    .catch(err => {
      CommonNotify('Not able to update  Good Fit')
    })
}

export const onChangeInterseted3 = (e, data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  if (lead_id[0] === '#') {
    lead_id = lead_id.substring(1)
  }

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-interested`
  const postData = {
    lead_id: lead_id,
    interested: data.value
  }
  return axios.post(url, postData)
}

export const onStatusChangeHandler3 = (e, data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  if (lead_id[0] === '#') {
    lead_id = lead_id.substring(1)
  }

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-status`
  const postData = {
    lead_id: lead_id,
    status: data.value
  }

  return axios.post(url, postData)
}

export const _onTagsSaveHandler = postData => {
  return new Promise((resolve, reject) => {
    const apiToken = localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/add-tags`

    axios
      .post(url, postData)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const _getLeadNotes = callId => {
  return new Promise((resolve, reject) => {
    const apiToken = localStorage.getItem('access_token')

    const geturl = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${callId}/note`
    axios
      .get(geturl)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const onLeadScoreHandler = (e, data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  const leadId = lead_id

  if (data.value == 'null' || !data.value) {
    CommonNotify('Cant set this option please select other option', 'warning')
    return
  }

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadId}/scores`
  const postData = {
    score: data.value
  }

  axios
    .post(url, postData)
    .then(res => {
      CommonNotify('Score updated', 'success')
    })
    .catch(err => {
      CommonNotify('Not able to update  Score')
    })
}

export const onLeadStageHandler = (stage, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/change-lead-stage`
  const postData = {
    lead_id: lead_id,
    stage: stage
  }

  axios
    .post(url, postData)
    .then(res => {
      CommonNotify('Done successfully', 'success')
    })
    .catch(err => {
      CommonNotify('Not able to update  Score')
    })
}

export const onLeadScore = (data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  const leadId = lead_id

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadId}/scores`
  const postData = {
    score: data
  }

  axios
    .post(url, postData)
    .then(res => {
      CommonNotify('Done successfully', 'success')
    })
    .catch(err => {
      CommonNotify('Not able to update  Score')
    })
}

export const onChangeOwner = (e, data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  const leadId = lead_id

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/set-owner`
  const postData = {
    user_id: data.value,
    lead_id: leadId
  }

  axios
    .post(url, postData)
    .then(res => {})
    .catch(err => {
      CommonNotify('Not able to update  Owner')
    })
}

export const onChangeOwner3 = (e, data, lead_id) => {
  const apiToken = localStorage.getItem('access_token')

  if (lead_id[0] === '#') {
    lead_id = lead_id.substring(1)
  }

  const leadId = lead_id

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/set-owner`
  const postData = {
    user_id: data.value,
    lead_id: leadId
  }

  return axios.post(url, postData)
}

export const getLeadStages = widget_id => {
  return new Promise((reslove, reject) => {
    const apiToken = localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget_id}/stages`

    axios
      .get(url)
      .then(res => {
        reslove(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const setLeadStage = (widget_id, stages, setLoading) => {
  const apiToken = localStorage.getItem('access_token')

  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/update-stages`
  const postData = {
    widget_id: widget_id,
    stages: stages
  }

  axios
    .post(url, postData)
    .then(res => {})
    .catch(err => {})
}

export const getLeadOwner = () => {
  return new Promise((reslove, reject) => {
    const apiToken = localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`

    axios
      .get(url)
      .then(res => {
        reslove(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const _getLeadActivities = lead_id => {
  return new Promise((resolve, reject) => {
    const apiToken = localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/logs/${lead_id}`

    axios
      .get(url)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
