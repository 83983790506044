import React, { Component } from 'react'
import { Form, Icon, TextArea } from 'semantic-ui-react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import WebIcon from '@mui/icons-material/Web'
import { ReactComponent as Wix } from '../../assets/images/wixLogo.svg'
import { ReactComponent as SquaredSpace } from '../../assets/images/squaredspace.svg'
import { ReactComponent as WordIcon } from '../../assets/images/wordpress.svg'
import { ReactComponent as GoogleTag } from '../../assets/images/logo_setting_1.svg'
import { ReactComponent as CodeBracket } from '../../assets/images/codeBracket.svg'
import { ReactComponent as WebFlow } from '../../assets/images/Webflow.svg'
// import { ReactComponent as GoDaddy } from '../../assets/images/godaddy.svg'
import { ReactComponent as Weebly } from '../../assets/images/weebly.svg'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import axios from 'axios'
import wordpress from '../../assets/images/wordpress.svg'
import logoSetting from '../../assets/images/logo_setting_1.svg'
import { Modal, Button, Input } from 'semantic-ui-react'
//redux
import { connect } from 'react-redux'
import { getUserIntegrations } from '../../actions/settings'
import { getWidget } from '../../actions/home'
import EmailSend from '../../common/EmailSend'
const apiToken = localStorage.getItem('access_token')

class InstallationSettings extends Component {
  state = {
    previewLink: null,
    script_text: null,
    isLoading: true,
    widgetInstalled: 0,
    open: false,
    devEmail: '',
    preview_link: null,
    widget_id: null,
    codeError: false,
    GtmStatus: 0,
    GtmIntegration: 'Waiting',
    url: '',
    value: 0
  }

  componentDidUpdate(prevProps) {
    if (this.props.widget !== prevProps.widget) {
      this.props.loading(false)
      this.setState({ isLoading: false })
      localStorage.setItem('data-api-key', this.props.widget.data[0].script_id)
      let previewLink = ''
      if (
        `${process.env.REACT_APP_BASE_APP_URL}` === 'https://app.limecall.com'
      ) {
        previewLink = 'https://' + this.props.widget.data[0].preview_link
      } else {
        previewLink = this.props.widget.data[0].preview_link
      }

      let code = `<script data-api-url="${process.env.REACT_APP_BASE_APP_URL}" data-api-key="${this.props.widget.data[0].script_id}" src="https://widget.limecall.com/widget.js"> </script>`
      this.setState({
        widgetInstalled: this.props.widget.data[0].installed,
        widget_id: this.props.widget.data[0].id,
        scriptId: this.props.widget.data[0].script_id
      })
      this.setState({
        script_text: code,
        isLoading: false,
        previewLink: previewLink
      })
    }

    if (this.props.userIntegrations !== prevProps.userIntegrations) {
      const c = this.props.userIntegrations.data.find(element => {
        return element.integration === 'Google Tag manager'
      })

      if (c) {
        this.setState({ GtmStatus: 1 })
      }
    }
  }

  componentDidMount() {
    this.fetchUrl()
    this.loadWidget()
    if (this.props.userIntegrations) {
      const c = this.props.userIntegrations.data.find(element => {
        return element.integration === 'Google Tag manager'
      })

      if (c) {
        this.setState({ GtmStatus: 1 })
      }
    } else {
      this.fetchUserIntegrations()
    }
  }

  openModal = () => {
    this.setState({ open: true })
  }

  fetchUrl = () => {
    let { url } = this.state
    const host = window.location.hostname
    if (host === 'localhost') {
      const port = window.location.port
      url = `//${host}:${port}`
    } else {
      url = `//${host}`
    }
    this.setState({ url })
  }

  sendCode = () => {
    if (this.state.devEmail == '') {
      CommonNotify('Please Enter Email Id', 'warning')
      return
    }

    this.props.loading(true)

    let data = {
      developer_email: this.state.devEmail,
      widget_script: this.state.script_text
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/send-code-to-developer`

    axios
      .post(url, data)
      .then(res => {
        this.props.loading(false)
        if (res.data.errors.length == 0) {
          this.setState({ devEmail: '', open: false })
          CommonNotify('Js Code snippet Send successfully', 'success')
        }
      })
      .catch(err => {
        this.props.loading(false)
        CommonNotify('Cant Send Email System Error occured')
      })
  }

  copyCode = () => {
    var copyText = this.state.script_text
    const el = document.createElement('textarea')
    el.value = copyText
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    CommonNotify('Text Copied', 'success')
  }

  loadWidgetPreview = () => {
    this.props.loading(true)
    this.setState({ isLoading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/install?widget_id=${this.state.widget_id}`

    axios
      .get(url)
      .then(res => {
        this.props.loading(false)
        if (res.data.data) {
          this.setState({
            previewLink: res.data.data.preview_link.split('value=')[1],
            widget_id: res.data.data.id
          })
          localStorage.setItem('widget_id', res.data.data.id)
        }
      })
      .catch(er => {
        this.props.loading(false)
        //CommonNotify('Cant Fetch Widget Link')
      })
  }
  loadWidget = () => {
    if (this.props.widget) {
      this.props.loading(false)
      this.setState({ isLoading: false })
      localStorage.setItem('data-api-key', this.props.widget.data[0].script_id)
      let previewLink = ''
      if (
        `${process.env.REACT_APP_BASE_APP_URL}` === 'https://app.limecall.com'
      ) {
        previewLink = 'https://' + this.props.widget.data[0].preview_link
      } else {
        previewLink = this.props.widget.data[0].preview_link
      }

      let code = `<script data-api-url="${process.env.REACT_APP_BASE_APP_URL}" data-api-key="${this.props.widget.data[0].script_id}" src="https://widget.limecall.com/widget.js"> </script>`
      this.setState({
        widgetInstalled: this.props.widget.data[0].installed,
        widget_id: this.props.widget.data[0].id,
        scriptId: this.props.widget.data[0].script_id
      })
      this.setState({
        script_text: code,
        isLoading: false,
        previewLink: previewLink
      })
    } else {
      this.props.loading(true)
      this.setState({ isLoading: true })
      this.props.getWidget()
    }
    /* 
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`

    axios
      .get(url)
      .then(res => {
        this.props.loading(false)
        if (res.data.data[0]) {
          localStorage.setItem('data-api-key', res.data.data[0].script_id)
          let previewLink = ''
          if(`${process.env.REACT_APP_BASE_APP_URL}` ==="https://app.limecall.com"){
           
            previewLink = "https://" + res.data.data[0].preview_link;
          }else{
            previewLink = res.data.data[0].preview_link;
          }
         
          let code = `<script data-api-url="${process.env.REACT_APP_BASE_APP_URL}" data-api-key="${res.data.data[0].script_id}" src="https://widget.limecall.com/widget.js"> </script>`
          this.setState({
            widgetInstalled: res.data.data[0].installed,
            widget_id: res.data.data[0].id,
            scriptId: res.data.data[0].script_id,
            codeError: codeError
          })
          this.setState({ script_text: code, isLoading: false, previewLink:previewLink })
         
        }
      })
      .catch(er => {
        this.props.loading(false)
        //CommonNotify('Cant Fetch Widget Details')       
      }) */
  }

  onCopyText = async jsCode => {
    try {
      await navigator.clipboard.writeText(jsCode)
      CommonNotify('Copied!', 'success')
    } catch (err) {
      CommonNotify('Failed to copy!')
    }
  }

  async componentWillMount() {
    const query = window?.location?.href
    const code = query?.split('code=')[1]?.split('&')[0]
    const state = query?.split('state=')[1]?.split('&')[0]

    if (!!code) {
      this.integrateGTM()
    }
  }

  integrateGTM = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/get-user-gtm-access`

    const query = window?.location?.href
    const state = decodeURIComponent(query?.split('state=')[1]?.split('&')[0])
    const code = decodeURIComponent(query?.split('code=')[1]?.split('&')[0])
    const scope = decodeURIComponent(
      query?.split('scope=')[1]?.split('&')[0]
    ).replaceAll('+', ' ')
    const authuser = query?.split('authuser=')[1]?.split('&')[0]
    const prompt = query?.split('prompt=')[1]?.split('&')[0]
    var bodyFormData = new FormData()
    bodyFormData.append('state', state)
    bodyFormData.append('code', code)
    bodyFormData.append('scope', scope)
    bodyFormData.append('authuser', authuser)
    bodyFormData.append('prompt', prompt)

    axios
      .post(url, bodyFormData)
      .then(res => {
        this.fetchUserIntegrations()
      })
      .catch(error => {})
  }

  fetchUserIntegrations = () => {
    this.props.getUserIntegrations()
    /* 
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/user-integrations`

    axios
      .get(url)
      .then(res => {
        const c = res.data.data.find((element) => {
          return element.integration === "Google Tag manager";
        })
      
        if(c)
        {
         
          this.setState({GtmStatus: 1})
        }
      })
      .catch(er => {
       
      }) */
  }

  showNotify = async () => {
    CommonNotify('GTM already connected.')
  }

  handleOpen = () => {
    switch ('googleTagManager') {
      case 'googleTagManager':
        window.open(
          `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=561194678958-bpdro47f9k0vub52o06rsiisqbqt2n8i.apps.googleusercontent.com&redirect_uri=${process.env.REACT_APP_ROOT_URL}/settings/installation/installation&scope=openid%20profile%20email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ftagmanager.manage.users%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ftagmanager.edit.containerversions%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ftagmanager.manage.accounts%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ftagmanager.publish%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ftagmanager.edit.containers%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ftagmanager.readonly&response_type=code&state=vXBndr4LysfVdjDjbPMGZi5yFq6cSG6GJpEyn0qE&flowName=GeneralOAuthFlow`,
          '_blank'
        )
        break
      default:
        return
    }
  }

  handleChangeTab = (event, newValue) => {
    this.setState({
      value: newValue
    })
  }

  handleRedirectPreview = () => {
    const href = `${this.state.url}/widget-new-preview/${this.state.scriptId}`
    let theLink = document.createElement('a')
    theLink.href = href
    theLink.target = '_blank'
    theLink.click()
  }
  handleManageTitle = () => {
    switch (this.state.value) {
      case 0:
        return (
          <div className="heading">
            <h3>Important step: Install the Limecall widget on your website</h3>
          </div>
        )
      case 3:
        return (
          <div className="heading">
            <h3>Install Limecall on Wix</h3>
            <span></span>
          </div>
        )
      case 2:
        return (
          <div className="heading">
            <h3>Install LimeCall on Google Tag Manager</h3>
          </div>
        )
      case 1:
        return (
          <div className="heading">
            <h3>Install LimeCall on Wordpress</h3>
          </div>
        )
      case 4:
        return (
          <div className="heading">
            <h3>Install Limecall on Weebly</h3>
          </div>
        )
      case 5:
        return (
          <div className="heading">
            <h3>Install Limecall on Squarespace</h3>
          </div>
        )

      case 6:
        return (
          <div className="heading">
            <h3>Install Limecall on Webflow</h3>
          </div>
        )

      case 7:
        return (
          <div className="heading">
            <h3>Install Limecall on Webhook</h3>
          </div>
        )

      default:
        return null
    }
  }

  handleManageSectionContant = () => {
    switch (this.state.value) {
      case 0:
        return (
          <>
            <div className="intallation_deatil">
              <p className="subtext">
                The script is essential for LimePhone to generate callback and
                capture leads and the companies visiting your site . It will
                only take 2 mins.
              </p>
              <CommonButtons
                style={{ cursor: 'default' }}
                content={
                  this.state.widgetInstalled == 1
                    ? 'Script Installed'
                    : 'Script Not Installed'
                }
                background="blue"
              />
            </div>
            <div>
              <Form>
                {!this.state.codeError && (
                  <>
                    <Icon
                      name="copy"
                      onClick={() => this.onCopyText(this.state.script_text)}
                    />
                    <TextArea
                      rows={5}
                      disabled
                      value={this.state.script_text}
                      className="call-textarea"
                    />
                  </>
                )}

                {this.state.codeError && (
                  <>
                    <Icon
                      name="reload"
                      onClick={() => this.onCopyText(this.state.script_text)}
                    />
                    <TextArea
                      rows={5}
                      disabled
                      value={'Script Code not loaded'}
                      className="call-textarea"
                    />
                  </>
                )}
              </Form>
              <div className="installation-footer">
                <div>
                  <CommonButtons
                    onClick={this.openModal}
                    content="Email Code"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                  <CommonButtons
                    onClick={this.handleRedirectPreview}
                    content="Preview Link"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                </div>
                <CommonButtons
                  onClick={this.copyCode}
                  content="Copy Code"
                  background="blue"
                  disabled={this.state.codeError}
                />
              </div>
            </div>
          </>
        )
      case 1:
        return (
          <>
            <div className="intallation_deatil">
              <p className="subtext">
                <p>
                  1.Copy the LimePhone Tracker script
                  <script> </script>Copy Script
                </p>
                <p>
                  2.Within your Wix dashboard open Settings > Custom Code
                  Advanced section
                </p>
                <p>3.Click on "Add Custom Code"</p>
                <p>
                  {' '}
                  4.Paste the tracking code, name it e.g "LimePhone", select "All
                  pages", select place code in "Head", click "Apply"
                </p>
                <p>
                  {' '}
                  5.Finished all the steps? To verify we'll open your website to
                  create a visit. If the tracker is correctly installed, we will
                  redirect you to LimePhone in few seconds.
                </p>
              </p>
              <CommonButtons
                style={{ cursor: 'default' }}
                content={
                  this.state.widgetInstalled == 1
                    ? 'Script Installed'
                    : 'Script Not Installed'
                }
                background="blue"
              />
            </div>
            <div>
              <Form>
                {!this.state.codeError && (
                  <>
                    <Icon
                      name="copy"
                      onClick={() => this.onCopyText(this.state.script_text)}
                    />
                    <TextArea
                      rows={5}
                      disabled
                      value={this.state.script_text}
                      className="call-textarea"
                    />
                  </>
                )}

                {this.state.codeError && (
                  <>
                    <Icon
                      name="reload"
                      onClick={() => this.onCopyText(this.state.script_text)}
                    />
                    <TextArea
                      rows={5}
                      disabled
                      value={'Script Code not loaded'}
                      className="call-textarea"
                    />
                  </>
                )}
              </Form>
              <div className="installation-footer">
                <div>
                  <CommonButtons
                    onClick={this.openModal}
                    content="Email Code"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                  <CommonButtons
                    onClick={this.handleRedirectPreview}
                    content="Preview Link"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                </div>
                <CommonButtons
                  onClick={this.copyCode}
                  content="Copy Code"
                  background="blue"
                  disabled={this.state.codeError}
                />
              </div>
            </div>
          </>
        )
      // case 2:
      //   return (
      //     <>
      //       <div className="intallation_deatil">
      //         <p className="subtext">
      //           <span>
      //             As GoDaddy has a few different products and options, not all
      //             with the ability to add custom scripts, we strongly recommend
      //             installing the Limecall tracker on your GoDaddy site by using
      //             Google Tag Manager. If that is not an option, please refer to{' '}
      //             <a
      //               target="_blank"
      //               href="https://help.leadfeeder.com/en/articles/3790396-i-use-a-website-builder-how-do-i-install-tracker"
      //             >
      //               this guide
      //             </a>{' '}
      //             or contact GoDaddy support. Our support team is always happy
      //             to help whenever possible.
      //           </span>
      //         </p>
      //   <CommonButtons
      //   style={{ cursor: 'default' }}
      //   content={
      //     this.state.widgetInstalled == 1
      //       ? 'Script Installed'
      //       : 'Script Not Installed'
      //   }
      //   background="blue"
      // />
      //       </div>
      //     </>
      //   )
      case 2:
        return (
          <>
            <div className="intallation_deatil">
              <p className="subtext">
                <p>1. Copy the LimePhone Tracker script</p>
                <p>
                  {
                    '2.Log in to Weebly, then go to Settings > SEO > Header Code'
                  }
                </p>
                <p>3.Click on "Add Custom Code"</p>
                <p>
                  {' '}
                  4. Paste the tracking code, name it as "LimePhone", select "All
                  pages", select place code in "Head", click "Apply"
                </p>
              </p>
              <CommonButtons
                style={{ cursor: 'default' }}
                content={
                  this.state.widgetInstalled == 1
                    ? 'Script Installed'
                    : 'Script Not Installed'
                }
                background="blue"
              />
            </div>
            <div>
              <Form>
                {!this.state.codeError && (
                  <>
                    <Icon
                      name="copy"
                      onClick={() => this.onCopyText(this.state.script_text)}
                    />
                    <TextArea
                      rows={5}
                      disabled
                      value={this.state.script_text}
                      className="call-textarea"
                    />
                  </>
                )}

                {this.state.codeError && (
                  <>
                    <Icon
                      name="reload"
                      onClick={() => this.onCopyText(this.state.script_text)}
                    />
                    <TextArea
                      rows={5}
                      disabled
                      value={'Script Code not loaded'}
                      className="call-textarea"
                    />
                  </>
                )}
              </Form>
              <div className="installation-footer">
                <div>
                  <CommonButtons
                    onClick={this.openModal}
                    content="Email Code"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                  <CommonButtons
                    onClick={this.handleRedirectPreview}
                    content="Preview Link"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                </div>
                <CommonButtons
                  onClick={this.copyCode}
                  content="Copy Code"
                  background="blue"
                  disabled={this.state.codeError}
                />
              </div>
            </div>
          </>
        )
      case 5:
        return (
          <>
            <div className="intallation_deatil">
              <p className="subtext">
                <p>
                  1.Google Tag Manager should already be installed on your site
                  before you follow these instructions
                </p>
                <p>
                  2.If you don't have Google Tag Manager installed yet you can
                  find out how in the{' '}
                  <a
                    href="https://support.google.com/tagmanager/answer/6103696?hl=en"
                    target="_blank"
                  >
                    Google Tag Manager support article about installing it
                  </a>
                  .
                </p>
                <p>
                  3.Sign in to Google Tag Manager, and click on the Add a New
                  Tag button. Next, click where it says{' '}
                  <b>Choose a tag type to begin setup</b> and select{' '}
                  <b>Custom HTML</b> from the list. Then, Copy & Paste the code
                  following code into the text field:
                </p>
                <p>
                  {' '}
                  4.Next, click <b>
                    Choose a trigger to make this tag fire...
                  </b>{' '}
                  and select <b>All Pages</b>. Give the tag a name and then
                  click the Save button.
                </p>
                <p> 5.Finally submit and publish changes.</p>
              </p>
              <CommonButtons
                style={{ cursor: 'default' }}
                content={
                  this.state.widgetInstalled == 1
                    ? 'Script Installed'
                    : 'Script Not Installed'
                }
                background="blue"
              />
            </div>
            <div>
              <Form>
                {!this.state.codeError && (
                  <>
                    <Icon
                      name="copy"
                      onClick={() => this.onCopyText(this.state.script_text)}
                    />
                    <TextArea
                      rows={5}
                      disabled
                      value={this.state.script_text}
                      className="call-textarea"
                    />
                  </>
                )}

                {this.state.codeError && (
                  <>
                    <Icon
                      name="reload"
                      onClick={() => this.onCopyText(this.state.script_text)}
                    />
                    <TextArea
                      rows={5}
                      disabled
                      value={'Script Code not loaded'}
                      className="call-textarea"
                    />
                  </>
                )}
              </Form>
              <div className="installation-footer">
                <div>
                  <CommonButtons
                    onClick={this.openModal}
                    content="Email Code"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                  <CommonButtons
                    onClick={this.handleRedirectPreview}
                    content="Preview Link"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                </div>
                <CommonButtons
                  onClick={this.copyCode}
                  content="Copy Code"
                  background="blue"
                  disabled={this.state.codeError}
                />
              </div>
            </div>
          </>
        )
      case 6:
        return (
          <>
            <div className="intallation_deatil">
              <p className="subtext">
                <p>1. Copy the LimePhone Tracker script</p>
                <p>
                  2. Log in to Wordpress, then install a plugin for header code
                  injection e.g. "Head, Footer and Post injections"
                </p>
                <p>3. {`Go to Settings > Header and Footer`}</p>
                <p> 4. Paste the tracking code to section: "HEAD"</p>
                <p>
                  {' '}
                  5. Finished all the steps? To verify we'll open your website
                  to create a visit. If the tracker is correctly installed, you
                  will be able to see the widget on your website
                </p>
                <p>
                  {' '}
                  6. For Wordpress, we recommend installing the{' '}
                  <a
                    href="https://wordpress.org/plugins/header-footer/"
                    target="_blank"
                  >
                    Head, Footer and Post Injections{' '}
                  </a>{' '}
                  plugin from Stefano Lissa. After installing and activating the
                  plugin, navigate to <b>Settings → Header and Footer</b> in
                  your WordPress dashboard. You’ll see a number of tabs in the
                  plugin’s interface. Make sure you're in the default 'Head and
                  footer' tab, then copy & paste the following code snippet:
                </p>
              </p>
              <CommonButtons
                style={{ cursor: 'default' }}
                content={
                  this.state.widgetInstalled == 1
                    ? 'Script Installed'
                    : 'Script Not Installed'
                }
                background="blue"
              />
            </div>
            <div>
              <Form>
                {!this.state.codeError && (
                  <>
                    <Icon
                      name="copy"
                      onClick={() => this.onCopyText(this.state.script_text)}
                    />
                    <TextArea
                      rows={5}
                      disabled
                      value={this.state.script_text}
                      className="call-textarea"
                    />
                  </>
                )}

                {this.state.codeError && (
                  <>
                    <Icon
                      name="reload"
                      onClick={() => this.onCopyText(this.state.script_text)}
                    />
                    <TextArea
                      rows={5}
                      disabled
                      value={'Script Code not loaded'}
                      className="call-textarea"
                    />
                  </>
                )}
              </Form>
              <div className="installation-footer">
                <div>
                  <CommonButtons
                    onClick={this.openModal}
                    content="Email Code"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                  <CommonButtons
                    onClick={this.handleRedirectPreview}
                    content="Preview Link"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                </div>
                <CommonButtons
                  onClick={this.copyCode}
                  content="Copy Code"
                  background="blue"
                  disabled={this.state.codeError}
                />
              </div>
            </div>
          </>
        )

        default:
          return (
            <>
              <div className="intallation_deatil">
              <p className="subtext">
              Integrate any web or mobile application through webhooks with limecall. Receive real time inbound lead notifications and trigger automatic call backs using limecall
  </p>
  
  
                <p className="subtext">
                1. Setup a new inbound webhook <a href="https://v2dev.limecall.com/settings/api/inbound_webhook">https://v2dev.limecall.com/settings/api/inbound_webhook</a>
                </p>
                <p className="subtext">
  2. Copy this hook url in your app
                </p>
                <p className="subtext">
               3. Receive leads
                </p>
                {/* <CommonButtons
                  style={{ cursor: 'default' }}
                  content={
                    this.state.widgetInstalled == 1
                      ? 'Script Installed'
                      : 'Script Not Installed'
                  }
                  background="blue"
                /> */}
              </div>
              {/* <div>
                <Form>
                  {!this.state.codeError && (
                    <>
                      <Icon
                        name="copy"
                        onClick={() => this.onCopyText(this.state.script_text)}
                      />
                      <TextArea
                        rows={5}
                        disabled
                        value={this.state.script_text}
                        className="call-textarea"
                      />
                    </>
                  )}
  
                  {this.state.codeError && (
                    <>
                      <Icon
                        name="reload"
                        onClick={() => this.onCopyText(this.state.script_text)}
                      />
                      <TextArea
                        rows={5}
                        disabled
                        value={'Script Code not loaded'}
                        className="call-textarea"
                      />
                    </>
                  )}
                </Form>
                <div className="installation-footer">
                  <div>
                    <CommonButtons
                      onClick={this.openModal}
                      content="Email Code"
                      background="blue"
                      disabled={this.state.codeError}
                    />
                    <CommonButtons
                      onClick={this.handleRedirectPreview}
                      content="Preview Link"
                      background="blue"
                      disabled={this.state.codeError}
                    />
                  </div>
                  <CommonButtons
                    onClick={this.copyCode}
                    content="Copy Code"
                    background="blue"
                    disabled={this.state.codeError}
                  />
                </div>
              </div> */}
            </>
          )
      }
    }
  render() {
    return (
      <>
        <div className="installation-wrapper installation_main">
          {this.handleManageTitle()}
          <Tabs
            value={this.state.value}
            onChange={this.handleChangeTab}
            aria-label="icon label tabs example"
          >
            <Tab icon={<WebIcon />} label="Single page app" />
            <Tab icon={<WordIcon />} label="WordPress" />
            <Tab icon={<GoogleTag />} label="Google Tag Manager" />
            <Tab icon={<Wix />} label="Wix" />
            {/* <Tab icon={<GoDaddy />} label="GoDaddy" /> */}
            <Tab icon={<Weebly />} label="Weebly" />
            <Tab icon={<SquaredSpace />} label="Squarespace" />
            <Tab icon={<WebFlow />} label="Webflow" />
     
          
            <Tab icon={<CodeBracket />} label="Webhook" />
          </Tabs>
          <div className="installation-content intallation_code">
            {this.handleManageSectionContant()}
          </div>
          {(this.state.value === 1 || this.state.value === 2 )&&
          <div className="installation-content">
            <div>
              <p className="subtext">Or Install Through</p>
            </div>
            <div>
              <a
                onClick={
                  this.state.GtmStatus ? this.showNotify : this.handleOpen
                }
              >
                <img src={logoSetting} alt="logo" />
              </a>
              <a
                href="https://limecall.com/wp-content/themes/limecall/plugin/limecall.zip"
                download
                rel="noopener noreferrer"
              >
                <img src={wordpress} alt="wordpress" />
              </a>
            </div>
          </div>
  }
          {this.state.open && <EmailSend script={this.state.script_text} open={this.state.open} onClose={()=>this.setState({open:!this.state.open})}/>}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  userIntegrations: state.settings.userIntegrations,
  widget: state.home.widget
})

export default connect(mapStateToProps, { getUserIntegrations, getWidget })(
  InstallationSettings
)
