export const dayLabels = [
  '',
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
]

export const dayData = [
  0,
  2,
  1,
  4,
  0,
  6,
  2,
  0,
  1,
  0,
  1,
  1,
  3,
  4,
  6,
  2,
  4,
  1,
  0,
  2,
  2,
  0,
  1,
  0,
  1
]

export const weekLabels = [
  '',
  '23/03/29',
  '23/04/2019',
  '23/05/2019',
  '23/06/2019',
  '23/07/2019',
  '23/08/2019',
  '23/10/2019'
]

export const weekData = [0, 10, 3, 9, 4, 5, 8, 2]

export const monthLabels = ['', '1st Week', '2nd Week', '3rd Week', '4th Week']

export const monthData = [0, 10, 8, 10, 9]
