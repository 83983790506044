import React, { useState, useEffect } from 'react'
import { Grid, Image, Dropdown } from 'semantic-ui-react'
import StarRatings from 'react-star-ratings'
import {
  _getLeadActivities,
  _getLeadNotes,
  _onTagsSaveHandler  
} from '../../../../config/leadAPI'


import axios from 'axios'

import { CommonNotify } from '../../../../common/CommonNotify'



import plusIcon from '../../../../assets/images/add.svg'
import callIcon from '../../../../assets/images/call.svg'
import Record from '../../../../assets/images/mic.png'
import clockIcon from '../../../../assets/images/clock.svg'
import backIcon from '../../../../assets/images/back-button.svg'
import userIcon from '../../../../assets/images/user.svg'

import transferIcon from '../../../../assets/images/transfer.svg'
import priceIcon from '../../../../assets/images/price-tag.svg'
import groupIcon from '../../../../assets/images/group.svg'
import chatIcon from '../../../../assets/images/chat.svg'
import starIcon from '../../../../assets/images/star.svg'
import penIcon from '../../../../assets/images/pen.svg'
import QualifyFooter from './QualifyFooter'
const Tags = [
  {
    key: 1,
    text: 'VIP',
    value: 1
  },
  {
    key: 2,
    text: 'NEW',
    value: 2
  },
  {
    key: 3,
    text: 'SOMETHING',
    value: 3
  }
]
const LeadAboutRight = ({ leadData, leadScore }) => {
  

  const [leadUpdated, setLeadUpdated] = useState(true);

  const [leadDetail, setLeadDetail] = useState( { message: leadData.lead.messages_within_call, email: leadData.lead.email, name: leadData.lead.customer_name } )


  const updateText = (e, index) => {
    let obj = {...leadDetail} 
    obj[index] = e.target.value;
    setLeadDetail(obj) 
 }

  const saveLeadDetail = () => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/rate-call`
  
  axios
    .post(URL, { feedback: leadDetail.message , lead_id: leadData.lead.id , name: leadDetail.name, email: leadDetail.email, rating: leadData.lead.customer_feedback_rate })
    .then(res => {
              
    })
    .catch(err => {
      CommonNotify('Not able to update call data')      
    })

  }


  useEffect(() => {
    let lUpdated = !leadUpdated
    setLeadUpdated(lUpdated)  

  }, [leadData])


  const trimUrl = (url = "") => {
     
    if(!url || url == "") return ""; 
    const newUrl = url.split("/", 3);
    if(!url.length) return "";

   
    url = newUrl[newUrl.length-1]    
    return url;         
  }

  const leads = leadData?.lead



  return (
    <div className="contact-card aboutTabpane">
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Lead Form :</h4>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Name:</p>
            </Grid.Column>
            <Grid.Column width={10}>              
               <p className="card-boxes-right-text">{leadDetail.name}</p> 
              
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Email:</p>
            </Grid.Column>
            <Grid.Column width={10}>
            <p className="card-boxes-right-text">{leadDetail.email}</p> 
              
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Message:</p>
            </Grid.Column>
            <Grid.Column width={10}>
            <p className="card-boxes-right-text">{leadDetail.message} </p>
              
            </Grid.Column>
          </Grid.Row>{' '}
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Referral:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <a className="card-boxes-right-text" href={'https://' + leads?.referral} target="_blank">{trimUrl(leads?.referral )}</a>             
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>{' '}
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Call Data:</h4>{' '}
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Time:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{leads?.createdAtDate} {leads?.createdAtTime} </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Timezone:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">UTC</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Status:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              {' '}
              {leads?.final_status == 'failed_to_connect_agent' ? (
                <p>Failed to Connect</p>
              ) : null }
              {
                  leads?.final_status == 'no_agents_available' ? (
                    <p>No Agents Available</p>
                  ): null
              }
              

              {leads?.final_status == 'completed' ? <p>connected</p> : ''}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Duration:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{leads?.duration > 0 ? 60*leads?.duration : 0 } Sec</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Recording :</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">

                  {leads?.recording_url ? ( 
                  <audio controls>
                    <source src={leads?.recording_url} type="audio/mp3"/>
                  </audio> ) : 
                  (<p>                     
                        Not Available
                  </p>)}

              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Team:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{leads?.team}</p>
            </Grid.Column>
          </Grid.Row>{' '}
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Handled by:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {' '}
                {leads?.owner?.first_name} {'  '}
                {leads?.owner?.last_name}
                {!leads?.owner ? 'Not Assigned' : ''}       
              </p>             

            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Source</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <a href={'https://' + leads?.source} target='_blank'>{trimUrl(leads?.source)}</a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>{' '}
     
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Agent Feedback:</h4>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Agent Rating:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {' '}
                <StarRatings
                  rating={!leads?.agent_feedback_rate ? 0 : leads?.agent_feedback_rate }
                  starRatedColor="#f78030"
                  numberOfStars={5}
                  name="rating"
                />
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Feedback:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{leads?.agent_feedback_text }</p>
            </Grid.Column>
          </Grid.Row>        
        </Grid>
      </div>{' '}


      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>After Call Lead Feedback:</h4>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Lead Rating:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">
                {' '}
                <StarRatings
                  rating={!leads?.customer_feedback_rate ? 0 : leads?.customer_feedback_rate}
                  starRatedColor="#f78030"
                  numberOfStars={5}
                  name="rating"
                />
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="card-boxes-left-text">Feedback:</p>
            </Grid.Column>
            <Grid.Column width={10}>
              <p className="card-boxes-right-text">{leads?.customer_feedback_text}</p>
            </Grid.Column>
          </Grid.Row>        
        </Grid>
      </div>{' '}


      <QualifyFooter leadUpdated={leadUpdated} leadData={leadData.lead} leadScore={leadScore}/>
    </div>
  )
}

export default LeadAboutRight
