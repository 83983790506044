import React, { useState, useEffect } from 'react'
import { Button, Dimmer, Form, Loader, Modal } from 'semantic-ui-react'

import Autosuggest from 'react-autosuggest'

import CommonInput from '../../common/CommonInput'
import ReactPhoneInput from 'react-phone-input-2'
import closeIcon from '../../assets/images/close-set.svg'
import { billingCountryList } from '../../helpers/billingCountryList'

const intialCompanyData = [
  {
    city: '',
    country: 'Afghanistan',
    name: 'Purpulerain',
    organization_name: 'Purpulerain',
    owner: '',
    phone_country: '',
    phone_number: '',
    company: '',
    type: 'organization'
  },

  {
    city: '',
    country: 'Afghanistan',
    name: 'Betacloud',
    organization_name: 'Betacloud',
    owner: '',
    phone_country: '',
    phone_number: '',
    company: '',
    type: 'organization'
  }
]

const EditDetailOpen = ({
  setDetailOpen,
  editDetailOpen,
  handleInputChange,
  editContact,
  onclickOwner,
  companyList,
  loading
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [value, setValue] = useState('')
  // const [companyList, setCompanyList] = useState(intialCompanyData)

  useEffect(() => {
    //console.info({editContact})
    setValue(editContact.organization_name)
  }, [editContact.organization_name])

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    return inputLength === 0
      ? []
      : companyList.filter(
          lang =>
            lang?.organization_name?.toLowerCase().slice(0, inputLength) ===
            inputValue
        )
  }

  const onChange = (event, { newValue }) => {
    setValue(newValue)
  }

  const getSuggestionValue = suggestion => suggestion.name

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const inputProps = {
    placeholder: 'Organization name',
    value,
    onChange: onChange
  }

  const renderSuggestion = suggestion => (
    <div className="autocomplete-items">{suggestion.organization_name}</div>
  )

  return (
    <Modal open={editDetailOpen} className="editModel">
      <Dimmer active={loading} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      <Modal.Header className="editHead">
        <h4>Edit Company </h4>
        <img src={closeIcon} onClick={() => setDetailOpen(false)} />
      </Modal.Header>
      <Form className="fromEdit" onSubmit={e => onclickOwner(e)}>
        <div className="contactWrap">
          <div className="profileMain">
            <div className="inputProfile">
              <Form.Field>
                {/* <label>First Name</label>
                <CommonInput
                  type="text"
                  className="form-control"
                  onChange={e => handleInputChange(e)}
                  name="name"
                  placeholder="Satish"
                  value={editContact.name}
                /> */}
                {/* <label>Last Name</label>
                <CommonInput
                  type="text"
                  className="form-control"
                  onChange={e => handleInputChange(e)}
                  name="name"
                  placeholder="dd"
                  value={editContact.name}
                /> */}
                <label>E-mail Address</label>
                <CommonInput
                  onChange={e => handleInputChange(e)}
                  placeholder="enter email address"
                  name="email"
                  type="email"
                  autocomplete="off"
                  value={editContact.email}
                />
                <label>Phone number</label>
                <ReactPhoneInput
                  inputExtraProps={{
                    name: 'phone_number',
                    required: true,
                    autoFocus: true
                  }}
                  defaultCountry={'sg'}
                  value={editContact.phone_number}
                  onChange={(e, c) => handleInputChange(e, c, 'phone_number')}
                />{' '}
                <label>Country</label>
                <select
                  name="country"
                  onChange={e => handleInputChange(e)}
                  value={editContact.country?.toString()?.toLocaleLowerCase()}
                  placeholder="country"
                >
                  {billingCountryList.map((country, key) => (
                    <option
                      key={key}
                      value={country.text?.toString()?.toLocaleLowerCase()}
                    >
                      {country.text}
                    </option>
                  ))}
                </select>
                <label>City</label>
                <CommonInput
                  onChange={e => handleInputChange(e)}
                  placeholder="city"
                  name="city"
                  type="city"
                  value={editContact.city}
                />
                <label>Company Owner</label>
                <select
                  name="owner"
                  type="owner"
                  value={editContact?.owner_id}
                  placeholder="Owner list"
                  onChange={e => handleInputChange(e)}
                >
                  <option>{editContact?.owner_name}</option>
                </select>{' '}
                {/* <CommonInput
                  onChange={e => handleInputChange(e)}
                  placeholder="Company Owner"
                  name="owner"
                  type="owner"
                  value={editContact.owner}
                /> */}
                <label>Organization Name </label>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                />
                {/* <CommonInput
                  onChange={e => handleInputChange(e)}
                  placeholder="Organization Name"
                  name="organization_name"
                  type="organization_name"
                  value={editContact.organization_name}
                /> */}
              </Form.Field>
            </div>
          </div>
        </div>
        <div className="btnMain">
          <Button onClick={() => setDetailOpen(false)}>Cancel</Button>
          <Button className="btnSave">Save</Button>
        </div>
      </Form>
    </Modal>
  )
}
export default EditDetailOpen
