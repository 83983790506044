import React, {useState} from 'react'
import likeIcon from '../../../../assets/images/like.png'
import dislikeIcon from '../../../../assets/images/dislike.png'

import { 
  onChangeIntersetedVal
} from '../../../../config/leadAPI'


const QualifyFooter = ({ leadData, leadScore }) => { 

  const processNegetiveArray = item => {
    const negetiveItems = ['dis', 'nege', 'bad', '0', 'false', 'spam', 'no'];
    let flag = false;
    negetiveItems.forEach(element => { 
      if(item.value.toString().toLowerCase().includes(element.toString().toLowerCase()) || item.text.toString().toLowerCase().includes(element.toString().toLowerCase())){  
        flag = true
        return
      }
    });
    return flag;
  }

  const processPositiveArray = item => {
    const negetiveItems = ['qualified', 'posi', 'good', '1', 'true', 'lead', 'won', 'customer', 'opportunity', 'yes'];
    let flag = false;
    negetiveItems.forEach(element => { 
      if(item.value.toString().toLowerCase().includes(element.toString().toLowerCase()) || item.text.toString().toLowerCase().includes(element.toString().toLowerCase())){  
        flag = true
        return
      }
    });
    return flag;
  }


     
  const [likeDislike, setLikeDislike] = useState(leadData.interseted)   
   
     return (<></>)


  // return (
  //   <div className="aboutQualify">
  //     <div className="qualifywrap">
        
  //       <div className="qualify">
  //         <div>
  //           {likeDislike == 1 ?  (
              
  //                 <div onClick={ e => {onChangeIntersetedVal( e, { value: 3 } , [leadData.id] ); setLikeDislike(3)} }>
  //                   <img src={dislikeIcon} alt="" />{' '}
  //                   <label className="redFont">Bad Fit</label>
  //                 </div>
             
  //           ) : (
  //             ''
  //           )}
         
  //         {likeDislike == 2 ? (
  //           <div className="like-or-dislike" style={{ display:"flex" }}> 
  //               <div className="like" style={{ display: "flex", marginRight: "20px", padding: "15px 5px", lineHeight: "16px" }} onClick={ e => {onChangeIntersetedVal( e, { value: 1 } , [leadData.id] );  setLikeDislike(1) } }>
  //                 <img src={likeIcon} alt="" />{' '}
  //                 <label className="greenFont">Good Fit</label>
  //               </div>  
  //               <div className="dislike"  style={{ display: "flex", marginLeft: "20px",  padding: "15px 5px", lineHeight: "16px"  }} onClick={ e => {onChangeIntersetedVal( e, { value: 3 } , [leadData.id] );  setLikeDislike(3) }}>
  //                 <img src={dislikeIcon} alt="" />{' '}
  //                 <label className="redFont">Bad Fit</label>
  //               </div>
  //           </div>
  //         ) : ('')}

  //           {likeDislike == 3 ?  (
           
  //               <div className="like" onClick={ e => {onChangeIntersetedVal( e, { value: 1 } , [leadData.id] );  setLikeDislike(1) } }>
  //                 <img src={likeIcon} alt="" />{' '}
  //                 <label className="greenFont">Good Fit</label>
  //               </div> 
            
  //           ) : (
  //             ''
  //           )}
              

            
  //       </div>
  //     </div>
  //     </div>
  //   </div>
  // )
}
export default QualifyFooter
